//Convertemos a data de UTC para o tempo local
export function ConvertDateToLocalDate(value): string {
    var date = "";
    try {

        //Se a DataFim for do tipo date
        if (IsDate(value)) {
            var tmpDate = new Date(value).toLocaleDateString();
            var tmpHour = new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            date = tmpDate + " " + tmpHour;
        } else {
            //Se não for uma data colocamos o valor que chegou da API
            date = value;
        }
        return date;
    } catch (Exp) {
        return date;
    }
}

export var IsDate = (function isDate(value: any) {
    switch (typeof value) {
        case 'number':
            return true;
        case 'string':
            return !isNaN(Date.parse(value));
        case "object":
            if (value instanceof Date) {
                return !isNaN(value.getTime());
            } else {
                return false;
            }
        default:
            return false;
    }
});


// Handle HTTP errors since fetch won't.
export function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export function IsValidNumber(value) {
    if (typeof value === "undefined" || value == null || value.trim() == "") return false;

    var valueParsed = parseFloat(value);
    if (isNaN(valueParsed) === true) return false;
    return true;
}