import { Col, Row } from "react-bootstrap";

export default function ErrorLoadingCart(props: any) {
    return (
        <div style={{ marginTop: "50px" }}>
            <Row>
                <Col>
                    <h3 style={{ fontWeight: "500", color: "red" }}>{props.errorMessage}</h3>
                </Col>
            </Row>
        </div>
    )
}