import React, { Component } from "react";
import CountUp from "react-countup";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { getClientsNumbers } from "../../../actions/userActions";

class NumeroClientes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberClientes: 0
    };
  }

  componentDidMount() {
    getClientsNumbers().then(data => {      
      if (data[0] && !data.message) {

        var item1 = data.findIndex(item => {
          return item.ID_TipoCliente == "CF";
        });
        
        var number = 0;

        if (item1 !== -1)  number = data[item1].number;
                
        this.setState({ numberClientes: number });
      }
    });
  }
  render() {
    return (
      <Col md="4">
        <div className="card mb-3 widget-chart">
          <div className="icon-wrapper rounded-circle">
            <div className="icon-wrapper-bg bg-primary" />
            <i className="lnr-users text-primary" />
          </div>
          <div className="widget-numbers">
            <CountUp start={0} end={this.state.numberClientes} duration="3" />
          </div>
          <div className="widget-subheading">Total de clientes</div>
        </div>
      </Col>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user,
  pesquisa: state.pesquisa
});

export default connect(mapStateToProps)(NumeroClientes);
