import cx from "classnames";
import React, { Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { getUpline, getUser } from "../../actions/userActions";
import Logo from "./Components/Logo";
import MegaMenu from "./Components/MegaMenu";
import SearchBox from "./Components/SearchBox";
import UserBox from "./Components/UserBox";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.dispatch(getUser());
    this.props.dispatch(getUpline());

    this.googleTranslateElementInit();
  }

  googleTranslateElementInit() {
    if (typeof google === 'undefined' || typeof google.translate === 'undefined' || typeof google.translate.TranslateElement === 'undefined') {
      setTimeout(() => {
        this.googleTranslateElementInit();
      }, 1000);
      return;
    }
    new google.translate.TranslateElement({ pageLanguage: 'pt', layout: google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element');
  }

  showOriginal() {
    var iframe = document.getElementsByClassName('goog-te-banner-frame')[0];
    if (!iframe) return;

    var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    var restore_el = innerDoc.getElementsByTagName("button");

    for (var i = 0; i < restore_el.length; i++) {
      if (restore_el[i].id.indexOf("restore") >= 0) {
        restore_el[i].click();
        var close_el = innerDoc.getElementsByClassName("goog-close-link");
        close_el[0].click();
        return;
      }
    }
  }


  render() {
    let {
      headerBackgroundColor,
      enableMobileMenuSmall,
      enableHeaderShadow
    } = this.props;
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          className={cx("app-header", headerBackgroundColor, {
            "header-shadow": enableHeaderShadow
          })}
          transitionName="HeaderAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Logo />

          <div
            className={cx("app-header__content", {
              "header-mobile-open": enableMobileMenuSmall
            })}
          >
            <div className="app-header-left">
              <SearchBox />
              <MegaMenu />
            </div>
            <div id="google_translate_element"></div>
            <button className="google_original_language" onClick={this.showOriginal}>Idioma Original</button>
            <div className="app-header-right">
              {/*<HeaderDots/>*/}
              <UserBox />
            </div>
          </div>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  enableHeaderShadow: state.enableHeaderShadow,
  closedSmallerSidebar: state.closedSmallerSidebar,
  headerBackgroundColor: state.headerBackgroundColor,
  enableMobileMenuSmall: state.enableMobileMenuSmall
});

export default connect(mapStateToProps)(Header);
