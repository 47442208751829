import { TextField } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addObservacao } from "../../actions/checkoutActions";
import "./Checkout.css";

export default function Observacao() {
	const dispatch = useDispatch();

	const observacoes = useSelector((state: any) => state.checkoutData.observacoes);

	const handleChange = async (event) => {
		await dispatch(addObservacao(event.target.value));
	};

	return (
		<div className="checkoutBox">
			<Row style={{ minHeight: "100%" }}>
				<Col xs={12} sm={12} md={12} lg={12}>
					<div className="observacaoBox" >
						<div className="checkoutBoxTitle">
							Observações
						</div>

						<TextField name="observacoes"
							fullWidth={true}
							multiline={true}
							rows={2}
							rowsMax={8}
							value={observacoes}
							onChange={handleChange}
							placeholder={"Insira algumas notas relevantes para a encomenda"}
						/>
					</div>
					<br />
					<br />
				</Col>
			</Row>
		</div>
	)
}