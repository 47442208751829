import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import { annualAccumulatedBilling } from "../../../actions/comparativosActions";
import { getContacts } from "../../../actions/userActions";
import ContactosCliente from "../../../helpers/ContactosCliente";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class FaturacaoAcumulada extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0,
      contactos: []
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    annualAccumulatedBilling().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
      columns: [
        {
          Header: "Nome",
          accessor: "NomeCompleto",
          resizable: false,
          style: { display: "block", textAlign: "center" }
        },
        {
          Header: "1º Trimestre",
          accessor: "FacturacaoTrimestre1",
          resizable: false,
          width: 150,
          style: { display: "block", textAlign: "center" },
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.FacturacaoTrimestre1}
                style="currency"
                currency="EUR"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: "2º Trimestre",
          accessor: "FacturacaoTrimestre2",
          resizable: false,
          width: 150,
          style: { display: "block", textAlign: "center" },
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.FacturacaoTrimestre2}
                style="currency"
                currency="EUR"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: "3º Trimestre",
          accessor: "FacturacaoTrimestre3",
          resizable: false,
          width: 150,
          style: { display: "block", textAlign: "center" },
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.FacturacaoTrimestre3}
                style="currency"
                currency="EUR"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: "4º Trimestre",
          accessor: "FacturacaoTrimestre4",
          resizable: false,
          width: 150,
          style: { display: "block", textAlign: "center" },
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.FacturacaoTrimestre4}
                style="currency"
                currency="EUR"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        },
        {
          Header: "Total",
          accessor: "Total",
          resizable: false,
          width: 150,
          style: { display: "block", textAlign: "center" },
          Cell: row => {
            return (
              <FormattedNumber
                value={row.original.Total}
                style="currency"
                currency="EUR"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            );
          }
        }
      ]
      });
      this.setState({ blocking: false });
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  getUserContacts(id) {
    getContacts(id).then(data => {
      if (data && !data.message) {
        this.setState({ contactos: data });
        this.toggle();
      }
    });
  }
  render() {
    return (
      <span>
        <ContactosCliente
          onRef={ref => (this.child = ref)}
          isOpen={this.state.modal}
          toggle={this.toggle}
          contactos={this.state.contactos}
        />
        <SimpleTable
          data={this.state.data}
          columns={this.state.columns}
          blocking={this.state.blocking}
          rowNumber={this.state.rowNumber}
          dataSize={this.state.dataSize}
          noDataText={"Sem faturações para mostrar"}
          title={"Facturação Acumulada"}
        />
      </span>
    );
  }
}

export default FaturacaoAcumulada;
