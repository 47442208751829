// 18-07-2019 - AFONSO - Funcao para login
export function loginFunction(email, password) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return fetch("/user/login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          Email: email,
          Password: password
        })
      })
        .then(res => res.json())
        .then(json => {
          dispatch(loginAccount(json.user));
          resolve(json);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

// 18-07-2019 - AFONSO - Funcao para obter um user
export function getUser() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return fetch("/user/getUser", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        credentials: "include"
      })
        .then(res => res.json())
        .then(json => {
          dispatch(loginAccount(json));
          resolve(json);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

// 18-07-2019 - AFONSO - Funcao para obter um user
export function checkLogin() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter subscricoes um cliente
export function getSubscriptions() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getSubscriptions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter protocolos um cliente
export function getProtocols() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getProtocols", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter vales um cliente
export function getVouchers() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getVouchers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter mediacenter um cliente
export function getMediaCenter() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getMediaCenter", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter mediacenter um cliente
export function changeUser(id_cliente) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return fetch("/user/changeUser", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        credentials: "include",
        body: JSON.stringify({
          id_cliente: id_cliente
        })
      })
        .then(res => res.json())
        .then(json => {
          dispatch(uplineFunction(json));
          resolve(json);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

// 18-07-2019 - AFONSO - Funcao para obter um user
export function getUpline() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return fetch("/user/getUpline", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        credentials: "include"
      })
        .then(res => res.json())
        .then(json => {
          dispatch(uplineFunction(json));
          resolve(json);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

// 18-07-2019 - AFONSO - Funcao para logout
export function logout() {
  return new Promise((resolve, reject) => {
    return fetch("/user/logout", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter clientes de um cliente
export function getClientsNumbers() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getClientsNumbers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 25-07-2019 - AFONSO - Funcao para obter clientes da rede de um cliente
export function getClientsNumbersRede() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getClientsNumbersRede", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 25-07-2019 - AFONSO - Funcao para obter numero de clientes de cada tipo
export function clientType() {
  return new Promise((resolve, reject) => {
    return fetch("/user/clientType", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 26-07-2019 - AFONSO - Funcao para obter perfil de um cliente
export function getClientDetailsByID() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getClientDetailsByID", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getLoggedInUser() {
  return new Promise((resolve, reject) => {
    return fetch("/user/getLoggedInUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


export function updateClientDetails(sonhos, preferencias) {
  return new Promise((resolve, reject) => {
    return fetch("/user/updateClientDetails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        Sonhos: sonhos,
        Preferencias: preferencias
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


// 30-07-2019 - AFONSO - Funcao para obter nivel de negocio dos consultores do cliente
export function consultorPin() {
  return new Promise((resolve, reject) => {
    return fetch("/user/consultorPin", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 30-07-2019 - AFONSO - Funcao para numero de promotores/afiliados sem encomendas
export function prorafWithNoOrders(ID_TipoCliente) {
  return new Promise((resolve, reject) => {
    return fetch("/user/prorafWithNoOrders", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_TipoCliente: ID_TipoCliente
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
// 30-07-2019 - AFONSO - Funcao para obter clientes sem encomendas
export function ClientesNoOrders() {
  return new Promise((resolve, reject) => {
    return fetch("/user/ClientesNoOrders", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function ClientsOrders() {
  return new Promise((resolve, reject) => {
    return fetch("/user/ClientsOrders", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 31-07-2019 - AFONSO - Funcao para obter contactos de um cliente
export function getContacts(id_cliente) {
  return new Promise((resolve, reject) => {
    return fetch("/user/getContacts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify({
        ID_Cliente: id_cliente
      })
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function statsAskToShowConsultantDetails(idConsultant) {
  return (dispatch, getState) => {    
    dispatch(statsShowDetails(idConsultant));    
  };
}

export function getUserCheckoutDefaultValues() {
  return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
          return fetch("/user/getCheckoutDefaultValues", {
              method: "POST",
              headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json"
              },
              credentials: "include",
          })
              .then(res => res.json())
              .then(json => {

                  if (json.success === true) {
                      dispatch(setUserCheckoutDefaultValues(json.obj));
                  }

                  resolve(json);
                  return json;
              })
              .catch(error => {
                  var response = {
                      success: false,
                      message: "Erro ao conectar com servidor. Por favor tente mais tarde."
                  }

                  resolve(response);
              });
      });
  };
}

export function addPhoneNumber(phonenumber) {
  return new Promise((resolve, reject) => {
      return fetch("/user/addContact", {
          method: "POST",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
          },
          credentials: "include",
          body: JSON.stringify({
              ID_TipoContacto: 2,
              Descricao: phonenumber,
              Principal: "N"
          })
      })
          .then(res => res.json())
          .then(json => {
              resolve(json);
              return json;
          })
          .catch(error => console.log(error.message));
  });
}

export function getPhoneNumbers() {
  return new Promise((resolve, reject) => {
      return fetch("/user/getContactsByContactType", {
          method: "POST",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
          },
          credentials: "include",
          body: JSON.stringify({
              TipoContacto: 2,
          })
      })
          .then(res => res.json())
          .then(json => {
              resolve(json);
              return json;
          })
          .catch(error => console.log(error.message));
  });
}

export function updateDefaultPaymentType(paymentType, defaultTelephone) {
  return new Promise((resolve, reject) => {
      return fetch("/user/updateDefaultPaymentType", {
          method: "POST",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
          },
          credentials: "include",
          body: JSON.stringify({
              PaymentType: paymentType,
              DefaultTelephone: defaultTelephone != null && typeof defaultTelephone != 'undefined' ? defaultTelephone : ""
          })
      })
          .then(res => res.json())
          .then(json => {
              resolve(json);
              return json;
          })
          .catch(error => {
              var response = {
                  success: false,
                  message: "Erro ao conectar com servidor. Por favor tente mais tarde."
              }
              resolve(response);
          });
  });
}

export function updateDefaultTransportType(transportType) {
  return new Promise((resolve, reject) => {
      return fetch("/user/updateDefaultTransportType", {
          method: "POST",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
          },
          credentials: "include",
          body: JSON.stringify({
              TransportType: transportType,
          })
      })
          .then(res => res.json())
          .then(json => {
              resolve(json);
              return json;
          })
          .catch(error => {
              var response = {
                  success: false,
                  message: "Erro ao conectar com servidor. Por favor tente mais tarde."
              }
              resolve(response);
          });
  });
}


export const LOGIN = "LOGIN";
export const GETUPLINE = "GETUPLINE";
export const loginAccount = user => ({
  type: LOGIN,
  payload: { user }
});
export const uplineFunction = upline => ({
  type: GETUPLINE,
  payload: { upline }
});


export const STATS_CONSULTANT_DETAILS = "STATS_CONSULTANT_DETAILS";
export const statsShowDetails = statsConsultantDetails => ({
  type: STATS_CONSULTANT_DETAILS,
  payload: { statsConsultantDetails }
});

export const USER_CHECKOUT_DEFAULT_VALUES = "USER_CHECKOUT_DEFAULT_VALUES";
export const setUserCheckoutDefaultValues = userCheckoutDefaultValues => ({
    type: USER_CHECKOUT_DEFAULT_VALUES,
    payload: { userCheckoutDefaultValues }
})