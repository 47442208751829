import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { alterarDadosPesquisa, getDadosPesquisa } from "../../actions/pesquisaActions";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import Campos from "./Components/Campos";
import Resultado from "./Components/Resultado";

class Pesquisa extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.pesquisarData = this.pesquisarData.bind(this);
  }

  pesquisarData(data, primeiraVez) {
    this.child.pesquisar(data, primeiraVez);
  }

  componentDidMount() {
    getDadosPesquisa().then(data => {
      if (data) {
        this.pesquisarData(data, "primeiraVez");
        alterarDadosPesquisa();
      }
    });
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Pesquisa"
            subheading="Página onde pode pesquisar os seus clientes"
            icon="pe-7s-graph icon-gradient bg-ripe-malin"
          />
          <Campos pesquisarFuncao={this.pesquisarData} />
          <Resultado onRef={ref => (this.child = ref)} />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}
const mapStateToProp = state => {
  return {
    pesquisa: state.pesquisa
  };
};

export default withRouter(connect(mapStateToProp)(Pesquisa));
