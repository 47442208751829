import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { getContacts, statsAskToShowConsultantDetails } from "../../../actions/userActions";
import ContactosCliente from "../../../helpers/ContactosCliente";
import PageTitleAlt2 from "../../../helpers/Layout/PageTitleAlt2";
import TableStats from "./Components/TableStats";
import ConversionStats from "./ConversionStats";

class AnaliseLeads extends Component {
    constructor() {
        super();
        this.state = {
            contactos: [],
            modal: false
        };

        this.toggle = this.toggle.bind(this);
    }

    //Abrir e fechar o modal
    toggle() {
        this.setState({
            modal: !this.state.modal
        }, (state) => {
            if (this.state.modal === false) {
                this.props.dispatch(statsAskToShowConsultantDetails(null));
            }
        });
    }

    //Quando os componentes atualizam
    componentDidUpdate(prevProps) {

        //Se o consultor para abrir for diferente de null e do anterior abrimos
        if (this.props.consultantToOpen !== prevProps.consultantToOpen && this.props.consultantToOpen != null) {
            this.getUserContacts(this.props.consultantToOpen);
        }
    }

    //Método para ir buscar as informações do cliente
    getUserContacts(id) {
        getContacts(id).then(data => {
            if (data && !data.message) {
                this.setState({ contactos: data });
                this.toggle();
            }
        });
    }

    render() {
        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
                    <PageTitleAlt2
                        heading="Análise de Leads"
                        subheading="Página onde pode analisar as estatísticas sobre as suas Leads"
                        icon="pe-7s-graph icon-gradient bg-ripe-malin"
                    />
                    <Row className="main-card mb-6">
                        <ContactosCliente onRef={ref => (this.child = ref)} isOpen={this.state.modal}
                            toggle={this.toggle} contactos={this.state.contactos} />

                        <Col md="12">
                            <TableStats leadType='CS' title={"Análise de Dados de Leads CS"} />
                            <br />
                            <TableStats leadType='BC' title={"Análise de Dados de Leads B2C"} />
                            <br />
                            <TableStats leadType='BB' title={"Análise de Dados de Leads B2B"} />
                            <br />
                            <ConversionStats leadType='CS' title={"Estatísticas de Conversão de Leads CS"} />
                            <br />
                            <ConversionStats leadType='BC' title={"Estatísticas de Conversão de Leads B2C"} />
                            <br />
                            <ConversionStats leadType='BB' title={"Estatísticas de Conversão de Leads B2B"} />
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    consultantToOpen: state.statsConsultantDetails
});

export default connect(mapStateToProps)(AnaliseLeads);

