import React, { Component } from "react";
import { FormattedDate } from "react-intl";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

const columns = [
  {
    Header: "Estado de Encomenda",
    accessor: "EstadoEncomenda",
    resizable: false,
    style: { display: "block", textAlign: "center" },
    Cell: row => {
      return row.original.EstadoEncomenda ? row.original.EstadoEncomenda : "-";
    }
  },
  {
    Header: "Data",
    accessor: "data",
    resizable: false,
    style: { display: "block", textAlign: "center" },
    Cell: row => {
      return row.original.data ? (
        <FormattedDate value={new Date(row.original.data)} />
      ) : (
        "-"
      );
    }
  },
  {
    Header: "Observação",
    accessor: "observacao",
    resizable: false,
    style: { display: "block", textAlign: "center" },
    Cell: row => {
      return row.original.observacao ? row.original.observacao : "Não existem observações";
    }
  },
  {
    Header: "Email Enviado",
    accessor: "enviado",
    resizable: false,
    style: { display: "block", textAlign: "center" },
    Cell: row => {
      return row.original.enviado ? row.original.enviado : "-";
    }
  }
]
class EstadosEncomenda extends Component {
  
  render() {
    return (
      <span>
        <SimpleTable
          data={this.props.states}
          columns={columns}
          blocking={this.props.isLoading}
          rowNumber={this.props.states.length > 5 ? 10 : 5}
          dataSize={this.props.states.length}
          noDataText={"Sem estados da encomenda para apresentar"}
          title={"Evolução da encomenda"}
        />
      </span>
    );
  }
}

export default EstadosEncomenda;
