import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, FormGroup, Label } from "reactstrap";
import ItemLead from "./ItemLead";
import './LeadsByEvent.css';

{/*Styles */ }
const headerTextStyle = {
    textAlign: "center",
    color: "#575FD9",
    fontSize: "20px",
    flex: 1,
    fontWeight: "600"
};

const headerLeftInfoStyle = {
    fontWeight: "600",
    color: "#707070",
    flex: 1,
    textAlign: "left",
    margin: "5px",
    fontSize: "15px"
}

const headerRightInfoStyle = {
    fontWeight: "600",
    color: "#707070",
    flex: 1,
    textAlign: "right",
    margin: "5px",
    fontSize: "15px"
}

export class LeadsByEvent extends Component {

    constructor() {
        super();
        this.getDateDiff = this.getDateDiff.bind(this);
    }

    render() {
        let {
            eventData
        } = this.props;

        return (
            <Col className="cardStyle" lg={3} md={4} sm={12} xs={11} >
                <FormGroup style={{ backgroundColor: "white", border: "1px solid #707070", borderRadius: "8px" }}>
                    <Row>
                        <Col md={12} style={{ display: "flex", alignContent: "center", alignItems: "center" }}><Label for="examplePassword" style={headerTextStyle}> {eventData.EventoNome} </Label></Col>
                    </Row>
                    <Row>
                        <Col style={{ display: "flex", alignItems: "left" }}><Label for="examplePassword" className="mr-sm-2" style={headerLeftInfoStyle}>{eventData.EventoNumLeads}</Label></Col>
                        <Col style={{ display: "flex", alignItems: "right", alignContent: "right" }}><Label for="examplePassword" className="mr-sm-2" style={headerRightInfoStyle}>{eventData.EventoValorLeads}€</Label></Col>
                    </Row>
                </FormGroup>

                {this.props.eventData.EventoDados.map(dados => {
                    return <ItemLead lead={dados} key={dados.Lead} id={dados.Lead} flash={dados.Flash} leadType={this.props.leadType} />
                })}
            </Col>
        )
    }

    //Função que retorna consoante a data da lead, o tempo que a lead já existe
    getDateDiff(leadDate) {

        //Vamos buscar a data atual em segundos
        var currentTimeInMs = Date.now() / 1000;

        //Vamos buscar a data da lead em segundos
        var leadDateInMs = (new Date(leadDate)).getTime() / 1000;

        //Calculamos a diferença entre as datas
        var differenceInSeconds = currentTimeInMs - leadDateInMs;

        //Significa que já passou mais do que 1 dia
        if (differenceInSeconds > 86400) return ((parseInt(differenceInSeconds / 86400)) + " dias");

        //Significa que já passou mais do que 1 hora
        if (differenceInSeconds > 3600) return ((parseInt(differenceInSeconds / 3600)) + " horas");

        //Significa que já passou mais do que 1 minuto
        if (differenceInSeconds > 60) return ((parseInt(differenceInSeconds / 60)) + " minutos");

        return "Mesmo agora";
    }
}

const mapStateToProps = state => {
    return {

    };
};

export default connect(mapStateToProps)(LeadsByEvent)
