import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import Arvore from "./Components/Arvore";

class Rede extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Rede"
            subheading="Página onde pode ver a sua rede"
            icon="pe-7s-graph icon-gradient bg-ripe-malin"
          />
          <Arvore />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}
const mapStateToProp = state => {
  return {};
};

export default withRouter(connect(mapStateToProp)(Rede));
