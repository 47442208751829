import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap";
import { LeadsByEvent } from "./LeadsByEvent";

class FunilLeads extends Component {
    constructor(props) {
        super(props);

        var hidden =  "initial";
        var show ="none";
        this.state = {
            hidden: hidden,
            show: show,
        };
    }

    render() {
        return (
            <span>
                {/* Lista de leads por evento */}
                <Card classNames="main-card mb-6" style={{ overflowX: "auto", marginBottom: "30px" }}>
                    <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">{" "}</i>
                            {this.props.title}
                        </div>
                        <div className="btn-actions-pane-right">
                            <Button className="mr-2 btn-icon btn-icon-only" outline color="danger" onClick={() => { this.setState({ hidden: "none", show: "initial" }); }} style={{ display: this.state.hidden }}>
                                <i
                                    className="lnr-circle-minus"
                                    style={{ display: this.state.hidden }}
                                    onClick={() => {
                                        this.setState({
                                            hidden: "none",
                                            show: "initial"
                                        });
                                    }}
                                />
                            </Button>
                            <Button className="mr-2 btn-icon btn-icon-only" outline color="success" onClick={() => {
                                this.setState({ hidden: "initial", show: "none" });
                            }}
                                style={{ display: this.state.show }}
                            >
                                <i className="lnr-plus-circle" style={{ display: this.state.show }} onClick={() => {
                                    this.setState({
                                        hidden: "initial",
                                        show: "none"
                                    });
                                }}
                                />
                            </Button>
                        </div>
                    </CardHeader>

                    <CardBody style={{ display: this.state.hidden, maxHeight: "600px", overflowY: "auto" }}>
                        <Row className="flex-nowrap">
                            {this.props.eventsWithLeads.length > 0 ?
                                (this.props.eventsWithLeads.map(element => {
                                    return <LeadsByEvent eventData={element} leadType={this.props.leadType} ></LeadsByEvent>
                                }))
                                : <Col>
                                    <Label>Não existem registos neste momento</Label>
                                </Col>
                            }
                        </Row>
                    </CardBody>
                </Card>
            </span >
        );
    }

}

const mapStateToProps = state => {
    return {
        modalIsOpen: state.modalIsOpen,
        funisData: state.funisData
    };
};

export default withRouter(connect(mapStateToProps)(FunilLeads));