export const MainNav = [
  {
    icon: "pe-7s-home",
    label: "Visão Geral",
    to: "/"
  },
  {
    icon: "pe-7s-id",
    label: "Perfil",
    to: "/perfil"
  },
  {
    icon: "pe-7s-shopbag",
    label: "Encomendas",
    to: "/encomendas"
  },
  {
    icon: "pe-7s-cart",
    label: "Fazer encomenda",
    to: "/cart"
  },
  {
    id: "leads",
    icon: "lnr-briefcase",
    label: "Negócios (Leads)",
    to: "/negocios"
  },
  {
    icon: "lnr-briefcase",
    label: "Negócios",
    to: "/negocios",
    parentId: "leads"
  },
  {
    icon: "pe-7s-users",
    label: "Nova Lead",
    to: "/leads/novaLead",
    parentId: "leads"
  },
  {
    icon: "pe-7s-users",
    label: "Lista Leads",
    to: "/leads/listaLeads",
    parentId: "leads"
  },
  {
    icon: "pe-7s-users",
    label: "Análise Leads",
    to: "/leads/analise",
    parentId: "leads"
  },
  {
    icon: "pe-7s-users",
    label: "Pesquisar Leads",
    to: "/leads/pesquisa",
    parentId: "leads"
  },
  {
    icon: "pe-7s-users",
    label: "Leads Perdidas Por Consultor",
    to: "/leads/leadsPerdidasPorConsultor",
    parentId: "leads"
  },
  {
    icon: "lnr-diamond",
    label: "Comissões",
    to: "/comissoes"
  },
  {
    icon: "lnr-rocket",
    label: "Oportunidades",
    to: "/oportunidades"
  },
  {
    icon: "pe-7s-graph2",
    label: "Indicadores",
    to: "/indicadores"
  },
  {
    id: "comparativos",
    icon: "pe-7s-edit",
    label: "Comparativos",
    to: "/comparativos"
  },
  {
    id: "posicaoConsultores",
    icon: "lnr-chart-bars",
    label: "Posição Consultores",
    to: "/posicaoConsultores"
  }
];

export const RedeNav = [
  {
    icon: "pe-7s-users",
    label: "Clientes",
    to: "/rede/clientes"
  },
  {
    icon: "pe-7s-users",
    label: "Afiliados",
    to: "/rede/afiliados"
  },
  {
    icon: "pe-7s-users",
    label: "Promotores",
    to: "/rede/promotores"
  },
  {
    icon: "pe-7s-users",
    label: "Consultores",
    to: "/rede/consultores"
  }
  // {
  //   icon: "pe-7s-plugin",
  //   label: "Estrutura da Rede",
  //   to: "/rede/estrutura"
  // }
];

export const ContactosNav = [
  {
    icon: "pe-7s-users",
    label: "Contactos",
    to: "/contactos/contactos"
  },
  /*{
    icon: "pe-7s-users",
    label: "Empresas",
    to: "/contactos/empresas"
  }*/
];

export const MarketingNav = [
  {
    icon: "pe-7s-users",
    label: "Contactos",
    to: "/marketing/contactos"
  },
  /*{
    icon: "pe-7s-users",
    label: "Empresas",
    to: "/contactos/empresas"
  },*/
  {
    icon: "pe-7s-users",
    label: "Leads",
    to: "/marketing/listaLeads"
  }
];

export const LeadsNav = [

];
