import moment from "moment";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { FormattedNumber } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SortingRule } from 'react-table';
import { Button, Col, Row } from "reactstrap";
import { fetchCart, incrementarPackCart, incrementarProdutoCart } from "../../../actions/cartActions";
import { getByNameOrId } from "../../../actions/productActions";
import SearchInputComponent from "../../../helpers/SearchComponents/SearchInputComponent";
import TableComponent from "../../Common/TableComponent";
import Toast from "../../../helpers/Toast";
import GoogleAnalytics, { GoogleAnalyticsProduct } from "../../../helpers/googleAnalytics";

interface Products {
    ID_Produto: number,
    ID_TipoProduto: string,
    Descricao: string,
    price: number,
    Tipo: string,
    DataInicio: Date,
    DataFim: Date
}

interface ProductQuantity {
    ID_Produto: number,
    Quantity: number
}

export default function PesquisaProdutos() {
    const [isSearchingProduct, setIsSearchingProduct] = useState<boolean>(false);
    const [productId, setProductId] = useState<string | null>(null);
    const [productName, setProductName] = useState<string | null>(null);
    const [data, setData] = useState<Products[]>([]);
    const [productQuantity, setProductQuantity] = useState<ProductQuantity[]>([]);
    const [totalPages, setTotalPages] = useState(1);
    const pageSizes: number[] = [5, 10, 15, 30];
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    const [sortOrder, setSortOrder] = useState<SortingRule<object>[]>([{ id: "ID_Produto", desc: false }])
    const [totalProducts, setTotalProducts] = useState<number>(0);
    const upline = useSelector((state: any) => state.upline); //Se estiver no seu user, aparece a sua informação. Se estiver num cliente em rede aparece a informação desse cliente

    const successAddItem = useSelector((state: any) => state.addItemCartSuccess);
    const addItemMessage = useSelector((state: any) => state.addItemCartMessage);

    var dispatch = useDispatch();

    const googleAnalytics: GoogleAnalytics = new GoogleAnalytics();

    const columns = React.useMemo(
        () => [
            {
                Header: "ID",
                accessor: "ID_Produto",
                width: 100,
                resizable: false
            },
            {
                Header: "Descricao",
                resizable: false,
                width: isMobile ? 500 : 1000,
                Cell: (row: any) => {
                    var descricao = row.original.Descricao;
                    if (row.original.DataInicio !== null && row.original.DataFim !== null) {
                        var dataInicio = moment(row.original.DataInicio).format("DD-MM-YYYY");
                        let dataFim = moment(row.original.DataFim).format("DD-MM-YYYY");

                        descricao += ` Data Início: ${dataInicio} - Data Fim: ${dataFim}`;
                    }
                    return (
                        <label>
                            {descricao}
                        </label>
                    )
                }
            },
            {
                Header: "Preço",
                // accessor: "price",
                sortable: false,
                resizable: false,
                width: 100,
                Cell: (row: any) => {
                    return (
                        <FormattedNumber value={row.original.price ?? 0}
                            style="currency"
                            currency={upline.ID_Moeda}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    )
                }
            },
            {
                Header: "Quantidade",
                sortable: false,
                resizable: false,
                width: 100,
                Cell: (row: any) => {
                    var productQuantityIndex = productQuantity.findIndex(a => a.ID_Produto == row.original.ID_Produto);
                    var value: string = "0";
                    if (productQuantityIndex !== -1) {
                        value = productQuantity[productQuantityIndex].Quantity.toString();
                    }
                    return (
                        <SearchInputComponent key={"quantity"}
                            inputType={"number"}
                            onChange={(e) => { setQuantity(row.original.ID_Produto, e.target.value) }}
                            initialValue={"0"}
                            value={value}
                            showLabel={false}
                            min={0} />
                    )
                }
            }, {
                Header: "Total",
                sortable: false,
                resizable: false,
                width: 100,
                Cell: (row: any) => {
                    var index: number = productQuantity.findIndex(a => a.ID_Produto == row.original.ID_Produto);
                    var price = index !== -1 ? productQuantity[index].Quantity * row.original.price : 0
                    return (
                        <FormattedNumber value={price ?? 0}
                            style="currency"
                            currency={upline.ID_Moeda}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                        />
                    )
                }
            },
            {
                sortable: false,
                resizable: false,
                width: isMobile ? 125 : 175,
                Cell: (row: any) => {
                    var index: number = productQuantity.findIndex(a => a.ID_Produto == row.original.ID_Produto);
                    var show: boolean = false;
                    if (typeof index !== 'undefined' && index !== null && index !== -1) {
                        if (productQuantity[index].Quantity > 0) {
                            show = true;
                        }
                    }
                    return (
                        <Button color="primary"
                            disabled={isSearchingProduct === true || show == false}
                            style={{ fontSize: "18px", width: "100%" }}
                            onClick={() => addToCart(row.original.ID_Produto, productQuantity[index].Quantity)}>Adicionar</Button>
                    )
                }
            }
        ],
        [isSearchingProduct, productQuantity],
    );

    const setQuantity = (idProduto: number, quantity: string) => {
        var productQuantityIndex = productQuantity.findIndex(a => a.ID_Produto == idProduto);
        //Se existir no array, então removo
        //Desta forma, a dependência que está nas colunas vai ser executada e vai atualizar a quantidade 
        if (productQuantityIndex !== -1) {
            productQuantity.splice(productQuantityIndex, 1);
        }

        var pQ: ProductQuantity = {
            ID_Produto: idProduto,
            Quantity: parseInt(quantity)
        };
        setProductQuantity((oldState) => [...oldState, pQ]);
    }

    const searchProduct = async (page: number, pageSize: number, sortOrderToApply: SortingRule<object>[]) => {
        setIsSearchingProduct(true);
        var ordenacao: string, ordenacaoAsc: string;

        if (typeof sortOrderToApply === 'undefined' || sortOrderToApply == null || sortOrderToApply.length == 0) {
            sortOrderToApply = [{ id: "ID_Produto", desc: false }];
        }

        ordenacao = sortOrderToApply[0].id;
        ordenacaoAsc = sortOrderToApply[0].desc == false ? "true" : "false";

        var resultSearchProduct = await getByNameOrId(productId, productName, page, pageSize, ordenacao, ordenacaoAsc);
        setIsSearchingProduct(false);

        if (resultSearchProduct.success === false) {
            Toast.Show("error", resultSearchProduct.message);
            setData([]);
            setTotalPages(1);
            setTotalProducts(0);
            setCurrentPage(1);
            setPageSize(pageSize);
            return;
        }

        setData(resultSearchProduct.obj?.products == null ? [] : resultSearchProduct.obj.products);
        setTotalPages(resultSearchProduct.obj?.totalPages == null ? 1 : resultSearchProduct.obj.totalPages);
        setTotalProducts(resultSearchProduct.obj?.totalProducts == null ? 1 : resultSearchProduct.obj.totalProducts)
        setCurrentPage(page);
        setPageSize(pageSize);
        setSortOrder(sortOrderToApply);
    }

    const addToCart = async (idProduto: number, quantityToAdd: number) => {
        var indexProduto: number = data.findIndex(a => a.ID_Produto == idProduto);
        if (indexProduto === -1) {
            Toast.Show("error", "Ocorreu um erro ao obter o produto para adicionar ao carrinho.");
            return;
        }

        var produto: Products = data[indexProduto];
        if (produto.Tipo.toUpperCase() === "PRODUTO") {
            dispatch(incrementarProdutoCart(idProduto, quantityToAdd, "ADD"));
            var product: GoogleAnalyticsProduct = {
                ID_Pack: null,
                ID_TipoPack: null,
                ID_Produto: idProduto + "",
                ID_TipoProduto: produto.ID_TipoProduto,
                Quantidade: quantityToAdd + "",
                Descricao: produto.Descricao,
                Preco: produto.price + ""
            };
            googleAnalytics.AddProductToCart(product);
        }
        else {
            dispatch(incrementarPackCart(idProduto, quantityToAdd));
            var pack: GoogleAnalyticsProduct = {
                ID_Pack: idProduto + "",
                ID_TipoPack: produto.ID_TipoProduto,
                ID_Produto: null,
                ID_TipoProduto: null,
                Quantidade: quantityToAdd + "",
                Descricao: produto.Descricao,
                Preco: produto.price + ""
            };
            googleAnalytics.AddProductToCart(pack);
        }

        var getCart: any = await dispatch(fetchCart());
        if (getCart.success === true) {
            googleAnalytics.SendCartToGA(getCart.obj);
        };
    }

    useEffect(() => {
        if (addItemMessage !== null && addItemMessage !== "") {
            if (successAddItem === true) {
                Toast.Show("success", addItemMessage);
            }
            else {
                Toast.Show("error", addItemMessage);
            }
        }
    }, [addItemMessage])

    return (
        <>
            <Row>
                <Col>
                    {/* Pesquisar por id do produto */}
                    <SearchInputComponent key={"productId"}
                        inputType={"text"}
                        labelText={"Id do Produto"}
                        onChange={(e) => setProductId(e.target.value == "" ? null : e.target.value)}
                        initialValue={""} />
                </Col>
                <Col>
                    {/* Pesquisar por nome do produto */}
                    <SearchInputComponent key={"productName"}
                        inputType={"text"}
                        labelText={"Nome do Produto"}
                        onChange={(e) => setProductName(e.target.value.trim() == "" ? null : e.target.value)}
                        initialValue={""} />
                </Col>
            </Row>

            {/* Pesquisar produto */}
            <Row style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", marginTop: "1em" }}>
                <Col md={4}>
                    <Button color="primary"
                        disabled={isSearchingProduct}
                        style={{ fontSize: "18px", width: "100%" }}
                        onClick={() => { setProductQuantity([]); searchProduct(0, pageSize, sortOrder) }}>Pesquisar</Button>
                </Col>
            </Row>

            <Row style={{ paddingTop: "2em" }}>
                <Col>
                    <TableComponent
                        currentPage={currentPage}
                        totalPages={totalPages}
                        pageSize={pageSize}
                        countData={totalProducts}
                        pageSizes={pageSizes}
                        isLoading={isSearchingProduct}
                        columns={columns}
                        data={data}
                        sortOrder={sortOrder}
                        fetchData={searchProduct}
                    />
                </Col>
            </Row>
        </>
    )
}