import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BlockUi from "react-block-ui";
import { FormattedDate } from "react-intl";
import { Loader } from "react-loaders";
import { Slide, toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap";
import { getClientDetailsByID, getLoggedInUser, updateClientDetails } from "../../../actions/userActions";

const headerTextStyle = {
  fontSize: "18px",
  textAlign: "center",
  color: "#495057"
};

const valueTextStyle = {
  fontSize: "16px",
  textAlign: "center"
};

class InformacaoPessoal extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      detalhes: {
        DataNascimento: '',
        DataRegisto: '',
        DataValidade: '',
        Descricao: '',
        Email: '',
        ID_Cliente: '',
        Localidade: '',
        NomeCompleto: '',
        Particular: '',
        Preferencias: '',
        Sonhos: '',
        ClienteConfirmado: '',
        ClienteRecNome: '',
        Creditos: '',
        TipoCliente: ''
      },
      inEditMode: false,
      canBeEdited: false,
      isDreamsAndPreferencesVisible: false,
      contactos: [],
      contactosPrincipais: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0
    };

    this.changeEditMode = this.changeEditMode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  //Carregar dadods da API
  loadClientInfo() {

    let shouldSeeDreams = false;
    getLoggedInUser().then(data => {
      if (data) {
        
        //Se o user atual for igual ao pessoa, significa que a pessoa está a ver o seu perfil
        //Se a própria pessoa estiver a ver o seu perfil, não pode ver os sonhos
        //Se a própria pessoa estiver a ver o perfil de outra pessoa, pode ver os sonhos
        shouldSeeDreams = !(data.pessoaId == data.userId);
      }
    })
      .then(getClientDetailsByID)
      .then(data => {

        if (data.success === true) {

          var canInfoBeEdited = true;

          //A informação só pode ser alterado se for um cliente final
          //Antes só podia ser editar se fosse um cliente final
          //canInfoBeEdited = (data.obj.Detalhes[0].TipoCliente === 'CF');

          this.setState(prevState => ({
            ...prevState,
            detalhes: data.obj.Detalhes[0],
            contactos: data.obj.Contactos,
            contactosPrincipais: data.obj.ContactosPrincipais,
            blocking: false,
            canBeEdited: canInfoBeEdited,
            isDreamsAndPreferencesVisible: shouldSeeDreams
          }));
        }
        this.setState({
          blocking: false
        });
      });
  }

  //Quando o componente for montado
  componentDidMount() {
    this.loadClientInfo();
  }

  //Lidar com os inputs do utilizador
  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(prevState => ({
      ...prevState,
      detalhes: {
        ...prevState.detalhes,
        [name]: value
      }
    }));
  }

  //Lidar com clique no botão de editar se pretendemos guardar ou alterar
  changeEditMode() {
    this.setState(prevState => ({
      ...prevState,
      inEditMode: !prevState.inEditMode
    }), () => {

      //Se o utilizador cancelou o modo de editar vamos buscar novamente a informação à API
      if (!this.state.inEditMode) this.loadClientInfo();
    });
  }

  //Lidar com o clique no botão de guardar as alterações
  saveChanges() {

    var dados = { ...this.state.detalhes };

    //Fazemos um pedido na API atualizar a informação do cliente
    updateClientDetails(dados.Sonhos, dados.Preferencias)
      .then(result => {
        if (result.success === true) {
          this.showToast(result.message, "success");

          this.setState(prevState => ({
            ...prevState,
            inEditMode: false
          }));
          this.loadClientInfo();
        } else {
          this.showToast(result.message, "error");
        }
      })
      .catch(err => {
        this.showToast("Erro ao atualizar dados na API", "error");
      })
  }

  showToast(message, type) {
    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-right",
      type: type
    });
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div />
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">
                      {" "}
                    </i>
                    Detalhe do cliente
                  </div>
                  <div className="btn-actions-pane-right">
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="danger"
                      onClick={() => {
                        this.setState({ hidden: "none", show: "initial" });
                      }}
                      style={{ display: this.state.hidden }}
                    >
                      <i
                        className="lnr-circle-minus"
                        style={{ display: this.state.hidden }}
                        onClick={() => {
                          this.setState({
                            hidden: "none",
                            show: "initial"
                          });
                        }}
                      />
                    </Button>
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="success"
                      onClick={() => {
                        this.setState({ hidden: "initial", show: "none" });
                      }}
                      style={{ display: this.state.show }}
                    >
                      <i
                        className="lnr-plus-circle"
                        style={{ display: this.state.show }}
                        onClick={() => {
                          this.setState({
                            hidden: "initial",
                            show: "none"
                          });
                        }}
                      />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody style={{ display: this.state.hidden }}>
                  <BlockUi
                    tag="div"
                    blocking={this.state.blocking}
                    className="block-overlay-dark"
                    loader={
                      <Loader
                        color="#ffffff"
                        active
                        type="ball-spin-fade-loader"
                      />
                    }
                  >
                    {!this.state.blocking ? (
                      <span>
                        <Row>
                          <Col xs="12" sm="12" md="6" lg="6">
                            <p style={headerTextStyle} >
                              <b> Informação: </b>
                            </p>
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <span style={headerTextStyle} >
                                  <b> Cliente recomendação: </b>
                                </span>{" "}
                                <span style={headerTextStyle}>
                                  {this.state.detalhes.ClienteRecNome
                                    ? this.state.detalhes.ClienteRecNome
                                    : "Não existem dados"}
                                </span>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <span style={headerTextStyle} >
                                  <b>
                                    {this.state.detalhes.ID_Cliente
                                      ? this.state.detalhes.ID_Cliente
                                      : "-"}{" "}
                                  </b>
                                </span>{" "}
                                <span style={headerTextStyle}>
                                  -{" "}
                                  {this.state.detalhes.NomeCompleto
                                    ? this.state.detalhes.NomeCompleto
                                    : "Não existem dados"}
                                </span>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <span style={headerTextStyle} >
                                  <b> Tipo de Entidade: </b>
                                </span>{" "}
                                <span style={valueTextStyle}>
                                  {this.state.detalhes.Particular
                                    ? this.state.detalhes.Particular
                                    : "Não existem dados"}
                                </span>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <span style={headerTextStyle} >
                                  <b> Email: </b>
                                </span>{" "}
                                <span style={valueTextStyle}>
                                  {this.state.detalhes.Email
                                    ? this.state.detalhes.Email
                                    : "Não existem dados"}
                                </span>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <span style={headerTextStyle} >
                                  <b> Localidade: </b>
                                </span>{" "}
                                <span style={valueTextStyle}>
                                  {this.state.detalhes.Localidade
                                    ? this.state.detalhes.Localidade
                                    : "Não existem dados"}
                                </span>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <span style={headerTextStyle} >
                                  <b>Nasceu em: </b>
                                </span>{" "}
                                <span style={valueTextStyle}>
                                  {this.state.detalhes.DataNascimento ? (
                                    <FormattedDate
                                      value={
                                        new Date(
                                          this.state.detalhes.DataNascimento
                                        )
                                      }
                                    />
                                  ) : (
                                      "Não existem dados"
                                    )}
                                </span>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <span style={headerTextStyle}>
                                  <b> Registado no IHTP desde: </b>
                                </span>{" "}
                                <span style={valueTextStyle}>
                                  {this.state.detalhes.DataRegisto ? (
                                    <FormattedDate
                                      value={
                                        new Date(
                                          this.state.detalhes.DataRegisto
                                        )
                                      }
                                    />
                                  ) : (
                                      "Não existem dados"
                                    )}
                                </span>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <span style={headerTextStyle}>
                                  <b>Data de Validade do Contrato: </b>
                                </span>{" "}
                                <span style={valueTextStyle}>
                                  {this.state.detalhes.DataValidade ? (
                                    <FormattedDate
                                      value={
                                        new Date(
                                          this.state.detalhes.DataValidade
                                        )
                                      }
                                    />
                                  ) : (
                                      "Não existem dados"
                                    )}
                                </span>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <span style={headerTextStyle}>
                                  <b>Confirmou registo: </b>
                                </span>{" "}
                                <span style={valueTextStyle}>
                                  {this.state.detalhes.ClienteConfirmado
                                    ? this.state.detalhes.ClienteConfirmado
                                    : "Não existem dados"}
                                </span>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs="12" sm="12" md="12" lg="12">
                                <span style={headerTextStyle}>
                                  <b>Créditos: </b>
                                </span>{" "}
                                <span style={valueTextStyle}>
                                  {this.state.detalhes.Creditos
                                    ? this.state.detalhes.Creditos
                                    : "0"}
                                </span>
                              </Col>
                            </Row>
                            <br />

                            {this.state.isDreamsAndPreferencesVisible === true ?
                              <div>
                                <Row>
                                  <Col xs="12" sm="12" md="12" lg="12">
                                    <span style={headerTextStyle}>
                                      <b>Necessidades e Sonhos: </b>
                                    </span>{" "}
                                    <Input name="Sonhos" id="Sonhos" style={{ marginTop: "5px" }} value={this.state.detalhes.Sonhos} onChange={this.handleChange}
                                      type="textarea" disabled={!this.state.inEditMode} rows={4} />
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                  <Col xs="12" sm="12" md="12" lg="12">
                                    <span style={headerTextStyle}>
                                      <b>Preferências: </b>
                                    </span>
                                    <Input name="Preferencias" id="Preferencias" style={{ marginTop: "5px" }} value={this.state.detalhes.Preferencias} onChange={this.handleChange}
                                      type="textarea" disabled={!this.state.inEditMode} rows={4} />
                                  </Col>
                                </Row>
                                <br />
                              </div>
                              : null}


                            {this.state.isDreamsAndPreferencesVisible === true && this.state.canBeEdited ?
                              <Row>
                                <Col md={6} sm={6} lg={6} xs={6}>
                                  <Button color={this.state.inEditMode ? "danger" : "primary"} onClick={this.changeEditMode}>
                                    {this.state.inEditMode ? "Cancelar" : "Editar"}
                                  </Button>
                                </Col>
                                <Col md={6} sm={6} lg={6} xs={6} style={{ textAlign: "right" }} onClick={this.saveChanges}>
                                  {this.state.inEditMode ?
                                    <Button color="secondary">
                                      Guardar
                                  </Button>
                                    : null}
                                </Col>
                              </Row>
                              : null}

                          </Col>
                          <Col xs="12" sm="12" md="6" lg="6">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                color: "#495057"
                              }}
                            >
                              <b> Contactos:</b>
                            </p>
                            {this.state.contactos ? (
                              this.state.contactos.map(contacto => {
                                return (
                                  <p>
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        textAlign: "center",
                                        color: "#495057"
                                      }}
                                    >
                                      <b> {contacto.TipoContacto}: </b>
                                    </span>{" "}
                                    <span
                                      style={{
                                        fontSize: "16px",
                                        textAlign: "center"
                                      }}
                                    >
                                      {contacto.Contacto}
                                    </span>
                                  </p>
                                );
                              })
                            ) : (
                                <span
                                  style={{
                                    fontSize: "16px",
                                    textAlign: "center"
                                  }}
                                >
                                  Não existem contactos disponiveis
                                </span>
                              )}
                          </Col>
                        </Row>


                      </span>
                    ) : (
                        <div style={{ textAlign: "center" }}>
                          {!this.state.blocking
                            ? "Não existem informações sobre as comissões"
                            : null}
                        </div>
                      )}
                  </BlockUi>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default InformacaoPessoal;
