import React, { useState } from 'react';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";

interface CardLayoutProps {
    title: string,
    icon: string,
    children?: | React.ReactChild | React.ReactChild[];
    startOpen: boolean
}

export default function CardLayout(props: CardLayoutProps) {

    const [hidden, setHidden] = useState<string>(props.startOpen === true ? "initial" : "none");
    const [show, setShow] = useState<string>(props.startOpen === true ? "none" : "initial");

    return (
        <>
            <ReactCSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}
            >
                <div />
                <Row>
                    <Col md="12">
                        <Card className="main-card mb-3">
                            <CardHeader className="card-header-tab">
                                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                    <i className={props.icon}>
                                        {" "}
                                    </i>
                                    {props.title}
                                </div>
                                <div className="btn-actions-pane-right">
                                    <Button
                                        className="mr-2 btn-icon btn-icon-only"
                                        outline
                                        color="danger"
                                        onClick={() => {
                                            setHidden("none");
                                            setShow("initial");
                                        }}
                                        style={{ display: hidden }}
                                    >
                                        <i
                                            className="lnr-circle-minus"
                                            style={{ display: hidden }}
                                            onClick={() => {
                                                setHidden("none");
                                                setShow("initial");
                                            }}
                                        />
                                    </Button>
                                    <Button
                                        className="mr-2 btn-icon btn-icon-only"
                                        outline
                                        color="success"
                                        onClick={() => {
                                            setHidden("initial");
                                            setShow("none");
                                        }}
                                        style={{ display: show }}
                                    >
                                        <i
                                            className="lnr-plus-circle"
                                            style={{ display: show }}
                                            onClick={() => {
                                                setHidden("initial");
                                                setShow("none");
                                            }}
                                        />
                                    </Button>
                                </div>
                            </CardHeader>
                            <CardBody style={{ display: hidden }}>
                                {props.children}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ReactCSSTransitionGroup>
        </>
    )
}
