import { useEffect, useState } from 'react';
import { FormattedNumber } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Row } from "reactstrap";
import { fetchCart, removePackCart, removeProdutoCart, updatePackQuantityCart, updateProductQuantityCart } from '../../../actions/cartActions';
import { getProductDetailsWP } from '../../../actions/productActions';
import GoogleAnalytics, { GoogleAnalyticsProduct } from '../../../helpers/googleAnalytics';
import Toast from '../../../helpers/Toast';
import TrashIcon from './TrashIcon';

export interface CartItemProps {
    item: ItemDTO,
    currency: string,
    canDelete: boolean,
    canUpdateQuantity: boolean,
    isOnCheckout: boolean
}

interface ItemDTO {
    Descricao: string,
    ID_Produto: number,
    ID_Pack: number,
    titleUrl: string,
    subtotal: number,
    Quantidade: string,
    ID_TipoProduto: string,
    ID_TipoPack: string
}

interface ProductDetailsWPDTO {
    priceToOtherUsers: number,
    price: number,
    priceWithoutDiscount: number,
    discountValue: number,
    discountPercentage: number,
    currency: string,
    id: number,
    outOfStock: boolean,
    mayAskToBeNotified: boolean,
    alreadyRequestNotification: boolean,
    notificationHash: string,
    netpoints: number,
    credits: number,
    initDate: Date,
    endDate: Date,
    promotionInitDate: Date,
    promotionEndDate: Date,
    description: string,
    productType: string,
    packType: string
}

export default function CartItem(props: CartItemProps) {
    const [quantity, setQuantity] = useState<string>("");
    const [userWantsToUpdateValue, setUserWantsToUpdateValue] = useState<boolean>(false);
    const [currency, setCurrency] = useState<string>("");

    const user = useSelector((state: any) => state.user);

    const dispatch = useDispatch();
    const googleAnalytics: GoogleAnalytics = new GoogleAnalytics();

    useEffect(() => {
        var currencyToUse = props.currency ? props.currency : user.currency;
        setCurrency(currencyToUse);
    }, [currency == ""])

    useEffect(() => {
        setQuantity(props.item.Quantidade);
    }, [quantity == ""])

    //Método para remover um item
    const removeItem = async () => {
        const { id, isProduct } = checkIfIsProduct();

        //Se for produto
        if (isProduct === true) {
            dispatch(removeProdutoCart(id!));
        } else {  //Se for Pack
            dispatch(removePackCart(id!));
        }

        var productFromDb = await getProductInfo();

        var productToRemove: GoogleAnalyticsProduct = {
            ID_Produto: props.item.ID_Produto + "",
            ID_Pack: props.item.ID_Pack + "",
            Descricao: props.item.Descricao,
            ID_TipoProduto: props.item.ID_TipoProduto,
            ID_TipoPack: props.item.ID_TipoPack,
            Quantidade: props.item.Quantidade + "",
            Preco: (productFromDb?.price ?? 0) + ""
        };

        googleAnalytics.RemoveFromCart(productToRemove);
        googleAnalytics.RemoveFromCart_Old(productToRemove);

        var data: any = await dispatch(fetchCart());
        if (data.success === true) {
            googleAnalytics.SendCartToGA(data.obj);
        }
    }

    const getProductInfo = (): Promise<ProductDetailsWPDTO | null> => {
        return new Promise((resolve) => {
            const { isProduct } = checkIfIsProduct();

            if (typeof props.item == 'undefined' || props.item == null) {
                resolve(null);
                return;
            }

            if (isProduct == true) {
                getProductDetailsWP(props.item?.ID_Produto, props.item?.ID_TipoProduto).then(result => {
                    if (result.success === true) {
                        resolve(result.obj);
                        return;
                    }
                });
            }
            else {
                getProductDetailsWP(props.item?.ID_Pack, props.item?.ID_TipoPack).then(result => {
                    if (result.success === true) {
                        resolve(result.obj);
                        return;
                    }
                });
            }
        })
    };

    const handleKeyPress = (target: any) => {
        if (target.charCode === 13) {

            //Se o utilizador especificou que pretende alterar o valor por este ser diferente do primeiro definido
            if (userWantsToUpdateValue === true) {
                updateItemQuantity();
            }
        }
    }

    //Método para lidar com as alterações no campo da quantidade
    const handleChangeInput = (e: any) => {
        var inputValue = e.target.value;

        setQuantity(inputValue);
        setUserWantsToUpdateValue((inputValue != props.item?.Quantidade) ? true : false)
    }

    //Atualizar quantidade no carrinho
    const updateItemQuantity = async () => {
        const { id, isProduct } = checkIfIsProduct();
        var quantityToUpdate = quantity;
        if (isNaN(filterInt(quantityToUpdate))) {
            Toast.Show("error", "Quantidade inserida não é válida!");
            return;
        }

        var quantityToUpdateInt = parseInt(quantityToUpdate);

        if (quantityToUpdateInt <= 0) {
            Toast.Show("error", "Quantidade deve ser superior ou igual a 1!");
            return;
        }

        if (quantityToUpdateInt > 2500) {
            Toast.Show("error", "Quantidade não deve ser superior a 2500!");
            return;
        }

        //Se for produto
        if (isProduct === true) {
            dispatch(updateProductQuantityCart(id!, quantityToUpdateInt));

        } else {  //Se for Pack
            dispatch(updatePackQuantityCart(id!, quantityToUpdateInt));
        }

        var getCart: any = await dispatch(fetchCart());
        if (getCart.success === true) {
            googleAnalytics.SendCartToGA(getCart.obj);
        }
    }

    //Verificamos se é um pack ou um produto
    const checkIfIsProduct = () => {
        try {
            var itemToCheck = props.item;
            var isProduct: boolean = true;
            var id: number | null = null;
            if (typeof itemToCheck?.ID_Pack !== 'undefined' && itemToCheck?.ID_Pack != null) {
                isProduct = false;
                id = itemToCheck.ID_Pack;
            } else {
                isProduct = true;
                id = itemToCheck!.ID_Produto;
            }

            return { isProduct, id };
        } catch (ex) {
            return { isProduct: null, id: null };
        }
    }

    const filterInt = (value: string) => {
        if (/^(\-|\+)?([0-9]+)$/.test(value))
            return Number(value);
        return NaN;
    }

    return (
        <>
            <Row className={props.isOnCheckout === false ? "cartItemContainer" : ""}>
                <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "left" }}>
                    <Row>
                        {/* Titulo do Produto*/}
                        <Col xs="12" sm="12" md="12" lg="8" className="cartItemTitleContainer">
                            {props.isOnCheckout == true ?
                                <label className="cartItemProductLink" style={{ fontSize: "13px", fontWeight: "600" }}>
                                    {props.item.Descricao}
                                </label>
                                :
                                <a className="cartItemProductLink">
                                    {props.item.Descricao}
                                </a>
                            }
                        </Col>

                        {/* Ações com o produto */}
                        <Col xs="12" sm="12" md="12" lg="4" >
                            <Row className="cartItemActionsGroupContainer">

                                {/* Botão de Remover Produto */}
                                {props.canDelete === true &&
                                    < Col xs={12} sm={4} md={3} lg={1} className="cartItemActionContainer cartItemRemove" >
                                        <div style={{ cursor: "pointer" }} onClick={removeItem}>
                                            <TrashIcon color={"black"} />
                                        </div>
                                    </Col>
                                }

                                <Col xs={12} sm={6} md={6} lg={10} className="cartItemActionContainer">
                                    <Row className="cartItemPriceQuantityContainer">
                                        {/* O alignItems: baseline é para alinhar a quantidade com o preço */}
                                        <Col md={12} className="cartItemPrice"
                                            style={{
                                                alignItems: props.isOnCheckout == true ? "baseline" : "",
                                                fontSize: props.isOnCheckout == true ? "13px" : "",
                                                fontWeight: props.isOnCheckout == true ? "600" : ""
                                            }}>
                                            {props.canUpdateQuantity === true ?
                                                <Input className="cartItemQuantityInput" min="1" max="2500" type="number" onKeyPress={handleKeyPress} onChange={handleChangeInput}
                                                    value={quantity}></Input>
                                                :
                                                <label>{quantity}</label>
                                            }

                                            {" = "}{" "}
                                            <FormattedNumber value={props.item.subtotal ?? 0}
                                                style="currency"
                                                currency={currency}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                            />
                                        </Col>
                                    </Row>

                                    {userWantsToUpdateValue === true ? (
                                        <Row className="cartItemUpdateQuantityContainer" onClick={updateItemQuantity}>
                                            <Col md={12}>
                                                <span className="cartItemUpdateQuantityContainerLink">
                                                    Atualizar
                                                </span>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </Col >
                            </Row >
                        </Col>
                    </Row >

                </Col >
            </Row >
            <hr style={{ textAlign: "center" }} />
        </>
    )
}