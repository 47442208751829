import { Visibility, VisibilityOff } from "@material-ui/icons";
import { AvFeedback, AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Slider from "react-slick";
import { Button, Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import InputGroup from "reactstrap/lib/InputGroup";
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import InputGroupText from "reactstrap/lib/InputGroupText";
import SweetAlert from "sweetalert-react";
import { loginFunction } from "../../actions/userActions";
import bg2 from "../../assets/utils/images/originals/eigual.png";
import bg1 from "../../assets/utils/images/originals/Madeira.png";
import bg3 from "../../assets/utils/images/originals/paris.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      show: false,
      message: "",
      titulo: "",
      type: "",
      email: "",
      password: "",
      showPassword: false
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleShowPasswordClick = this.handleShowPasswordClick.bind(this);
  }

  componentDidMount() {
    this.googleTranslateElementInit();
  }


  handleChangeEmail(event) {
    this.setState({
      email: event.target.value
    });
  }

  handleChangePassword(event) {
    this.setState({
      password: event.target.value
    });
  }

  loginAccount = async e => {
    this.setState({ loading: true });
    this.props
      .dispatch(loginFunction(this.state.email, this.state.password))
      .then(data => {
        if (data.messageType === "error") {
          this.setState({
            message: data.message,
            type: "error",
            titulo: "Erro!",
            show: true
          });
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false });
          window.location = "/";
        }
      });
  };

  handleShowPasswordClick() {
    this.setState(prevState => ({
      ...prevState,
      showPassword: !prevState.showPassword
    }))
  }


  googleTranslateElementInit() {
    if (typeof google === 'undefined' || typeof google.translate === 'undefined' || typeof google.translate.TranslateElement === 'undefined') {
      setTimeout(() => {
        this.googleTranslateElementInit();
      }, 1000);
      return;
    }
    new google.translate.TranslateElement({ pageLanguage: 'pt', layout: google.translate.TranslateElement.InlineLayout.SIMPLE }, 'google_translate_element');
  }

  showOriginal() {
    var iframe = document.getElementsByClassName('goog-te-banner-frame')[0];
    if (!iframe) return;

    var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    var restore_el = innerDoc.getElementsByTagName("button");

    for (var i = 0; i < restore_el.length; i++) {
      if (restore_el[i].id.indexOf("restore") >= 0) {
        restore_el[i].click();
        var close_el = innerDoc.getElementsByClassName("goog-close-link");
        close_el[0].click();
        return;
      }
    }
  }

  render() {
    let settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      initialSlide: 0,
      autoplay: true,
      adaptiveHeight: true
    };


    return (
      <Fragment>
        <div className="h-100-2">
          <Row className="h-100-2 no-gutters">
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider {...settings}>
                  <div className="h-100-2 d-flex justify-content-center align-items-center bg-heavy-rain">
                    <div
                      className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg1 + ")"
                      }}
                    />
                    <div className="slider-content">
                      <h4>
                        Posso ainda não ter chegado onde queria, mas estou mais
                        perto que ontem.
                      </h4>
                    </div>
                  </div>
                  <div className="h-100-2 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div
                      className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg3 + ")"
                      }}
                    />
                    <div className="slider-content">
                      <h4>
                        A diferença entre o impossível e o possível está na
                        determinação de uma pessoa
                      </h4>
                    </div>
                  </div>
                  <div className="h-100-2 d-flex justify-content-center align-items-center bg-sunny-morning">
                    <div
                      className="slide-img-bg opacity-6"
                      style={{
                        backgroundImage: "url(" + bg2 + ")"
                      }}
                    />
                    <div className="slider-content">
                      <h4>
                        Não fique a olhar para o relógio, faça como ele:
                        Mexa-se!
                      </h4>
                    </div>
                  </div>
                </Slider>
              </div>
            </Col>
            <Col
              lg="8"
              md="12"
              className="h-100-2 d-flex bg-white justify-content-center align-items-center"
            >
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                <div className="login-container">
                  <div id="google_translate_element"></div>
                  <div>
                    <button className="google_original_language" onClick={this.showOriginal}>Idioma Original</button>
                  </div>
                </div>
                <div style={{ flex: "2", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Row
                    style={{
                      textAlign: "center",
                      display: "block"
                    }}
                  >
                    <Col
                      md={{ size: 8, offset: 2 }}
                      lg={{ size: 8, offset: 2 }}
                      style={{ marginBottom: "3rem" }}
                    >
                      <img
                        src="https://doremon.ihavethepower.net/assets/uploads/Logo_IHTP(3).png"
                        width="80%"
                        height="auto"
                      />
                    </Col>
                  </Row>
                  <h4 className="mb-0">
                    <div>Bem-vindo de novo,</div>
                    <span>Por favor entre na sua conta.</span>
                  </h4>
                  <Row className="divider" />
                  <div>
                    <SweetAlert
                      title={this.state.titulo}
                      confirmButtonColor=""
                      confirmButtonText={
                        this.state.type === "success" ? "Continuar" : "Ok"
                      }
                      show={this.state.show}
                      text={this.state.message}
                      html
                      type={this.state.type}
                      onConfirm={() => {
                        if (this.state.type === "success") {
                          this.setState({ show: false });
                          window.location = "/";
                        } else {
                          this.setState({ show: false });
                        }
                      }}
                    />
                    <AvForm onSubmit={this.loginAccount}>
                      <Row>
                        <Col md="6">
                          <AvGroup>
                            <Label for="email">Endereço de Email</Label>
                            <AvInput
                              name="email"
                              type="email"
                              id="email"
                              placeholder="Endereço de Email"
                              onChange={this.handleChangeEmail}
                              required
                            />
                            <AvFeedback>Endereço de email requerido!</AvFeedback>
                          </AvGroup>
                        </Col>
                        <Col md="6">
                          <AvGroup>
                            <Label for="password">Palavra-Passe</Label>
                            <InputGroup >
                              <AvInput
                                name="password"
                                id="password"
                                type={this.state.showPassword == true ? "text" : "password"}
                                placeholder="Palavra-Passe"
                                onChange={this.handleChangePassword}
                                required
                              />

                              {/* "Olho" para ver ou não password*/}
                              <InputGroupAddon addonType={"append"} style={{ cursor: "pointer" }} onClick={this.handleShowPasswordClick}>
                                <InputGroupText>
                                  {this.state.showPassword == true ? (
                                    <Visibility fontSize={"small"} />
                                  ) : (
                                    <VisibilityOff fontSize={"small"} />
                                  )
                                  }
                                </InputGroupText>
                              </InputGroupAddon>

                              {/* Feedback de erro */}
                              <AvFeedback>Palavra-passe requerida!</AvFeedback>
                            </InputGroup>

                          </AvGroup>
                        </Col>
                      </Row>
                      <div className="d-flex align-items-center">
                        <div className="ml-auto">
                          {this.state.loading ? (
                            <FormGroup>
                              <Button
                                className="btn-wide mb-2 mr-2 btn-pill"
                                size="lg"
                                color="primary"
                                disabled
                              >
                                <Spinner size="sm" color="white" />
                              </Button>
                            </FormGroup>
                          ) : (
                            <FormGroup>
                              <Button
                                className="btn-wide mb-2 mr-2 btn-pill"
                                size="lg"
                                color="primary"
                              >
                                Entrar
                              </Button>
                            </FormGroup>
                          )}
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </div>
                <div style={{ flex: "1" }}></div>
              </Col>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProp = state => ({
  user: state.user
});

export default withRouter(connect(mapStateToProp)(Login));
