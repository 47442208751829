import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Tabs from "react-responsive-tabs";
import { Card, Col, Row } from "reactstrap";
import { getContactCalls, getContactComments, getContactEmails, getContactoTimeline, getContactTasks } from "../../../actions/contactosActions";
import Atividades from "./Atividades/Atividades";
import Chamadas from "./Chamadas";
import Observacoes from "./Observacoes/Observacoes";
import Tarefas from "./Tarefas";

var that;
class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: true,
      hidden: "initial",
      show: "none",
      obervacoesData: "initial",
      obervacoesAdicionar: "none",
      activeTab: "1",
      actividades: [],
      comentarios: [],
      emails: [],
      chamadas: [],
      tarefas: [],
      showMore: true,
      transform: true,
      showInkBar: true,
      items: [
        {
          key: 0,
          title: "Atividades",
          getContent: () => {
            return <Atividades contactID={this.props.id} />;
          }
        },
        {
          key: 1,
          title: "Observações",
          getContent: () => {
            return <Observacoes contactID={this.props.id} />;
          }
        },
        /*{
          key: 2,
          title: "Emails",
          getContent: () => {
            return <Emails contactID={this.props.id} />;
          }
        },*/
        {
          key: 3,
          title: "Chamadas",
          getContent: () => {
            return <Chamadas contactID={this.props.id} />;
          }
        },
        {
          key: 4,
          title: "Tarefas",
          getContent: () => {
            return <Tarefas contactID={this.props.id} />;
          }
        }
      ],
      selectedTabKey: 0,
      transformWidth: 400
    };
    that = this;
  }

  getData() {
    getContactoTimeline(this.props.id).then(data => {
      if (data && !data.message) {
        this.setState({
          actividades: data,
          dataSize: 1
        });
      }
    });
    getContactEmails(this.props.id).then(data => {
      if (data && !data.message) {
        this.setState({
          emails: data,
          dataSize: 1
        });
      }
    });
    getContactComments(this.props.id).then(data => {
      if (data && !data.message) {
        this.setState({
          comentarios: data,
          dataSize: 1
        });
      }
    });
    getContactTasks(this.props.id).then(data => {
      if (data && !data.message) {
        this.setState({
          tarefas: data,
          dataSize: 1
        });
      }
    });
    getContactCalls(this.props.id).then(data => {
      if (data && !data.message) {
        this.setState({
          chamadas: data,
          dataSize: 1
        });
      }
    });
  }
  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              {this.state.dataSize > 0 && this.state.blocking ? (
                <div>
                  <Card className="mb-3 card-tabs" color="alternate">
                    <Tabs tabsWrapperClass="card-header" {...this.state} />
                  </Card>
                </div>
              ) : (
                  <div style={{ textAlign: "center" }}>
                    {!this.state.blocking
                      ? "Não existem informações sobre a encomenda"
                      : null}
                  </div>
                )}
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Timeline;
