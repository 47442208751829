import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedNumber } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button } from 'reactstrap';
import { removeVouchers, selectVouchers } from "../../actions/checkoutActions";
import SearchInputComponent from '../../helpers/SearchComponents/SearchInputComponent';
import Toast from '../../helpers/Toast';
import { IsValidNumber } from '../../helpers/UsefulFunctions';
import { HasError } from '../../helpers/UtilsCheckout';
import IHTPButton from "../Common/Button";
import SkeletonComponent from "../Common/Skeleton";
import IHTPSpacer from '../Common/Spacer';
import "./Checkout.css";

export default function Vales() {

	const currentVouchersCheckout = useSelector((state: any) => state.checkoutData.vouchers);
	const valesPermitidos = useSelector((state: any) => state.checkoutData.valesPermitidos ?? 0);
	const loadingCheckoutPrices = useSelector((state: any) => state.loadingCheckoutPrices);
	const errorCode = useSelector((state: any) => state.errorCode);
	const upline = useSelector((state: any) => state.upline); //Se estiver no seu user, aparece a sua informação. Se estiver num cliente em rede aparece a informação desse cliente

	const [vouchers, setVouchers] = useState<string>("");
	const [hasError, setHasError] = useState<boolean>(false);
	const [loadingVoucher, setLoadingVoucher] = useState<boolean>(false);

	const dispatch = useDispatch();

	useEffect(() => {
		let tmpHasError = HasError(errorCode, ["Vales_Codigos", "Creditos_Vales", "Vales"]);
		if (hasError !== tmpHasError) {
			setHasError(tmpHasError);
		}
		return () => { }
	}, [errorCode])

	useEffect(() => {
		if (hasError === true) {
			let el = document.getElementById("vales");
			if (el != null) el.scrollIntoView({ block: "center" });
		}
	}, [hasError])

	const handleChange = (text) => {

		if (IsValidNumber(text) === false) {
			setVouchers("");
			return;
		}

		const finalValue = Number(text);
		const maxAllowedVouchers = parseFloat(valesPermitidos);

		// Somente consegue adicionar um valor até perfazer o valor de vales permitidos
		if (finalValue <= maxAllowedVouchers) {
			setVouchers(text)
		}
	}

	const handleFormSubmit = async () => {
		if (!vouchers) return;

		var totalVouchers = 0;

		//Verificar o valor dos vouchers preenchidos
		var tmpVoucher = vouchers;

		//Substituimos , para . 
		tmpVoucher = tmpVoucher.replace(/,/g, ".");

		//Se não for um número ou for um valor menor que 0 mostramos erro
		var vouchersInNumber = Number(tmpVoucher);
		if (isNaN(vouchersInNumber) || vouchersInNumber < 0) {
			Toast.Show("error", "Não inseriu um valor válido de vales");
			return;
		}

		//Se já tiver lá vouchers
		if (typeof currentVouchersCheckout !== "undefined" && currentVouchersCheckout != null && Number.isNaN(Number(currentVouchersCheckout)) == false) {
			totalVouchers = parseFloat(Number(currentVouchersCheckout) + "") + parseFloat(tmpVoucher);

			//Se ainda não tivermos vouchers
		} else {
			totalVouchers = parseFloat(tmpVoucher);
		}

		setLoadingVoucher(true);
		var data: any = await dispatch(selectVouchers(totalVouchers));
		setLoadingVoucher(false);

		if (typeof data.success !== "undefined") {
			setVouchers("");
			Toast.Show("success", data.success);
			return;
		}

		if (typeof data.error !== "undefined") {
			setVouchers("");
			await dispatch(removeVouchers());
			Toast.Show("error", data.error);

		} else {
			Toast.Show("error", "Não inseriu nenhum vale");
		}
	}

	var loadingPrices = loadingCheckoutPrices;
	return (
		<div>
			<div
				className={hasError === true ? "checkoutBox errorBorder"
					:/* Validar se existe créditos metendo a disabled se o valor for nulo ou 0 */
					Math.round(valesPermitidos * 100) / 100 === 0
						? "checkoutBox disabledCreditsBox"
						: "checkoutBox "
				}
				id="vales">
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} className="checkoutBoxTitle">
						Vales
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>

						<IHTPSpacer verticalSpace='10px' />

						<SearchInputComponent showLabel={false}
							inputType="number" min={0}
							onChange={(e) => handleChange(e.target.value)} initialValue={vouchers} value={vouchers}
							placeholder="Insira um valor" />

						<IHTPSpacer verticalSpace='10px' />

						<div className='discountsText'>
							<span className='vouchersAvailableContainer'>
								<span>Utilizados:</span>
								<span style={{ fontWeight: "600", color: "#007bff" }}>
									{loadingPrices === true ? (
										<SkeletonComponent width={50} height={24} />
									) : (
										<FormattedNumber
											value={currentVouchersCheckout ? currentVouchersCheckout : 0}
											style="currency"
											currency={upline.ID_Moeda}
											minimumFractionDigits={2}
											maximumFractionDigits={2}
										/>
									)}
								</span>
							</span>

							<IHTPSpacer verticalSpace='10px' />

							<span className='vouchersAvailableContainer'>
								<span>Consegue utilizar:</span>
								<span style={{ fontWeight: "600", color: "#007bff" }}>
									{loadingPrices === true ? (
										<SkeletonComponent width={50} height={24} />
									) : (
										<FormattedNumber
											value={valesPermitidos}
											style="currency"
											currency={upline.ID_Moeda}
											minimumFractionDigits={2}
											maximumFractionDigits={2}
										/>
									)}
								</span>
							</span>
						</div>

						<IHTPSpacer verticalSpace='10px' />

						<div className="buttonsValidarCodigos">
							<IHTPButton
								text={"Usar vales"}
								onClick={handleFormSubmit}
								buttonStyle={"secondary"}
								loading={loadingVoucher}
							/>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}