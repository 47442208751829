//Obter os detalhes de estatísticas para os filhos de consultor
export function getChildrenTreeLeadStats(tipoLead, profundidade, currentPage, recordsPerPage, fase, orderField, orderAsc) {
    return new Promise((resolve, reject) => {
        return fetch("/stats/getChildrenTreeLeadStats", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                TipoLead: tipoLead,
                Profundidade: profundidade,
                Pagina: currentPage,
                RegistosPorPagina: recordsPerPage,
                Fase: fase,
                CampoOrdenacao: orderField,
                OrdenacaoAsc: orderAsc
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

//Obter os totais de estatísticas para os filhos de consultor
export function getChildrenTreeLeadTotalStats(tipoLead, profundidade) {
    return new Promise((resolve, reject) => {
        return fetch("/stats/getChildrenTreeLeadTotalStats", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                TipoLead: tipoLead,
                Profundidade: profundidade,
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

//Obter os totais de estatísticas para os filhos de consultor por fase
export function getChildrenTreeLeadTotalStatsByFase(idFase, profundidade, currentRequestId) {
    return new Promise((resolve, reject) => {
        return fetch("/stats/getChildrenTreeLeadTotalStatsByFase", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                Fase: idFase,
                Profundidade: profundidade,
            })
        })
            .then(res => res.json())
            .then(json => {
                json.requestId = currentRequestId;
                resolve(json);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

//Obter as stats de conversão de fases por tipo de negocio
export function getConversionStats(tipoLead, profundidade, initDate, endDate) {
    return new Promise((resolve, reject) => {
        return fetch("/stats/getConversionStats", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                TipoLead: tipoLead,
                Profundidade: profundidade,
                DataInicio: initDate,
                DataFim: endDate
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}