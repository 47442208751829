// 18-07-2019 - AFONSO - Funcao para obter os negocios com aquele cliente
export function getBusinessesWithClient(ID_Cliente) {
  return new Promise((resolve, reject) => {
    return fetch("/negocios/getBusinessesWithClient", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter outros negocios do cliente
export function getOtherBusinesses() {
  return new Promise((resolve, reject) => {
    return fetch("/negocios/getOtherBusinesses", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter negocios do cliente
export function getBusinesses() {
  return new Promise((resolve, reject) => {
    return fetch("/negocios/getBusinesses", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 22-07-2019 - AFONSO - Funcao para adicionar um negocio com um cliente
export function addBusiness(negocio) {
  return new Promise((resolve, reject) => {
    return fetch("/negocios/addBusiness", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_TipoNegocio: negocio.ID_TipoNegocio,
        Referencia: negocio.Referencia,
        Descricao: negocio.Descricao,
        DataPrevistaFecho: negocio.DataPrevistaFecho,
        DataInicio: negocio.DataInicio,
        ValorPrevisto: negocio.ValorPrevisto,
        Observacao: negocio.Observacao
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 22-07-2019 - AFONSO - Funcao para adicionar um negocio com um cliente
export function editBusiness(negocio) {
  return new Promise((resolve, reject) => {
    return fetch("/negocios/editBusiness", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Negocio: negocio.idNegocio,
        NomeContacto: negocio.nomeContacto,
        EmailContacto: negocio.emailContacto,
        TelemovelContacto: negocio.telemovelContacto,
        Localidade: negocio.localidade,
        Referencia: negocio.referencia,
        ValorPrevisto: negocio.valorPrevisto,
        DataPrevistaInicio: negocio.dataPrevistaInicio,
        DataPrevistaFecho: negocio.dataPrevistaFim,
        Observacao: negocio.obs
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 22-07-2019 - AFONSO - Funcao para obter detalhe de um negocios com cliente
export function getBusinessDetail(ID_Negocio) {
  return new Promise((resolve, reject) => {
    return fetch("/negocios/getBusinessDetail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Negocio: ID_Negocio
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


export function updateBusinessDetailPageInfo(ID_Negocio, showLoadingIndicator) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {

      if (showLoadingIndicator) {
        dispatch(loadingBusinessDetailPage(true));
      }

      return fetch("/negocios/getBusinessDetail", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          ID_Negocio: ID_Negocio
        }),
        credentials: "include"
      })
        .then(res => res.json())
        .then(json => {

          var isLeadManual = false;
          //Significa que não existiu erro
          if (!json.message) {

            //Se tivermos o resultado dos detalhes
            if (json.Detalhes) {

              if (json.Detalhes.Origem) {
                isLeadManual = json.Detalhes.Origem.startsWith("Manual");
              }

              dispatch(atualizarBusinessDetailInfo(json.Detalhes));
            }

            //Se tivermos o resultados dos negocios
            if (json.EstadosNegocios) {

              var estados = json.EstadosNegocios;
              estados.forEach(e => {
                e.PodeEditar = false;
              });

              //Verificamos qual deles é o mais recente, só pode editar a mais recente
              var ultimoEstado = estados[estados.length - 1]

              var podeEditar = true;
              //Se o contacto estiver no estado de registo e não for uma lead manual não podemos editar
              if (ultimoEstado.EstadoRegisto === true && isLeadManual === false) podeEditar = false;

              ultimoEstado.PodeEditar = podeEditar;
              dispatch(atualizarBusinessDetailStates(estados));
            }
          }

          if (showLoadingIndicator) {
            dispatch(loadingBusinessDetailPage(false));
          }

          resolve(true);
        })
        .catch(error => {
          if (showLoadingIndicator) {
            dispatch(loadingBusinessDetailPage(false));
          }
          resolve(false);
        });
    });
  }
}


// 22-07-2019 - AFONSO - Funcao para remover um negocio com um cliente
export function removeBusiness(ID_Negocio) {
  return new Promise((resolve, reject) => {
    return fetch("/negocios/removeBusiness", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Negocio: ID_Negocio
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


// 22-07-2019 - AFONSO - Funcao para obter tipos de negocio
export function getTypeBusinesses() {
  return new Promise((resolve, reject) => {
    return fetch("/negocios/getTypeBusinesses", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 22-07-2019 - AFONSO - Funcao para obter tipos de estado de negocio
export function getTypeBusinessStatus() {
  return new Promise((resolve, reject) => {
    return fetch("/negocios/getTypeBusinessStatus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// Funcao para atualizar os dados do contacto de uma lead
export function updateBusinessContactInfo(idNegocio, nomeContacto, emailContacto, telemovelContacto, localidade) {
  return new Promise((resolve, reject) => {
    return fetch("/negocios/updateBusinessContactInfo", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Negocio: idNegocio,
        NomeContacto: nomeContacto,
        EmailContacto: emailContacto,
        TelemovelContacto: telemovelContacto,
        Localidade: localidade,
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function leadWasUpdated() {
  return (dispatch, getState) => {
    dispatch(negocioFoiAtualizado(Date.now()));
  }
}

export const NEGOCIO_ATUALIZADO = "NEGOCIO_ATUALIZADO";
export const negocioFoiAtualizado = negocioAtualizado => ({
  type: NEGOCIO_ATUALIZADO,
  payload: { negocioAtualizado }
})

export const BUSINESS_DETAIL_PAGE_INFO = "BUSINESS_DETAIL_PAGE_INFO";
export const atualizarBusinessDetailInfo = businessDetailPageInfo => ({
  type: BUSINESS_DETAIL_PAGE_INFO,
  payload: { businessDetailPageInfo }
})


export const BUSINESS_DETAIL_PAGE_STATES = "BUSINESS_DETAIL_PAGE_STATES";
export const atualizarBusinessDetailStates = businessDetailPageStates => ({
  type: BUSINESS_DETAIL_PAGE_STATES,
  payload: { businessDetailPageStates }
})

export const BUSINESS_DETAIL_PAGE_LOADING = "BUSINESS_DETAIL_PAGE_LOADING";
export const loadingBusinessDetailPage = businessDetailLoading => ({
  type: BUSINESS_DETAIL_PAGE_LOADING,
  payload: { businessDetailLoading }
})