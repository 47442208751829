import { Component } from "react";
import { allConsultantsFourthQuarterBilling } from "../../../actions/indicadoresActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";
import Toast from "../../../helpers/Toast";

class Faturacao4TrimestreTodosConsultores extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            columns: [],
            blocking: true,
            hidden: "initial",
            show: "none",
            rowNumber: 10,
            dataSize: 0,
            contactos: []
        };
    }

    componentDidMount() {
        allConsultantsFourthQuarterBilling().then(result => {
            if (result.success === false) {
                Toast.Show("error", data.message);
                return;
            }

            if (result.obj.data) {
                this.setState({ dataSize: result.obj.data.length });
                this.setState({
                    data: result.obj.data,
                    rowNumber: result.obj.rowNumber
                });
            }
            this.setState({
                columns: [
                    {
                        Header: "Posição",
                        accessor: "Posicao",
                        resizable: false,
                        style: { display: "block", textAlign: "center" },
                        width: 100
                    },
                    {
                        Header: "Nome",
                        accessor: "NomeCompleto",
                        resizable: false,
                        style: { display: "block", textAlign: "center" }
                    }
                ]
            });
            this.setState({ blocking: false });
        });
    }

    render() {
        return (
            <SimpleTable
                data={this.state.data}
                columns={this.state.columns}
                blocking={this.state.blocking}
                rowNumber={20}
                dataSize={this.state.dataSize}
                noDataText={"Sem faturações para mostrar"}
                title={"Posição faturação 4º trimestre de todos os consultores"}
            />
        );
    }
}

export default Faturacao4TrimestreTodosConsultores;