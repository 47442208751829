import { isNumber } from "lodash";
import { handleErrors } from "../helpers/UsefulFunctions";

//Método que retorna se os créditos estão a ser usados no checkout
export function areCheckoutCreditsUsed() {
    return (dispatch, getState) => {

        var credits = getState().checkoutData.credits;
        var areCreditsUsed = (typeof credits != 'undefined' && credits != null && isNumber(credits) && credits > 0);
        return {
            areCreditsUsed,
            credits
        };
    }
}

//Método que retorna se os vouchers estão a ser usados no checkout
export function areCheckoutVouchersUsed() {
    return (dispatch, getState) => {

        var vouchers = getState().checkoutData.vouchers;
        var areVouchersUsed = (typeof vouchers != 'undefined' && vouchers != null && isNumber(vouchers) && vouchers > 0);
        return {
            areVouchersUsed,
            vouchers
        };
    }
}

//Método que atualiza apenas o valor dos créditos
export function onlyUpdateCredits(credits) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.credits = credits;
            resolve(checkoutData);
        });
    };
}

//Método que atualiza apenas o valor dos vouchers
export function onlyUpdateVouchers(vouchers) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.vouchers = vouchers;
            resolve(checkoutData);
        });
    };
}

export function verifyOrder(action?: string) {
    return (dispatch, getState) => {
        dispatch(setLoadingCheckoutPrices(true));
        return new Promise((resolve, reject) => {
            return fetch("/checkout/verifyOrder", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_ClienteMoradaEnvio: getState().checkoutData.shippingAddress,
                    ID_ClienteMoradaFatura: getState().checkoutData.billingAddress,
                    ID_TipoTransporte: getState().checkoutData.selectedShipmentType,
                    ID_TipoPagamento: getState().checkoutData.selectedPaymentType,
                    PromotionalCode: getState().checkoutData.promotionalCode,
                    Vouchers: getState().checkoutData.vouchers,
                    Credits: getState().checkoutData.credits,
                    Observacoes: getState().checkoutData.observacoes,
                    Codigo_Postal: getState().shippingAddress.codigoPostal,
                    ID_Pais: getState().shippingAddress.id_pais
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    json.checkoutData.selectedShipmentType = getState().checkoutData.selectedShipmentType;
                    json.checkoutData.selectedPaymentType = getState().checkoutData.selectedPaymentType;
                    json.checkoutData.billingAddress = getState().checkoutData.billingAddress;
                    json.checkoutData.shippingAddress = getState().checkoutData.shippingAddress;
                    json.checkoutData.confirmOrderClicked = getState().checkoutData.confirmOrderClicked;
                    json.checkoutData.observacoes = getState().checkoutData.observacoes;
                    json.checkoutData.promotionalCode = getState().checkoutData.promotionalCode;

                    dispatch(setShouldVerifyOrder(false));

                    //Se a ação não for o remover, então coloca o erro
                    //Caso contrário mantém o erro
                    if (action == null || typeof action == 'undefined' || (typeof action == 'string' && action.toUpperCase() != 'Remove'.toUpperCase())) {
                        dispatch(setErrorCodeCheckout(json.checkoutData.CodigoErro));
                    }

                    dispatch(fetchCheckoutData(json.checkoutData));
                    dispatch(setLoadingCheckoutPrices(false));

                    resolve({ json });
                    return json.checkoutData;
                })
                .catch(error => console.log(error));
        });
    };
}

export function selectVouchers(vouchers) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.vouchers = vouchers;
            dispatch(verifyOrder()).then(data => {
                if (data.json.checkoutData.MensagemErro) {
                    resolve({ error: data.json.checkoutData.MensagemErro });
                } else {
                    resolve({ success: "Vales inseridos com sucesso" });
                }
            });
        });
    };
}

export function removeVouchers() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.vouchers = 0;
            dispatch(verifyOrder("remove")).then(data => {
                if (data.json.checkoutData.message) {
                    resolve({ error: data.json.checkoutData.message });
                } else {
                    resolve({ success: "Vales removidos com sucesso" });
                }
            });
        });
    };
}

export function confirmOrder(gaClientId) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/checkout/confirmOrder", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_ClienteMoradaEnvio: getState().checkoutData.shippingAddress,
                    ID_ClienteMoradaFatura: getState().checkoutData.billingAddress,
                    ID_TipoTransporte: getState().checkoutData.selectedShipmentType,
                    ID_TipoPagamento: getState().checkoutData.selectedPaymentType,
                    ID_Portes: getState().checkoutData.ID_Portes,
                    Observacoes: getState().checkoutData.observacoes,
                    ChaveCodigoPromocional: getState().checkoutData.ChaveCodigoPromocional,
                    CodigoVale: getState().checkoutData.CodigoVale,
                    DescontoVale: getState().checkoutData.vouchers,
                    DescontoCreditos: getState().checkoutData.credits,
                    Parcelas: getState().selectedPaymentInstallments,
                    NumeroTelemovelPagamento: getState().selectedPaymentPhoneNumber,
                    AnalyticsClientId: gaClientId
                })
            })
                .then(res => res.json())
                .then(json => {
                    // dispatch(fetchCart());
                    // Encomenda processou com sucesso
                    dispatch(loading(false));
                    if (json.success === true) {
                        getState().checkoutData.confirmOrderClicked = true;
                    }
                    else {
                        dispatch(setErrorCodeCheckout(json.errorCode))
                    }
                    resolve(json);
                }).catch(err => {
                    console.log(err)
                    reject();
                });
        });
    };
}

export function getOrderDetails(id_encomenda) {
    return new Promise((resolve, reject) => {
        return fetch("/encomendas/getOrderDetail", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ID_Encomenda: id_encomenda
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });
}

export function removeCredits() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.credits = 0;
            dispatch(verifyOrder("remove")).then(data => {
                if (data.json.checkoutData.message) {
                    resolve({ error: data.json.checkoutData.message });
                } else {
                    resolve({ success: "Créditos removidos com sucesso" });
                }
            });
        });
    };
}

export function removePromotionalCode() {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.promotionalCode = null;
            dispatch(verifyOrder("remove")).then(data => {
                if (data.json.checkoutData.message) {
                    resolve({ error: data.json.checkoutData.message });
                } else {
                    resolve({ success: "Código promocional removido com sucesso" });
                }
            });
        });
    };
}

export function getPaymentTypes() {
    return dispatch => {
        return fetch("/checkout/getPaymentMethods", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                json.paymentTypes.map(obj => {
                    obj.Selected = false;
                    return obj;
                });
                dispatch(fetchPaymentTypes(json.paymentTypes));
                return json.paymentTypes;
            })
            .catch(error => console.log(error));
    };
}

export function selectPaymentPhoneNumber(phoneNumber) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            dispatch(selectedPaymentPhoneNumber(phoneNumber));
        });
    };
}

export function selectPaymentType(id, numOfInstallments, phoneNumber) {
    return (dispatch, getState) => {
        return new Promise<object | void>((resolve, reject) => {
            var paymentTypes = getState().paymentTypes.map(function (item) {
                if (item.ID_TipoPagamento === id) {
                    item.Selected = true;
                } else {
                    item.Selected = false;
                }
                return item;
            });

            var checkoutData = getState().checkoutData;
            checkoutData.selectedPaymentType = id;

            dispatch(fetchCheckoutData(checkoutData));
            dispatch(selectedPaymentInstallments(numOfInstallments));
            dispatch(selectedPaymentPhoneNumber(phoneNumber));
            dispatch(fetchPaymentTypes(paymentTypes));

            dispatch(verifyOrder()).then(data => {
                if (data.json.checkoutData.MensagemErro) {
                    resolve({ error: data.json.checkoutData.MensagemErro });
                } else {
                    resolve();
                }
            });
        });
    };
}

export function addObservacao(notes) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.observacoes = notes;
            dispatch(fetchCheckoutData(checkoutData));
            resolve(checkoutData);
        });
    };
}

export function getShipmentTypes() {
    return dispatch => {
        return fetch("/checkout/getDeliveryMethods", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                json.shipmentTypes.map(obj => {
                    obj.Selected = false;
                    return obj;
                });
                dispatch(fetchShipmentTypes(json.shipmentTypes));
                return json.shipmentTypes;
            })
            .catch(error => console.log(error));
    };
}

export function selectShipmentType(id) {
    return (dispatch, getState) => {
        return new Promise<object | void>((resolve, reject) => {

            let externalShippingValue = "S";
            getState().shipmentTypes.map((item) => {
                if (item.ID_TipoTransporte === id) {
                    item.Selected = true;
                    externalShippingValue = item.Externo;
                } else {
                    item.Selected = false;
                }
                return item;
            });

            var checkoutData = getState().checkoutData;
            checkoutData.selectedShipmentType = id;

            //Indicamos logo se o tipo de transporte é externo ou não
            checkoutData.TipoTransporteExterno = externalShippingValue;

            dispatch(fetchCheckoutData(checkoutData));

            dispatch(verifyOrder()).then(data => {
                if (data.json.checkoutData.MensagemErro) {
                    resolve({ error: data.json.checkoutData.MensagemErro });
                } else {
                    resolve();
                }
            });
        });
    };
}

export function selectCredits(credits) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.credits = credits;
            dispatch(verifyOrder()).then(data => {
                var response = {
                    success: false,
                    message: ""
                }
                if (data.json.checkoutData.MensagemErro) {
                    response.success = false
                    response.message = data.json.checkoutData.MensagemErro;
                    resolve(response);
                } else {
                    response.success = true;
                    resolve(response);
                }
            });
        });
    };
}

export function selectPromotionalCode(promotionalCode) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.promotionalCode = promotionalCode;
            dispatch(verifyOrder()).then(data => {
                if (data.json.checkoutData.MensagemErro !== "") {
                    checkoutData.promotionalCode = "";
                    resolve({ error: data.json.checkoutData.MensagemErro });
                } else {
                    resolve({ success: "Código promocional inserido com sucesso" });
                }
            });
        });
    };
}

export function getAddress(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/getAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Cliente_Morada: id
                })
            })
                .then(res => res.json())
                .then(json => {
                    resolve({ data: json });
                })
                .catch(error => console.log(error));
        });
    };
}

export function modifyBillingAddress(alterarMorada) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/updateAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Cliente_Morada: alterarMorada.id_cliente_morada,
                    ID_Tipo_Morada: "F",
                    Nome: alterarMorada.nome,
                    Nif: alterarMorada.nif,
                    Morada: alterarMorada.morada,
                    Localidade: alterarMorada.localidade,
                    CodigoPostal: alterarMorada.codigoPostal,
                    Principal: alterarMorada.principal,
                    ID_Pais: alterarMorada.id_pais
                })
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(getBillingAddresses());
                    resolve(json);
                })
                .catch(error => console.log(error));
        });
    };
}

export function getBillingAddresses() {
    return dispatch => {
        return fetch("/user/getBillingAddress", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                dispatch(fetchBillingAddresses(json.billingAddresses));
                return json.billingAddresses;
            })
            .catch(error => console.log(error));
    };
}

export function addBillingAddressCheckout(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.billingAddress = id;
            dispatch(fetchCheckoutData(checkoutData));

            resolve(checkoutData);
        });
    };
}

export function removeBillingAddress(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/removeAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Cliente_Morada: id
                })
            })
                .then(res => res.json())
                .then(json => {
                    resolve(json);
                })
                .catch(error => console.log(error));
        });
    };
}

export function setBillingAddressAsDefault(addressId) {
    return new Promise((resolve, reject) => {
        return fetch("/user/setBillingAddressAsDefault", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                AddressId: addressId
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => console.log(error));
    });
}

export function addNewBillingAddress(novaMorada) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/addAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Tipo_Morada: "F",
                    Morada: novaMorada.morada,
                    Nome: novaMorada.nome,
                    Nif: novaMorada.nif,
                    Localidade: novaMorada.localidade,
                    CodigoPostal: novaMorada.codigoPostal,
                    Principal: novaMorada.principal,
                    ID_Pais: novaMorada.id_pais
                })
            })
                .then(res => res.json())
                .then(json => {
                    resolve(json);
                })
                .catch(error => console.log(error));
        });
    };
}

export function modifyShippingAddress(alterarMorada) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/updateAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Cliente_Morada: alterarMorada.id_cliente_morada,
                    Nome: alterarMorada.nome,
                    Morada: alterarMorada.morada,
                    Localidade: alterarMorada.localidade,
                    CodigoPostal: alterarMorada.codigoPostal,
                    Principal: alterarMorada.principal,
                    ID_Pais: alterarMorada.id_pais,
                    ID_Tipo_Morada: alterarMorada.ID_Tipo_Morada
                })
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(getShippingAddresses());
                    dispatch(verifyOrder());
                    resolve(json);
                })
                .catch(error => console.log(error));
        });
    };
}

export function getShippingAddresses() {
    return dispatch => {
        return fetch("/user/getDeliveryAddress", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                dispatch(fetchDeliveryAddresses(json.deliveryAddresses));
                return json.deliveryAddresses;
            })
            .catch(error => console.log(error));
    };
}

export function addShippingAddressCheckout(id, reloadOrder?: boolean) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            var checkoutData = getState().checkoutData;
            checkoutData.shippingAddress = id;
            dispatch(fetchCheckoutData(checkoutData));

            if (typeof reloadOrder !== 'undefined' && reloadOrder !== null && reloadOrder === true) {
                dispatch(verifyOrder());
            }
            resolve(checkoutData);
        });
    };
}

export function removeShippingAddress(id) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/removeAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Cliente_Morada: id
                })
            })
                .then(res => res.json())
                .then(json => {
                    dispatch(getShippingAddresses()).then(result => {
                        resolve({ data: json, moradas: result });
                    }).catch(error => {
                        resolve({ data: json });
                    });

                })
                .catch(error => console.log(error));
        });
    };
}

export function setShippingAddressAsDefault(addressId) {
    return new Promise((resolve, reject) => {
        return fetch("/user/setShippingAddressAsDefault", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                AddressId: addressId
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => console.log(error));
    });
}

export function addNewShippingAddress(novaMorada, shouldReloadAddresses) {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            return fetch("/user/addAddress", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Tipo_Morada: "R",
                    Morada: novaMorada.morada,
                    Localidade: novaMorada.localidade,
                    Nome: novaMorada.nome,
                    CodigoPostal: novaMorada.codigoPostal,
                    Principal: novaMorada.principal,
                    ID_Pais: novaMorada.id_pais
                })
            })
                .then(res => res.json())
                .then(json => {

                    if (shouldReloadAddresses === true) {
                        dispatch(getShippingAddresses());
                    }
                    resolve(json);
                })
                .catch(error => console.log(error));
        });
    };
}

export function getOrderPayment(hash) {
    return new Promise((resolve, reject) => {
        return fetch("/payment/getOrderPayment", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                hash: hash
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve({ data: json });
            })
            .catch(error => console.log(error));
    });
}

export function changePayment(TipoPagamento, encomendaID, hashEncomenda, NumParcelas, NumeroTelemovelPagamento) {
    return new Promise((resolve, reject) => {
        return fetch("/payment/changePayment", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                TipoPagamento: TipoPagamento,
                encomendaID: encomendaID,
                hashEncomenda: hashEncomenda,
                NumParcelas: NumParcelas,
                NumeroTelemovelPagamento: NumeroTelemovelPagamento
            })
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    });
}

export function getEbanxCheckoutURL() {
    return new Promise((resolve, reject) => {
        return fetch("/payment/getEbanxCheckoutURL", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => console.log(error));
    });
}

export function paymentPaypalConfirmed(id_encomenda) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/payment/paypalConfirmed", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    orderID: id_encomenda
                })
            })
                .then(res => res.json())
                .then(json => {
                    resolve({ data: json });
                    dispatch(loading(false));
                })
                .catch(error => console.log(error));
        });
    };
}

export function paymentPaypalCanceled(id_encomenda) {
    return (dispatch, getState) => {
        dispatch(loading(true));
        return new Promise((resolve, reject) => {
            return fetch("/payment/paypalCanceled", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    orderID: id_encomenda
                })
            })
                .then(res => res.json())
                .then(json => {
                    resolve({ data: json });
                    dispatch(loading(false));
                })
                .catch(error => console.log(error));
        });
    };
}

export const LOADING_CHECKOUT_PRICES = "LOADING_CHECKOUT_PRICES";
export const SHOULD_VERIFY_ORDER = "SHOULD_VERIFY_ORDER";
export const ERROR_CODE_CHECKOUT = "ERROR_CODE_CHECKOUT";
export const FETCH_CHECKOUT_DATA = "FETCH_CHECKOUT_DATA";
export const LOADING = "LOADING";
export const FETCH_PAYMENT_TYPES = "FETCH_PAYMENT_TYPES";
export const SELECT_PAYMENT_PHONE_NUMBER = "SELECT_PAYMENT_PHONE_NUMBER";
export const SELECT_PAYMENT_INSTALLMENTS = "SELECT_PAYMENT_INSTALLMENTS";
export const FETCH_SHIPMENT_TYPES = "FETCH_SHIPMENT_TYPES";
export const FETCH_BILLING_ADDRESSES = "FETCH_BILLING_ADDRESSES";
export const BILLING_ADDRESS = "BILLING_ADDRESS";
export const FETCH_DELIVERY_ADDRESSES = "FETCH_DELIVERY_ADDRESSES";
export const SHIPPING_ADDRESS = "SHIPPING_ADDRESS";

export const setLoadingCheckoutPrices = loadingCheckoutPrices => ({
    type: LOADING_CHECKOUT_PRICES,
    payload: { loadingCheckoutPrices }
})

export const setShouldVerifyOrder = shouldVerifyOrder => ({
    type: SHOULD_VERIFY_ORDER,
    payload: { shouldVerifyOrder }
})

export const setErrorCodeCheckout = errorCode => ({
    type: ERROR_CODE_CHECKOUT,
    payload: { errorCode }
});


export const fetchCheckoutData = checkoutData => ({
    type: FETCH_CHECKOUT_DATA,
    payload: { checkoutData }
});

export const loading = loadingState => ({
    type: LOADING,
    payload: { loadingState }
});

export const fetchPaymentTypes = paymentTypes => ({
    type: FETCH_PAYMENT_TYPES,
    payload: { paymentTypes }
});

export const selectedPaymentPhoneNumber = selectedPaymentPhoneNumber => ({
    type: SELECT_PAYMENT_PHONE_NUMBER,
    payload: { selectedPaymentPhoneNumber }
});

export const selectedPaymentInstallments = selectedPaymentInstallments => ({
    type: SELECT_PAYMENT_INSTALLMENTS,
    payload: { selectedPaymentInstallments }
});

export const fetchShipmentTypes = shipmentTypes => ({
    type: FETCH_SHIPMENT_TYPES,
    payload: { shipmentTypes }
});

export const fetchBillingAddresses = billingAddresses => ({
    type: FETCH_BILLING_ADDRESSES,
    payload: { billingAddresses }
});

export const setBillingAddress = billingAddress => ({
    type: BILLING_ADDRESS,
    payload: { billingAddress }
});

export const fetchDeliveryAddresses = deliveryAddresses => ({
    type: FETCH_DELIVERY_ADDRESSES,
    payload: { deliveryAddresses }
});

export const setShippingAddress = shippingAddress => ({
    type: SHIPPING_ADDRESS,
    payload: { shippingAddress }
})