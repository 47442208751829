import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row } from "reactstrap";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import AfiliadosSemEncomendas from "./Components/AfiliadosSemEncomendas";
import NumeroAfiliados from "./Components/NumeroAfiliados";
import NumeroAfiliadosRede from "./Components/NumeroAfiliadosRede";
import TabelaAfiliados from "./Components/TabelaAfiliados";

class Afiliados extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Afiliados"
            subheading="Página onde pode ver os seus afiliados"
            icon="pe-7s-users icon-gradient bg-ripe-malin"
          />
          <Row>
            <NumeroAfiliados />
            <NumeroAfiliadosRede />
            <AfiliadosSemEncomendas />
          </Row>
          <TabelaAfiliados />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}
const mapStateToProp = state => {
  return {};
};

export default withRouter(connect(mapStateToProp)(Afiliados));
