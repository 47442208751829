import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

class ContactosCliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      contactos: []
    };
  }

  componentDidMount() { }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        style={{ minWidth: "35%" }}
      >
        <ModalHeader toggle={this.props.toggle}>
          Contactos do Cliente
        </ModalHeader>
        <ModalBody>
          {this.props.contactos ? (
            this.props.contactos.map(contacto => {
              return (
                <span style={{ fontSize: "16px",marginBottom:"6px" }}>
                  {contacto.TipoContacto + ": " + contacto.Descricao} <br />
                </span>
              );
            })
          ) : (
              <span>O cliente não tem contactos disponiveis </span>
            )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.props.toggle}>
            Fechar
          </Button>{" "}
        </ModalFooter>
      </Modal>
    );
  }
}

export default ContactosCliente;
