import React, { Component } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { getBusinessesWithClient } from "../../../actions/negociosActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";
import EditarNegocio from "./EditarNegocio";
import RemoverNegocio from "./RemoverNegocio";

var that;
class NegociosComCliente extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0,
      deleteMessage: false
    };
    that = this;
    this.toggle = this.toggle.bind(this);
    this.deleteModal = this.deleteModal.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  deleteModal() {
    this.setState({
      deleteMessage: !this.state.deleteMessage
    });
  }

  getData() {
    getBusinessesWithClient().then(data => {
      if (data.data && !data.message) {
        that.setState({ dataSize: data.data.length });
        that.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      if (data.message) {
        that.setState({
          data: [],
          dataSize: 0
        });
      }
      that.setState({
        columns: [
          {
            Header: "Referencia",
            accessor: "Referencia",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.Referencia ? row.original.Referencia : "-";
            }
          },
          {
            Header: "Descrição",
            accessor: "Descricao",
            resizable: false,
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Data Registo",
            accessor: "DataRegisto",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.DataRegisto ? (
                <FormattedDate value={new Date(row.original.DataRegisto)} />
              ) : (
                "-"
              );
            }
          },
          {
            Header: "Data Último Evento",
            accessor: "UltimoContacto",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.UltimoContacto ? (
                <FormattedMessage id="UltimoContacto" defaultMessage="{date} {time}" values={
                  {
                    date: <FormattedDate value={new Date(row.original.UltimoContacto)} />,
                    time: new Date(row.original.UltimoContacto).getHours() + ":" + new Date(row.original.UltimoContacto).getMinutes() + ":" + new Date(row.original.UltimoContacto).getSeconds()
                  }
                }
                />
              ) : (
                "-"
              );
            }
          },
          {
            Header: "Valor Previsto",
            accessor: "ValorPrevisto",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.ValorPrevisto
                ? row.original.ValorPrevisto
                : "-";
            }
          },
          {
            Header: "Estado Negócio",
            accessor: "EstadoNegocio",
            resizable: false,
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Ações",
            accessor: "EstadoNegocio",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <div>
                  <div className="d-block w-100 text-center">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon btn-icon-only btn btn-link"
                        color="link"
                      >
                        <i className="lnr-menu-circle btn-icon-wrapper" />
                      </DropdownToggle>
                      <DropdownMenu
                        right
                        className="rm-pointers dropdown-menu-hover-link"
                      >
                        <DropdownItem
                          onClick={() => {
                            window.location.href =
                              "/negocio/" + row.original.ID_Negocio;
                          }}
                        >
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Ver Detalhe</span>
                        </DropdownItem>
                        <DropdownItem>
                          <i
                            className="dropdown-icon lnr-file-empty"
                            onClick={() => {
                              that.toggle();
                              that.child.getBusiness(row.original.ID_Negocio);
                            }}
                          >
                            {" "}
                          </i>
                          <span
                            onClick={() => {
                              that.toggle();
                              that.child.getBusiness(row.original.ID_Negocio);
                            }}
                          >
                            Editar Negócio
                          </span>
                        </DropdownItem>
                        {/*<DropdownItem>
                          <i
                            className="dropdown-icon lnr-book"
                            onClick={() => {
                              that.deleteModal();
                              that.child2.setID(row.original.ID_Negocio);
                            }}
                          >
                            {" "}
                          </i>
                          <span
                            onClick={() => {
                              that.deleteModal();
                              that.child2.setID(row.original.ID_Negocio);
                            }}
                          >
                            Remover Negócio
                          </span>
                          </DropdownItem>*/}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                </div>
              );
            }
          }
        ]
      });
      that.setState({ blocking: false });
    });
  }

  componentDidMount() {
    this.props.onRef(this);
    this.getData();
  }
  render() {
    return (
      <span>
        <RemoverNegocio
          onRef={ref => {
            this.child2 = ref;
          }}
          deleteMessage={this.state.deleteMessage}
          deleteModal={this.deleteModal}
          updateData={this.getData}
        />
        <EditarNegocio
          onRef={ref => (this.child = ref)}
          isOpen={this.state.modal}
          toggle={this.toggle}
          updateData={this.getData}
        />
        <SimpleTable
          data={this.state.data}
          columns={this.state.columns}
          blocking={this.state.blocking}
          rowNumber={this.state.rowNumber}
          dataSize={this.state.dataSize}
          noDataText={"Sem negócios para apresentar"}
          title={"Os meus negócios com este cliente"}
        />
      </span>
    );
  }
}

export default NegociosComCliente;
