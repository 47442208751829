// 18-07-2019 - AFONSO - Funcao para obter encomendas de um cliente
export function getAllOrders() {
  return new Promise((resolve, reject) => {
    return fetch("/encomendas/getAllOrders", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter produtos comprados por um cliente
export function getProductsPurchased() {
  return new Promise((resolve, reject) => {
    return fetch("/encomendas/getProductsPurchased", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 23-07-2019 - AFONSO - Funcao para obter detalhe de uma encomenda
export function getOrderDetail(ID_Encomenda) {
  return new Promise((resolve, reject) => {
    return fetch("/encomendas/getOrderDetail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Encomenda: ID_Encomenda
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 24-07-2019 - AFONSO - Funcao para obter produtos mais vendidos
export function getTopSellingProducts() {
  return new Promise((resolve, reject) => {
    return fetch("/encomendas/getTopSellingProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 24-07-2019 - AFONSO - Funcao para obter produtos menos vendidos
export function getBottomSellingProducts() {
  return new Promise((resolve, reject) => {
    return fetch("/encomendas/getBottomSellingProducts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 30-07-2019 - AFONSO - Funcao para obter produtos nao comprados
export function ProductsNotBuyed() {
  return new Promise((resolve, reject) => {
    return fetch("/encomendas/ProductsNotBuyed", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
