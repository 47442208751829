import cx from "classnames";
import React, { Fragment } from "react";

class SearchBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSearch: false
    };
  }

  render() {
    return (
      <Fragment>
        <div
          className={cx("search-wrapper", {
            active: this.state.activeSearch
          })}
        >
          <div className="input-holder">
            <button
              onClick={() => {
                window.location.href = "/pesquisa";
              }}
              className="search-icon"
            >
              <span />
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SearchBox;
