import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Card, CardBody } from "reactstrap";
import { getConsultantsWithLostLeads } from "../../../actions/consultantActions";
import PageTitleAlt4 from "../../../helpers/Layout/PageTitleAlt4";
import { toast, Slide } from "react-toastify";

const columns = [
  {
    Header: "Nome Consultor",
    accessor: "NomeCompleto"
  },
  {
    Header: "Número de Leads Não Tratadas",
    accessor: "NumLeadsNaoTratadas"
  }
]

class LeadsPerdidasPorConsultor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consultores: []
    };
  }

  componentDidMount() {
    this.loadConsultores();
  }

  //Carregar os consultores
  loadConsultores() {
    var promise = new Promise((resolve, reject) => {
      getConsultantsWithLostLeads().then(result => {
        if (result.success === true) {
          this.setState({
            consultores: result.obj
          })
        }
        else {
          //Caso resposta inválida
          this.showToast(result.message, "error");
        }
        resolve(result.obj);
      }).catch(err => {
        this.showToast("Ocorreu um erro ao obter o número de leads perdidas por consultor", "error");
        reject(err);
      })
    });

    return promise;
  }

  showToast(message, type) {
    toast.dismiss();

    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-right",
      type: type
    });
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
          <PageTitleAlt4 heading="Número de Leads perdidas por Consultor" subheading="Página onde pode consultar o Número de Leads perdidas por Consultor" icon="lnr-list icon-gradient bg-ripe-malin" />

          <Card className="main-card mb-6">
            <CardBody>
              <ReactTable                
                resizable={false}
                data={this.state.consultores}
                columns={columns}
                defaultPageSize={5}
                pageSizeOptions={[5, 10, 20]}
                previousText={"Anterior"}
                nextText={"Próxima"}
                pageText={"Página"}
                ofText={"de"}
                rowsText={"linhas"}
                noDataText={"Sem dados"}
                getTheadProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      "text-align": "center"
                    }
                  };
                }}
                getTdProps={(state, rowInfo, column) => {
                  return {
                    style: {
                      "text-align": "center",
                      justifyContent: "center",
                      color:"#495057"
                    }
                  };
                }}
                getTrProps={(state, rowInfo, column, instance) => {
                  return {
                    style: {
                      color:
                        (rowInfo &&
                          rowInfo.original.PodeReceberLeads == 0
                          ? "rgb(255, 0, 0)"
                          : "rgb(0, 0, 0)")
                    }
                  };
                }}
              />
            </CardBody>
          </Card>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(LeadsPerdidasPorConsultor);