import { func } from "prop-types";

// 19-08-2019 - AFONSO - Funcao para obter as leads
export function getLeads() {
  return new Promise((resolve, reject) => {
    return fetch("/lead/getLeads", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getLeadsByEvent(evento, tipoLead) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/getLeadsByEvent", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Evento: evento,
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        var response = {
          tipoLead: tipoLead,
          evento: evento,
          success: json.success,
          obj: json.obj,
          message: json.message
        };
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


export function changeCurrentLeadInFocus(lead) {
  return (dispatch, getState) => {
    dispatch(currentLeadOpen(lead));
  }
}

export function getLeadInfoById(idLead) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/getLeadById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Lead: idLead,
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getLeadById(idLead) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      return fetch("/lead/getLeadById", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Lead: idLead,
        }),
        credentials: "include"
      })
        .then(res => res.json())
        .then(json => {

          //Caso o resultado da API seja sucesso
          if (json.success === true) {

            //Atualizamos os eventos possíveis
            dispatch(possibleEvents(json.obj.EventosPossiveis));

            //Atualizamos os resultados possiveis
            dispatch(possibleResults(json.obj.ResultadosPossiveis));
          }

          resolve(json);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };
}

// 19-08-2019 - AFONSO - Funcao para obter as leads
export function getLeadsCliente() {
  return new Promise((resolve, reject) => {
    return fetch("/lead/getLeadsCliente", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 19-08-2019 - AFONSO - Funcao para criar lead
export function newLead(lead) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/newLead", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Nome: lead.nome,
        Email: lead.email,
        Telemovel: lead.telemovel,
        Pais: lead.id_pais,
        Localidade: lead.localidade,
        Origem: lead.id_origem,
        Tipo_Lead: lead.id_tipo_lead,
        Empresa: lead.id_empresa,
        Cliente_Registou: "",
        NIF: lead.nif,
        Evento: lead.id_estado,
        Nome_Contacto: lead.contacto.nome,
        Email_Contacto: lead.contacto.email,
        Telemovel_Contacto: lead.contacto.telemovel,
        Cargo_Contacto: lead.contacto.cargo,
        Observacoes: lead.observacoes,
        Valor_Previsto: lead.valor
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getTypesLead() {
  return new Promise((resolve, reject) => {
    return fetch("/lead/getTypesLead", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export function getTypeOrigins() {
  return new Promise((resolve, reject) => {
    return fetch("/lead/getTypeOrigins", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export function checkEmail(email) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/checkEmailAssociated", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export function checkNIF(nif, pais) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/checkNIFAssociated", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        nif: nif,
        pais: pais
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export function createContactInLead(leadId, lead) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/createContactInLead", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Lead: leadId,
        Evento: lead.nextEvent,
        Resultado: lead.result,
        Nome_Pessoa: lead.nomePessoa,
        Contacto_Pessoa: lead.contactoPessoa,
        Observacoes: lead.obs,
        Data_Inicio: lead.dataInicio,
        Data_Fim: lead.dataFim,
        Valor_Previsto: lead.value,
        Id_Encomenda: lead.orderId ? lead.orderId : null,
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function changeModalState(state) {
  return (dispatch, getState) => {
    dispatch(clickModal(state));
  };
}

//Carregamos os eventos para cada funil
export function funilLoadEvents(leadType, events) {
  return (dispatch, getState) => {

    //Criamos uma copia do objeto funis
    let funis = [...getState().funisData]

    //Procuramos nos funils, o funil para o tipo de lead=leadType e alteramos os eventos
    funis.find(funil => funil.leadType == leadType).events = events;

    dispatch(changeFunis(funis));
  };
}

//Carregamos as leads para um evento dum funil
export function funilEventsLoadLead(leadType, eventId, leads) {
  return (dispatch, getState) => {

    //Criamos uma copia do objeto funis
    let funis = [...getState().funisData]

    //Procuramos nos funils, o funil para o tipo de lead=leadType e ficamos com os eventos
    var eventsFromFunil = funis.find(funil => funil.leadType == leadType).events;

    //Procuramos o evento cujo id=eventId e alteramos as leads que tem 
    eventsFromFunil.find(event => event.EventoId == eventId).EventoDados = leads;

    dispatch(changeFunis(funis));
  };
}

export function funilChangeLeadEvent(leadId, leadType, leadInfo, currentEvent, nextEvent) {
  return (dispatch, getState) => {

    var currentLead = {};
    var nextEventIndex = -1, currentEventIndex = -1;
    let funis = [...getState().funisData]

    var currentFunil = funis.find(funil => funil.leadType == leadType);
    var currentFunilEvents = currentFunil.events;

    //Percorremos todos os eventos
    for (let i = 0; i < currentFunilEvents.length; i++) {

      //Se o evento da iteração, for o evento atual da lead
      if (currentFunilEvents[i].EventoId === currentEvent) {
        currentEventIndex = i;

        //Guardamos o objeto que é para ser mudado de Lead
        currentLead = currentFunilEvents[i].EventoDados.find(obj => {
          return obj.Lead === leadId;
        });
      }

      //Se o evento da iteração, for o evento a seguir da lead
      if (currentFunilEvents[i].EventoId === nextEvent) {
        nextEventIndex = i;
      }
    }

    //Atribuir novos dados do contacto inserido
    currentLead.Evento = leadInfo.Evento;
    currentLead.Evolucao = leadInfo.Evolucao;
    currentLead.Flash = 1;
    currentLead.Valor = leadInfo.ValorPrevisto;
    currentLead.DataInicio = leadInfo.EventoDataInicio;
    currentLead.DataFim = leadInfo.EventoDataFim;

    //Se estivermos com uma lead que está no estado de registo, e ela está aqui significa que ela mudou de fase, portanto dizemos que já não está no estado de registo
    if (currentLead.EstadoRegisto === true) {
      currentLead.EstadoRegisto = false;
      currentLead.DataFimTratamento = null;
    }
        
    //Apagamos do evento atual a informação sobre a lead
    currentFunilEvents[currentEventIndex].EventoDados = currentFunilEvents[currentEventIndex].EventoDados.filter(lead => {
      return lead.Lead !== leadId;
    });

    //Se conseguirmos encontrar o próximo evento e portanto ele não estiver escondido movemos a Lead para lá
    if (nextEventIndex !== -1) {
      currentLead.EstadoArquivo = currentFunilEvents[nextEventIndex].EventoEstadoArquivo;

      //Acrescentamos a informação da lead no próximo evento
      currentFunilEvents[nextEventIndex].EventoDados.push(currentLead);
    }

    dispatch(changeFunis(funis));
  };
}


export function leadRemoveFlashing(leadId, leadType, eventId) {
  return (dispatch, getState) => {

    let funis = [...getState().funisData]

    var currentFunil = funis.find(funil => funil.leadType == leadType);

    var currentFunilEvents = currentFunil.events;

    var currentEvent = currentFunilEvents.find(event => event.EventoId === eventId).EventoDados;

    var currentLead = currentEvent.find(lead => lead.Lead === leadId);

    currentLead.Flash = 0;

    dispatch(changeFunis(funis));
  };
}

export function getCompanyInfoByLead(leadId) {
  return new Promise((resolve, reject) => {
    return fetch("/lead/getCompanyInfoByLead", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Lead: leadId,
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

// Método para alterar a informação do evento
export function changeEventInfo(leadType, eventId, eventInfo) {
  return (dispatch, getState) => {

    let funis = [...getState().funisData]

    var currentFunil = funis.find(funil => funil.leadType === leadType);
    var currentFunilEvents = currentFunil.events;

    //Percorremos todos os eventos
    for (let i = 0; i < currentFunilEvents.length; i++) {

      //Se o evento da iteração, for o evento atual da lead
      if (currentFunilEvents[i].EventoId === eventId) {
        currentFunilEvents[i].EventoValorLeads = eventInfo.Valor;
        currentFunilEvents[i].EventoNumLeads = eventInfo.Numero;
      }
    }

    dispatch(changeFunis(funis));
  }
}

export function getTypesResults() {
  return new Promise((resolve, reject) => {
    return fetch("/lead/getTypesResults", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export function editLeadContact(leadId) {
  return (dispatch, getState) => {
    dispatch(clickEditModal(leadId));
  }
}

export function advancedLeadSearch(lead, consultant, client, company, contact, distanciaRede, recordsPerPage, currentPage) {
  return (dispatch, getState) => {
    dispatch(leadSearchResultsLoading(true));
    return new Promise((resolve, reject) => {
      return fetch("/lead/advancedLeadSearch", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          DistanciaRede: distanciaRede,
          IdConsultor: consultant.consultantId,
          NomeConsultor: consultant.consultantName,
          TipoLead: lead.leadType,
          Origem: lead.leadOrigin,
          Descricao: lead.leadDescription,
          IdCliente: client.clientId,
          NomeCliente: client.clientName,
          EmailCliente: client.clientEmail,
          TelemovelCliente: client.clientPhoneNumber,
          Localidade: client.clientCity,
          DataInicio: lead.initDate,
          DataFim: lead.endDate,
          ValorPrevisto: lead.value,
          Referencia: lead.leadRef,
          Encomenda: lead.orderId,
          Observacoes: lead.observations,
          IdEmpresa: company.companyId,
          NomeEmpresa: company.companyName,
          EmailEmpresa: company.companyEmail,
          TelemovelEmpresa: company.companyPhoneNumber,
          LocalidadeEmpresa: company.companyCity,
          EvolucaoFase: contact.contactState,
          EvolucaoDataInicio: contact.contactInitDate,
          EvolucaoDataFim: contact.contactEndDate,
          EvolucaoResultado: contact.contactResult,
          EvolucaoObservacao: contact.contactObservation,
          ResultadosPorPagina: recordsPerPage,
          Pagina: currentPage,
        }),
        credentials: "include"
      })
        .then(res => res.json())
        .then(json => {

          if (json.success === true) {
            var totalPages = Math.ceil(json.obj.total / recordsPerPage);
            var newCurrentPage = currentPage;
            if (newCurrentPage + 1 > totalPages) newCurrentPage = 0;

            dispatch(leadSearchResultsCurrentPage({ page: newCurrentPage, pageSize: recordsPerPage, totalPages: totalPages }))
            dispatch(leadSearchResultsLoading(false));
            dispatch(leadSearchResults(json.obj.dados));
            dispatch(leadSearchResultsError(""));
            resolve(true);
            return;
          } else {
            dispatch(leadSearchResultsLoading(false));
            dispatch(leadSearchResultsError(json.message));
            resolve(false);
          }

        })
        .catch(function (error) {
          dispatch(leadSearchResultsLoading(false));
          dispatch(leadSearchResultsError("Erro ao pesquisar as Leads"));
          resolve(false);
        });
    });
  }
};

export function askSearchForNextPage(nextPage, pageSize, requestId) {
  return (dispatch, getState) => {
    var obj = {
      nextPage: nextPage,
      pageSize: pageSize,
      nextRequestId: requestId
    };
    dispatch(leadSearchResultsNextPage(obj));
  };
}


export const SEARCH_RESULTS = "SEARCH_RESULTS";
export const leadSearchResults = searchLeadResults => ({
  type: SEARCH_RESULTS,
  payload: { searchLeadResults }
});

export const SEARCH_RESULTS_LOAD = "SEARCH_RESULTS_LOAD";
export const leadSearchResultsLoading = searchLeadLoading => ({
  type: SEARCH_RESULTS_LOAD,
  payload: { searchLeadLoading }
});

export const SEARCH_RESULTS_ERROR = "SEARCH_RESULTS_ERROR";
export const leadSearchResultsError = searchLeadError => ({
  type: SEARCH_RESULTS_ERROR,
  payload: { searchLeadError }
});

export const SEARCH_RESULTS_NEXT_PAGE = "SEARCH_RESULTS_NEXT_PAGE";
export const leadSearchResultsNextPage = searchLeadNextPage => ({
  type: SEARCH_RESULTS_NEXT_PAGE,
  payload: { searchLeadNextPage }
});

export const SEARCH_RESULTS_CURRENT_PAGE = "SEARCH_RESULTS_CURRENT_PAGE";
export const leadSearchResultsCurrentPage = searchLeadCurrentPage => ({
  type: SEARCH_RESULTS_CURRENT_PAGE,
  payload: { searchLeadCurrentPage }
});

export const EDIT_LEAD_CONTACT = "EDIT_LEAD_CONTACT";
export const clickEditModal = editLeadContact => ({
  type: EDIT_LEAD_CONTACT,
  payload: { editLeadContact }
});

export const MODAL_OPEN = "MODAL";
export const clickModal = modalIsOpen => ({
  type: MODAL_OPEN,
  payload: { modalIsOpen }
});

export const POSSIBLE_EVENTS = "POSSIBLE_EVENTS";
export const possibleEvents = events => ({
  type: POSSIBLE_EVENTS,
  payload: { events }
});

export const POSSIBLE_RESULTS = "POSSIBLE_RESULTS";
export const possibleResults = results => ({
  type: POSSIBLE_RESULTS,
  payload: { results }
});

export const LEAD_OPEN = "LEAD_OPEN";
export const currentLeadOpen = leadOpen => ({
  type: LEAD_OPEN,
  payload: { leadOpen }
})

export const ALL_FUNIS = "ALL_FUNIS";
export const changeFunis = funisData => ({
  type: ALL_FUNIS,
  payload: { funisData }
})

