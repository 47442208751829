// 18-07-2019 - AFONSO - Funcao para obter os pagamentos
export function currentMonthBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/comparativos/currentMonthBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter os pagamentos
export function annualAccumulatedBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/comparativos/annualAccumulatedBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter os pagamentos
export function firstQuarterBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/comparativos/firstQuarterBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter os pagamentos
export function secondQuarterBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/comparativos/secondQuarterBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter os pagamentos
export function thirdQuarterBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/comparativos/thirdQuarterBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter os pagamentos
export function fourthQuarterBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/comparativos/fourthQuarterBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter os pagamentos
export function businessLevels() {
  return new Promise((resolve, reject) => {
    return fetch("/comparativos/businessLevels", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter os pagamentos
export function recommendation() {
  return new Promise((resolve, reject) => {
    return fetch("/comparativos/recommendation", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter os pagamentos
export function untilNowProfitability() {
  return new Promise((resolve, reject) => {
    return fetch("/comparativos/untilNowProfitability", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
