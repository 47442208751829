import { CSSProperties } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export interface SkeletonProps {
    style?: CSSProperties,
    width: number,
    height: number
}

export default function SkeletonComponent(props: SkeletonProps) {
    return (
        <Skeleton style={props.style} height={props.height} width={props.width} />
    );

}