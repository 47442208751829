export function getPaises() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      return fetch("/geral/countries", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        credentials: "include"
      })
        .then(res => res.json())
        .then(json => {
          dispatch(allCountries(json.paises));
          resolve({ data: json });
        })
        .catch(error => console.log(error));
    });
  };
}

export function getPositions() {
  return new Promise((resolve, reject) => {
    return fetch("/geral/getPositions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(error => console.log(error));
  });
}

export const ALL_COUNTRIES = "ALL_COUNTRIES";

export const allCountries = allPaises => ({
  type: ALL_COUNTRIES,
  payload: { allPaises }
});