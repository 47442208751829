import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { getDashboardComparativoAnual } from "../../../actions/homeActions";

var that;
class ComparativoComissoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: true,
      hidden: "initial",
      show: "none",
      data: [],
      maior: 0,
      comissoesGraph: []
    };
    that = this;
  }

  getData() {
    getDashboardComparativoAnual().then(data => {
      if (data && !data.message) {
        this.setState({
          comissoesGraph: data.data,
          maior: data.maior
        });
      }
      this.setState({ blocking: false });
    });
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div />
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">
                      {" "}
                    </i>
                    Comparativo das comissões
                  </div>
                  <div className="btn-actions-pane-right">
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="danger"
                      onClick={() => {
                        this.setState({ hidden: "none", show: "initial" });
                      }}
                      style={{ display: this.state.hidden }}
                    >
                      <i
                        className="lnr-circle-minus"
                        style={{ display: this.state.hidden }}
                        onClick={() => {
                          this.setState({
                            hidden: "none",
                            show: "initial"
                          });
                        }}
                      />
                    </Button>
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="success"
                      onClick={() => {
                        this.setState({ hidden: "initial", show: "none" });
                      }}
                      style={{ display: this.state.show }}
                    >
                      <i
                        className="lnr-plus-circle"
                        style={{ display: this.state.show }}
                        onClick={() => {
                          this.setState({
                            hidden: "initial",
                            show: "none"
                          });
                        }}
                      />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody style={{ display: this.state.hidden }}>
                  <BlockUi
                    tag="div"
                    blocking={this.state.blocking}
                    className="block-overlay-dark"
                    loader={
                      <Loader
                        color="#ffffff"
                        active
                        type="ball-spin-fade-loader"
                      />
                    }
                  >
                    {!this.state.blocking ? (
                      this.state.comissoesGraph[0] ? (
                        <span>
                          <Row>
                            <Col xs="12" sm="12" md="12" lg="12">
                              <Card className="main-card mb-3">
                                <CardBody>
                                  <CardTitle>Comparativo</CardTitle>
                                  <div className="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
                                    <ResponsiveContainer
                                      width="100%"
                                      height={240}
                                    >
                                      <AreaChart
                                        data={this.state.comissoesGraph}
                                        margin={{
                                          top: 0,
                                          right: 10,
                                          left: 10,
                                          bottom: 0
                                        }}
                                      >
                                        <defs>
                                          <linearGradient
                                            id="colorUv"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                          >
                                            <stop
                                              offset="5%"
                                              stopColor="var(--primary)"
                                              stopOpacity={0.8}
                                            />
                                            <stop
                                              offset="95%"
                                              stopColor="var(--primary)"
                                              stopOpacity={0}
                                            />
                                          </linearGradient>
                                          <linearGradient
                                            id="colorPv"
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                          >
                                            <stop
                                              offset="5%"
                                              stopColor="var(--success)"
                                              stopOpacity={0.8}
                                            />
                                            <stop
                                              offset="95%"
                                              stopColor="var(--success)"
                                              stopOpacity={0}
                                            />
                                          </linearGradient>
                                        </defs>
                                        <XAxis dataKey="Mes" />
                                        <YAxis dataKey={this.state.maior} />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <Area
                                          name={`Valor Comissão Ano Anterior (${new Date().getFullYear() - 1})`}
                                          type="monotone"
                                          dataKey="ValorComissaoAnoAnterior"
                                          stroke="var(--primary)"
                                          fillOpacity={1}
                                          fill="url(#colorUv)"
                                        />
                                        <Area
                                          name={`Valor Comissão Ano Atual (${new Date().getFullYear()})`}
                                          type="monotone"
                                          dataKey="ValorComissaoAnoAtual"
                                          stroke="var(--success)"
                                          fillOpacity={1}
                                          fill="url(#colorPv)"
                                        />
                                      </AreaChart>
                                    </ResponsiveContainer>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </span>
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          {!this.state.blocking
                            ? "Não existem informações sobre as comissões"
                            : null}
                        </div>
                      )
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        {!this.state.blocking
                          ? "Não existem informações sobre as comissões"
                          : null}
                      </div>
                    )}
                  </BlockUi>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default ComparativoComissoes;
