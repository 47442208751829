const _ = require("lodash");

var datalayer = {
    addToCart: {},
    addToCart_Old: {},
    removeFromCart: {},
    removeFromCart_Old: {},
    view_cart: {},
    checkout_Encomendar: {},
    checkout_Encomendar_Old: {},
    checkout_MeioEnvio: {},
    checkout_MetodoPagamento: {}
};

export interface GoogleAnalyticsProduct {
    ID_Produto: string | null,
    ID_TipoProduto: string | null,
    ID_Pack: string | null,
    ID_TipoPack: string | null,
    Descricao: string,
    Quantidade: string,
    Preco: string
}

export default class GoogleAnalytics {
    //Adicionar produto ao carrinho
    AddProductToCart(product: GoogleAnalyticsProduct) {
        this.AddToCart(product);
        this.AddToCart_Old(product);
    }

    private ConstructItemToSend(product: GoogleAnalyticsProduct) {
        return {
            item_name: product.Descricao,
            item_id: typeof product.ID_Produto !== 'undefined' && product.ID_Produto != null
                ? "P" + product.ID_Produto
                : "K" + product.ID_Pack,
            price: product.Preco,
            item_category: typeof product.ID_TipoProduto !== 'undefined' && product.ID_TipoProduto != null
                ? "P-" + product.ID_TipoProduto
                : "K-" + product.ID_TipoPack,
            quantity: product.Quantidade
        }
    }

    private AddToCart(product: GoogleAnalyticsProduct) {
        datalayer.addToCart = {
            event: "add_to_cart",
            ecommerce: {
                items: [this.ConstructItemToSend(product)]
            }
        };

        var x = window as any;
        x.dataLayer = x.dataLayer || [];
        x.dataLayer.push(datalayer.addToCart);
    }

    private AddToCart_Old(product: GoogleAnalyticsProduct) {
        datalayer.addToCart_Old = {
            event: "eec.addToCart",
            ecommerce: {
                add: {
                    actionField: {
                        list: typeof product.ID_TipoProduto !== 'undefined' && product.ID_TipoProduto != null ? product.ID_TipoProduto : product.ID_TipoPack
                    },
                    products: [
                        {
                            id: typeof product.ID_Produto !== 'undefined' && product.ID_Produto != null
                                ? "P" + product.ID_Produto
                                : "K" + product.ID_Pack,
                            name: product.Descricao,
                            category: typeof product.ID_TipoProduto !== 'undefined' && product.ID_TipoProduto != null
                                ? "P-" + product.ID_TipoProduto
                                : "K-" + product.ID_TipoPack,
                            quantity: product.Quantidade
                        }
                    ]
                }
            }
        };

        var x = window as any;
        x.dataLayer = x.dataLayer || [];
        x.dataLayer.push(datalayer.addToCart_Old);
    }

    // Remover do carrinho
    RemoveFromCart(product: GoogleAnalyticsProduct) {
        datalayer.removeFromCart = {
            event: "remove_from_cart",
            ecommerce: {
                items: [{
                    item_name: product.Descricao,
                    item_id: product.ID_Produto !== null
                        ? "P" + product.ID_Produto
                        : "K" + product.ID_Pack,
                    price: product.Preco,
                    item_category: product.ID_TipoProduto !== null
                        ? "P-" + product.ID_TipoProduto
                        : "K-" + product.ID_TipoPack,
                    quantity: product.Quantidade
                }]
            }
        };

        var x = window as any;
        x.dataLayer = x.dataLayer || [];
        x.dataLayer.push(datalayer.removeFromCart);
    }

    RemoveFromCart_Old(product: GoogleAnalyticsProduct) {
        datalayer.removeFromCart_Old = {
            event: "eec.remove",
            ecommerce: {
                remove: {
                    actionField: {
                        list: product.ID_TipoProduto
                    },
                    products: [
                        {
                            id:
                                product.ID_Produto !== null
                                    ? "P" + product.ID_Produto
                                    : "K" + product.ID_Pack,
                            name: product.Descricao,
                            category:
                                product.ID_TipoProduto !== null
                                    ? "P-" + product.ID_TipoProduto
                                    : "K-" + product.ID_TipoPack,
                            quantity: product.Quantidade
                        }
                    ]
                }
            }
        };

        var x = window as any;
        x.dataLayer = x.dataLayer || [];
        x.dataLayer.push(datalayer.removeFromCart_Old);
    }

    SendCartToGA(cart: any) {
        if (typeof cart !== 'undefined' && cart !== null) {
            var items = this.CreateProductObjectToSendToGoogleAnalytics(cart.Carrinho, cart.CarrinhoPack);
            this.ViewCart(items);
        }
    }

    CreateProductObjectToSendToGoogleAnalytics(products: any[], packs: any[]) {
        var items: GoogleAnalyticsProduct[] = [];

        if (typeof products !== 'undefined' && products !== null) {
            products.forEach((product): any => {
                items.push({
                    ID_Produto: product.ID_Produto,
                    ID_TipoProduto: product.ID_TipoProduto,
                    Descricao: product.Descricao,
                    Preco: product.subtotal ?? 0,
                    Quantidade: product.Quantidade,
                    ID_Pack: null,
                    ID_TipoPack: null
                });
            });
        }

        if (typeof packs !== 'undefined' && packs !== null) {
            packs.forEach((pack): any => {
                items.push({
                    ID_Pack: pack.ID_Pack,
                    ID_TipoPack: pack.ID_TipoPack,
                    Descricao: pack.Descricao,
                    Preco: pack.subtotal ?? 0,
                    Quantidade: pack.Quantidade,
                    ID_Produto: null,
                    ID_TipoProduto: null
                });
            })
        }

        return items
    }

    private ViewCart(products: GoogleAnalyticsProduct[]) {
        // Obter os produtos formatados para analytics
        var items = this.ProductsToProductsAnalytics(products);
        // Criação de dataLayer de compra
        datalayer.view_cart = {
            event: "view_cart",
            ecommerce: {
                items: items
            }
        };

        var x = window as any;
        x.dataLayer = x.dataLayer || [];
        x.dataLayer.push(datalayer.view_cart);
    }

    // Função para transformar produtos em produtos de analytics
    private ProductsToProductsAnalytics(orderProducts: GoogleAnalyticsProduct[]) {
        var items: any = [];
        orderProducts.forEach((product: GoogleAnalyticsProduct) => {
            items.push(this.ConstructItemToSend(product));
        });

        return items;
    };

    // Adicionar metodo de pagamento
    Checkout_Encomendar(order, orderProducts: GoogleAnalyticsProduct[]) {
        // Obter os produtos formatados para analytics
        var items = this.ProductsToProductsAnalytics(orderProducts);
        // Criação de dataLayer de compra
        datalayer.checkout_Encomendar = {
            event: "purchase",
            ecommerce: {
                currency: "EUR",
                value: order.precoFinal,
                tax: order.precoTaxa,
                affiliation: "Loja Online",
                transaction_id: order.IDEncomenda,
                coupon: order.codigoPromocional,
                items: items
            }
        };

        var x = window as any;
        x.dataLayer = x.dataLayer || [];
        x.dataLayer.push(datalayer.checkout_Encomendar);
    }

    Checkout_Encomendar_Old(order, products) {
        // Obter os produtos formatados para analytics
        var productsAnalytics = this.ProductsToProductsAnalytics_Old(products);
        // Criação de dataLayer de compra
        datalayer.checkout_Encomendar_Old = {
            event: "eec.purchase",
            ecommerce: {
                currencyCode: "EUR",
                purchase: {
                    actionField: {
                        id: order.IDEncomenda,
                        affiliation: "Loja Online",
                        revenue: order.precoFinal,
                        tax: order.precoTaxa,
                        shipping: order.precoEnvio,
                        coupon: order.CodigoPromocional
                    },
                    products: productsAnalytics
                }
            }
        };

        var x = window as any;
        x.dataLayer = x.dataLayer || [];
        x.dataLayer.push(datalayer.checkout_Encomendar_Old);
    }

    private ProductsToProductsAnalytics_Old(products) {
        var productAux = {},
            productsAnalytics: any = [];

        products.map(function (x, index) {
            productAux = {
                // Id de produto
                id: !_.isUndefined(x.ID_Produto) ? "P" + x.ID_Produto : "K" + x.ID_Pack,
                // Nome de produto
                name: x.Descricao,
                // Categoria do produto
                category: !_.isUndefined(x.ID_TipoProduto)
                    ? "P-" + x.ID_TipoProduto
                    : "K-" + x.ID_TipoPack,
                price: x.price,
                quantity: x.quantity
            };
            // Adicionar ao array de produtos
            productsAnalytics.push(productAux);
        });
        return productsAnalytics;
    };

    // Adicionar meio de envio
    Checkout_MeioEnvio(orderProducts: GoogleAnalyticsProduct[], shippment_type) {
        var items = this.ProductsToProductsAnalytics(orderProducts);

        datalayer.checkout_MeioEnvio = {
            event: "add_shipping_info",
            ecommerce: {
                shipping_tier: shippment_type,
                items: items
            }
        };

        var x = window as any;
        x.dataLayer = x.dataLayer || [];
        x.dataLayer.push(datalayer.checkout_MeioEnvio);
    }

    // Adicionar metodo de pagamento
    Checkout_MetodoPagamento(orderProducts: GoogleAnalyticsProduct[], paymentType) {
        var items = this.ProductsToProductsAnalytics(orderProducts);

        datalayer.checkout_MetodoPagamento = {
            event: "add_payment_info",
            ecommerce: {
                payment_type: paymentType,
                items: items
            }
        };

        var x = window as any;
        x.dataLayer = x.dataLayer || [];
        x.dataLayer.push(datalayer.checkout_MetodoPagamento);
    }
}