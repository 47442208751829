import React, { Component } from "react";
import CountUp from "react-countup";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { commissionsResume } from "../../../actions/comissoesActions";

class ComissoesDisponiveis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comissoes: 0
    };
  }

  componentDidMount() {
    commissionsResume().then(data => {
      if (data.DisponivelPagamento && !data.message) {
        this.setState({ comissoes: data.DisponivelPagamento });
      }
    });
  }
  render() {
    return (
      <Col md="4">
        <div className="card mb-3 widget-chart">
          <div className="icon-wrapper rounded-circle">
            <div className="icon-wrapper-bg bg-primary" />
            <i className="pe-7s-cash text-primary" />
          </div>
          <div className="widget-numbers text-success">
            <CountUp
              start={0}
              decimals={2}
              end={this.state.comissoes}
              prefix="€"
              duration="3"
            />
          </div>
          <div className="widget-subheading">Comissões disponiveis</div>
        </div>
      </Col>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user,
  pesquisa: state.pesquisa
});

export default connect(mapStateToProps)(ComissoesDisponiveis);
