import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { FormattedDate } from "react-intl";
import { connect } from "react-redux";
import { expirateDateDownline } from "../../../actions/homeActions";
import { changeUser, getContacts } from "../../../actions/userActions";
import ContactosCliente from "../../../helpers/ContactosCliente";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class Downlines extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0,
      contactos: []
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    expirateDateDownline().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "",
            width: 45,
            Cell: row => {
              return (
                <span
                  onClick={() => {
                    this.getUserContacts(row.original.ID_Cliente);
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                </span>
              );
            }
          },
          {
            Header: "Nome",
            accessor: "NomeCompleto",
            resizable: false,
            Cell: row => {
              return (
                <span style={{cursor:"pointer"}}
                onClick={() => {
                  this.props
                    .dispatch(changeUser(row.original.ID_Cliente))
                    .then(() => {
                      window.location.href = "/";
                    });
                }}
                >
                  {row.original.NomeCompleto}
                </span>
              );
            },
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Tipo de cliente",
            accessor: "Descricao",
            resizable: false,
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Data Validade",
            accessor: "DataValidade",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.DataValidade ? (
                <FormattedDate
                  value={new Date(row.original.DataValidade)}
                />
              ) : (
                "-"
              );
            }
          },
        ]
      });
      this.setState({ blocking: false });
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  getUserContacts(id) {
    getContacts(id).then(data => {
      if (data && !data.message) {
        this.setState({ contactos: data });
        this.toggle();
      }
    });
  }
  render() {
    return (
      <span>
        <ContactosCliente
          onRef={ref => (this.child = ref)}
          isOpen={this.state.modal}
          toggle={this.toggle}
          contactos={this.state.contactos}
        />
        <SimpleTable
          data={this.state.data}
          columns={this.state.columns}
          blocking={this.state.blocking}
          rowNumber={this.state.rowNumber}
          dataSize={this.state.dataSize}
          noDataText={"Sem downlines para apresentar"}
          title={"Downlines com contrato a expirar nos proximos 90 dias"}
        />
      </span>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(Downlines);
