import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { getTopBuyers } from "../../../actions/indicadoresActions";
import { changeUser, getContacts } from "../../../actions/userActions";
import ContactosCliente from "../../../helpers/ContactosCliente";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class TopClientes extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0,
      contactos: []
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    getTopBuyers().then(data => {
      if (data.clientes && !data.message) {
        this.setState({ dataSize: data.clientes.length });
        this.setState({
          data: data.clientes,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "",
            width: 45,
            Cell: row => {
              return (
                <span
                  onClick={() => {
                    this.getUserContacts(row.original.ID_Cliente);
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                </span>
              );
            }
          },
          {
            Header: "Nome Cliente",
            accessor: "NomeCompleto",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <span  style={{cursor:"pointer"}}
                  onClick={() => {
                    this.props
                      .dispatch(changeUser(row.original.ID_Cliente))
                      .then(() => {
                        window.location.href = "/";
                      });
                  }}
                >
                  {row.original.NomeCompleto}
                </span>
              );
            }
          },
          {
            Header: "Valor faturação",
            accessor: "totalCompras",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.totalCompras}
                  style="currency"
                  currency="EUR"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  getUserContacts(id) {
    getContacts(id).then(data => {
      if (data && !data.message) {
        this.setState({ contactos: data });
        this.toggle();
      }
    });
  }
  render() {
    return (
      <span>
      <ContactosCliente
        onRef={ref => (this.child = ref)}
        isOpen={this.state.modal}
        toggle={this.toggle}
        contactos={this.state.contactos}
      />
      <SimpleTable
        data={this.state.data}
        columns={this.state.columns}
        blocking={this.state.blocking}
        rowNumber={this.state.rowNumber}
        dataSize={this.state.dataSize}
        noDataText={"Sem clientes para apresentar"}
        title={"Clientes com maior volume de compras"}
      />
    </span>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(TopClientes);
