import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@material-ui/core';
import React, { Component } from 'react';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import { Slide, toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import { Funnel, FunnelChart, LabelList } from 'recharts';
import { getConversionStats } from '../../../actions/statsActions';

export default class ConversionStats extends Component {

    constructor(props) {
        super(props);

        var hidden = "none";
        var show = "initial";

        this.state = {
            hidden: hidden,
            show: show,
            stats: [],
            screenWidth: 0,
            funnelWidth: 1000,
            funnelHeight: 400,
            funnelMargin: 5,
            funnelBarHeight: 70,
            isLoading: true,
            profundidadeRede: 999,
            initDate: null,
            endDate: null,
        }

        this.handleChange = this.handleChange.bind(this);
        this.loadStats = this.loadStats.bind(this);
    }

    //Label com o nome da Fase
    CustomLabel = (props) => {
        const { y, height, value } = props;

        return (
            <g>
                <text x={(this.state.funnelWidth - this.state.funnelMargin * 2) / 2} y={y + height / 3} style={{ fontSize: "18px", fontWeight: "600", padding: "5px", textShadow: "black 0px 0px 9px" }} fill="#fff" textAnchor="middle" dominantBaseline="middle">
                    {value}
                </text>
            </g>
        );
    };

    //Label com a informação descritiva da fASE
    CustomLabelDesc = (props) => {
        const { y, height, value } = props;

        var info = this.state.stats.find(s => s.id == value);
        return (
            <g>
                <text x={(this.state.funnelWidth - this.state.funnelMargin * 2) / 2} y={y + height * 0.75} style={{ fontSize: "15px", fontWeight: "600", padding: "5px", textShadow: "black 0px 0px 9px" }} fill="#fff" textAnchor="middle" dominantBaseline="middle">
                    Número Leads: {info.NumeroLeads} | Valor: {info.ValorAcumulado} | Taxa Conversão: {info.TaxaConversao}% | Tempo Méd. Transição: {this.calculateTimeInHumanLanguage(info.TempoMedio)}
                </text>
            </g>
        );
    };

    //Função para calcular o tempo de forma "humana"
    calculateTimeInHumanLanguage(timeInSeconds) {

        //Maior que 1 dia 
        if (timeInSeconds >= 86400) {
            return (Math.floor(timeInSeconds / 86400)) + " dias";
        }

        //Maior que 1 hora
        if (timeInSeconds >= 3600) {
            return (Math.floor(timeInSeconds / 3600)) + " horas";
        }

        //Maior que 1 minuto 
        if (timeInSeconds >= 60) {
            return (Math.floor(timeInSeconds / 60)) + " minutos";
        }

        if (timeInSeconds <= 0) return 0;

        return timeInSeconds + " segundos";
    };

    //Quando estiver visivel, vamos buscar as stats
    componentDidUpdate(prevProps, prevState) {
        if (prevState.show === 'initial' && this.state.show === 'none') {
            this.getFunilStats();
        }
    }

    //Método para ir buscar as stats
    getFunilStats() {
        this.setLoading(true);
        getConversionStats(this.props.leadType, this.state.profundidadeRede, this.state.initDate, this.state.endDate)
            .then(result => {

                if (result.success === true) {
                    var dados = [];

                    result.obj.forEach(o => {
                        dados.push({
                            fill: o.Cor,
                            Descricao: o.Descricao,
                            NumeroLeads: o.NumeroLeads,
                            TaxaConversao: o.TaxaConversao,
                            TempoMedio: o.TempoMedio,
                            ValorAcumulado: o.ValorAcumulado,
                            id: o.ID_EstadoNegocio
                        });
                    });


                    var count = dados.length;
                    this.setState(prevState => ({
                        ...prevState,
                        stats: dados,
                        funnelHeight: count * prevState.funnelBarHeight
                    }))
                    this.setLoading(false);
                } else {
                    this.setLoading(false);
                    this.showToast(result.message, "error");
                }
            })
            .catch(err => {
                console.log(err.message);
                this.setLoading(false);
                this.showToast("Erro ao carregar estatísticas de conversão", "error");
            })
    }

    //Show Toast de error
    showToast(message, type) {
        toast(message, {
            transition: Slide,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-right",
            type: type
        });
    }

    //Colocamos se estamos a carregar ou não a informação
    setLoading(value) {
        this.setState(prevState => ({
            ...prevState,
            isLoading: value
        }));
    }

    //Handle botão de pesquisar
    loadStats() {
        this.getFunilStats();
    }

    //Lidar com as alterações no campo id de profundiade
    handleChange(e) {
        let value = e.target.value;
        let name = e.target.name;

        this.setState(prevState => ({
            ...prevState,
            [name]: value
        })
        );
    }

    handleDataFim = DataFim => {
        this.setState(prevState => ({
            ...prevState,
            endDate: DataFim
        }))
    }

    handleDataInicio = DataInicio => {
        this.setState(prevState => ({
            ...prevState,
            initDate: DataInicio
        }))
    }

    render() {
        return (
            <div>
                <Card className="main-card mb-6">

                    {/* Onde está o titulo do cartão e a opção de minimar ou aumentar*/}
                    <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">{" "}</i>
                            {this.props.title}
                        </div>
                        <div className="btn-actions-pane-right">
                            <Button className="mr-2 btn-icon btn-icon-only" outline color="danger" onClick={() => { this.setState({ hidden: "none", show: "initial" }); }} style={{ display: this.state.hidden }}>
                                <i className="lnr-circle-minus" style={{ display: this.state.hidden }} onClick={() => { this.setState({ hidden: "none", show: "initial" }); }} />
                            </Button>
                            <Button className="mr-2 btn-icon btn-icon-only" outline color="success" onClick={() => { this.setState({ hidden: "initial", show: "none" }); }} style={{ display: this.state.show }}>
                                <i className="lnr-plus-circle" style={{ display: this.state.show }} onClick={() => { this.setState({ hidden: "initial", show: "none" }); }} />
                            </Button>
                        </div>
                    </CardHeader>

                    <CardBody style={{ display: this.state.hidden, maxHeight: "600px", overflowY: "auto" }}>
                        <Row className="flex-nowrap">
                            <Col md={12}>

                                {/* Campos para a parte de pesquisa */}
                                <Row style={{ display: "flex", alignItems: "center", alignContent: "center" }}>

                                    {/* Contem input de profundidade e de datas*/}
                                    <Col  md={7} lg={6}>

                                        {/* Label para input de profundidade */}
                                        <Row style={{ margin: "0px", padding: "0px" }}>
                                            <Col style={{ margin: "0px", padding: "0px" }}>
                                                <Label style={{ margin: "0px" }} >Insira o nível de profundidade da rede</Label>
                                            </Col>
                                        </Row>

                                        {/* Input de profundidade */}
                                        <Row style={{ display: "flex", alignItems: "start", justifyContent: "start", margin: "0px", padding: "0px" }}>
                                            <Col style={{ margin: "0px 7px 5px 0px", padding: "0px" }}>
                                                <Input type="number" min="1" max="999" bsSize="sm" fullWidth={false} name="profundidadeRede" value={this.state.profundidadeRede} onChange={this.handleChange} placeholder={"Nível Rede"}></Input>
                                            </Col>
                                        </Row>
                                        <Row style={{ margin: "0px", padding: "0px" }}>
                                            <Col md={6}>
                                                <Row>
                                                    <Col xs={12} lg={12} md={12} style={{ margin: "0px", padding: "0px" }}>
                                                        <Label style={{ margin: "0px", textAlign: "right" }}> Data Início: </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{ margin: "0px", padding: "0px" }}>
                                                        <DateTimePicker calendarIcon={<div className="input-group-text"> <FontAwesomeIcon icon={faCalendarAlt} /> </div>} autoComplete="off" value={this.state.initDate} onChange={this.handleDataInicio} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={6}>
                                                <Row>
                                                    <Col xs={12} lg={12} md={12} style={{ margin: "0px", padding: "0px" }}>
                                                        <Label style={{ margin: "0px", textAlign: "right" }}> Data Fim: </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{ margin: "0px", padding: "0px" }}>
                                                        <DateTimePicker calendarIcon={<div className="input-group-text"> <FontAwesomeIcon icon={faCalendarAlt} /> </div>} autoComplete="off" value={this.state.endDate} onChange={this.handleDataFim} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>

                                    {/* Botão de pesquisar lead*/}
                                    <Col md={5} lg={6}>
                                        <Button color="primary" disabled={this.state.isLoading} onClick={this.loadStats}>Pesquisar</Button>
                                    </Col>
                                </Row>


                                <br />

                                {this.state.isLoading === true ?
                                    (<div>
                                        <label style={{ fontWeight: "600", fontStyle: "italic", marginTop: "15px", marginRight: "10px", fontSize: "15px" }}>
                                            A carregar informação de conversão...
                                            </label>
                                        <CircularProgress thickness={2} size={25} disableShrink />
                                    </div>)
                                    : (
                                        <Card style={{ marginTop: "10px" }}>
                                            <Row className="flex-nowrap" style={{ backgroundColor: "#E9ECEE", overflowY: "auto", margin: "0px", padding: "0px" }}>
                                                <Col md={12} style={{ margin: "0px", padding: "0px" }}>
                                                    <FunnelChart height={this.state.funnelHeight} width={this.state.funnelWidth}>
                                                        <Funnel dataKey="NumeroLeads" data={this.state.stats} isAnimationActive animationDuration={400} offset={0}>
                                                            <LabelList dataKey="Descricao" offset={0} content={this.CustomLabel} />
                                                            <LabelList dataKey="id" offset={0} content={this.CustomLabelDesc} />
                                                        </Funnel>
                                                    </FunnelChart>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div >
        )
    }
}
