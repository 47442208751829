import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { getAddress, modifyShippingAddress } from '../../../actions/checkoutActions';
import SearchInputComponent from '../../../helpers/SearchComponents/SearchInputComponent';
import SearchListComponent, { ListProps } from '../../../helpers/SearchComponents/SearchListComponent';
import Toast from '../../../helpers/Toast';
import { ValidateShippingAddress } from '../../../helpers/UtilsCheckout';
import IHTPButton from '../../Common/Button';
import IHTPSpacer from '../../Common/Spacer';

const alterarMoradaInitialState = {
    id_cliente_morada: "",
    nome: "",
    morada: "",
    localidade: "",
    codigoPostal: "",
    principal: "S",
    id_pais: "",
    ID_Tipo_Morada: ""
}

export class EditShippingDTO {
    isOpen: boolean = false;
    shippingAddressId: number | null = null;
}

export interface EditShippingAddressProps {
    onClose: () => void,
    shippingDTO: EditShippingDTO
}

export default function EditShippingAddress(props: EditShippingAddressProps) {

    const [alterarMorada, setAlterarMorada] = useState(alterarMoradaInitialState);
    const [loadingUpdateAddress, setLoadingUpdateAddress] = useState<boolean>(false);
    const [loadingInput, setLoadingInput] = useState<boolean>(true);

    const dispatch = useDispatch();
    const allPaises = useSelector((state: any) => state.allPaises);
    const paisesOptions: ListProps[] = useMemo(() => {
        if (typeof allPaises === "undefined" || allPaises == null) return [];

        var paises: ListProps[] = [];
        allPaises.forEach(i => {
            paises.push({
                value: i.alpha3,
                label: i.name
            });
        })
        return paises;
    }, [allPaises])

    const [defaultAddressOptions, setDefaultAddressOptions] = useState<ListProps[]>([
        { value: "N", label: "Não" },
        { value: "S", label: "Sim" }
    ]);

    useEffect(() => {

        if (props.shippingDTO.isOpen === true) {
            loadEditAddress(props.shippingDTO.shippingAddressId);
        }

        return () => { }
    }, [props.shippingDTO.isOpen, props.shippingDTO.shippingAddressId])


    const loadEditAddress = async (addressId: any) => {
        setLoadingInput(true);
        var data: any = await dispatch(getAddress(addressId));
        setLoadingInput(false);

        setAlterarMorada({
            id_cliente_morada: data.data.address[0].ID_Cliente_Morada,
            nome: data.data.address[0].Nome,
            morada: data.data.address[0].Morada,
            localidade: data.data.address[0].Localidade,
            codigoPostal: data.data.address[0].CodigoPostal,
            principal: data.data.address[0].Principal,
            id_pais: data.data.address[0].Pais,
            ID_Tipo_Morada: data.data.address[0].ID_Tipo_Morada
        });
    }

    const handleFormModifyShippingAddressSubmit = async () => {

        var resultValidation = ValidateShippingAddress(alterarMorada, dispatch);
        if (resultValidation.success === false) {
            Toast.Show("error", resultValidation.message);
            return;
        }

        setLoadingUpdateAddress(true);
        const data: any = await dispatch(modifyShippingAddress(alterarMorada))
        setLoadingUpdateAddress(false);

        if (data.success !== true) {
            Toast.Show("error", data.message);
            return;
        }

        Toast.Show("success", data.message);

        props.onClose();
    }

    const handleCleanModifyShippingAddress = () => {
        props.onClose();
    }

    return (
        <Row style={{ textAlign: "center" }}>
            <div className="shippingModifyAddress">
                <Col xs={12} sm={12} md={12} lg={12}>

                    {/*Linha com titulo e botão de cancelar alteração*/}
                    <Row className="shippingRowTitleAddress">
                        <span className="titleOptionsAddress">
                            Modificar morada de envio
                        </span>

                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
                            <SearchInputComponent labelText={'Nome'}
                                onChange={(e: any) => setAlterarMorada({ ...alterarMorada, nome: e.target.value })}
                                initialValue={alterarMorada.nome} value={alterarMorada.nome}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchInputComponent labelText={"Morada"}
                                onChange={(e: any) => setAlterarMorada({ ...alterarMorada, morada: e.target.value })}
                                initialValue={alterarMorada.morada} value={alterarMorada.morada}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchInputComponent labelText={"Localidade"}
                                onChange={(e: any) => setAlterarMorada({ ...alterarMorada, localidade: e.target.value })}
                                initialValue={alterarMorada.localidade} value={alterarMorada.localidade}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchInputComponent labelText={"Código Postal"}
                                onChange={(e: any) => setAlterarMorada({ ...alterarMorada, codigoPostal: e.target.value })}
                                initialValue={alterarMorada.codigoPostal} value={alterarMorada.codigoPostal}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchListComponent labelText={"País"}
                                list={paisesOptions}
                                onChange={(e: any) => setAlterarMorada({ ...alterarMorada, id_pais: e.target.value })}
                                initialValue={alterarMorada.id_pais}
                                value={alterarMorada.id_pais}
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchListComponent labelText={"Definir como padrão"}
                                list={defaultAddressOptions}
                                onChange={(e: any) => setAlterarMorada({ ...alterarMorada, principal: e.target.value })}
                                initialValue={alterarMorada.principal}
                                value={alterarMorada.principal}
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"20px"} />

                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                <Col xl={6} lg={6} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "1rem" }}>
                                    <IHTPButton
                                        text='Guardar alterações'
                                        buttonStyle='primary'
                                        onClick={handleFormModifyShippingAddressSubmit}
                                        loading={loadingUpdateAddress}
                                        disabled={loadingInput} />

                                    <IHTPButton
                                        text='Cancelar'
                                        buttonStyle='secondary'
                                        onClick={handleCleanModifyShippingAddress}
                                        disabled={loadingUpdateAddress} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Row>
    )
}
