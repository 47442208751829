import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { getEventsByLeadTypeFiltered } from "../../../../actions/eventsActions";
import { advancedLeadSearch, getTypeOrigins, getTypesLead, getTypesResults } from "../../../../actions/leadsActions";
import './Pesquisa.css';


var consultant = {
    consultantId: null,
    consultantName: null,
};

var lead = {
    leadType: null,
    leadOrigin: null,
    leadDescription: null,
    initDate: null,
    endDate: null,
    value: null,
    leadRef: null,
    orderId: null,
    observations: null,
};

var client = {
    clientId: null,
    clientName: null,
    clientEmail: null,
    clientPhoneNumber: null,
    clientCity: null,
};

var company = {
    companyId: null,
    companyName: null,
    companyEmail: null,
    companyPhoneNumber: null,
    companyCity: null,
};

var contact = {
    contactState: null,
    contactInitDate: null,
    contactEndDate: null,
    contactResult: null,
    contactObservation: null,
};

class Pesquisa extends Component {

    constructor(props) {
        super(props);

        this.state = {
            recordsPerPage: 5,
            currentPage: 0,
            profundidadeRede: 1,
            leadTypes: [],
            leadOrigins: [],
            leadResults: [],
            leadEvents: [],
            searchInfo: {
                consultantId: "",
                consultantName: "",
                leadType: "",
                leadOrigin: "",
                description: "",
                clientId: "",
                clientName: "",
                clientEmail: "",
                clientPhoneNumber: "",
                clientCity: "",
                companyId: "",
                companyName: "",
                companyEmail: "",
                companyPhoneNumber: "",
                companyCity: "",
                initDate: "",
                endDate: "",
                value: null,
                ref: "",
                orderId: null,
                observations: "",
                contactState: "",
                contactInitDate: "",
                contactEndDate: "",
                contactResult: "",
                contactObservation: "",
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDistancia = this.handleChangeDistancia.bind(this);
        this.loadLeadOrigins = this.loadLeadOrigins.bind(this);
        this.searchForResults = this.searchForResults.bind(this);
        this.handleChangeLeadType = this.handleChangeLeadType.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
    }

    handleSearchClick() {
        this.setState(prevState => ({
            ...prevState,
            currentPage: 0
        }), () => {
            var state = { ...this.state.searchInfo };

            consultant = {
                consultantId: state.consultantId,
                consultantName: state.consultantName,
            };

            lead = {
                leadType: (state.leadType == "0") ? null : state.leadType,
                leadOrigin: (state.leadOrigin == "0") ? null : state.leadOrigin,
                leadDescription: state.leadDescription,
                initDate: state.initDate,
                endDate: state.endDate,
                value: state.value,
                leadRef: state.leadRef,
                orderId: state.orderId,
                observations: state.observations
            };

            client = {
                clientId: state.clientId,
                clientName: state.clientName,
                clientEmail: state.clientEmail,
                clientPhoneNumber: state.clientPhoneNumber,
                clientCity: state.clientCity
            };

            company = {
                companyId: state.companyId,
                companyName: state.companyName,
                companyEmail: state.companyEmail,
                companyPhoneNumber: state.companyPhoneNumber,
                companyCity: state.companyCity
            };

            contact = {
                contactState: (state.contactState == '0') ? null : state.contactState,
                contactInitDate: state.contactInitDate,
                contactEndDate: state.contactEndDate,
                contactResult: (state.contactResult == '0') ? null : state.contactResult,
                contactObservation: state.contactObservation
            };

            this.searchForResults()
        })
    }

    componentDidMount() {
        this.loadLeadOrigins();
        this.loadLeadTypes();
        this.loadLeadResults();
    }

    //Buscar os diferentes tipos de origens de leads
    loadLeadOrigins() {
        getTypeOrigins()
            .then(result => {
                this.setState(prevState => ({
                    ...prevState,
                    leadOrigins: result
                }));
            }).catch(() => {

            });
    }

    //Buscar os diferentes tipos de lead existentes
    loadLeadTypes() {
        getTypesLead()
            .then(result => {
                this.setState(prevState => ({
                    ...prevState,
                    leadTypes: result
                }));
            })
            .catch(err => {

            })
    }

    //Buscar os possiveis resultados de evolução de uma lead
    loadLeadResults() {
        getTypesResults()
            .then(result => {
                if (result.success === true) {
                    this.setState(prevState => ({
                        ...prevState,
                        leadResults: result.obj
                    }));
                }
            })
            .catch(err => {

            })
    }

    //Lidar com alterações na distancia de rede 
    handleChangeDistancia(e) {
        let value = e.target.value;
        this.setState(prevState => ({

            ...prevState,
            profundidadeRede: value
        }));
    }

    //Carregar os eventos disponiveis por tipo de lead
    loadEventsByLeadType() {
        getEventsByLeadTypeFiltered(this.state.searchInfo.leadType, null, null, null, 'S')
            .then(result => {
                if (result && result.dados) {
                    this.setState(prevState => ({
                        ...prevState,
                        leadEvents: result.dados
                    }))
                }
            })
    }

    //Lidar com alterações no formulário
    handleChange(e) {
        let value = e.target.value;
        let name = e.target.name;

        this.setState(prevState => ({
            searchInfo: {
                ...prevState.searchInfo,
                [name]: value
            }
        }));
    }

    //Lidar com alterações no campo tipo de lead
    handleChangeLeadType(e) {
        let value = e.target.value;

        this.setState(prevState => ({
            searchInfo: {
                ...prevState.searchInfo,
                leadType: value,
                companyId: "",
                companyName: "",
                companyEmail: "",
                companyPhoneNumber: "",
                companyCity: "",
            }
        }), () => {
            this.loadEventsByLeadType();
        });
    }

    //Lidar com as alterações das datas de negócio
    handleDataFim = DataFim => {
        this.setState(prevState => ({
            searchInfo: {
                ...prevState.searchInfo,
                endDate: DataFim,
            }
        }));
    }
    handleDataInicio = DataInicio => {
        this.setState(prevState => ({
            searchInfo: {
                ...prevState.searchInfo,
                initDate: DataInicio,
            }
        }));
    }

    //Lidar com as alterações das datas de evolução
    handleContactDataFim = DataFim => {
        this.setState(prevState => ({
            searchInfo: {
                ...prevState.searchInfo,
                contactEndDate: DataFim,
            }
        }));
    }

    handleContactDataInicio = DataInicio => {
        this.setState(prevState => ({
            searchInfo: {
                ...prevState.searchInfo,
                contactInitDate: DataInicio,
            }
        }));
    }

    componentDidUpdate(prevProps, prevState) {

        //Atualizaram os campos de pesquisa
        if (prevProps.searchLeadNextPage.nextRequestId !== this.props.searchLeadNextPage.nextRequestId) {

            this.setState(prevState => ({
                ...prevState,
                recordsPerPage: this.props.searchLeadNextPage.pageSize,
                currentPage: this.props.searchLeadNextPage.nextPage
            }), () => {
                this.searchForResults();
            });
        }
    }

    //Pesquisar pelos resultados
    searchForResults() {
        this.props.dispatch(advancedLeadSearch(lead, consultant, client, company, contact, this.state.profundidadeRede, this.state.recordsPerPage, this.state.currentPage))
            .then(result => {
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    render() {
        return (
            <div>

                {/* Linhas com a informação do consultor e do tipo de lead */}
                <Row>

                    {/* Id de Consultor */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStyle"> Id Consultor  </Label>
                        <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.consultantId} name="consultantId" id="consultantId" />
                    </Col>

                    {/* Nome de Consultor */}
                    <Col xs="12" sm="12" md="4" lg="3" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStyle"> Nome Consultor </Label>
                        <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.consultantName} name="consultantName" id="consultantName" />
                    </Col>

                    {/* Tipo de Lead */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStyle"> Tipo Lead </Label>
                        <Input className="columnValueStyle" type="select" onChange={this.handleChangeLeadType} value={this.state.searchInfo.leadType} name="leadType" id="leadType" >
                            <option value="0">Não Especificado...</option>
                            {this.state.leadTypes && this.state.leadTypes.length > 0 ?
                                this.state.leadTypes.map(l => {
                                    return <option value={l.Id}>{l.Descricao}</option>
                                })
                                : null}
                        </Input>
                    </Col>

                    {/* Origem de Lead */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStyle"> Origem </Label>
                        <Input className="columnValueStyle" type="select" onChange={this.handleChange} value={this.state.searchInfo.leadOrigin} name="leadOrigin" id="leadOrigin">
                            <option value="0">Não Especificado...</option>
                            {this.state.leadOrigins && this.state.leadOrigins.length > 0 ?
                                this.state.leadOrigins.map(l => {
                                    return <option value={l.Id}>{l.Descricao}</option>
                                })
                                : null}
                        </Input>
                    </Col>

                    {/* Descrição */}
                    <Col xs="12" sm="12" md="4" lg="3" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStyle">Descrição</Label>
                        <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.description} name="description" id="description" />
                    </Col>
                </Row>

                <br />
                {/* Linhas com as datas e valor previsto */}
                <Row style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>

                    {/* Valor Previsto */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStyle">Valor Previsto</Label>
                        <Input className="columnValueStyle" type="number" onChange={this.handleChange} value={this.state.searchInfo.value} name="value" id="value" />
                    </Col>

                    {/* Data de Inicio */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStyle">Data Inicio</Label>
                        <div className="customDatePickerWidth">
                            <DateTimePicker calendarIcon={<div className="input-group-text"> <FontAwesomeIcon icon={faCalendarAlt} /> </div>} autoComplete="off" value={this.state.searchInfo.initDate} onChange={this.handleDataInicio} />
                        </div>
                    </Col>

                    {/* Data Prevista de Fim */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStyle">Data Prevista de Fim</Label>
                        <div className="customDatePickerWidth">
                            <DateTimePicker calendarIcon={<div className="input-group-text"> <FontAwesomeIcon icon={faCalendarAlt} /> </div>} autoComplete="off" value={this.state.searchInfo.endDate} onChange={this.handleDataFim} />
                        </div>
                    </Col>

                    {/* Referencia */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStyle">Referencia</Label>
                        <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.ref} name="ref" id="ref" />
                    </Col>

                    {/* Observação */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStyle">Observação</Label>
                        <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.observations} name="observations" id="observations" />
                    </Col>

                    {/* Id de Encomenda */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStyle">ID Encomenda</Label>
                        <Input className="columnValueStyle" type="number" onChange={this.handleChange} value={this.state.searchInfo.orderId} name="orderId" id="orderId" />
                    </Col>
                </Row>

                <br />
                {/* Linha com informação do contacto */}
                <Row style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>

                    {/* Id de Cliente */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameClientStyle"> Id Cliente</Label>
                        <Input className="columnValueStyle" onChange={this.handleChange} type="number" value={this.state.searchInfo.clientId} name="clientId" id="clientId" />
                    </Col>

                    {/* Nome de Contacto */}
                    <Col xs="12" sm="12" md="4" lg="3" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameClientStyle" ound-color> Nome Cliente</Label>
                        <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.clientName} name="clientName" id="clientName" />
                    </Col>

                    {/* Email Contacto */}
                    <Col xs="12" sm="12" md="4" lg="3" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameClientStyle" ound-color> Email Cliente</Label>
                        <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.clientEmail} name="clientEmail" id="clientEmail" />
                    </Col>

                    {/* Telemovel  Contacto */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameClientStyle" ound-color> Telemóvel Cliente</Label>
                        <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.clientPhoneNumber} name="clientPhoneNumber" id="clientPhoneNumber" />
                    </Col>

                    {/* Localidade Contacto */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameClientStyle" ound-color> Localidade Cliente</Label>
                        <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.clientCity} name="clientCity" id="clientCity" />
                    </Col>
                </Row>

                {/* Linha com informação da Empresa */}
                {
                    (this.state.searchInfo.leadType === 'BB') ? (
                        <div>
                            <br />
                            <Row>

                                {/* Id de Empresa */}
                                <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }} >
                                    <Label className="columnNameCompanyStyle"> Id Empresa</Label>
                                    <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.companyId} name="companyId" id="companyId" />
                                </Col>

                                {/* Nome de Empresa */}
                                <Col xs="12" sm="12" md="4" lg="3" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                                    <Label className="columnNameCompanyStyle"> Nome Empresa</Label>
                                    <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.companyName} name="companyName" id="companyName" />
                                </Col>

                                {/* Email Empresa */}
                                <Col xs="12" sm="12" md="4" lg="3" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                                    <Label className="columnNameCompanyStyle">Email Empresa </Label>
                                    <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.companyEmail} name="companyEmail" id="companyEmail" />
                                </Col>

                                {/* Telemovel  Empresa */}
                                <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                                    <Label className="columnNameCompanyStyle">Telemóvel Empresa</Label>
                                    <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.companyPhoneNumber} name="companyPhoneNumber" id="companyPhoneNumber" />
                                </Col>

                                {/* Localidade Empresa */}
                                <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                                    <Label className="columnNameCompanyStyle">Localidade Empresa </Label>
                                    <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.companyCity} name="companyCity" id="companyCity" />
                                </Col>

                            </Row>
                        </div>
                    )
                        : (null)
                }

                {/* Linha para pesquiar por os estados de evolução do negócio*/}
                <br />
                <Row style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>

                    {/* Fase Atual Evolução */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStateStyle"> Fase Atual Evolução</Label>
                        <Input className="columnValueStyle" type="select" onChange={this.handleChange} value={this.state.searchInfo.contactState} name="contactState" id="contactState">
                            <option value="0">Não Especificado...</option>
                            {this.state.leadEvents && this.state.leadEvents.length > 0 ?
                                this.state.leadEvents.map(l => {
                                    return <option value={l.Id}>{l.Descricao}</option>
                                })
                                : null}
                        </Input>
                    </Col>

                    {/* Data de Inicio Evolução */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStateStyle">Data Início Evolução</Label>
                        <div className="customDatePickerWidth">
                            <DateTimePicker calendarIcon={<div className="input-group-text"> <FontAwesomeIcon icon={faCalendarAlt} /> </div>} autoComplete="off" value={this.state.searchInfo.contactInitDate} onChange={this.handleContactDataInicio} />
                        </div>
                    </Col>

                    {/* Data de Fim Evolução */}
                    <Col xs="12" sm="12" md="4" lg="2" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStateStyle">Data Fim Evolução</Label>
                        <div className="customDatePickerWidth">
                            <DateTimePicker calendarIcon={<div className="input-group-text"> <FontAwesomeIcon icon={faCalendarAlt} /> </div>} autoComplete="off" value={this.state.searchInfo.contactEndDate} onChange={this.handleContactDataFim} />
                        </div>
                    </Col>

                    {/* Resultado Evolução */}
                    <Col xs="12" sm="12" md="4" lg="3" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStateStyle">Resultado Evolução</Label>
                        <Input className="columnValueStyle" type="select" onChange={this.handleChange} value={this.state.searchInfo.contactResult} name="contactResult" id="contactResult">
                            <option value="0">Não Especificado...</option>
                            {this.state.leadResults && this.state.leadResults.length > 0 ?
                                this.state.leadResults.map(l => {
                                    return <option value={l.Id}>{l.Descricao}</option>
                                })
                                : null}
                        </Input>
                    </Col>

                    {/* Observação Evolução */}
                    <Col xs="12" sm="12" md="4" lg="3" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", marginBottom: "10px" }}>
                        <Label className="columnNameStateStyle">Observação Evolução</Label>
                        <Input className="columnValueStyle" onChange={this.handleChange} value={this.state.searchInfo.contactObservation} name="contactObservation" id="contactObservation" />
                    </Col>
                </Row>

                {/* Campo para a parte de pesquisa */}
                <br />
                <Row style={{ margin: "0px", padding: "0px" }}>
                    <Col md={12}>
                        <Row>
                            <Col md={12} style={{ margin: "0px", padding: "0px" }}>
                                <Label style={{ margin: "0px" }} >Insira o nível de profundidade da rede</Label>
                            </Col>
                        </Row>
                        <Row style={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                            <Col md={3} style={{ margin: "5px 7px 10px 0px", padding: "0px" }}>
                                <Input type="number" min="1" max="999" bsSize="sm" fullWidth={false} name="profundidadeRede" value={this.state.profundidadeRede} onChange={this.handleChangeDistancia} placeholder={"Nível Rede"}></Input>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Linha com botão de procurar pelas leads com os parametros mencionados */}
                <Row style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <Col xs="12" sm="12" md="3" lg="3">
                        <Button color="primary" style={{ fontSize: "18px", width: "100%" }} onClick={this.handleSearchClick}>Pesquisar</Button>
                    </Col>
                </Row>

            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        searchLeadNextPage: state.searchLeadNextPage
    };
};

export default withRouter(connect(mapStateToProps)(Pesquisa));