import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { consultorPin } from "../../../actions/userActions";

class PinoConsultores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: ["0"],
        datasets: [
          {
            data: [1],
            backgroundColor: ["#545cd8"],
            hoverBackgroundColor: ["#545cd8"]
          }
        ]
      }
    };
  }

  componentDidMount() {
    consultorPin().then(data => {
      if (data.data && !data.message) {
        this.setState({ data: data.data });
      }
    });
  }
  render() {
    return (
      <Col md="4">
        <div className="card mb-3 widget-chart">
          <div className="widget-numbers">
            <Doughnut data={this.state.data} />
          </div>
          <div className="widget-subheading">Niveis do negócio</div>
        </div>
      </Col>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(PinoConsultores);
