import React, { Component } from "react";
import { getBottomSellingProducts } from "../../../actions/encomendasActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class ProdutosMenosComprados extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0
    };
  }

  componentDidMount() {
    getBottomSellingProducts().then(data => {
      if (data.produtos && !data.message) {
        this.setState({ dataSize: data.produtos.length });
        this.setState({
          data: data.produtos,
          rowNumber: 10
        });
      }
      this.setState({
        columns: [
          {
            Header: "Nome Produto",
            accessor: "Descricao",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <a
                  class="fake-link"
                  id="fake-link-1"
                  target={"_blank"}
                  href={process.env.REACT_APP_MAIN_WEBSITE_PRODUCTDETAIL_URL_PT + row.original.ID_Produto}>
                  {row.original.Descricao ? row.original.Descricao : "-"}
                </a>
              );
            }
          },
          {
            Header: "Quantidade",
            accessor: "totalQuantidade",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            width: 100,
            Cell: row => {
              return (
                <span>
                  {row.original.totalQuantidade
                    ? row.original.totalQuantidade
                    : "-"}
                </span>
              );
            }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }
  render() {
    return (
      <SimpleTable
        data={this.state.data}
        columns={this.state.columns}
        blocking={this.state.blocking}
        rowNumber={this.state.rowNumber}
        dataSize={this.state.dataSize}
        noDataText={"Sem produtos para apresentar"}
        title={"Produtos menos comprados"}
      />
    );
  }
}

export default ProdutosMenosComprados;
