// 18-07-2019 - AFONSO - Funcao para obter os pagamentos
export function getPayments() {
  return new Promise((resolve, reject) => {
    return fetch("/comissoes/getPayments", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter as comissoes calculadas
export function allCommissions() {
  return new Promise((resolve, reject) => {
    return fetch("/comissoes/allCommissions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 23-07-2019 - AFONSO - Funcao para obter resumo das comissões
export function commissionsResume() {
  return new Promise((resolve, reject) => {
    return fetch("/comissoes/commissionsResume", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 23-07-2019 - AFONSO - Funcao para obter comissoes dos ultimos 12 meses para o grafico
export function last12MonthGraph() {
  return new Promise((resolve, reject) => {
    return fetch("/comissoes/last12MonthGraph", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 23-07-2019 - AFONSO - Funcao para obter detalhe das comissoes
export function getDetailCommissions(Ano, Mes) {
  return new Promise((resolve, reject) => {
    return fetch("/comissoes/getDetailCommissions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Ano: Ano,
        Mes: Mes
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 23-07-2019 - AFONSO - Funcao para obter resumo do detalhe das comissoes
export function getResumoDetailCommissions(Ano, Mes) {
  return new Promise((resolve, reject) => {
    return fetch("/comissoes/getResumoDetailCommissions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Ano: Ano,
        Mes: Mes
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 23-07-2019 - AFONSO - Funcao para obter resumo do detalhe das comissoes
export function comparativeMonthly() {
  return new Promise((resolve, reject) => {
    return fetch("/comissoes/comparativeMonthly", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 23-07-2019 - AFONSO - Funcao para obter resumo do detalhe das comissoes
export function comparativeAnually() {
  return new Promise((resolve, reject) => {
    return fetch("/comissoes/comparativeAnually", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
