import React, { Component } from "react";
import { FormattedDate, FormattedNumber } from "react-intl";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { getAllOrders } from "../../../actions/encomendasActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class EncomendasCliente extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0
    };
  }

  componentDidMount() {
    getAllOrders().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "ID Encomenda",
            accessor: "ID_Encomenda",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.ID_Encomenda
                ? row.original.ID_Encomenda
                : "-";
            }
          },
          {
            Header: "Estado Encomenda",
            accessor: "EstadoEncomenda",
            resizable: false,
            width: 400,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.EstadoEncomenda
                ? row.original.EstadoEncomenda
                : "-";
            }
          },
          {
            Header: "Data Encomenda",
            accessor: "DataEncomenda",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.DataEncomenda ? (
                <FormattedDate value={new Date(row.original.DataEncomenda)} />
              ) : (
                  "-"
                );
            }
          },
          {
            Header: "Data Envio",
            accessor: "DataEnvio",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.DataEnvio ? (
                <FormattedDate value={new Date(row.original.DataEnvio)} />
              ) : (
                  "-"
                );
            }
          },
          {
            Header: "Preço Final",
            accessor: "PrecoFinal",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.PrecoFinal}
                  style="currency"
                  currency={row.original.ID_Moeda}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: "Ações",
            accessor: "EstadoNegocio",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <div>
                  <div className="d-block w-100 text-center">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon btn-icon-only btn btn-link"
                        color="link"
                      >
                        <i className="lnr-menu-circle btn-icon-wrapper" />
                      </DropdownToggle>
                      <DropdownMenu
                        right
                        className="rm-pointers dropdown-menu-hover-link"
                      >
                        <DropdownItem
                          onClick={() => {
                            window.location.href =
                              "/encomenda/" + row.original.ID_Encomenda;
                          }}
                        >
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Ver Detalhe</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                </div>
              );
            }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }
  render() {
    return (
      <SimpleTable
        data={this.state.data}
        columns={this.state.columns}
        blocking={this.state.blocking}
        rowNumber={this.state.rowNumber}
        dataSize={this.state.dataSize}
        noDataText={"Sem encomendas para apresentar"}
        title={"Encomendas"}
      />
    );
  }
}

export default EncomendasCliente;
