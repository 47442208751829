import React, { Component } from "react";

class ArrowDownStatus extends Component {
  
  render() {
    return (
      <svg
        height="25px"
        viewBox="0 0 512 512.00115"
        width="25px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M434.252,185.721l-21.409-21.413c-7.419-7.042-16.084-10.564-25.975-10.564c-10.095,0-18.657,3.521-25.7,10.564
                    l-83.938,83.939V47.255c0-9.9-3.621-18.464-10.855-25.697c-7.234-7.232-15.797-10.85-25.693-10.85h-36.545
                    c-9.897,0-18.464,3.621-25.693,10.85c-7.236,7.233-10.85,15.797-10.85,25.697v200.992l-83.939-83.939
                    c-7.042-7.042-15.606-10.564-25.697-10.564c-9.896,0-18.559,3.521-25.979,10.564l-21.128,21.413C3.615,192.948,0,201.615,0,211.7
                    c0,10.282,3.619,18.848,10.848,25.698l185.864,186.146c7.045,7.046,15.609,10.567,25.697,10.567
                    c9.897,0,18.558-3.521,25.977-10.567l185.865-186.146c7.043-7.043,10.567-15.608,10.567-25.698
                    C444.819,201.805,441.295,193.145,434.252,185.721z"
          style={{ fill: "red" }}
        />
      </svg>
    );
  }
}

export default ArrowDownStatus;
