import React, { Component } from "react";
import { Button, Col, Row } from "reactstrap";

class AdicionarObservacao extends Component {

  render() {
    return (
      <Row>
        <Col style={{ textAlign: "right" }}>
          <Button
            style={{ display: this.props.addButtonState ? "initial" : "none" }}
            className="mb-2 mr-2"
            size="lg"
            color="info"
            onClick={() => {
              this.props.onChangeState();
            }}
          >
            Adicionar Observação
          </Button>
          <Button
            style={{ display: !this.props.addButtonState ? "initial" : "none" }}
            className="mb-2 mr-2 btn-pill"
            size="lg"
            color="danger"
            onClick={() => {
              this.props.onChangeState();
            }}
          >
            Cancelar
          </Button>
        </Col>
      </Row>
    );
  }
}

export default AdicionarObservacao;
