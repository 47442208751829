import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedNumber } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button } from 'reactstrap';
import { removeCredits, selectCredits } from "../../actions/checkoutActions";
import SearchInputComponent from '../../helpers/SearchComponents/SearchInputComponent';
import Toast from '../../helpers/Toast';
import { IsValidNumber } from "../../helpers/UsefulFunctions";
import { HasError } from "../../helpers/UtilsCheckout";
import IHTPButton from "../Common/Button";
import SkeletonComponent from "../Common/Skeleton";
import IHTPSpacer from '../Common/Spacer';
import "./Checkout.css";

export default function Creditos() {

	const [credits, setCredits] = useState<string>("");
	const [hasError, setHasError] = useState<boolean>(false);
	const [loadingCredits, setLoadingCredits] = useState<boolean>(false);

	const creditosPermitidos = useSelector((state: any) => state.checkoutData.creditosPermitidos ?? 0);
	const currentCheckoutCredits = useSelector((state: any) => state.checkoutData.credits);
	const loadingCheckoutPrices = useSelector((state: any) => state.loadingCheckoutPrices);
	const errorCode = useSelector((state: any) => state.errorCode);
	const upline = useSelector((state: any) => state.upline); //Se estiver no seu user, aparece a sua informação. Se estiver num cliente em rede aparece a informação desse cliente

	const dispatch = useDispatch();

	var loadingPrices = loadingCheckoutPrices;

	useEffect(() => {
		let tmpHasError = HasError(errorCode, ["Creditos_Vales", "Creditos_Codigos", "Creditos"]);
		if (hasError !== tmpHasError) {
			setHasError(tmpHasError);
		}
		return () => { }
	}, [errorCode])

	useEffect(() => {
		if (hasError === true) {
			let el = document.getElementById("creditos");
			if (el != null) el.scrollIntoView({ block: "center" });
		}
	}, [hasError])

	const handleChange = (text) => {

		if (IsValidNumber(text) === false) {
			setCredits("");
			return;
		}

		let finalValue = Number(text);
		let maxAllowedCredits = parseFloat(creditosPermitidos);

		if (finalValue <= maxAllowedCredits) {
			setCredits(text);
		}
	};

	const handleFormSubmit = async () => {

		if (!credits || credits === "0") {
			Toast.Show("error", "Não inseriu nenhum valor em créditos");
			setCredits("");
			return;
		}

		var totalCreditos = 0;

		//Verificar o valor dos credits preenchidos
		var tmpCredits = credits;

		//Substituimos , para . 
		tmpCredits = tmpCredits.replace(/,/g, ".");

		//Se não for um número ou for um valor menor que 0 mostramos erro
		var creditsInNumber = Number(tmpCredits);
		if (isNaN(creditsInNumber) || creditsInNumber < 0) {
			Toast.Show("error", "Não inseriu um valor válido de créditos");
			return;
		}

		if (typeof currentCheckoutCredits !== "undefined" && currentCheckoutCredits != null && Number.isNaN(Number(currentCheckoutCredits)) == false) {
			totalCreditos = parseFloat(Number(currentCheckoutCredits) + "") + parseFloat(tmpCredits);
		} else {
			totalCreditos = parseFloat(tmpCredits);
		}

		setLoadingCredits(true);
		var data: any = await dispatch(selectCredits(totalCreditos));
		setLoadingCredits(false);

		if (data.success === true) {
			setCredits("");
			Toast.Show("success", "Créditos adicionados com sucesso");
			return;
		}

		setCredits("");
		await dispatch(removeCredits());
		Toast.Show("error", data.message);
	}

	return (
		<div>
			<div
				className={hasError === true ? "checkoutBox errorBorder"
					:/* Validar se existe créditos metendo a disabled se o valor for nulo ou 0 */
					creditosPermitidos === 0 || creditosPermitidos == null
						? "checkoutBox disabledCreditsBox"
						: "checkoutBox "
				}
				id="creditos"
			>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} className="checkoutBoxTitle">
						Créditos
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>

						<IHTPSpacer verticalSpace='10px' />

						<SearchInputComponent showLabel={false}
							inputType="number"
							min={0}
							onChange={(e) => handleChange(e.target.value)} initialValue={credits} value={credits}
							placeholder="Insira um valor" />

						<IHTPSpacer verticalSpace='10px' />

						<div className='discountsText'>
							<span className='creditsAvailableContainer'>
								<span>Utilizados:</span>
								<span style={{ fontWeight: "600", color: "#007bff" }}>
									{loadingPrices === true ? (
										<SkeletonComponent width={50} height={24} />
									) : (
										<FormattedNumber
											value={currentCheckoutCredits ? currentCheckoutCredits : 0}
											style="currency"
											currency={upline.ID_Moeda}
											minimumFractionDigits={2}
											maximumFractionDigits={2}
										/>
									)}
								</span>
							</span>

							<IHTPSpacer verticalSpace='10px' />

							<span className='creditsAvailableContainer'>
								<span>Consegue utilizar:</span>
								<span style={{ fontWeight: "600", color: "#007bff" }}>
									{loadingPrices === true ? (
										<SkeletonComponent width={50} height={24} />
									) : (
										<FormattedNumber
											value={creditosPermitidos}
											style="currency"
											currency={upline.ID_Moeda}
											minimumFractionDigits={2}
											maximumFractionDigits={2}
										/>
									)}
								</span>
							</span>

						</div>

						<IHTPSpacer verticalSpace='10px' />

						<div className="buttonsValidarCodigos">
							<IHTPButton
								text={"Usar créditos"}
								onClick={handleFormSubmit}
								buttonStyle={"secondary"}
								loading={loadingCredits}
							/>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	)
}