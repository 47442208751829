import { faAngleDoubleUp, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Nav, NavItem, NavLink } from "reactstrap";
import { changeUser } from "../../../actions/userActions";

class MegaMenu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      popoverOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      popoverOpen: !this.state.popoverOpen
    });
  }

  state = {};

  render() {
    const { user, upline } = this.props;
    return (
      <Fragment>
        <Nav className="header-megamenu">
          <NavItem>
            <NavLink
              onClick={() => {
                this.props.dispatch(changeUser(user.ID_Cliente)).then(() => {
                  window.location.href = "/";
                });
              }}
            >
              Topo Rede
              <FontAwesomeIcon
                className="ml-2 opacity-5"
                icon={faAngleDoubleUp}
              />
            </NavLink>
          </NavItem>
          {upline.rede ? (
            <NavItem>
              <NavLink
                onClick={() => {
                  this.props.dispatch(changeUser(upline.rede)).then(() => {
                    window.location.href = "/";
                  });
                }}
              >
                Subir Rede
                <FontAwesomeIcon className="ml-2 opacity-5" icon={faAngleUp} />
              </NavLink>{" "}
            </NavItem>
          ) : null}
          <NavItem>
            <NavLink onClick={this.toggle}>
              {upline.NomeCompleto ? upline.NomeCompleto : user.NomeCompleto} -{" "}
              {upline.ID_TipoCliente
                ? upline.ID_TipoCliente
                : user.ID_TipoCliente}
            </NavLink>
          </NavItem>
        </Nav>
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user,
  upline: state.upline
});

export default connect(mapStateToProps)(MegaMenu);
