import React, { Component } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { getOtherBusinesses } from "../../../actions/negociosActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class OutrosNegocios extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0
    };
  }

  componentDidMount() {
    getOtherBusinesses().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "Nome Consultor",
            accessor: "Consultor_NomeCompleto",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.Consultor_NomeCompleto
                ? row.original.Consultor_NomeCompleto
                : "-";
            }
          },
          {
            Header: "Referencia",
            accessor: "Referencia",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.Referencia ? row.original.Referencia : "-";
            }
          },
          {
            Header: "Descrição",
            accessor: "Descricao",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.Descricao ? row.original.Descricao : "-";
            }
          },
          {
            Header: "Data Registo",
            accessor: "DataRegisto",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.DataRegisto ? (
                <FormattedDate value={new Date(row.original.DataRegisto)} />
              ) : (
                  "-"
                );
            }
          },
          {
            Header: "Data Último Evento",
            accessor: "UltimoContacto",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.UltimoContacto ? (
                <FormattedMessage id="UltimoContacto" defaultMessage="{date} {time}" values={
                  {
                    date: <FormattedDate value={new Date(row.original.UltimoContacto)} />,
                    time: new Date(row.original.UltimoContacto).getHours() + ":" + new Date(row.original.UltimoContacto).getMinutes() + ":" + new Date(row.original.UltimoContacto).getSeconds()
                  }
                }
                />
              ) : (
                  "-"
                );
            }
          },
          {
            Header: "Valor Previsto",
            accessor: "ValorPrevisto",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.ValorPrevisto
                ? row.original.ValorPrevisto
                : "-";
            }
          },
          {
            Header: "Estado Negócio",
            accessor: "EstadoNegocio",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.EstadoNegocio
                ? row.original.EstadoNegocio
                : "-";
            }
          },
          {
            Header: "Ações",
            accessor: "EstadoNegocio",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <div>
                  <div className="d-block w-100 text-center">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon btn-icon-only btn btn-link"
                        color="link"
                      >
                        <i className="lnr-menu-circle btn-icon-wrapper" />
                      </DropdownToggle>
                      <DropdownMenu
                        right
                        className="rm-pointers dropdown-menu-hover-link"
                      >
                        <DropdownItem
                          onClick={() => {
                            window.location.href =
                              "/negocio/" + row.original.ID_Negocio;
                          }}
                        >
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Ver Detalhe</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                </div>
              );
            }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }
  render() {
    return (
      <SimpleTable
        data={this.state.data}
        columns={this.state.columns}
        blocking={this.state.blocking}
        rowNumber={this.state.rowNumber}
        dataSize={this.state.dataSize}
        noDataText={"Sem negócios para apresentar"}
        title={"Outros negócios com este cliente"}
      />
    );
  }
}

export default OutrosNegocios;
