import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import { secondQuarterBilling } from "../../../actions/comparativosActions";
import { getContacts } from "../../../actions/userActions";
import ContactosCliente from "../../../helpers/ContactosCliente";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class Faturacao2Trimestre extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0,
      contactos: []
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    secondQuarterBilling().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "Nome",
            accessor: "NomeCompleto",
            resizable: false,
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Pessoal",
            accessor: "FacturacaoPessoal",
            resizable: false,
            width: 150,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.FacturacaoPessoal}
                  style="currency"
                  currency="EUR"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: "Clientes",
            accessor: "FacturacaoClientes",
            resizable: false,
            width: 150,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.FacturacaoClientes}
                  style="currency"
                  currency="EUR"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: "Total",
            accessor: "FacturacaoTotal",
            resizable: false,
            width: 150,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.FacturacaoTotal}
                  style="currency"
                  currency="EUR"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: "Acima do Mínimo",
            accessor: "AcimaMinimo",
            resizable: false,
            width: 150,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.AcimaMinimo}
                  style="currency"
                  currency="EUR"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: "STARS",
            accessor: "Reconhecimento",
            resizable: false,
            width: 150,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.original.Reconhecimento ? (
                    <span>{row.original.Reconhecimento} + &nbsp; STARS </span>
                  ) : null}
                </span>
              );
            }
          },
          {
            Header: "Cor",
            accessor: "Semaforo",
            resizable: false,
            width: 75,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              let faturacaoClientes2 = parseFloat(
                row.original.FacturacaoClientes
              );
              let faturacaoPessoal2 = parseFloat(
                row.original.FacturacaoPessoal
              );
              let total = faturacaoClientes2 + faturacaoPessoal2;
              if (total <= 125) {
                return (
                  <div>
                    <a>
                      <svg
                        height="20px"
                        viewBox="0 0 512 512.00115"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"
                          style={{ fill: "black" }}
                        />
                      </svg>
                    </a>
                  </div>
                );
              } else if (total > 125 && total <= 250) {
                return (
                  <div>
                    <a>
                      <svg
                        height="20px"
                        viewBox="0 0 512 512.00115"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"
                          style={{ fill: "red" }}
                        />
                      </svg>
                    </a>
                  </div>
                );
              } else if (total > 250 && total <= 1000) {
                return (
                  <div>
                    <a>
                      <svg
                        height="20px"
                        viewBox="0 0 512 512.00115"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"
                          style={{ fill: "yellow" }}
                        />
                      </svg>
                    </a>
                  </div>
                );
              } else if (total > 1000) {
                return (
                  <div>
                    <a>
                      <svg
                        height="20px"
                        viewBox="0 0 512 512.00115"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z"
                          style={{ fill: "green" }}
                        />
                      </svg>
                    </a>
                  </div>
                );
              }
            }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  getUserContacts(id) {
    getContacts(id).then(data => {
      if (data && !data.message) {
        this.setState({ contactos: data });
        this.toggle();
      }
    });
  }
  render() {
    return (
      <span>
        <ContactosCliente
          onRef={ref => (this.child = ref)}
          isOpen={this.state.modal}
          toggle={this.toggle}
          contactos={this.state.contactos}
        />
        <SimpleTable
          data={this.state.data}
          columns={this.state.columns}
          blocking={this.state.blocking}
          rowNumber={this.state.rowNumber}
          dataSize={this.state.dataSize}
          noDataText={"Sem faturações para mostrar"}
          title={"Faturação 2º Trimestre"}
        />
      </span>
    );
  }
}

export default Faturacao2Trimestre;
