import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitleAlt4 from "../../helpers/Layout/PageTitleAlt4";
import Detalhe from "./Components/Detalhe";
import EstadosNegocio from "./Components/EstadosNegocio";
import { connect } from "react-redux";
import { updateBusinessDetailPageInfo } from "../../actions/negociosActions";

class DetalheNegocios extends Component {
  constructor() {
    super();
    this.state = {};
    this.updateData = this.updateData.bind(this);
  }

  updateData() {    
    this.props.dispatch(updateBusinessDetailPageInfo(this.props.id,false))
  }
  
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt4
            heading="Detalhe do Negócio (Lead)"
            subheading="Página onde pode ver o detalhe de um negócio (lead)"
            icon="pe-7s-graph icon-gradient bg-ripe-malin"
            id={this.props.id}
            updateData={this.updateData}
          />

          <Detalhe id={this.props.id}/>
          <EstadosNegocio id={this.props.id}/>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {    
  };
};

export default connect(mapStateToProps)(DetalheNegocios);
