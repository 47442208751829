import { CircularProgress, IconButton } from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React, { Component } from "react";
import { Slide, toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from "reactstrap";
import { getEventsByLeadTypeFiltered } from "../../../../actions/eventsActions";
import { getChildrenTreeLeadStats, getChildrenTreeLeadTotalStatsByFase } from "../../../../actions/statsActions";
import "./TableStats.css";
import TableStatsColumn from "./TableStatsColumn";
import TableStatsColumnCell from "./TableStatsColumnCell";
import TableStatsRow from "./TableStatsRow";

const buttonChangePage = {
    margin: "10px"
}

class TableStats extends Component {

    constructor() {
        super();
        var hidden = "none";
        var show = "initial";

        this.state = {
            totalPages: 1,
            currentPage: 1,
            recordsPerPage: 5,
            orderField: 0,
            fase: null,
            orderAsc: false,
            isLoading: true,
            contactos: [],
            events: [],
            consultantStats: [{
                Id: '',
                Nome: '',
                Eventos: []
            }],
            hidden: hidden,
            show: show,
            profundidadeRede: 1,
            lastRequestId: 1,
        };

        this.getEventsForLeadType = this.getEventsForLeadType.bind(this);
        this.getStats = this.getStats.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRecordsPerPageChange = this.handleRecordsPerPageChange.bind(this);
        this.loadStats = this.loadStats.bind(this);
        this.handleChangeOrder = this.handleChangeOrder.bind(this);
        this.handleChangeFase = this.handleChangeFase.bind(this);
    }

    //Quando o componente carrega vamos buscar os consultores e os eventos existentes
    componentDidMount() {
        this.getEventsForLeadType();
    }

    //Quando estiver visivel, vamos buscar as stats
    componentDidUpdate(prevProps, prevState) {
        if (prevState.show === 'initial' && this.state.show === 'none') {
            this.getStats();
        }
    }

    //Vamos buscar os eventos por tipo de lead filtrados
    getEventsForLeadType() {
        var leadType = this.props.leadType;
        var that = this;
        var promise = new Promise(function (resolve, reject) {
            getEventsByLeadTypeFiltered(leadType, null, false, false)
                .then(result => {
                    if (result) {

                        var eventosWithTotal = result.dados;
                        eventosWithTotal.forEach(event => {
                            event.Total = {
                                NumeroLeads: 0,
                                TempoMaximo: 0,
                                TempoMedio: 0,
                                TempoMinimo: 0,
                                ValorAcumulado: 0
                            };
                        });

                        that.setState(prevState => ({
                            ...prevState,
                            events: eventosWithTotal,
                        }), () => {
                            resolve()
                        }
                        );

                    } else {
                        resolve();
                    }
                })
                .catch(err => {
                    that.showToast("Erro ao carregar Eventos", "error");
                    resolve();
                });
        });
        return promise;
    }

    //Colocamos se estamos a carregar ou não a informação
    setLoading(value) {
        this.setState(prevState => ({
            ...prevState,
            isLoading: value
        }));
    }

    //Vamos buscar as stats
    getStats() {
        this.setLoading(true);

        //Vamos buscar as stats para os consultores filhos, para essa fase
        getChildrenTreeLeadStats(this.props.leadType, this.state.profundidadeRede, this.state.currentPage, this.state.recordsPerPage, this.state.fase, this.state.orderField, this.state.orderAsc)
            .then(result => {

                //Se conseguirmos obter as stats com sucesso
                if (result.success === true) {

                    var stats = result.obj.stats;
                    var totalPages = result.obj.totalPages;
                    var consultants = [];

                    //Vamos por cada uma das stats que chegaram, atribuir aos consultores
                    stats.forEach(s => {

                        //Vamos tentar buscar o consultor
                        var consultant = consultants.find(c => c.ID_Cliente == s.ID_Cliente);

                        //Se o consultor não existir
                        if (consultant == null) {

                            //Criamos os dados do consultor
                            consultant = {
                                ID_Cliente: s.ID_Cliente,
                                Nome: s.NomeCompleto,
                                NumLeadsNaoTratadas: s.NumLeadsNaoTratadas,
                                Fases: [{
                                    ID_Fase: s.ID_Fase,
                                    NumeroLeads: s.NumeroLeads,
                                    TempoMaximo: s.TempoMaximo,
                                    TempoMedio: s.TempoMedio,
                                    TempoMinimo: s.TempoMinimo,
                                    ValorAcumulado: s.ValorAcumulado,
                                }]
                            };
                            consultants.push(consultant);
                        }
                        //Se o consultor existir adicionamos os dados da página
                        else {
                            consultant.Fases.push({
                                ID_Fase: s.ID_Fase,
                                NumeroLeads: s.NumeroLeads,
                                TempoMaximo: s.TempoMaximo,
                                TempoMedio: s.TempoMedio,
                                TempoMinimo: s.TempoMinimo,
                                ValorAcumulado: s.ValorAcumulado,
                            });
                        }
                    });


                    this.setState(prevState => ({
                        ...prevState,
                        consultantStats: consultants,
                        totalPages: totalPages
                    }));

                    this.setLoading(false);
                } else {
                    this.showToast(result.message, "error");
                    this.setLoading(false);
                }

            })
            .catch(err => {
                this.showToast("Erro ao carregar as estatísticas", "error");
                this.setLoading(false);
            })

        //Carregar totais para eventos
        var events = [...this.state.events];

        //Ficamos com um identificador do último pedido
        var currentRequestId = Date.now();
        this.setState(prevState => ({
            ...prevState,
            lastRequestId: currentRequestId
        }));
        
        //Por cada um dos eventos
        events.forEach(e => {
            getChildrenTreeLeadTotalStatsByFase(e.Id, this.state.profundidadeRede, currentRequestId)
                .then(result => {
                    //Se este tiver sido o ultimo pedido que foi feito, se não foi ignoramos
                    if (this.state.lastRequestId === result.requestId) {

                        //Se conseguirmos obter as stats com sucesso
                        if (result.success === true) {

                            var obj = result.obj;
                            var eventsWithTotals = [...this.state.events];

                            eventsWithTotals.find(e => e.Id == obj.IdFase).Total = {
                                ...obj.Total
                            };

                            this.setState(prevState => ({
                                ...prevState,
                                events: eventsWithTotals
                            }));

                        } else {
                            this.showToast(result.message, "error");
                        }
                    }
                })
                .catch(err => {
                    this.showToast("Erro ao carregar os totais das Estatísticas", "error");
                })

        });
    }

    //Lidar com as alterações no campo id de profundiade
    handleChange(e) {
        let value = e.target.value;
        let name = e.target.name;

        this.setState(prevState => ({
            ...prevState,
            [name]: value
        })
        );
    }

    //Lidar com as alterações no campo dos registos
    handleRecordsPerPageChange(e) {
        let value = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            recordsPerPage: value,
            currentPage: 1 //Ao mudar o nº de registos, passamos para a página 1
        }), () => {
            this.getStats(); //Depois de alterarmos tudo procuramos as stats
        }
        );
    }

    //Lidar com as alterações no campo de ordenacao
    handleChangeOrder(e) {
        let value = e.target.value;

        this.setState(prevState => ({
            ...prevState,
            orderField: value,
            currentPage: 1 //Ao mudar o nº de registos, passamos para a página 1
        }), () => {
            if (this.state.fase != null && this.state.fase.trim().length > 0 && this.state.orderField !== "0") {
                this.getStats(); //Depois de alterarmos tudo procuramos as stats
            }
        }
        );
    }

    //Lidar com as alterações no campo fase
    handleChangeFase(e) {
        let value = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            fase: value,
            currentPage: 1 //Ao mudar o nº de registos, passamos para a página 1
        }), () => {
            if (this.state.fase != null && this.state.fase.trim().length > 0 && this.state.orderField != "0") {
                this.getStats(); //Depois de alterarmos tudo procuramos as stats
            }
        }
        );
    }

    //Handle botão de pesquisar
    loadStats() {
        //Quando carregamos no botão resetamos a página
        this.setState(prevState => ({
            ...prevState,
            currentPage: 1
        }), () => {
            this.getStats();
        })
    }

    //Show Toast de error
    showToast(message, type) {
        toast(message, {
            transition: Slide,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-right",
            type: type
        });
    }

    //Mudar de página
    changePage(value) {
        var currentPage = this.state.currentPage;
        //Se o valor passar o máximo saimos
        if ((currentPage + value) <= 0 || (currentPage + value) > this.state.totalPages) return;

        this.setState(prevState => ({
            ...prevState,
            currentPage: prevState.currentPage + value,
        }), () => {
            this.getStats();
        });
    }

    //Método para lidar com a alteração Ascendente ou descendente da ordenação
    changeOrderAscOrDesc = value => async e => {
        this.setState(
            prevState => ({
                ...prevState,
                orderAsc: value,
                currentPage: 1 //Ao mudar o nº de registos, passamos para a página 1
            }), () => {
                if (this.state.fase != null && this.state.fase.trim().length > 0 && this.state.orderField !== "0") {
                    this.getStats(); //Depois de alterarmos tudo procuramos as stats
                }
            }
        );
    };

    render() {
        return (
            <div>
                <Card className="main-card mb-6">

                    {/* Onde está o titulo do cartão e a opção de minimar ou aumentar*/}
                    <CardHeader className="card-header-tab">
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">{" "}</i>
                            {this.props.title}
                        </div>
                        <div className="btn-actions-pane-right">
                            <Button className="mr-2 btn-icon btn-icon-only" outline color="danger" onClick={() => { this.setState({ hidden: "none", show: "initial" }); }} style={{ display: this.state.hidden }}>
                                <i className="lnr-circle-minus" style={{ display: this.state.hidden }} onClick={() => { this.setState({ hidden: "none", show: "initial" }); }} />
                            </Button>
                            <Button className="mr-2 btn-icon btn-icon-only" outline color="success" onClick={() => { this.setState({ hidden: "initial", show: "none" }); }} style={{ display: this.state.show }}>
                                <i className="lnr-plus-circle" style={{ display: this.state.show }} onClick={() => { this.setState({ hidden: "initial", show: "none" }); }} />
                            </Button>
                        </div>
                    </CardHeader>

                    {/* Mostrar o conteudo do cartão*/}
                    <CardBody style={{ display: this.state.hidden, maxHeight: "750px", overflowY: "auto" }}>
                        <Row className="flex-nowrap" style={{ paddingLeft: "5px" }}>
                            <Col md={12}>

                                {/* Campo para a parte de pesquisa */}
                                <Row>
                                    <Col md={12}>
                                        <Row>
                                            <Col style={{ margin: "0px 0px 0px 7px", padding: "0px" }}>
                                                <Label style={{ margin: "0px" }} >Insira o nível de profundidade da rede</Label>
                                            </Col>
                                        </Row>
                                        <Row style={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                                            <Col md={3} style={{ margin: "0px 7px 5px 7px", padding: "0px" }}>
                                                <Input type="number" min="1" max="999" bsSize="sm" fullWidth={false} name="profundidadeRede" value={this.state.profundidadeRede} onChange={this.handleChange} placeholder={"Nível Rede"}></Input>
                                            </Col>
                                            <Col md={2} xs={12} >
                                                <Button color="primary" disabled={this.state.isLoading} onClick={this.loadStats}>Pesquisar</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                {/* Campo para a parte de escolher como ordenar */}
                                <Row style={{ marginBottom: "0px", display: "flex", alignItems: "center", justifyContent: "start" }}>
                                    <Col md={12} style={{ margin: "7px", padding: "0px" }}>
                                        <Row style={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                                            <Col md={3} xs={12} style={{ margin: "0px 0px 8px 0px" }}>
                                                <Label style={{ margin: "0px 5px 0px 0px" }}>Fase:</Label>
                                                <select className="selectStyle" id="fase" name="fase" value={this.state.fase} onChange={this.handleChangeFase} style={{ textAlign: "center" }}>
                                                    <option value="">Escolher fase...</option>
                                                    {this.state.events && this.state.events.length > 0 ?
                                                        this.state.events.map(e => {
                                                            return <option value={e.Id}>{e.Descricao}</option>
                                                        })
                                                        : null}
                                                </select>
                                            </Col>
                                            <Col md={3} xs={12} style={{ margin: "0px 0px 8px 0px" }}>
                                                <Label style={{ margin: "0px 5px 0px 0px" }}>Ordenar por:</Label>
                                                <select className="selectStyle" value={this.state.orderField} id="orderField" name="orderField" onChange={this.handleChangeOrder}>
                                                    <option value="0">Escolher campo...</option>
                                                    <option value="1">Número de Leads</option>
                                                    <option value="2">Valor Acumulado</option>
                                                    <option value="3">Tempo Mínimo</option>
                                                    <option value="4">Tempo Médio</option>
                                                    <option value="5">Tempo Máximo</option>
                                                </select>

                                                {this.state.orderAsc === false ?
                                                    <IconButton style={{ padding: "5px" }} onClick={this.changeOrderAscOrDesc(true)} title="Ver Ascendente" >
                                                        <ArrowDownwardIcon />
                                                    </IconButton>
                                                    :
                                                    <IconButton style={{ padding: "5px" }} onClick={this.changeOrderAscOrDesc(false)} title="Ver Descendente">
                                                        <ArrowUpwardIcon />
                                                    </IconButton>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                {/* Campo para a parte de escolher quantos resultados mostrar */}
                                <Row style={{ marginBottom: "2px", display: "flex", alignItems: "center", justifyContent: "start" }}>
                                    <Col md={12} style={{ margin: "7px", padding: "0px" }}>
                                        <Label style={{ margin: "0px 5px 0px 0px" }}>Resultados por página:</Label>
                                        <select className="selectStyle" value={this.state.recordsPerPage} id="recordsPerPage" name="recordsPerPage" onChange={this.handleRecordsPerPageChange}>
                                            <option>5</option>
                                            <option>10</option>
                                            <option>20</option>
                                        </select>
                                    </Col>
                                </Row>

                                {/* Linha que define o cabeçalho da "tabela"*/}
                                <Row className="flex-nowrap">
                                    <Col md={2} xs={11} lg={2} className="cardStyleStats">
                                        <Label style={{ fontSize: "20px", fontWeight: "600", color: "rgb(87, 95, 217)" }}>Nome Consultor</Label>
                                    </Col>
                                    {
                                        this.state.events.map(event => {
                                            return <TableStatsColumn event={event} />
                                        })
                                    }
                                </Row>

                                {this.state.isLoading === true ?
                                    (<div>
                                        <label style={{ fontWeight: "600", fontStyle: "italic", marginTop: "15px", marginRight: "10px", fontSize: "15px" }}>
                                            A carregar informação detalhada dos consultores...
                                            </label>
                                        <CircularProgress thickness={2} size={25} disableShrink />
                                    </div>)
                                    : <div>

                                        {/* Linha que define as linhas da tabela*/}
                                        <Row className="flex-nowrap">
                                            <Col md={12} style={{ margin: "0px", padding: "0px" }}>
                                                {this.state.consultantStats && this.state.consultantStats.length > 0 ? (
                                                    this.state.consultantStats.map(cs => {
                                                        return <TableStatsRow consultant={cs} key={cs.Id} />
                                                    })) : null}
                                            </Col>
                                        </Row>

                                        {/* Linha que mostra a parte de mudar de página*/}
                                        <Row>
                                            <Col>
                                                <Button color="primary" style={buttonChangePage} onClick={() => this.changePage(-1)} disabled={this.state.currentPage <= 1 ? true : false} >Anterior </Button>
                                                <Label>Página <b>{this.state.currentPage}</b> de {this.state.totalPages}</Label>
                                                <Button color="primary" style={buttonChangePage} onClick={() => this.changePage(1)} disabled={this.state.currentPage >= this.state.totalPages ? true : false}>Próxima </Button>
                                            </Col>
                                        </Row>

                                        {/* Separador*/}
                                        <hr />

                                        {/* Linha dos totais*/}
                                        <Row className="flex-nowrap">
                                            <Col md={2} xs={11} lg={2} className="cardStyleStats" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Label style={{ fontSize: "20px", fontWeight: "600", color: "rgb(87, 95, 217)" }}>Total</Label>
                                            </Col>
                                            {
                                                this.state.events && this.state.events.length > 0 ? (
                                                    this.state.events.map(event => {
                                                        return <Col md={4} xs={11} lg={3} className="cardStyleStats" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            <TableStatsColumnCell stats={event.Total} />
                                                        </Col>
                                                    })) : null
                                            }
                                        </Row>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div >
        );
    }
}

export default TableStats;
