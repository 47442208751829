import cx from "classnames";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { getBusinessDetail } from "../../actions/negociosActions";
import NovoEstadoNegocio from "../../components/DetalheNegocio/Components/NovoEstadoNegocio";

class PageTitleAlt4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      data: {}
    };
    this.toggle = this.toggle.bind(this);
  }

  state = {
    expZoomIn: false
  };

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentDidMount() {
    getBusinessDetail(this.props.id).then(data => {
      if (data && !data.message) {
        this.setState({
          data: data.Detalhes,
          dataSize: 1
        });
      }
      this.setState({ blocking: false });
    });
  }

  render() {
    let {
      enablePageTitleIcon,
      enablePageTitleSubheading,
      heading,
      icon,
      subheading,
      user,
      upline
    } = this.props;
    
    return (
      <div>
        <NovoEstadoNegocio
          isOpen={this.state.modal}
          updateData={this.props.updateData}
          toggle={this.toggle}
          id={this.props.id}
        />
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div
                className={cx("page-title-icon rounded-circle", {
                  "d-none": !enablePageTitleIcon
                })}
              >
                <i className={icon} />
              </div>
              <div>
                {heading}
                <div
                  className={cx("page-title-subheading", {
                    "d-none": !enablePageTitleSubheading
                  })}
                >
                  {subheading}
                </div>
              </div>
            </div>
            {user && upline ? (
              upline.ID_Cliente !== this.state.data.ID_Consultor  ? null : (
                <div className="page-title-actions">
                  <Fragment>
                    <Button
                      className="mb-2 mr-2 btn-pill"
                      size="lg"
                      color="primary"
                      onClick={this.toggle}
                    >
                      Adicionar Estado Negócio
                    </Button>
                  </Fragment>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  enablePageTitleIcon: state.enablePageTitleIcon,
  enablePageTitleSubheading: state.enablePageTitleSubheading,
  user: state.user,
  upline: state.upline
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageTitleAlt4);
