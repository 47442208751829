import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row } from "reactstrap";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import NumeroPromotores from "./Components/NumeroPromotores";
import NumeroPromotoresRede from "./Components/NumeroPromotoresRede";
import PromotoresSemEncomendas from "./Components/PromotoresSemEncomendas";
import TabelaPromotores from "./Components/TabelaPromotores";

class Clientes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Promotores"
            subheading="Página onde pode ver os seus promotores"
            icon="pe-7s-users icon-gradient bg-ripe-malin"
          />
          <Row>
            <NumeroPromotores />
            <NumeroPromotoresRede />
            <PromotoresSemEncomendas />
          </Row>
          <TabelaPromotores />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}
const mapStateToProp = state => {
  return {};
};

export default withRouter(connect(mapStateToProp)(Clientes));
