// 18-07-2019 - AFONSO - Funcao para obter clientes
export function getClientes() {
  return new Promise((resolve, reject) => {
    return fetch("/rede/getClientes", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter promotores
export function getPromotores() {
  return new Promise((resolve, reject) => {
    return fetch("/rede/getPromotores", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter afiliados
export function getAfiliados() {
  return new Promise((resolve, reject) => {
    return fetch("/rede/getAfiliados", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter consultores
export function getConsultants() {
  return new Promise((resolve, reject) => {
    return fetch("/rede/getConsultants", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
