import { Component } from "react";
import Toast from "../../../helpers/Toast";
import { allConsultantsCurrentMonthBilling } from "../../../actions/indicadoresActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class FaturacaoMesCorrenteTodosConsultores extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            columns: [],
            blocking: true,
            hidden: "initial",
            show: "none",
            rowNumber: 10,
            dataSize: 0,
            contactos: [],
            headerStyle: {
                "wdith": "50%"
            },
            columnsStyle: {
                "wdith": "50%"
            }
        }
    }

    componentDidMount() {
        allConsultantsCurrentMonthBilling().then(result => {
            if (result.success === false) {
                Toast.Show("error", data.message);
                return;
            }

            if (result.obj.data) {
                this.setState({ dataSize: result.obj.data.length });
                this.setState({
                    data: result.obj.data,
                    rowNumber: result.obj.data.length
                });
            }
            this.setState({
                columns: [
                    {
                        Header: "Posição",
                        accessor: "Posicao",
                        resizable: false,
                        style: { display: "block", textAlign: "center", padding: 0 },
                        width: 150,
                        Cell: row => {
                            return (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "inherit",
                                    height: "100%",
                                    padding: "0.55rem",
                                    backgroundColor: row.original.Posicao <= 10 ? "#a6e595" //verde
                                        : row.original.Posicao > this.state.rowNumber - 10 ? "#ea8889" //vermelho
                                            : "#fff"
                                }}>
                                    {row.original.Posicao}
                                </div>
                            )
                        }
                    },
                    {
                        Header: "Nome",
                        accessor: "NomeCompleto",
                        resizable: false,
                        style: { display: "block", textAlign: "center", padding: 0 },
                        Cell: row => {
                            return (
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "inherit",
                                    height: "100%",
                                    padding: "0.55rem",
                                    backgroundColor: row.original.Posicao <= 10 ? "#a6e595"//verde
                                        : row.original.Posicao > this.state.rowNumber - 10 ? "#ea8889" //vermelho
                                            : "#fff"
                                }}>
                                    {row.original.NomeCompleto}
                                </div>
                            )
                        }
                    }
                ]
            });
            this.setState({ blocking: false });
        });
    }

    render() {
        return (
            <SimpleTable
                data={this.state.data}
                columns={this.state.columns}
                blocking={this.state.blocking}
                rowNumber={this.state.rowNumber}
                dataSize={this.state.dataSize}
                noDataText={"Sem faturações para mostrar"}
                title={"Posição faturação mensal de todos os consultores"}
                headerStyle={this.state.headerStyle}
                columnsStyle={this.state.columnsStyle}
            />
        );
    }
}

export default FaturacaoMesCorrenteTodosConsultores;