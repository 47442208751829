import React from "react";
import SweetAlert from "sweetalert-react";
import { removeBusiness } from "../../../actions/negociosActions";

class RemoverNegocio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      negocio: {}
    };
  }

  setID(id_negocio) {
    this.setState({ negocio: id_negocio });
  }

  removerNegocio = async e => {
    removeBusiness(this.state.negocio).then(data => {
      if (data.messageType === "success") {
        this.setState({
          showDeleteSuccessAlert: true,
          DeleteAlertType: "success",
          DeleteAlertTitle: "Apagado",
          DeleteAlertText: data.message
        });
        this.props.updateData();
      } else {
        this.setState({
          showDeleteSuccessAlert: true,
          DeleteAlertType: "error",
          DeleteAlertTitle: "Erro"
        });
        if (data.message) {
          this.setState({
            DeleteAlertText: data.message
          });
        } else {
          this.setState({
            DeleteAlertText: "Ocorreu um erro ao apagar o seu negócio"
          });
        }
      }
    });
  };

  componentDidMount() {
    this.props.onRef(this);
  }

  render() {
    return (
      <div>
        <SweetAlert
          title="Deseja remover o negocio?"
          confirmButtonColor=""
          show={this.props.deleteMessage}
          text="Não será possivel recuperar o negócio!"
          showCancelButton
          onConfirm={() => {
            this.removerNegocio();
          }}
          onCancel={() => this.setState({ message5: true })}
        />

        <SweetAlert
          title={this.state.DeleteAlertTitle}
          confirmButtonColor=""
          show={this.state.showDeleteSuccessAlert}
          text={this.state.DeleteAlertText}
          type={this.state.DeleteAlertType}
          onConfirm={() => {
            this.setState({ showDeleteSuccessAlert: false });
            this.props.updateData();
            this.props.deleteModal();
          }}
        />
      </div>
    );
  }
}

export default RemoverNegocio;
