import React, { Component } from "react";
import Avatar from "react-avatar";
import Countdown from "react-countdown-now";
import { getLeads } from "../../../actions/leadsActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

var that;
class ListaLeads extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0,
      deleteMessage: false
    };
    that = this;
    this.toggle = this.toggle.bind(this);
    this.deleteModal = this.deleteModal.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  deleteModal() {
    this.setState({
      deleteMessage: !this.state.deleteMessage
    });
  }

  getData() {
    getLeads().then(data => {
      if (data && !data.message) {
        that.setState({ dataSize: 1 });
        that.setState({
          data: data
        });
      }
      if (data.message) {
        that.setState({
          data: [],
          dataSize: 0
        });
      }
      that.setState({
        columns: [
          {
            Header: "Nome",
            accessor: "Nome",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <div>
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left mr-3">
                        <div className="widget-content-left">
                          <Avatar
                            name={row.original.Nome}
                            round={true}
                            size={"50px"}
                          />
                        </div>
                      </div>
                      <div className="widget-content-left flex2">
                        <span
                          className="widget-heading fake-link"
                          id="fake-link-1"
                          onClick={() => {
                            window.location.href =
                              "/contacto/" + row.original.ID_Contacto;
                          }}
                        >
                          {row.original.Nome}
                        </span>
                        <div className="widget-subheading opacity-10">
                          <span className="pr-2">
                            <b>Estado: </b> {row.original.ID_TipoStatusContacto}
                          </span>
                          <span className="pr-2">
                            <b>LP: </b> {row.original.OrigemLead}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          },
          {
            Header: "Email",
            accessor: "Email",
            resizable: false,
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Telemóvel",
            accessor: "Telemovel",
            resizable: false,
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Tempo Restante",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            show: true,
            Cell: row => {
              return (
                <Countdown
                  date={
                    Date.parse(row.original.DataFimTratamentoNew) +
                    24 * 60 * 60 * 1000 -
                    60 * 60 * 1000
                  }
                />
              );
            }
          }
        ]
      });
      that.setState({ blocking: false });
    });
  }

  componentDidMount() {
    this.props.onRef(this);
    this.getData();
  }
  render() {
    return (
      <span>
        <SimpleTable
          data={this.state.data}
          columns={this.state.columns}
          blocking={this.state.blocking}
          rowNumber={this.state.rowNumber}
          dataSize={this.state.dataSize}
          noDataText={"Sem leads para apresentar"}
          title={"Todas as leads disponiveis"}
        />
      </span>
    );
  }
}

export default ListaLeads;
