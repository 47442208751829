import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from 'reactstrap';
import { getAddress, modifyBillingAddress, setErrorCodeCheckout } from "../../../actions/checkoutActions";
import SearchInputComponent from '../../../helpers/SearchComponents/SearchInputComponent';
import SearchListComponent, { ListProps } from '../../../helpers/SearchComponents/SearchListComponent';
import Toast from "../../../helpers/Toast";
import { ValidateBillingAddress } from '../../../helpers/UtilsCheckout';
import { AddressDTO } from '../../../Models/AddressDTO';
import IHTPButton from '../../Common/Button';
import IHTPSpacer from '../../Common/Spacer';

export interface EditBillingAddressProps {
    onClose: () => void,
    billingDTO: EditBillingDTO
}

export class EditBillingDTO {
    isOpen: boolean = false;
    billingAddressId: number | null = null;
}

const novaMoradaInitialState: AddressDTO = {
    id_cliente_morada: "",
    nome: "",
    nif: "",
    morada: "",
    localidade: "",
    codigoPostal: "",
    principal: "N",
    id_pais: "",
    ID_Tipo_Morada: ""
}

export default function EditBillingAddress(props: EditBillingAddressProps) {

    const [alterarMorada, setAlterarMorada] = useState(novaMoradaInitialState);
    const [loadingModifyAddress, setLoadingModifyAddress] = useState<boolean>(false);
    const [loadingInput, setLoadingInput] = useState<boolean>(true);
    const dispatch = useDispatch();

    const allPaises = useSelector((state: any) => state.allPaises);

    const paisesOptions: ListProps[] = useMemo(() => {
        if (typeof allPaises === "undefined" || allPaises == null) return [];

        var paises: ListProps[] = [];
        allPaises.forEach(i => {
            paises.push({
                value: i.alpha3,
                label: i.name
            });
        })
        return paises;
    }, [allPaises])


    const [defaultAddressOptions, setDefaultAddressOptions] = useState<ListProps[]>([
        { value: "N", label: "Não" },
        { value: "S", label: "Sim" }
    ]);

    useEffect(() => {

        if (props.billingDTO.isOpen === true && props.billingDTO.billingAddressId != null) {
            loadAddress(props.billingDTO.billingAddressId);
        }

        return () => { }
    }, [props.billingDTO.isOpen, props.billingDTO.billingAddressId])

    const loadAddress = async (addressId: number) => {
        setLoadingInput(true);
        var resultGetAddress: any = await dispatch(getAddress(addressId));
        setAlterarMorada((prevState) => ({
            ...prevState,
            id_cliente_morada: resultGetAddress.data.address[0].ID_Cliente_Morada,
            nome: resultGetAddress.data.address[0].Nome,
            nif: resultGetAddress.data.address[0].Nif,
            morada: resultGetAddress.data.address[0].Morada,
            localidade: resultGetAddress.data.address[0].Localidade,
            codigoPostal: resultGetAddress.data.address[0].CodigoPostal,
            principal: resultGetAddress.data.address[0].Principal,
            id_pais: resultGetAddress.data.address[0].Pais
        }));

        setLoadingInput(false);
    }


    const handleCleanModifyBillingAddress = () => {
        setAlterarMorada(novaMoradaInitialState);
        props.onClose();
    };

    const handleModifyBillingChangePais = (value: string) => {
        setAlterarMorada((prevState) => ({
            ...prevState,
            id_pais: value,
            nif: ""
        }));
    };

    const handleModifyAddressValidateNif = (value: string) => {
        if (alterarMorada.id_pais === "PRT") {
            if (value.length < 10 && /^\d*$/.test(value)) {
                setAlterarMorada((prevState) => ({
                    ...prevState,
                    nif: value
                }));
            }
        } else {
            setAlterarMorada((prevState) => ({
                ...prevState,
                nif: value
            }));
        }
    }

    const handleFormModifyBillingAddressSubmit = async () => {

        var validateAddress = ValidateBillingAddress(alterarMorada, dispatch);
        if (validateAddress.success === false) {
            Toast.Show("error", validateAddress.message);
            return;
        }

        setLoadingModifyAddress(true);
        var data: any = await dispatch(modifyBillingAddress(alterarMorada));
        setLoadingModifyAddress(false);

        if (data.success != true) {
            Toast.Show("error", `${data.message}`);
            return;
        }

        Toast.Show("success", `${data.message}`);

        await dispatch(setErrorCodeCheckout(""))

        props.onClose();
    }

    return (
        <Row>
            <div className="billingModifyAddress">
                <Col xs={12} sm={12} md={12} lg={12}>

                    <Row className="billingRowTitleAddress">
                        <span className="titleOptionsAddress">
                            Modificar morada de faturação
                        </span>
                    </Row>

                    {/* Nome da Morada */}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>

                            {/* Morada */}
                            <SearchInputComponent labelText={"Nome"}
                                onChange={(e: any) => setAlterarMorada({ ...alterarMorada, nome: e.target.value })}
                                value={alterarMorada.nome} initialValue={alterarMorada.nome}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchInputComponent labelText={"Morada"}
                                onChange={(e: any) => setAlterarMorada({ ...alterarMorada, morada: e.target.value })}
                                value={alterarMorada.morada} initialValue={alterarMorada.morada}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            {/* Localidade*/}
                            <SearchInputComponent labelText={"Localidade"}
                                onChange={(e: any) => setAlterarMorada({ ...alterarMorada, localidade: e.target.value })}
                                value={alterarMorada.localidade} initialValue={alterarMorada.localidade}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            {/* Codigo Postal */}
                            <SearchInputComponent labelText={"Código Postal"}
                                onChange={(e: any) => setAlterarMorada({ ...alterarMorada, codigoPostal: e.target.value })}
                                value={alterarMorada.codigoPostal} initialValue={alterarMorada.codigoPostal}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            {/* Pais da Morada */}
                            <SearchListComponent labelText={"País"}
                                list={paisesOptions}
                                onChange={(e: any) => handleModifyBillingChangePais(e.target.value)}
                                initialValue={alterarMorada.id_pais}
                                value={alterarMorada.id_pais}
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            {/* Nif */}
                            <SearchInputComponent labelText={"NIF"}
                                onChange={(e: any) => handleModifyAddressValidateNif(e.target.value)}
                                value={alterarMorada.nif} initialValue={alterarMorada.nif}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchListComponent labelText={"Definir como padrão"}
                                list={defaultAddressOptions}
                                onChange={(e: any) => setAlterarMorada({ ...alterarMorada, principal: e.target.value })}
                                initialValue={alterarMorada.principal}
                                value={alterarMorada.principal}
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"20px"} />

                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                <Col xl={7} lg={8} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "1rem" }}>
                                    <IHTPButton
                                        text='Guardar alterações'
                                        buttonStyle='primary'
                                        onClick={handleFormModifyBillingAddressSubmit}
                                        loading={loadingModifyAddress}
                                        disabled={loadingInput} />

                                    <IHTPButton
                                        text='Cancelar'
                                        buttonStyle='secondary'
                                        onClick={handleCleanModifyBillingAddress}
                                        disabled={loadingModifyAddress} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Row>
    )
}
