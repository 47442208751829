import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { verifyOrder } from "../../actions/checkoutActions";
import { getPaises } from "../../actions/geralActions";
import { getUser, getUserCheckoutDefaultValues } from "../../actions/userActions";
import Toast from "../../helpers/Toast";
import "./Checkout.css";
import CodigosPromocionais from "./CodigosPromocionais";
import Creditos from "./Creditos";
import DadosEnvio from "./DadosEnvio/DadosEnvio";
import DadosFaturacao from "./DadosFaturacao/DadosFaturacao";
import FormasEnvio from "./FormasEnvio";
import Observacao from "./Observacao";
import TiposPagamento from "./TiposPagamento";
import Total from "./Total";
import Vales from "./Vales";

export default function Checkout() {
	const dispatch = useDispatch();

	const user = useSelector((state: any) => state.user);

	const freeOrder: number = useSelector((state: any) => state.checkoutData.ProdutosTodosGratuitos);
	const selectedShippingTypeExternal = useSelector((state: any) => state.checkoutData.TipoTransporteExterno ?? "S");
	const numberOfPhysicalProducts: number = useSelector((state: any) => state.checkoutData.TemProdutosFisicos);
	const total = useSelector((state: any) => state.checkoutData.Total);

	useEffect(() => {
		dispatch(getUser());
		dispatch(getUserCheckoutDefaultValues());

		loadOrder();
		//Moradas precisam dos países
		dispatch(getPaises());
	}, [])



	const loadOrder = async () => {
		try {
			var data: any = await dispatch(verifyOrder());
			if (typeof data.json.checkoutData.MensagemErro !== "undefined" && data.json.checkoutData.MensagemErro !== null && data.json.checkoutData.MensagemErro != "") {
				Toast.Show("error", data.json.checkoutData.MensagemErro);
			}
		} catch (exp) { }
	}

	return (
		<div style={{textAlign: "center"}}>
			<Row className="checkoutTitleParent">
				<Col>
					<div className="checkoutTitle">
						Encomendar
					</div>
				</Col>
			</Row>
			<hr />
			<Row>
				{/*DADOS ESQUERDA*/}
				<Col xs={12} sm={12} md={12} lg={7}>
					{/*DADOS FATURAÇÃO*/}
					{freeOrder != 1 &&
						<Row style={{ marginBottom: "15px" }}>
							<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px" }}>
								<DadosFaturacao />
							</Col>
						</Row>
					}

					{/*FORMA DE ENVIO*/}
					{numberOfPhysicalProducts !== 0 &&
						<Row style={{ marginBottom: "15px" }}>
							<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px" }} className="formasEnvioCol">
								<FormasEnvio />
							</Col>
						</Row>
					}
					{/*DADOS ENVIO*/}
					{(selectedShippingTypeExternal === 'S' && numberOfPhysicalProducts !== 0) &&
						<Row style={{ marginBottom: "15px" }}>
							<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px" }}>
								<DadosEnvio />
							</Col>
						</Row>
					}

					{/*CÓDIGO PROMOCIONAL, VALES, CRÉDITOS*/}
					{freeOrder != 1 &&
						<Row>
							<Col xs={12} sm={12} md={12} lg={4}>
								<CodigosPromocionais />
							</Col>
							<Col xs={12} sm={12} md={12} lg={4}>
								<Vales />
							</Col>
							<Col xs={12} sm={12} md={12} lg={4}>
								<Creditos />
							</Col>
						</Row>
					}

					{/*OBSERVAÇÕES*/}
					<Row>
						<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px", minHeight: "100%" }} className="observacaoCol">
							<Observacao />
						</Col>
					</Row>
				</Col>

				{/*DADOS DIREITA*/}
				<Col xs={12} sm={12} md={12} lg={5}>
					{/*TIPO PAGAMENTO*/}
					{total != 0 &&
						<Row id="tiposPagamento">
							<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px" }} className="tiposPagamentoCol">
								<TiposPagamento />
							</Col>
						</Row>
					}

					{/*TOTAL*/}
					<Row >
						<Col xs={12} sm={12} md={12} lg={12} style={{ marginTop: "15px", minHeight: "100%" }} className="totalCol">
							<Total user={user} showMoreDetailsOnMount={true} />
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}