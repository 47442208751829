import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { Slide, toast } from "react-toastify";
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from "reactstrap";
import { getContactCalls, insertContactCall } from "../../../actions/contactosActions";

var that;
class Chamadas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: true,
      hidden: "initial",
      show: "none",
      obervacoesData: "initial",
      obervacoesAdicionar: "none",
      chamadas: [],
      chamada: { DataChamada: new Date() }
    };
    that = this;
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(prevState => ({
      chamada: {
        ...prevState.chamada,
        [name]: value
      }
    }));
  }

  getData() {
    getContactCalls(this.props.contactID).then(data => {
      if (data && !data.message) {
        this.setState({
          chamadas: data,
          dataSize: 1
        });
      }
    });
  }
  componentDidMount() {
    this.getData();
  }

  handleChangeDate = ({ DataChamada }) => {
    DataChamada = DataChamada || this.state.chamada.DataChamada;
    this.setState(prevState => ({
      chamada: {
        ...prevState.chamada,
        DataChamada: DataChamada
      }
    }));
  };

  handleDataChamada = DataChamada => this.handleChangeDate({ DataChamada });

  novoChamada = async e => {
    insertContactCall(this.props.contactID, this.state.chamada).then(data => {
      if (data.message === "Nova chamada registada com sucesso") {
        toast(data.message, {
          transition: Slide,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-right",
          type: "success"
        });
        this.getData();
        this.setState({
          chamada: { DataChamada: new Date(), ResultadoChamada: "" },
          obervacoesAdicionar: "none",
          obervacoesData: "initial"
        });
      } else {
        toast(data.message, {
          transition: Slide,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-right",
          type: "error"
        });
      }
    });
  };

  render() {
    return (
      <div>
        <Row>
          <Col style={{ textAlign: "right" }}>
            <Button
              style={{ display: this.state.obervacoesData }}
              className="mb-2 mr-2 btn-pill"
              size="lg"
              color="primary"
              onClick={() => {
                this.setState({
                  obervacoesAdicionar: "initial",
                  obervacoesData: "none"
                });
              }}
            >
              Registar Chamada
            </Button>
            <Button
              style={{ display: this.state.obervacoesAdicionar }}
              className="mb-2 mr-2 btn-pill"
              size="lg"
              color="danger"
              onClick={() => {
                this.setState({
                  obervacoesAdicionar: "none",
                  obervacoesData: "initial"
                });
              }}
            >
              Cancelar
            </Button>
          </Col>
        </Row>
        <div style={{ display: this.state.obervacoesData }}>
          {this.state.chamadas.map(chamada => {
            return (
              <div>
                <span style={{ color: "black" }}>
                  {chamada.ResultadoChamada}
                </span>
                <span style={{ color: "black" }}>{chamada.DataChamada}</span>
                <span style={{ color: "black" }}>{chamada.DataCriacao}</span>
              </div>
            );
          })}
        </div>
        <div style={{ display: this.state.obervacoesAdicionar }}>
          <FormGroup>
            <Label for="examplePassword" className="mr-sm-2">
              Resultado
            </Label>
            <Input
              type="text"
              name="Resultado"
              id="Resultado"
              value={this.state.chamada.Assunto}
              onChange={this.handleChange}
              placeholder="Resultado"
            />
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword" className="mr-sm-2">
              Data Chamada
            </Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <div className="input-group-text">
                  <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
              </InputGroupAddon>
              <DatePicker
                className="form-control"
                name="DataChamada"
                selected={this.state.chamada.DataChamada}
                onChange={this.handleDataEmail}
              />
            </InputGroup>
          </FormGroup>
          <Row>
            <Col style={{ textAlign: "right" }}>
              <Button
                style={{ display: this.state.obervacoesAdicionar }}
                className="mb-2 mr-2 btn-pill"
                size="lg"
                color="primary"
                onClick={this.novoChamada}
              >
                Registar Chamada
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Chamadas;
