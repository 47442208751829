import React from 'react';
import { Input, Label } from 'reactstrap';
import "./SearchInputComponent.css";

export interface ListProps {
    label: string,
    value: string | number
}

interface SearchListComponentProps {
    labelText: string,
    initialValue: string | undefined,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    labelBackgroundColor?: string,
    inputTextColor?: string,
    list: ListProps[] | null | undefined
    value?: string
    labelClassName?: string;
    inputClassName?: string;
}

export default function SearchListComponent(props: SearchListComponentProps) {
    var labelClassName = typeof props.labelClassName === 'undefined' || props.labelClassName === null || props.labelClassName === "" ? "searchComponent_labelStyle" : props.labelClassName;
    var inputClassName = typeof props.inputClassName === 'undefined' || props.inputClassName === null || props.inputClassName === "" ? "searchComponent_inputStyle" : props.inputClassName;

    return (
        <div>
            <Label className={labelClassName} style={{ backgroundColor: props.labelBackgroundColor }}> {props.labelText}  </Label>
            <Input type={"select"} className={inputClassName} style={{ color: props.inputTextColor }} onChange={props.onChange} defaultValue={props.initialValue} value={props.value}>
                <option value={""}>Não Especificado...</option>
                {props.list != null && props.list.length > 0 ?
                    props.list.map(l => {
                        return <option value={l.value}>{l.label}</option>
                    })
                    : null}
            </Input>
        </div>
    )
}
