import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BlockUi from "react-block-ui";
import { FormattedNumber } from "react-intl";
import { Loader } from "react-loaders";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getResumoDetailCommissions } from "../../../actions/comissoesActions";

var that;
class Detalhe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: true,
      hidden: "initial",
      show: "none",
      data: []
    };
    that = this;
  }

  getData() {
    getResumoDetailCommissions(this.props.ano, this.props.mes).then(data => {
      if (data && !data.message) {
        this.setState({
          data: data.comissoes[0],
          dataSize: data.comissoes.length
        });
      }
      this.setState({ blocking: false });
    });
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div />
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">
                      {" "}
                    </i>
                    Informações da comissão
                  </div>
                  <div className="btn-actions-pane-right">
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="danger"
                      onClick={() => {
                        this.setState({ hidden: "none", show: "initial" });
                      }}
                      style={{ display: this.state.hidden }}
                    >
                      <i
                        className="lnr-circle-minus"
                        style={{ display: this.state.hidden }}
                        onClick={() => {
                          this.setState({
                            hidden: "none",
                            show: "initial"
                          });
                        }}
                      />
                    </Button>
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="success"
                      onClick={() => {
                        this.setState({ hidden: "initial", show: "none" });
                      }}
                      style={{ display: this.state.show }}
                    >
                      <i
                        className="lnr-plus-circle"
                        style={{ display: this.state.show }}
                        onClick={() => {
                          this.setState({
                            hidden: "initial",
                            show: "none"
                          });
                        }}
                      />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody style={{ display: this.state.hidden }}>
                  <BlockUi
                    tag="div"
                    blocking={this.state.blocking}
                    className="block-overlay-dark"
                    loader={
                      <Loader
                        color="#ffffff"
                        active
                        type="ball-spin-fade-loader"
                      />
                    }
                  >
                    {this.state.dataSize > 0 && !this.state.blocking ? (
                      <span>
                        <Row>
                          <Col xs="12" sm="12" md="3" lg="3">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              Ano - Mês
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {this.state.data.Ano} - {this.state.data.Mes}
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="3" lg="3">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              NetPoints Cliente
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {this.state.data.NetPointsCliente ? (
                                <FormattedNumber
                                  value={this.state.data.NetPointsCliente}
                                  style="number"
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />
                              ) : (
                                  "0.00"
                                )}
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="3" lg="3">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              NetPoints Pessoal
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {this.state.data.NetPointsPessoal ? (
                                <FormattedNumber
                                  value={this.state.data.NetPointsPessoal}
                                  style="number"
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />
                              ) : (
                                  "0.00"
                                )}
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="3" lg="3">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              NetPoints Rede
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {this.state.data.NetPointsRede ? (
                                <FormattedNumber
                                  value={this.state.data.NetPointsRede}
                                  style="number"
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />
                              ) : (
                                  "0.00"
                                )}
                            </p>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs="12" sm="12" md="4" lg="4">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              Créditos
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {this.state.data.Creditos}
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="4" lg="4">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              Valor de Comissão
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {this.state.data.ValorComissao ? (
                                <FormattedNumber
                                  value={this.state.data.ValorComissao}
                                  style="currency"
                                  currency={this.state.data.ID_Moeda}
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />
                              ) : (
                                  "0.00"
                                )}
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="4" lg="4">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              Valor de encomendas
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {this.state.data.ValorEncomendas ? (
                                <FormattedNumber
                                  value={this.state.data.ValorEncomendas}
                                  style="currency"
                                  currency={this.state.data.ID_Moeda}
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />
                              ) : (
                                  "0.00"
                                )}
                            </p>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs="12" sm="12" md="4" lg="4">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              NetPoints Rede
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {this.state.data.NetPointsRede ? (
                                <FormattedNumber
                                  value={this.state.data.NetPointsRede}
                                  style="number"
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />
                              ) : (
                                  "0.00"
                                )}
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="4" lg="4">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              NetPoints Total
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {this.state.data.NetPointsTotal ? (
                                <FormattedNumber
                                  value={this.state.data.NetPointsTotal}
                                  style="number"
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />
                              ) : (
                                  "0.00"
                                )}
                            </p>
                          </Col>
                          <Col xs="6" sm="6" md="4" lg="4">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              Valor Total
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {this.state.data.ValorTotal ? (
                                <FormattedNumber
                                  value={this.state.data.ValorTotal}
                                  style="currency"
                                  currency={this.state.data.ID_Moeda}
                                  minimumFractionDigits={2}
                                  maximumFractionDigits={2}
                                />
                              ) : (
                                  "0.00"
                                )}
                            </p>
                          </Col>
                        </Row>
                      </span>
                    ) : (
                        <div style={{ textAlign: "center" }}>
                          {!this.state.blocking
                            ? "Não existem detalhes da comissão"
                            : null}
                        </div>
                      )}
                  </BlockUi>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Detalhe;
