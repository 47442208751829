export function getConsultantDownline(profundidade) {
  return new Promise((resolve, reject) => {
    return fetch("/consultant/getConsultantDownline", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Profundidade: profundidade
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


//Obter os consultores com menos de 3 possibilidades de receber leads
export function getConsultantsWithLostLeads(){
  return new Promise((resolve, reject) =>{
    return fetch("/consultant/getConsultantsWithLostLeads", {
      method: "POST",
      headers:{
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
    .then(res => res.json())
    .then(json => {
      resolve(json);
    })
    .catch(function(error){
      reject(error);
    })
  });
}