import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitleAlt4 from "../../helpers/Layout/PageTitleAlt4";
import Detalhe from "./Components/Detalhe";
import Tabela from "./Components/Tabela";

class DetalheComissao extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt4
            heading="Detalhe da Comissão"
            subheading="Página onde pode ver o detalhe das comissões"
            icon="pe-7s-graph icon-gradient bg-ripe-malin"
            id={this.props.id}
          />
          <Detalhe ano={this.props.ano} mes={this.props.mes}/>
          <Tabela ano={this.props.ano} mes={this.props.mes} />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default DetalheComissao;
