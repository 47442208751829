import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { MenuItem, Select } from '@mui/material';
import $ from "jquery";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { addBillingAddressCheckout, getBillingAddresses, removeBillingAddress, setBillingAddress, setBillingAddressAsDefault, verifyOrder } from "../../../actions/checkoutActions";
import Toast from "../../../helpers/Toast";
import { HasError, ShowOrHideBillingAddressButtons } from '../../../helpers/UtilsCheckout';
import { AddressDTO } from "../../../Models/AddressDTO";
import IHTPButton from '../../Common/Button';
import CreateBillingAddress from "./CreateBillingAddress";
import EditBillingAddress, { EditBillingDTO } from "./EditBillingAddress";

const customStyles = {
	buttonsEditAndRemove: {
		minWidth: "100%",
		backgroundColor: "rgba(0, 0, 0, 0)",
		border: "none"
	},
	buttonAdd: {
		minWidth: "fit-content",
		backgroundColor: "rgba(0, 0, 0, 0)",
		border: "none"
	}
}

export interface MoradaDTO {
	id_cliente_morada: string,
	nome: string,
	nif: string,
	morada: string,
	localidade: string,
	codigoPostal: string,
	principal: string,
	id_pais: string,
	ID_Tipo_Morada: string
}

export default function DadosFaturacao() {
	const novaMoradaInitialState = {
		id_cliente_morada: "",
		nome: "",
		nif: "",
		morada: "",
		localidade: "",
		codigoPostal: "",
		principal: "N",
		id_pais: "",
		ID_Tipo_Morada: ""
	}

	const [justChoosingAddress, setJustChoosingAddress] = useState<boolean>(true);

	const [novaMorada, setNovaMorada] = useState(novaMoradaInitialState);
	const [adicionar, setAdicionar] = useState<boolean>(true);
	const [remover, setRemover] = useState<boolean>(false);
	const [alterar, setAlterar] = useState<boolean>(false);
	const [hasError, setHasError] = useState<boolean>(false);
	const [shouldCreateNewAddress, setShouldCreateNewAddress] = useState<boolean>(false);
	const [editBillingDTO, setEditBillingDTO] = useState<EditBillingDTO>(new EditBillingDTO());
	const [loadingSetAsDefault, setLoadingSetAsDefault] = useState<boolean>(false);
	const [loadingRemove, setLoadingRemove] = useState<boolean>(false);

	const dispatch = useDispatch();

	const billingAddresses = useSelector((state: any) => state.billingAddresses);
	const confirmOrderClicked = useSelector((state: any) => state.checkoutData.confirmOrderClicked);
	const freeOrder: number = useSelector((state: any) => state.checkoutData.ProdutosTodosGratuitos);
	const selectedBillingAddressId = useSelector((state: any) => state.checkoutData.billingAddress);
	const errorCode = useSelector((state: any) => state.errorCode);

	const currentBillingAddress: null | AddressDTO = useMemo(() => {

		if (typeof billingAddresses == "undefined" || billingAddresses == null || Array.isArray(billingAddresses) === false || billingAddresses.length <= 0) return null;

		let selectedAddress = billingAddresses.find(a => a.ID_Cliente_Morada == selectedBillingAddressId);
		if (selectedAddress == null) return null;

		var newAddress: AddressDTO = {
			nome: selectedAddress.Nome,
			morada: selectedAddress.Morada,
			localidade: selectedAddress.Localidade,
			codigoPostal: selectedAddress.CodigoPostal,
			id_pais: selectedAddress.ID_Pais,
			ID_Tipo_Morada: selectedAddress.ID_Tipo_Morada,
			principal: selectedAddress.Principal
		}

		return newAddress;
	}, [selectedBillingAddressId, billingAddresses])

	useEffect(() => {
		initialLoad();
	}, [])

	const initialLoad = async () => {
		var resultGetBillingAddresses: any = await dispatch(getBillingAddresses());

		//Se não existirem moradas ainda
		if (typeof resultGetBillingAddresses == "undefined" || resultGetBillingAddresses == null || Array.isArray(resultGetBillingAddresses) === false || resultGetBillingAddresses.length <= 0) {

			//Se ele não tiver moradas colocamos ID 0 no checkout
			await dispatch(addBillingAddressCheckout(0));

			//Verificamos a encomenda
			var data: any = await dispatch(verifyOrder());

			//Se a encomenda tiver produtos com valor unitário > 0, então dizemos que é preciso adicionar uma morada de faturação
			if (data.json.checkoutData.ProdutosTodosGratuitos !== 1) {
				//Abrimos ecrã para criar
				onOpenCreateBillingAddressComponent();
			}
			return;
		}

		setAlterar(true);
		setRemover(true);
		setAdicionar(true);
		await dispatch(addBillingAddressCheckout(getNextBillingAddress(resultGetBillingAddresses)));
	}

	useEffect(() => {
		var updateBillingAddress = async () => {
			var moradaDTO: MoradaDTO = {
				id_cliente_morada: novaMorada.id_cliente_morada,
				nome: novaMorada.nome,
				nif: novaMorada.nif,
				morada: novaMorada.morada,
				localidade: novaMorada.localidade,
				codigoPostal: novaMorada.codigoPostal,
				principal: novaMorada.principal,
				id_pais: novaMorada.id_pais,
				ID_Tipo_Morada: novaMorada.ID_Tipo_Morada
			}

			await dispatch(setBillingAddress(moradaDTO))
		}

		updateBillingAddress();
	}, [novaMorada])

	useEffect(() => {
		let hasError = HasError(errorCode, ["Faturacao"]);
		if (hasError !== errorCode) {
			setHasError(hasError);

			if (hasError === true) {
				var p = document.getElementById("billingAddress");
				if (p != null) p.scrollIntoView({ block: "center" });
			}
		}
	}, [errorCode]);

	useEffect(() => {
		var shouldCreate = checkIfShouldCreateNewAddress();
		if (shouldCreate != shouldCreateNewAddress) {
			setShouldCreateNewAddress(shouldCreate);
			if (shouldCreate === false) {
				onOpenCreateBillingAddressComponent();
			}
		}
	}, [freeOrder]);

	useEffect(() => {
		//Para prevenir situações em que ao mudar de transporte o componente deixe de ficar interagível.				
		setAdicionar(true);
		if (selectedBillingAddressId) {
			setRemover(true);
			setAlterar(true);
		}

		handleChangeBillingAddress(selectedBillingAddressId)
	}, [freeOrder])

	const getNextBillingAddress = (addresses): number => {

		let newBillingAddressId = 0;
		if (typeof addresses === "undefined" || addresses == null || Array.isArray(addresses) === false || addresses.length <= 0) return 0;

		var principalAddress = addresses.find(a => a.Principal == "S");
		if (principalAddress != null) {
			newBillingAddressId = principalAddress.ID_Cliente_Morada;
		} else {
			var lastAddress = addresses[addresses.length - 1];
			newBillingAddressId = lastAddress.ID_Cliente_Morada;
		}
		return newBillingAddressId;
	}

	const handleRemoveBillingAddress = async (e: React.MouseEvent<{}, MouseEvent>) => {
		e.stopPropagation();

		setLoadingRemove(true);
		var data: any = await dispatch(removeBillingAddress(selectedBillingAddressId));
		setLoadingRemove(false);

		if (data.success === false) {
			Toast.Show("error", data.message);
			return;
		}

		var addresses: any = await dispatch(getBillingAddresses());

		var novaMorada = getNextBillingAddress(addresses);

		await dispatch(addBillingAddressCheckout(novaMorada));

		if (addresses.length === 0) {
			onOpenCreateBillingAddressComponent();
		}

		Toast.Show("success", `${data.message}`);
	}

	const handleChangeBillingAddress = async (value: any) => {
		if (value == null || typeof value == 'undefined' || value == "") {
			return;
		}

		if (remover === false) {
			setRemover(true);
		}
		if (alterar === false) {
			setAlterar(true);
		}

		await dispatch(addBillingAddressCheckout(value));
	};

	const checkIfShouldCreateNewAddress = () => {
		var numberOfAddresses = billingAddresses.length;

		//Se a encomenda só tiver produtos gratuitos ou se tiver alguma morada de faturação adicionada
		//então retornamos indicação de que não deve ser para criar uma nova morada.
		if (freeOrder !== 1 || numberOfAddresses > 0) {
			return false;
		}
		return true;
	}

	const onCloseCreateBillingAddressComponent = () => {
		var item = $(".billingAddAddress");
		var list = $(".billingAddresses");
		item.removeClass("is-open");
		ShowOrHideBillingAddressButtons(1);

		item.one("transitionend", function (e) {
			item.removeClass("is-visible");
			list.removeClass("is-not-visible");
		});

		$(".breadCrumbRow").removeClass("disabledBox");
		$(".dadosEnvioCol").removeClass("disabledBox");
		$(".formasEnvioCol").removeClass("disabledBox");
		$(".tiposPagamentoCol").removeClass("disabledBox");
		$(".codigosPromocionaisCol").removeClass("disabledBox");
		$(".valesCol").removeClass("disabledBox");
		$(".creditosCol").removeClass("disabledBox");
		$(".observacaoCol").removeClass("disabledBox");
		$(".totalCol").removeClass("disabledBox");

		setAlterar(true);
		setAdicionar(true);
		setRemover(true);
		setJustChoosingAddress(true);
	}

	const onOpenCreateBillingAddressComponent = async () => {
		var item = $(".billingAddAddress");
		var list = $(".billingAddresses");
		item.addClass("is-visible");
		list.addClass("is-not-visible");
		ShowOrHideBillingAddressButtons(0);

		setTimeout(function () {
			item.addClass("is-open");
		}, 20);

		setJustChoosingAddress(false);
	}

	const onCloseEditBillingComponent = () => {
		var item = $(".billingModifyAddress");
		var list = $(".billingAddresses");

		item.removeClass("is-open");
		ShowOrHideBillingAddressButtons(1);

		item.one("transitionend", function (e) {
			item.removeClass("is-visible");
			list.removeClass("is-not-visible");
		});

		$(".breadCrumbRow").removeClass("disabledBox");
		$(".dadosEnvioCol").removeClass("disabledBox");
		$(".formasEnvioCol").removeClass("disabledBox");
		$(".tiposPagamentoCol").removeClass("disabledBox");
		$(".codigosPromocionaisCol").removeClass("disabledBox");
		$(".valesCol").removeClass("disabledBox");
		$(".creditosCol").removeClass("disabledBox");
		$(".observacaoCol").removeClass("disabledBox");
		$(".totalCol").removeClass("disabledBox");

		setJustChoosingAddress(true);
		setAlterar(true);
		setAdicionar(true);
		setRemover(true);

		var newEdit = new EditBillingDTO();
		newEdit.isOpen = false;
		newEdit.billingAddressId = null;
		setEditBillingDTO(newEdit)
	}

	const onOpenEditBillingComponent = () => {
		var item = $(".billingModifyAddress");
		var list = $(".billingAddresses");

		item.addClass("is-visible");
		list.addClass("is-not-visible");
		ShowOrHideBillingAddressButtons(0);

		setTimeout(function () {
			item.addClass("is-open");
		}, 20);

		setJustChoosingAddress(false);

		var newEdit = new EditBillingDTO();
		newEdit.isOpen = true;
		newEdit.billingAddressId = parseInt(selectedBillingAddressId);

		setEditBillingDTO(newEdit)
	}

	const setAddressAsDefaultBillingAddress = async () => {

		setLoadingSetAsDefault(true);
		var result: any = await setBillingAddressAsDefault(selectedBillingAddressId);
		setLoadingSetAsDefault(false);

		if (result.success === false) {
			Toast.Show("error", result.message);
			return;
		}

		Toast.Show("success", result.message);

		dispatch(getBillingAddresses());
	}

	return (
		<div
			className={hasError === true ? "checkoutBox errorBorder"
				: !confirmOrderClicked && selectedBillingAddressId
					? "checkoutBox doneSelected"
					: confirmOrderClicked
						? selectedBillingAddressId
							? "checkoutBox doneSelected"
							: "checkoutBox discountSelected"
						: "checkoutBox"
			}
			id="billingAddress">
			<Row>
				<Col xs={12} sm={12} md={12} lg={12}>

					{/* Informação na parte de cima: titulo e adicionar*/}
					<Row style={{ textAlign: "center" }}>
						<Col xs={9} sm={9} md={9} lg={10} style={{ textAlign: "left" }} className="checkoutBoxTitle">
							Dados de faturação
						</Col>
						{billingAddresses.length !== 0 ? (
							<Col xs={3} sm={3} md={3} lg={2} className="addButtonBillingAddress">
								<Button
									className="addBillingAddress"
									disabled={!adicionar}
									secondary={true}
									onClick={onOpenCreateBillingAddressComponent}
									style={customStyles.buttonAdd}
								>
									<svg
										height="25px"
										viewBox="0 0 512 512"
										width="25px"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
										<path d="m256 365.714844c-10.097656 0-18.285156-8.1875-18.285156-18.285156v-182.859376c0-10.097656 8.1875-18.285156 18.285156-18.285156s18.285156 8.1875 18.285156 18.285156v182.859376c0 10.097656-8.1875 18.285156-18.285156 18.285156zm0 0" />
										<path d="m347.429688 274.285156h-182.859376c-10.097656 0-18.285156-8.1875-18.285156-18.285156s8.1875-18.285156 18.285156-18.285156h182.859376c10.097656 0 18.285156 8.1875 18.285156 18.285156s-8.1875 18.285156-18.285156 18.285156zm0 0" />
									</svg>
								</Button>
							</Col>) : null}
					</Row>

					{/* Adicionar nova morada */}
					<CreateBillingAddress onClose={onCloseCreateBillingAddressComponent} />

					{/* Modificar morada */}
					<EditBillingAddress onClose={onCloseEditBillingComponent} billingDTO={editBillingDTO} />

					{/* Escolher moradas */}
					{billingAddresses.length > 0 && (
						<Row style={{
							textAlign: "center",
							marginTop: "1em"
						}}>
							<Col xs={12} sm={12} md={10} lg={10} xl={10}>

								{/* Selecionar morada */}
								<div className="billingAddresses">

									<Select
										id="changeBillingAddress"
										value={selectedBillingAddressId}
										onChange={(e) => handleChangeBillingAddress(e.target.value)}
										fullWidth={true}
										className="SelectField"
									>
										{billingAddresses.map((address, j) => {
											return (
												<MenuItem key={address.ID_Cliente_Morada} value={address.ID_Cliente_Morada}>
													{address.Nome + " - " + address.Nif + " - " + address.Morada}
												</MenuItem>
											);
										})}
									</Select>
								</div>

								{justChoosingAddress === true && (
									<>
										{/* Definir como padrão */}
										<Row style={{ display: "flex", justifyContent: "right", alignItems: "flex-end" }}>
											<Col xs={12} sm={12} md={12} lg={10} xl={5} style={{ display: "flex", justifyContent: "right", alignItems: "flex-end" }}>
												{currentBillingAddress != null && currentBillingAddress.principal === "S" ? (
													<span style={{
														textAlign: "right",
														fontFamily: "Montserrat", fontWeight: 600, fontSize: "13px", color: "#007bff"
													}}>Morada faturação padrão selecionada</span>
												) : (
													<IHTPButton
													text={"Definir como morada faturação padrão"}
													onClick={setAddressAsDefaultBillingAddress}
													loading={loadingSetAsDefault}
													buttonStyle={"primary"} />
												)}
											</Col>
										</Row>
									</>
								)}
							</Col>

							<Col xs={6} sm={6} md={1} lg={1} xl={1} className="changeButtonBillingAddress">
								<Button
									style={customStyles.buttonsEditAndRemove}
									className=""
									secondary={true}
									disabled={!alterar}
									onClick={(e) => onOpenEditBillingComponent()}>
									<svg
										height="25px"
										viewBox="0 0 512 512.00115"
										width="25px"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="m485.191406 26.8125c-35.738281-35.742188-93.929687-35.757812-129.683594 0l-324.195312 324.199219c-.082031.085937-.105469.203125-.191406.289062-1.476563 1.53125-2.632813 3.316407-3.503906 5.28125-.226563.511719-.355469 1.015625-.535157 1.539063-.257812.757812-.625 1.460937-.78125 2.257812l-25.941406 129.679688c-1.207031 6.019531.679687 12.234375 5.015625 16.570312 3.476562 3.472656 8.148438 5.371094 12.964844 5.371094 1.199218 0 2.402344-.117188 3.601562-.355469l129.683594-25.941406c.792969-.160156 1.5-.527344 2.253906-.78125.523438-.179687 1.03125-.308594 1.539063-.535156 1.964843-.875 3.75-2.03125 5.28125-3.503907.085937-.085937.203125-.109374.289062-.195312l324.199219-324.191406c35.75-35.757813 35.75-93.9375.003906-129.683594zm-337.167968 414.972656-77.808594-77.808594 272.320312-272.328124 77.816406 77.816406zm-106.304688 28.5 13.824219-69.105468 55.28125 55.277343zm417.539062-339.726562-12.972656 12.972656-77.816406-77.816406 12.972656-12.972656c21.453125-21.472657 56.359375-21.4375 77.816406 0 21.445313 21.457031 21.445313 56.363281 0 77.816406zm0 0" />
										<path d="m148.023438 382.320312c-4.691407 0-9.382813-1.789062-12.964844-5.375-7.164063-7.164062-7.164063-18.769531 0-25.929687l194.515625-194.519531c7.164062-7.164063 18.773437-7.164063 25.933593 0 7.160157 7.164062 7.164063 18.769531 0 25.933594l-194.519531 194.515624c-3.582031 3.582032-8.273437 5.375-12.964843 5.375zm0 0" />
									</svg>
								</Button>
							</Col>
							<Col xs={6} sm={6} md={1} lg={1} xl={1} className="removeButtonBillingAddress">
								{loadingRemove === true ? (
									<CircularProgress size={24} className="buttonProgress" />
								) : (
									<Button
										style={customStyles.buttonsEditAndRemove}
										className=""
										secondary={true}
										disabled={!remover}
										onClick={(e) => handleRemoveBillingAddress(e)}>
										<svg
											height="25px"
											viewBox="-26 0 512 512"
											width="25px"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="m441.378906 70.621094h-105.929687v-34.691406c0-19.808594-16.121094-35.929688-35.929688-35.929688h-140.003906c-19.808594 0-35.929687 16.121094-35.929687 35.929688v34.691406h-105.929688c-9.75 0-17.65625 7.894531-17.65625 17.65625 0 9.757812 7.90625 17.652344 17.65625 17.652344h18.40625l14.652344 336.96875c1.679687 38.757812 33.386718 69.101562 72.175781 69.101562h213.257813c38.785156 0 70.488281-30.34375 72.171874-69.101562l14.652344-336.96875h18.40625c9.75 0 17.65625-7.894532 17.65625-17.652344 0-9.761719-7.90625-17.65625-17.65625-17.65625zm-281.863281-35.3125h140.003906l.617188 35.3125h-141.03125zm213.527344 406.070312c-.863281 19.792969-17.066407 35.3125-36.898438 35.3125h-213.257812c-19.828125 0-36.035157-15.519531-36.898438-35.3125l-14.585937-335.449218h316.226562zm0 0" />
											<path d="m229.515625 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
											<path d="m300.136719 406.070312c-9.75 0-17.652344-7.898437-17.652344-17.65625v-211.863281c0-9.757812 7.902344-17.652343 17.652344-17.652343s17.65625 7.894531 17.65625 17.652343v211.863281c0 9.757813-7.90625 17.65625-17.65625 17.65625zm0 0" />
											<path d="m158.898438 406.070312c-9.753907 0-17.65625-7.898437-17.65625-17.65625v-211.863281c0-9.757812 7.902343-17.652343 17.65625-17.652343 9.75 0 17.652343 7.894531 17.652343 17.652343v211.863281c0 9.757813-7.902343 17.65625-17.652343 17.65625zm0 0" />
										</svg>
									</Button>
								)}
							</Col>
						</Row>
					)}
				</Col>
			</Row>
		</div>
	)
}