import React, { Component } from "react";

class EqualStatus extends Component {

  render() {
    return (
      <svg
        height="25px"
        viewBox="0 0 16 16"
        width="25px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={{ fill: "yellow" }}
          d="M14,2.5H2c-1.104,0-2,0.896-2,2s0.896,2,2,2h12c1.104,0,2-0.896,2-2S15.104,2.5,14,2.5z"
        />
        <path
          style={{ fill: "yellow" }}
          d="M14,9.5H2c-1.104,0-2,0.896-2,2s0.896,2,2,2h12c1.104,0,2-0.896,2-2S15.104,9.5,14,9.5z"
        />
      </svg>
    );
  }
}

export default EqualStatus;
