import React, { Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";

import ResizeDetector from "react-resize-detector";

import Rede from "../../../components/Rede/Rede";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { changeUser } from "../../../actions/userActions";

class RedirectView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false
    };
  }

  componentWillMount(){
    this.props
      .dispatch(changeUser(this.props.id))
      .then(() => {
        window.location.href = "/";
      });
  }

  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt
    } = this.props;

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProp = state => ({
  colorScheme: state.colorScheme,
  enableFixedHeader: state.enableFixedHeader,
  enableMobileMenu: state.enableMobileMenu,
  enableFixedFooter: state.enableFixedFooter,
  enableFixedSidebar: state.enableFixedSidebar,
  enableClosedSidebar: state.enableClosedSidebar,
  enablePageTabsAlt: state.enablePageTabsAlt
});

export default withRouter(connect(mapStateToProp)(RedirectView));
