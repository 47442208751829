import {
  ADD_ITEM_CART_MESSAGE, ADD_ITEM_CART_SUCCESS, FETCH_CART_FAILURE, FETCH_CART_NUMBER_ITEMS, FETCH_CART_SUCCESS,
  LOADINGCART, REMOVE_CART_FAILURE
} from "../actions/cartActions";
import {
  BILLING_ADDRESS,
  ERROR_CODE_CHECKOUT, FETCH_BILLING_ADDRESSES, FETCH_CHECKOUT_DATA, FETCH_DELIVERY_ADDRESSES, FETCH_PAYMENT_TYPES, FETCH_SHIPMENT_TYPES, LOADING, LOADING_CHECKOUT_PRICES,
  SELECT_PAYMENT_INSTALLMENTS, SELECT_PAYMENT_PHONE_NUMBER, SHIPPING_ADDRESS, SHOULD_VERIFY_ORDER
} from "../actions/checkoutActions";
import { ALL_COUNTRIES } from "../actions/geralActions";
import {
  ALL_FUNIS, EDIT_LEAD_CONTACT, LEAD_OPEN, MODAL_OPEN, POSSIBLE_EVENTS, POSSIBLE_RESULTS, SEARCH_RESULTS, SEARCH_RESULTS_CURRENT_PAGE,
  SEARCH_RESULTS_ERROR, SEARCH_RESULTS_LOAD, SEARCH_RESULTS_NEXT_PAGE
} from "../actions/leadsActions";
import { BUSINESS_DETAIL_PAGE_INFO, BUSINESS_DETAIL_PAGE_LOADING, BUSINESS_DETAIL_PAGE_STATES, NEGOCIO_ATUALIZADO } from "../actions/negociosActions";
import { PESQUISA } from "../actions/pesquisaActions";
import { GETUPLINE, LOGIN, STATS_CONSULTANT_DETAILS, USER_CHECKOUT_DEFAULT_VALUES } from "../actions/userActions";
import sideBar6 from "../assets/utils/images/sidebar/city1.jpg";


export const SET_ENABLE_BACKGROUND_IMAGE =
  "THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE";

export const SET_ENABLE_MOBILE_MENU = "THEME_OPTIONS/SET_ENABLE_MOBILE_MENU";
export const SET_ENABLE_MOBILE_MENU_SMALL =
  "THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL";

export const SET_ENABLE_FIXED_HEADER = "THEME_OPTIONS/SET_ENABLE_FIXED_HEADER";
export const SET_ENABLE_HEADER_SHADOW =
  "THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW";
export const SET_ENABLE_SIDEBAR_SHADOW =
  "THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW";
export const SET_ENABLE_FIXED_SIDEBAR =
  "THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR";
export const SET_ENABLE_CLOSED_SIDEBAR =
  "THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR";
export const SET_ENABLE_FIXED_FOOTER = "THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER";

export const SET_ENABLE_PAGETITLE_ICON =
  "THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON";
export const SET_ENABLE_PAGETITLE_SUBHEADING =
  "THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING";
export const SET_ENABLE_PAGE_TABS_ALT =
  "THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT";

export const SET_BACKGROUND_IMAGE = "THEME_OPTIONS/SET_BACKGROUND_IMAGE";
export const SET_BACKGROUND_COLOR = "THEME_OPTIONS/SET_BACKGROUND_COLOR";
export const SET_COLOR_SCHEME = "THEME_OPTIONS/SET_COLOR_SCHEME";
export const SET_BACKGROUND_IMAGE_OPACITY =
  "THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY";

export const SET_HEADER_BACKGROUND_COLOR =
  "THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR";

export const setEnableBackgroundImage = enableBackgroundImage => ({
  type: SET_ENABLE_BACKGROUND_IMAGE,
  enableBackgroundImage
});

export const setEnableFixedHeader = enableFixedHeader => ({
  type: SET_ENABLE_FIXED_HEADER,
  enableFixedHeader
});

export const setEnableHeaderShadow = enableHeaderShadow => ({
  type: SET_ENABLE_HEADER_SHADOW,
  enableHeaderShadow
});

export const setEnableSidebarShadow = enableSidebarShadow => ({
  type: SET_ENABLE_SIDEBAR_SHADOW,
  enableSidebarShadow
});

export const setEnablePageTitleIcon = enablePageTitleIcon => ({
  type: SET_ENABLE_PAGETITLE_ICON,
  enablePageTitleIcon
});

export const setEnablePageTitleSubheading = enablePageTitleSubheading => ({
  type: SET_ENABLE_PAGETITLE_SUBHEADING,
  enablePageTitleSubheading
});

export const setEnablePageTabsAlt = enablePageTabsAlt => ({
  type: SET_ENABLE_PAGE_TABS_ALT,
  enablePageTabsAlt
});

export const setEnableFixedSidebar = enableFixedSidebar => ({
  type: SET_ENABLE_FIXED_SIDEBAR,
  enableFixedSidebar
});

export const setEnableClosedSidebar = enableClosedSidebar => ({
  type: SET_ENABLE_CLOSED_SIDEBAR,
  enableClosedSidebar
});

export const setEnableMobileMenu = enableMobileMenu => ({
  type: SET_ENABLE_MOBILE_MENU,
  enableMobileMenu
});

export const setEnableMobileMenuSmall = enableMobileMenuSmall => ({
  type: SET_ENABLE_MOBILE_MENU_SMALL,
  enableMobileMenuSmall
});

export const setEnableFixedFooter = enableFixedFooter => ({
  type: SET_ENABLE_FIXED_FOOTER,
  enableFixedFooter
});

export const setBackgroundColor = backgroundColor => ({
  type: SET_BACKGROUND_COLOR,
  backgroundColor
});

export const setHeaderBackgroundColor = headerBackgroundColor => ({
  type: SET_HEADER_BACKGROUND_COLOR,
  headerBackgroundColor
});

export const setColorScheme = colorScheme => ({
  type: SET_COLOR_SCHEME,
  colorScheme
});

export const setBackgroundImageOpacity = backgroundImageOpacity => ({
  type: SET_BACKGROUND_IMAGE_OPACITY,
  backgroundImageOpacity
});

export const setBackgroundImage = backgroundImage => ({
  type: SET_BACKGROUND_IMAGE,
  backgroundImage
});

const initialState = {
  backgroundColor: "",
  headerBackgroundColor: "",
  enableMobileMenuSmall: "",
  enableBackgroundImage: false,
  enableClosedSidebar: false,
  enableFixedHeader: true,
  enableHeaderShadow: true,
  enableSidebarShadow: true,
  enableFixedFooter: true,
  enableFixedSidebar: true,
  colorScheme: "white",
  backgroundImage: sideBar6,
  backgroundImageOpacity: "opacity-06",
  enablePageTitleIcon: true,
  enablePageTitleSubheading: true,
  enablePageTabsAlt: true,
  user: {},
  modalIsOpen: false,
  editLeadContact: null,
  upline: {},
  pesquisa: {},
  results: [],
  name: "",
  businessDetailPageInfo: {},
  businessDetailPageStates: [],
  businessDetailLoading: false,
  funisData: [
    {
      leadType: "CS",
      title: "Evolução de Leads do Tipo Consultor",
      events: [],
    },
    {
      leadType: "BC",
      title: "Evolução de Leads do Tipo B2C",
      events: [],
    },
    {
      leadType: "BB",
      title: "Evolução de Leads do Tipo B2B",
      events: [],
    },
  ],
  negocioAtualizado: 1,
  statsConsultantDetails: null,
  searchLeadResults: [],
  searchLeadLoading: false,
  searchLeadError: "",
  searchLeadCurrentPage: {
    page: 0,
    pageSize: 5,
    totalPages: 0,
  },
  searchLeadNextPage: {
    nextPage: 1,
    pageSize: 5,
    nextRequest: 1,
  },
  cartNumberItems: 0,
  cartLoadError: null,
  cart: [],
  loadingCart: false,
  cartRemoveError: null,
  addItemCartSuccess: true,
  addItemCartMessage: null,
  userCheckoutDefaultValues: {
    transportType: null,
    paymentType: null
  },
  checkoutData: {
    shippingAddress: null,
    billingAddress: null,
    selectedShipmentType: null,
    selectedPaymentType: null,
    ID_Portes: null,
    observacoes: null,
    ChaveCodigoPromocional: null,
    CodigoVale: null,
    vouchers: null,
    credits: null,
  },
  errorCode: "",
  shouldVerifyOrder: false,
  loadingCheckoutPrices: false,
  allPaises: [],
  loading: false,
  paymentTypes: [],
  selectedPaymentPhoneNumber: null,
  selectedPaymentInstallments: 1,
  shipmentTypes: [],
  billingAddresses: [],
  billingAddress: {},
  deliveryAddresses: [],
  shippingAddress: {},
};

const miniCartReducer = (state = initialState, action) => {
  switch (action.type) {


    case SEARCH_RESULTS_CURRENT_PAGE: {
      return {
        ...state,
        searchLeadCurrentPage: action.payload.searchLeadCurrentPage
      }
    }

    case SEARCH_RESULTS_NEXT_PAGE: {
      return {
        ...state,
        searchLeadNextPage: action.payload.searchLeadNextPage
      }
    }
    case SEARCH_RESULTS: {
      return {
        ...state,
        searchLeadResults: action.payload.searchLeadResults
      }
    }
    case SEARCH_RESULTS_ERROR: {
      return {
        ...state,
        searchLeadError: action.payload.searchLeadError
      }
    }
    case SEARCH_RESULTS_LOAD: {
      return {
        ...state,
        searchLeadLoading: action.payload.searchLeadLoading
      }
    }

    case BUSINESS_DETAIL_PAGE_STATES: {
      return {
        ...state,
        businessDetailPageStates: action.payload.businessDetailPageStates
      }
    }

    case BUSINESS_DETAIL_PAGE_LOADING: {
      return {
        ...state,
        businessDetailLoading: action.payload.businessDetailLoading
      }
    }

    case BUSINESS_DETAIL_PAGE_INFO: {
      return {
        ...state,
        businessDetailPageInfo: action.payload.businessDetailPageInfo
      }
    }

    case EDIT_LEAD_CONTACT: {
      return {
        ...state,
        editLeadContact: action.payload.editLeadContact
      }
    }

    case STATS_CONSULTANT_DETAILS: {
      return {
        ...state,
        statsConsultantDetails: action.payload.statsConsultantDetails
      }
    }

    case ALL_FUNIS: {
      return {
        ...state,
        funisData: action.payload.funisData
      }
    }
    case LEAD_OPEN: {
      return {
        ...state,
        leadOpen: action.payload.leadOpen
      }
    }

    case NEGOCIO_ATUALIZADO: {
      return {
        ...state,
        negocioAtualizado: action.payload.negocioAtualizado
      }
    }

    case POSSIBLE_EVENTS: {
      return {
        ...state,
        events: action.payload.events
      }
    }
    case POSSIBLE_RESULTS: {
      return {
        ...state,
        results: action.payload.results
      }
    }

    case MODAL_OPEN: {
      return {
        ...state,
        modalIsOpen: action.payload.modalIsOpen
      };
    }
    case LOGIN:
      return {
        ...state,
        user: action.payload.user
      };
    case PESQUISA:
      return {
        ...state,
        pesquisa: action.payload.pesquisa
      };
    case GETUPLINE:
      return {
        ...state,
        upline: action.payload.upline
      };
    case SET_ENABLE_BACKGROUND_IMAGE:
      return {
        ...state,
        enableBackgroundImage: action.enableBackgroundImage
      };

    case SET_ENABLE_FIXED_HEADER:
      return {
        ...state,
        enableFixedHeader: action.enableFixedHeader
      };

    case SET_ENABLE_HEADER_SHADOW:
      return {
        ...state,
        enableHeaderShadow: action.enableHeaderShadow
      };

    case SET_ENABLE_SIDEBAR_SHADOW:
      return {
        ...state,
        enableSidebarShadow: action.enableSidebarShadow
      };

    case SET_ENABLE_PAGETITLE_ICON:
      return {
        ...state,
        enablePageTitleIcon: action.enablePageTitleIcon
      };

    case SET_ENABLE_PAGETITLE_SUBHEADING:
      return {
        ...state,
        enablePageTitleSubheading: action.enablePageTitleSubheading
      };

    case SET_ENABLE_PAGE_TABS_ALT:
      return {
        ...state,
        enablePageTabsAlt: action.enablePageTabsAlt
      };

    case SET_ENABLE_FIXED_SIDEBAR:
      return {
        ...state,
        enableFixedSidebar: action.enableFixedSidebar
      };

    case SET_ENABLE_MOBILE_MENU:
      return {
        ...state,
        enableMobileMenu: action.enableMobileMenu
      };

    case SET_ENABLE_MOBILE_MENU_SMALL:
      return {
        ...state,
        enableMobileMenuSmall: action.enableMobileMenuSmall
      };

    case SET_ENABLE_CLOSED_SIDEBAR:
      return {
        ...state,
        enableClosedSidebar: action.enableClosedSidebar
      };

    case SET_ENABLE_FIXED_FOOTER:
      return {
        ...state,
        enableFixedFooter: action.enableFixedFooter
      };

    case SET_BACKGROUND_COLOR:
      return {
        ...state,
        backgroundColor: action.backgroundColor
      };

    case SET_HEADER_BACKGROUND_COLOR:
      return {
        ...state,
        headerBackgroundColor: action.headerBackgroundColor
      };

    case SET_COLOR_SCHEME:
      return {
        ...state,
        colorScheme: action.colorScheme
      };

    case SET_BACKGROUND_IMAGE:
      return {
        ...state,
        backgroundImage: action.backgroundImage
      };

    case SET_BACKGROUND_IMAGE_OPACITY:
      return {
        ...state,
        backgroundImageOpacity: action.backgroundImageOpacity
      };

    case FETCH_CART_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        cart: action.payload.cartSuccess
      };

    case FETCH_CART_FAILURE:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      // This is up to you and your app though: maybe you want to keep the items
      // around! Do whatever seems right.
      return {
        ...state,
        cartLoadError: action.payload.cartLoadError,
      };

    case FETCH_CART_NUMBER_ITEMS:
      return {
        ...state,
        cartNumberItems: action.payload.cartNumberItems
      };

    case LOADINGCART:
      return {
        ...state,
        loadingCart: action.payload.loadingCartState
      };

    case REMOVE_CART_FAILURE:
      return {
        ...state,
        cartRemoveError: action.payload.cartRemoveError
      }

    case ADD_ITEM_CART_SUCCESS:
      return {
        ...state,
        addItemCartSuccess: action.payload.addItemCartSuccess
      }

    case ADD_ITEM_CART_MESSAGE:
      return {
        ...state,
        addItemCartMessage: action.payload.addItemCartMessage
      }

    case USER_CHECKOUT_DEFAULT_VALUES:
      return {
        ...state,
        userCheckoutDefaultValues: action.payload.userCheckoutDefaultValues
      }

    case LOADING_CHECKOUT_PRICES:
      return {
        ...state,
        loadingCheckoutPrices: action.payload.loadingCheckoutPrices
      }

    case SHOULD_VERIFY_ORDER:
      return {
        ...state,
        shouldVerifyOrder: action.payload.shouldVerifyOrder
      }

    case ERROR_CODE_CHECKOUT:
      return {
        ...state,
        errorCode: action.payload.errorCode
      };

    case FETCH_CHECKOUT_DATA:
      return {
        ...state,
        checkoutData: action.payload.checkoutData
      };


    case ALL_COUNTRIES: {
      return {
        ...state,
        allPaises: action.payload.allPaises
      };
    }

    case LOADING:
      return {
        ...state,
        loading: action.payload.loadingState
      };

    case FETCH_PAYMENT_TYPES:
      return {
        ...state,
        paymentTypes: action.payload.paymentTypes
      };

    case SELECT_PAYMENT_PHONE_NUMBER:
      return {
        ...state,
        selectedPaymentPhoneNumber: action.payload.selectedPaymentPhoneNumber
      }

    case SELECT_PAYMENT_INSTALLMENTS:
      return {
        ...state,
        selectedPaymentInstallments: action.payload.selectedPaymentInstallments
      }

    case FETCH_SHIPMENT_TYPES:
      return {
        ...state,
        shipmentTypes: action.payload.shipmentTypes
      };

    case FETCH_BILLING_ADDRESSES:
      return {
        ...state,
        billingAddresses: action.payload.billingAddresses
      };

    case BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: action.payload.billingAddress
      };

    case FETCH_DELIVERY_ADDRESSES:
      return {
        ...state,
        deliveryAddresses: action.payload.deliveryAddresses
      };

      case SHIPPING_ADDRESS:
        return {
          ...state,
          shippingAddress: action.payload.shippingAddress
        };
  

    default:
  }
  return state;
};

export default miniCartReducer;