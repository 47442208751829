// 29-07-2019 - AFONSO - Funcao para obter faturacao mes corrente
export function getDashboardFaturacaoMesCorrente() {
  return new Promise((resolve, reject) => {
    return fetch("/home/getDashboardFaturacaoMesCorrente", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 29-07-2019 - AFONSO - Funcao para obter faturacao acumulada
export function getDashboardFaturacaoAcumulada() {
  return new Promise((resolve, reject) => {
    return fetch("/home/getDashboardFaturacaoAcumulada", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 29-07-2019 - AFONSO - Funcao para obter comparativo comissoes
export function getDashboardComparativoAnual() {
  return new Promise((resolve, reject) => {
    return fetch("/home/getDashboardComparativoAnual", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 01-08-2019 - AFONSO - Funcao para obter calendario de user
export function getCalendar() {
  return new Promise((resolve, reject) => {
    return fetch("/home/getCalendar", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 01-08-2019 - AFONSO - Funcao para obter aniversarios
export function birthdays() {
  return new Promise((resolve, reject) => {
    return fetch("/home/birthdays", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 01-08-2019 - AFONSO - Funcao para obter downlines a expirar
export function expirateDateDownline() {
  return new Promise((resolve, reject) => {
    return fetch("/home/expirateDateDownline", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
