import { handleErrors } from "../helpers/UsefulFunctions";
import { verifyOrder } from "./checkoutActions";

export function sendUserID(id: string) {
    return new Promise((resolve, reject) => {
        return fetch("/sendUserID", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                UserID: id
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                return json.product;
            })
            .catch(error => console.log(error));
    });
}

export function fetchCart() {
    return dispatch => {
        dispatch(loadingCart(true));
        dispatch(fetchCartFailure(null));
        dispatch(removeCartFailure(""));
        return new Promise((resolve, reject) => {

            return fetch("/user/getCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(loadingCart(false));
                    if (json.success === true) {
                        dispatch(fetchCartSuccess(json.obj));
                        dispatch(fetchCartNumber(json.obj.Itens));
                    } else {
                        dispatch(fetchCartFailure(json.message));
                    }

                    resolve(json);
                })
                .catch(error => {
                    // dispatch(fetchCartFailure("Erro ao comunicar com o servidor para obter Carrinho."));
                    resolve({ success: false, message: "Erro ao comunicar com o servidor para obter carrinho." });
                });
        });
    };
}

// Remover pack
export function removePackCart(id: number) {
    return dispatch => {
        return fetch("/user/removePackCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                PackId: id
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCart());
                dispatch(verifyOrder());
                return json.cart;
            })
            .catch(error => {
                return ({
                    success: false,
                    message: "Erro inesperado ao remover o pack do carrinho!"
                })
            });
    };
}

// Remover produto
export function removeProdutoCart(id: number) {
    return dispatch => {
        return fetch("/user/removeItemCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ProductId: id
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCart());
                dispatch(verifyOrder());
                return json.cart;
            })
            .catch(error => {
                return ({
                    success: false,
                    message: "Erro inesperado ao remover o produto do carrinho!"
                })
            });
    };
}

export function updatePackQuantityCart(packId: number, quantity: number) {
    return dispatch => {
        return fetch("/user/updatePackQuantityInCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                PackId: packId,
                Quantity: quantity
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCart());
                dispatch(verifyOrder());
                return json;
            })
            .catch(error => {
                return ({
                    success: false,
                    message: "Erro inesperado ao atualizar quantidade do pack no carrinho!"
                })
            });
    };
}

export function updateProductQuantityCart(productId: number, quantity: number) {
    return dispatch => {
        return fetch("/user/updateProductQuantityInCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ProductId: productId,
                Quantity: quantity
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchCart());
                dispatch(verifyOrder());
                return json;
            })
            .catch(error => {
                return ({
                    success: false,
                    message: "Erro inesperado ao atualizar quantidade do produto no carrinho!"
                })
            });
    };
}

// Remover Todos os produtos do carrinho
export function clearCart() {
    return dispatch => {
        dispatch(loadingCart(true));
        dispatch(removeCartFailure(""));
        return new Promise((resolve, reject) => {
            return fetch("/user/clearCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include"
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(loadingCart(false));
                    if (json.cart.success === false) {
                        dispatch(removeCartFailure(json.cart.message));
                    }

                    dispatch(fetchCart());
                    dispatch(verifyOrder());
                    resolve(json.cart);
                })

                .catch(error => {
                    console.log(error);
                    dispatch(removeCartFailure("Erro ao comunicar com o servidor para remover o carrinho."));
                    resolve({ success: false, message: "Erro ao comunicar com o servidor para remover o carrinho." });
                });
        })
    };
}

// Incrementar produto
export function incrementarProdutoCart(id: number, quantity: number, typeOfUpdate: string) {
    return dispatch => {
        dispatch(addItemCartMessage(""));

        return fetch("/user/addItemCart", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                ID_Produto: id,
                Quantidade: quantity,
                TypeOfUpdate: typeOfUpdate
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(addItemCartSuccess(json.success));
                dispatch(addItemCartMessage(json.message));
                dispatch(fetchCart());
                dispatch(verifyOrder());
                return json;
            })
            .catch(error => {
                dispatch(addItemCartSuccess(false));
                dispatch(addItemCartMessage("Erro inesperado ao adicionar o produto ao carrinho!"));
                return ({
                    success: false,
                    message: "Erro inesperado ao adicionar o produto ao carrinho!"
                })
            });
    };
}

// Incrementar produto
export function incrementarPackCart(id: number, quantity: number) {
    return dispatch => {
        dispatch(addItemCartMessage(""));

        return new Promise((resolve, reject) => {
            return fetch("/user/addPackCart", {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify({
                    ID_Pack: id,
                    Quantidade: quantity
                })
            })
                .then(handleErrors)
                .then(res => res.json())
                .then(json => {
                    dispatch(addItemCartSuccess(json.success));
                    dispatch(addItemCartMessage(json.message));
                    dispatch(fetchCart());
                    dispatch(verifyOrder());

                    return json;
                })
                .catch(error => {
                    dispatch(addItemCartSuccess(false));
                    dispatch(addItemCartMessage("Erro inesperado ao adicionar o pack ao carrinho!"));
    
                    return ({
                        success: false,
                        message: "Erro inesperado ao adicionar o pack ao carrinho!"
                    })
                });
        });
    };
}

export const FETCH_CART_NUMBER_ITEMS = "FETCH_CART_NUMBER_ITEMS";
export const FETCH_CART_SUCCESS = "FETCH_CART_SUCCESS";
export const FETCH_CART_FAILURE = "FETCH_CART_FAILURE";
export const REMOVE_CART_FAILURE = "REMOVE_CART_FAILURE";
export const LOADINGCART = "LOADINGCART";
export const ADD_ITEM_CART_SUCCESS = "ADD_ITEM_CART_SUCCESS";
export const ADD_ITEM_CART_MESSAGE = "ADD_ITEM_CART_MESSAGE";

export const fetchCartNumber = cartNumberItems => ({
    type: FETCH_CART_NUMBER_ITEMS,
    payload: { cartNumberItems }
});

export const fetchCartSuccess = cartSuccess => ({
    type: FETCH_CART_SUCCESS,
    payload: { cartSuccess }
});

export const fetchCartFailure = cartLoadError => ({
    type: FETCH_CART_FAILURE,
    payload: { cartLoadError }
});

export const loadingCart = loadingCartState => ({
    type: LOADINGCART,
    payload: { loadingCartState }
});

export const removeCartFailure = cartRemoveError => ({
    type: REMOVE_CART_FAILURE,
    payload: { cartRemoveError }
})

export const addItemCartSuccess = addItemCartSuccess => ({
    type: ADD_ITEM_CART_SUCCESS,
    payload: { addItemCartSuccess }
})

export const addItemCartMessage = addItemCartMessage => ({
    type: ADD_ITEM_CART_MESSAGE,
    payload: { addItemCartMessage }
})