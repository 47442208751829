import React, { Component } from "react";
import { Slide, toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { insertContactComment } from "../../../../actions/contactosActions";

class Observacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addButtonState: true,
      novaObservacaoData: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  handleChange(e) {
    let value = e.target.value;
    this.setState({ novaObservacaoData: value });
  }

  novaObservacao = async e => {
    // Inserir uma nova observação no contacto
    insertContactComment(
      this.props.contactID,
      this.state.novaObservacaoData
    ).then(data => {
      if (data.message === "Nova observação registada com sucesso") {
        toast(data.message, {
          transition: Slide,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-right",
          type: "success"
        });
        //Vai buscar novamente a lista de observacoes com a nova observacao
        this.props.updateObservacoes(this.props.contactID);
        //Faz desaparecer o form de adicionar nova observacao e faz aparecer a lista de observacoes
        this.props.changeAddButtonState();
        //Limpa o campo de observacao
        this.setState({
          novaObservacaoData: ""
        });
      } else {
        toast(data.message, {
          transition: Slide,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-right",
          type: "error"
        });
      }
    });
  };

  render() {
    return (
      <Row style={{ display: !this.props.addButtonState ? "initial" : "none" }}>
        <Col lg={12}>
          <FormGroup>
            <Label for="examplePassword" className="mr-sm-2">
              Observação
            </Label>
            <Input
              type="textarea"
              name="novaObservacaoData"
              id="novaObservacaoData"
              value={this.state.novaObservacaoData}
              onChange={this.handleChange}
              placeholder="Observação"
            />
          </FormGroup>
          <Row>
            <Col style={{ textAlign: "right" }}>
              <Button
                style={{ display: this.props.addButtonState }}
                className="mb-2 mr-2 btn-pill"
                size="lg"
                color="primary"
                onClick={this.novaObservacao}
              >
                Adicionar Observação
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default Observacao;
