import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Col, Label } from 'reactstrap';
import { changeUser, statsAskToShowConsultantDetails } from "../../../../actions/userActions";

class ConsultantItem extends Component {

    constructor(props) {
        super(props);

        this.showContactDetails = this.showContactDetails.bind(this);
        this.changeCurrentUser = this.changeCurrentUser.bind(this);
    }

    //Fazer um pedido para mostrar os detalhes de contacto
    showContactDetails() {
        this.props.dispatch(statsAskToShowConsultantDetails(this.props.id));
    }

    changeCurrentUser() {
        this.props
            .dispatch(changeUser(this.props.id))
            .then(() => {
                window.location.href = "/";
            });
    }

    render() {
        return (
            <Col md={2} xs={11} lg={2} className="cardStyleStats" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                <span style={{ fontSize: "20px", display: "block", cursor: "pointer" }} onClick={this.changeCurrentUser} >{this.props.nome}</span>
                <span style={{ fontSize: "13px", cursor: "pointer" }} onClick={this.changeCurrentUser}>{this.props.id}</span>
                <Label style={{ fontSize: "13px" }}>Nº Leads Não Tratadas: {this.props.numLeads}</Label>
                <span style={{ cursor: "pointer" }} onClick={() => this.showContactDetails()} >
                    <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                </span>
            </Col >
        )
    }
}

const mapStateToProps = state => ({
    consultantToOpen: state.statsConsultantDetails
});

export default connect(mapStateToProps)(ConsultantItem);