import React, { Component } from "react";
import CountUp from "react-countup";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { getClientsNumbersRede } from "../../../actions/userActions";

class NumeroPromotoresRede extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberClientes: 0
    };
  }

  componentDidMount() {
    getClientsNumbersRede().then(data => {
      if (
        data[0] &&
        data[
          data.findIndex(item => {
            return item.ID_TipoCliente == "PR";
          })
        ] &&
        !data.message
      ) {
        var number =
          data[
            data.findIndex(item => {
              return item.ID_TipoCliente == "PR";
            })
          ].number;
        this.setState({ numberClientes: number });
      }
    });
  }
  render() {
    return (
      <Col md="4">
        <div className="card mb-3 widget-chart">
          <div className="icon-wrapper rounded-circle">
            <div className="icon-wrapper-bg bg-primary" />
            <i className="lnr-users text-primary" />
          </div>
          <div className="widget-numbers">
            <CountUp start={0} end={this.state.numberClientes} duration="3" />
          </div>
          <div className="widget-subheading">Total de promotores da rede</div>
        </div>
      </Col>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(NumeroPromotoresRede);
