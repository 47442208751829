import $ from "jquery";
import React, { Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import Switch from "react-switch";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
import { getCurrencies, getLanguages, getTypeClients } from "../../../actions/pesquisaActions";

const initialState = {
  blocking: false,
  hidden: "initial",
  show: "none",
  pesquisaAvancada: false,
  pesquisa: {},
  idiomas: [],
  moedas: [],
  tipoUser: []
};

var that;
class Campos extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRGPD = this.handleChangeRGPD.bind(this);
    this.handleChangeParticular = this.handleChangeParticular.bind(this);
    that = this;
  }

  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(prevState => ({
      pesquisa: {
        ...prevState.pesquisa,
        [name]: value
      }
    }));
  }

  handleChangeRGPD(e) {
    this.setState(prevState => ({
      pesquisa: {
        ...prevState.pesquisa,
        RGPD: e
      }
    }));
  }

  handleChangeParticular(e) {
    this.setState(prevState => ({
      pesquisa: {
        ...prevState.pesquisa,
        Particular: e
      }
    }));
  }

  limparPesquisa() {
    this.state = initialState;
    this.setState(initialState);
    $("#ID_Cliente").val("");
    $("#Nome").val("");
    $("#Email").val("");
    $("#NIF").val("");
    $("#Concelho").val("");
    $("#Naturalidade").val("");
    $("#Distrito").val("");
    $("#Empresa").val("");
    $("#Cargo").val("");
    $("#ID_ClienteRecomendacao").val("");
    $("#NomeRecomendacao").val("");
    $("#ID_Encomenda").val("");
    $("#ID_Produto").val("");
    $("#DescricaoProduto").val("");
    $("#EmailRecomendacao").val("");
    $("#Preferencias").val("");
    $("#Sonhos").val("");
    getLanguages().then(data => {
      if (data[0]) {
        this.setState({ idiomas: data });
      }
    });
    getCurrencies().then(data => {
      if (data[0]) {
        this.setState({ moedas: data });
      }
    });
    getTypeClients().then(data => {
      if (data[0]) {
        this.setState({ tipoUser: data });
      }
    });
  }

  componentDidMount() {
    getLanguages().then(data => {
      if (data[0]) {
        this.setState({ idiomas: data });
      }
    });
    getCurrencies().then(data => {
      if (data[0]) {
        this.setState({ moedas: data });
      }
    });
    getTypeClients().then(data => {
      if (data[0]) {
        this.setState({ tipoUser: data });
      }
    });
  }

  handleKeyPress(target) {
    if (target.charCode == 13) {
      that.props.pesquisarFuncao(that.state.pesquisa);
    }
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div />
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">
                      {" "}
                    </i>
                    Campos pesquisa
                  </div>
                  <div className="btn-actions-pane-right">
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="danger"
                      onClick={() => {
                        this.setState({ hidden: "none", show: "initial" });
                      }}
                      style={{ display: this.state.hidden }}
                    >
                      <i
                        className="lnr-circle-minus"
                        style={{ display: this.state.hidden }}
                        onClick={() => {
                          this.setState({
                            hidden: "none",
                            show: "initial"
                          });
                        }}
                      />
                    </Button>
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="success"
                      onClick={() => {
                        this.setState({ hidden: "initial", show: "none" });
                      }}
                      style={{ display: this.state.show }}
                    >
                      <i
                        className="lnr-plus-circle"
                        style={{ display: this.state.show }}
                        onClick={() => {
                          this.setState({
                            hidden: "initial",
                            show: "none"
                          });
                        }}
                      />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody style={{ display: this.state.hidden }}>
                  <span>
                    <Row>
                      <Col xs="12" sm="12" md="4" lg="4">
                        <FormGroup>
                          <Input
                            type="number"
                            name="ID_Cliente"
                            id="ID_Cliente"
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleChange}
                            placeholder="ID Cliente"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="4" lg="4">
                        <FormGroup>
                          <Input
                            type="text"
                            name="Nome"
                            id="Nome"
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleChange}
                            placeholder="Nome Cliente"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="4" lg="4">
                        <FormGroup>
                          <Input
                            type="email"
                            name="Email"
                            id="Email"
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleChange}
                            placeholder="Email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </span>
                  <span
                    style={{
                      display: this.state.pesquisaAvancada ? "initial" : "none"
                    }}
                  >
                    <Row>
                      <Col xs="12" sm="12" md="3" lg="3">
                        <FormGroup>
                          <Input
                            type="text"
                            name="NIF"
                            id="NIF"
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleChange}
                            placeholder="NIF"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="3" lg="3">
                        <FormGroup>
                          <Input
                            type="text"
                            name="Concelho"
                            id="Concelho"
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleChange}
                            placeholder="Concelho"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="3" lg="3">
                        <FormGroup>
                          <Input
                            type="text"
                            name="Naturalidade"
                            id="Naturalidade"
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleChange}
                            placeholder="Naturalidade"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="3" lg="3">
                        <FormGroup>
                          <Input
                            type="text"
                            name="Distrito"
                            id="Distrito"
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleChange}
                            placeholder="Distrito"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="12" md="4" lg="4">
                        <FormGroup>
                          <Input
                            type="text"
                            name="Empresa"
                            id="Empresa"
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleChange}
                            placeholder="Empresa"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="3" lg="3">
                        <FormGroup>
                          <Input
                            type="text"
                            name="Cargo"
                            id="Cargo"
                            onKeyPress={this.handleKeyPress}
                            onChange={this.handleChange}
                            placeholder="Cargo"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="2" lg="2">
                        <FormGroup>
                          <Input
                            type="text"
                            onKeyPress={this.handleKeyPress}
                            name="ID_ClienteRecomendacao"
                            id="ID_ClienteRecomendacao"
                            onChange={this.handleChange}
                            placeholder="ID Cliente Recomendação"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="3" lg="3">
                        <FormGroup>
                          <Input
                            type="text"
                            onKeyPress={this.handleKeyPress}
                            name="NomeRecomendacao"
                            id="NomeRecomendacao"
                            onChange={this.handleChange}
                            placeholder="Nome Recomendação"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="12" md="3" lg="3">
                        <FormGroup>
                          <Input
                            type="text"
                            onKeyPress={this.handleKeyPress}
                            name="ID_Encomenda"
                            id="ID_Encomenda"
                            onChange={this.handleChange}
                            placeholder="ID Encomenda"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="3" lg="3">
                        <FormGroup>
                          <Input
                            type="text"
                            onKeyPress={this.handleKeyPress}
                            name="ID_Produto"
                            id="ID_Produto"
                            onChange={this.handleChange}
                            placeholder="ID Produto"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="6" lg="6">
                        <FormGroup>
                          <Input
                            type="text"
                            onKeyPress={this.handleKeyPress}
                            name="DescricaoProduto"
                            id="DescricaoProduto"
                            onChange={this.handleChange}
                            placeholder="Descrição Produto"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="12" md="4" lg="4">
                        <FormGroup>
                          <Input
                            type="text"
                            onKeyPress={this.handleKeyPress}
                            name="EmailRecomendacao"
                            id="EmailRecomendacao"
                            onChange={this.handleChange}
                            placeholder="Email de Recomendação"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="4" lg="4">
                        <FormGroup>
                          <Input
                            type="text"
                            onKeyPress={this.handleKeyPress}
                            name="Preferencias"
                            id="Preferencias"
                            onChange={this.handleChange}
                            placeholder="Preferências"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="4" lg="4">
                        <FormGroup>
                          <Input
                            type="text"
                            name="Sonhos"
                            onKeyPress={this.handleKeyPress}
                            id="Sonhos"
                            onChange={this.handleChange}
                            placeholder="Sonhos"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        xs="12"
                        sm="12"
                        md="4"
                        lg="3"
                        style={{ textAlign: "center" }}
                      >
                        <FormGroup
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <span>RGPD</span>&nbsp;
                          <Switch
                            placeholder="Preferências"
                            name="RGPD"
                            id="RGPD"
                            checked={this.state.pesquisa.RGPD}
                            onChange={this.handleChangeRGPD}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span>Particular</span>&nbsp;
                          <Switch
                            placeholder="Particular"
                            name="Particular"
                            id="Particular"
                            checked={this.state.pesquisa.Particular}
                            onChange={this.handleChangeParticular}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="3" lg="3">
                        <FormGroup>
                          <Input
                            type="select"
                            name="ID_Lingua"
                            id="ID_Lingua"
                            onChange={this.handleChange}
                          >
                            <option>Idioma</option>
                            {this.state.idiomas
                              ? this.state.idiomas.map(idioma => {
                                return (
                                  <option value={idioma.ID_Lingua}>
                                    {idioma.Descricao}
                                  </option>
                                );
                              })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="2" lg="3">
                        <FormGroup>
                          <Input
                            type="select"
                            name="ID_Moeda"
                            id="ID_Moeda"
                            onChange={this.handleChange}
                          >
                            <option>Moeda</option>
                            {this.state.moedas
                              ? this.state.moedas.map(moeda => {
                                return (
                                  <option value={moeda.ID_Moeda}>
                                    {moeda.Descricao}
                                  </option>
                                );
                              })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="12" md="3" lg="3">
                        <FormGroup>
                          <Input
                            type="select"
                            name="TiposCliente"
                            id="TiposCliente"
                            onChange={this.handleChange}
                          >
                            <option>Tipo Cliente</option>
                            {this.state.tipoUser
                              ? this.state.tipoUser.map(user => {
                                return (
                                  <option value={user.ID_TipoCliente}>
                                    {user.Descricao}
                                  </option>
                                );
                              })
                              : null}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </span>
                </CardBody>
                <CardFooter className="d-block">
                  <Row>
                    <Col
                      lg="4"
                      className="text-left"
                      style={{ marginBottom: "15px" }}
                    >
                      <Button
                        onClick={() => {
                          document.getElementById("ID_Cliente").value = "";
                          this.limparPesquisa();
                        }}
                        size="lg"
                        color="danger"
                      >
                        Limpar Pesquisa
                      </Button>
                    </Col>
                    <Col lg="8" className="text-right">
                      <Button
                        onClick={() => {
                          this.setState({
                            pesquisaAvancada: !this.state.pesquisaAvancada
                          });
                        }}
                        size="lg"
                        color="success"
                      >
                        Pesquisa avançada
                      </Button>{" "}
                      <Button
                        onClick={() => {
                          this.props.pesquisarFuncao(this.state.pesquisa);
                        }}
                        size="lg"
                        color="primary"
                      >
                        Pesquisar
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(Campos);
