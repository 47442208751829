import { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "reactstrap";
import CacheBuster from "../CacheBuster";
// Caminhos para as páginas
import { instanceOf } from "prop-types";
import { Cookies, withCookies } from "react-cookie";
import { v4 as uuidv4 } from 'uuid';
import { sendUserID } from "../actions/cartActions";
import Routes from "../helpers/Routes";
import AfiliadosView from "./views/Afiliados/Afiliados";
import Cart from "./views/Cart/Cart";
import ClientesView from "./views/Clientes/Clientes";
import ComissoesView from "./views/Comissoes/Comissoes";
import ComparativosView from "./views/Comparativos/Comparativos";
import ConsultoresView from "./views/Consultores/Consultores";
import ContactosView from "./views/Contactos/Contactos";
import DetalheComissaoView from "./views/DetalheComissao/DetalheComissao";
import DetalheContactoView from "./views/DetalheContacto/DetalheContacto";
import DetalheEncomendaView from "./views/DetalheEncomenda/DetalheEncomenda";
import DetalheNegocioView from "./views/DetalheNegocio/DetalheNegocio";
import EmpresasView from "./views/Empresas/Empresas";
import EncomendasView from "./views/Encomendas/Encomendas";
import HomeView from "./views/Home/Home";
import IndicadoresView from "./views/Indicadores/Indicadores";
import AnaliseLeadsView from "./views/Leads/AnaliseLeadsView";
import LeadsPerdidasPorConsultor from "./views/Leads/LeadsPerdidasPorConsultorView";
import LeadsView from "./views/Leads/LeadsView";
import NovaLeadView from "./views/Leads/NovaLeadView";
import PesquisaLeadView from "./views/Leads/PesquisaLeadView";
import LoginView from "./views/Login/Login";
import MarketingContactosView from "./views/Marketing/ContactosView";
import MarketingLeadsView from "./views/Marketing/LeadsView";
import NegociosView from "./views/Negocios/Negocios";
import OportunidadesView from "./views/Oportunidades/Oportunidades";
import PerfilView from "./views/Perfil/Perfil";
import PesquisaView from "./views/Pesquisa/Pesquisa";
import PosicaoConsultoresView from "./views/PosicaoConsultores/PosicaoConsultores";
import PromotoresView from "./views/Promotores/Promotores";
import RedeView from "./views/Rede/Rede";
import RedirectView from "./views/Redirect/Redirect";
import Payment from "./views/Payment/Payment";

const PrivateRoute = ({ component: Component, loggedIn, userIds, userType, userTypes, ...rest }) => {
  var hasPermission = loggedIn;


  //Se não tiver permissão pelo userId e se não tiver permissão pelo userType não dá acesso
  //Ou seja, basta o userId ou o userType ter permissão para dar acesso
  if (userIds !== 'undefined' && userIds && userIds.length > 0 && !userIds.includes(loggedIn) &&
    userTypes !== 'undefined' && userTypes && userTypes.length > 0 && !userTypes.includes(userType)) {
    hasPermission = false;
  }

  return (
    <Route
      {...rest}
      render={props =>
        !hasPermission ? <Redirect to={Routes.user.login} />
          :
          <Component {...props} />
      }
    />
  )
};

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = this.props;
    this.state = {
      timestamp: "Loading"
    };

    // Caso exista userID envia para o servidor, senão cria e envia na mesma
    if (!cookies.get("userID")) {
      var userID = uuidv4()
      cookies.set("userID", userID, {
        domain: process.env.REACT_APP_HTTP_DOMAIN,
        path: "/",
        maxAge: 259200 //userID demora 3 dias a expirar
      });
      sendUserID(userID).then(data => { });
    } else {
      sendUserID(cookies.get("userID")).then(data => { });
    }
  }

  render() {
    const App = () => (
      <div>

        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            return (
              <div>
                {(!loading && !isLatestVersion ?
                  (
                    <Alert color="warning" style={{ marginTop: "70px", zIndex: "1000", textAlign: "center", marginBottom: "10px", position: "fixed", width: "100%" }} >
                      <label style={{ margin: 0, fontSize: "14px" }}>Detetámos que está a usar uma versão antiga do Website
                        carregue<a onClick={refreshCacheAndReload} className="alert-link"> aqui para atualizar para a última versão</a>
                      </label>
                    </Alert>
                  )
                  : null)}
              </div>
            );
          }}
        </CacheBuster>

        <ToastContainer
          bodyClassName="myToast"
          position="bottom-right"
          autoClose={5000}
          pauseOnFocusLoss={false}
          pauseOnHover={true}
          hideProgressBar={true}
        />

        <Switch>
          <Route exact path={Routes.user.login} component={LoginView} />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.index.index}
            component={HomeView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.user.perfil}
            component={PerfilView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.negocios.index}
            component={NegociosView}
            userTypes={['CS', 'CT']}
            userType={this.props.userType}
            userIds={[1]}
          />
          <Route
            exact
            path={Routes.negocios.detalhe}
            render={props => <DetalheNegocioView {...props.match.params} />}
          />
          <Route
            exact
            path={Routes.encomendas.detalhe}
            render={props => <DetalheEncomendaView {...props.match.params} />}
          />
          <Route
            exact
            path={Routes.contactos.detalhe}
            render={props => <DetalheContactoView {...props.match.params} />}
          />
          <Route
            exact
            path={Routes.comissoes.detalhe}
            render={props => <DetalheComissaoView {...props.match.params} />}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.oportunidades.index}
            component={OportunidadesView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.encomendas.index}
            component={EncomendasView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.comissoes.index}
            component={ComissoesView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.rede.estrutura}
            component={RedeView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.rede.clientes}
            component={ClientesView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.contactos.contactos}
            component={ContactosView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.contactos.empresas}
            component={EmpresasView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.rede.consultores}
            component={ConsultoresView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.rede.afiliados}
            component={AfiliadosView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.rede.promotores}
            component={PromotoresView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.pesquisa.index}
            component={PesquisaView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.indicadores.index}
            component={IndicadoresView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.comparativos.index}
            component={ComparativosView}
            userTypes={['CS', 'CT']}
            userType={this.props.userType}
            userIds={[1]}
          />

          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.posicaoConsultores.index}
            component={PosicaoConsultoresView}
            userTypes={['CS', 'CT']}
            userType={this.props.userType}
            userIds={[1]}
          />

          <Route
            exact
            path={Routes.prospect.index}
            render={props => <RedirectView {...props.match.params} />}
          />
          <Route
            exact
            path={Routes.cliente.index}
            render={props => <RedirectView {...props.match.params} />}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.leads.listaLeads}
            component={LeadsView}
            userTypes={['CS', 'CT']}
            userType={this.props.userType}
            userIds={[1]}
          />

          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.leads.pesquisa}
            component={PesquisaLeadView}
            userTypes={['CS', 'CT']}
            userType={this.props.userType}
            userIds={[1]}
          />

          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.marketing.listaLeads}
            component={MarketingLeadsView}
            userTypes={['CS', 'CT']}
            userType={this.props.userType}
            userIds={[1]}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.marketing.contactos}
            component={MarketingContactosView}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.leads.novaLead}
            component={NovaLeadView}
            userTypes={['CS', 'CT']}
            userType={this.props.userType}
            userIds={[1]}
          />
          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.leads.analise}
            component={AnaliseLeadsView}
            userTypes={['CS', 'CT']}
            userType={this.props.userType}
            userIds={[1]}
          />

          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.cart.index}
            component={Cart}
          />

          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.leads.leadsPerdidasPorConsultor}
            component={LeadsPerdidasPorConsultor}
            userTypes={['CS', 'CT']}
            userType={this.props.userType}
            userIds={[1]}
          />

          <PrivateRoute
            loggedIn={this.props.userID}
            exact
            path={Routes.payment.index}
            component={Payment}
          />
        </Switch>
      </div>
    );
    return (
      <Switch>
        <App />
      </Switch>
    );
  }
}

export default withCookies(App);