import React, { Component } from "react";
import { FormattedDate } from "react-intl";
import { getProtocols } from "../../../actions/userActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class ProtocolosCliente extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0
    };
  }

  componentDidMount() {
    getProtocols().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "Descrição",
            accessor: "Descricao",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.Descricao
                ? row.original.Descricao
                : "-";
            }
          },
          {
            Header: "Data Inicio Validade",
            accessor: "DataInicioValidade",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.DataInicioValidade ? (
                <FormattedDate value={new Date(row.original.DataInicioValidade)} />
              ) : (
                "-"
              );
            }
          },
          {
            Header: "Data Fim Validade",
            accessor: "DataFimValidade",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.DataFimValidade ? (
                <FormattedDate value={new Date(row.original.DataFimValidade)} />
              ) : (
                "-"
              );
            }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }
  render() {
    return (
      <SimpleTable
        data={this.state.data}
        columns={this.state.columns}
        blocking={this.state.blocking}
        rowNumber={this.state.rowNumber}
        dataSize={this.state.dataSize}
        noDataText={"Sem protocolos para apresentar"}
        title={"Protocolos"}
      />
    );
  }
}

export default ProtocolosCliente;
