import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import ProdutosComprados from "./Components/ProdutosComprados";
import MediaCenter from "./Components/MediaCenter";
import PacksVisualizados from "./Components/PacksVisualizados";
import ProdutosEncomendados from "./Components/ProdutosEncomendados";
import ProdutosVisualizados from "./Components/ProdutosVisualizados";

class Oportunidades extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Oportunidades"
            subheading="Página onde pode ver as suas oportunidades de negócio"
            icon="lnr-rocket icon-gradient bg-ripe-malin"
          />
          <ProdutosVisualizados />
          <PacksVisualizados />
          <ProdutosEncomendados />
          <MediaCenter />
          <ProdutosComprados />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Oportunidades;
