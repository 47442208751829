import React, { useEffect } from 'react'
import { Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/lib/Input';
import "./SearchInputComponent.css";

type SearchInputComponentProps = {
    initialValue: string | undefined;
    value?: string | undefined;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    labelBackgroundColor?: string;
    inputTextColor?: string;
    inputType: InputType;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    min?: number;
    placeholder?: string;
    labelClassName?: string;
    inputClassName?: string;
}
    & ({

        showLabel?: true | undefined;
        labelText: string;
    } | {
        showLabel: false
        labelText?: string
    })


export default function SearchInputComponent(props: SearchInputComponentProps) {
    var labelClassName = typeof props.labelClassName === 'undefined' || props.labelClassName === null || props.labelClassName === "" ? "searchComponent_labelStyle" : props.labelClassName;
    var inputClassName = typeof props.inputClassName === 'undefined' || props.inputClassName === null || props.inputClassName === "" ? "searchComponent_inputStyle" : props.inputClassName;

    return (
        <div>
            {(typeof props.showLabel === 'undefined' || props.showLabel == true) &&
                (
                    <Label className={labelClassName}
                        style={{ backgroundColor: props.labelBackgroundColor }}>
                        {props.labelText}
                    </Label>
                )}

            <Input placeholder={props.placeholder} min={props.min} value={props.value} onBlur={props.onBlur} type={props.inputType}
                className={inputClassName}
                style={{ color: props.inputTextColor }}
                onChange={props.onChange} defaultValue={props.initialValue} />
        </div>
    )
}
