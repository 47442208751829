import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BlockUi from "react-block-ui";
import { FormattedNumber } from "react-intl";
import { Loader } from "react-loaders";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { commissionsResume, last12MonthGraph } from "../../../actions/comissoesActions";

var that;
class ResumoComissoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: true,
      hidden: "initial",
      show: "none",
      data: [],
      comissoesGraph: []
    };
    that = this;
  }

  getData() {
    commissionsResume().then(data => {
      if (data && !data.message) {
        this.setState({
          data: data
        });
      }
    });
    last12MonthGraph().then(data => {
      if (data.data && !data.message) {
        this.setState({
          comissoesGraph: data.data
        });
      }
      this.setState({ blocking: false });
    });
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div />
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">
                      {" "}
                    </i>
                    Resumo das comissões
                  </div>
                  <div className="btn-actions-pane-right">
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="danger"
                      onClick={() => {
                        this.setState({ hidden: "none", show: "initial" });
                      }}
                      style={{ display: this.state.hidden }}
                    >
                      <i
                        className="lnr-circle-minus"
                        style={{ display: this.state.hidden }}
                        onClick={() => {
                          this.setState({
                            hidden: "none",
                            show: "initial"
                          });
                        }}
                      />
                    </Button>
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="success"
                      onClick={() => {
                        this.setState({ hidden: "initial", show: "none" });
                      }}
                      style={{ display: this.state.show }}
                    >
                      <i
                        className="lnr-plus-circle"
                        style={{ display: this.state.show }}
                        onClick={() => {
                          this.setState({
                            hidden: "initial",
                            show: "none"
                          });
                        }}
                      />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody style={{ display: this.state.hidden }}>
                  <BlockUi
                    tag="div"
                    blocking={this.state.blocking}
                    className="block-overlay-dark"
                    loader={
                      <Loader
                        color="#ffffff"
                        active
                        type="ball-spin-fade-loader"
                      />
                    }
                  >
                    {!this.state.blocking ? (
                      <span>
                        <Row>
                          <Col xs="12" sm="12" md="6" lg="6">
                            <Card className="main-card mb-3">
                              <CardBody>
                                <CardTitle>
                                  Comissões ultimos 12 meses
                                </CardTitle>
                                <div className="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
                                  <ResponsiveContainer
                                    width="100%"
                                    height={240}
                                  >
                                    {this.state.comissoesGraph ? (
                                      this.state.comissoesGraph[0] ? (
                                        <AreaChart
                                          data={this.state.comissoesGraph}
                                          margin={{
                                            top: 0,
                                            right: 10,
                                            left: 10,
                                            bottom: 0
                                          }}
                                        >
                                          <defs>
                                            <linearGradient
                                              id="colorPv"
                                              x1="0"
                                              y1="0"
                                              x2="0"
                                              y2="1"
                                            >
                                              <stop
                                                offset="5%"
                                                stopColor="var(--success)"
                                                stopOpacity={0.8}
                                              />
                                              <stop
                                                offset="95%"
                                                stopColor="var(--success)"
                                                stopOpacity={0}
                                              />
                                            </linearGradient>
                                          </defs>
                                          <XAxis dataKey="Mes" />
                                          <YAxis dataKey="ValorComissao" />
                                          <CartesianGrid strokeDasharray="3 3" />
                                          <Tooltip />
                                          <Area
                                            type="monotone"
                                            dataKey="ValorComissao"
                                            stroke="var(--success)"
                                            fillOpacity={1}
                                            fill="url(#colorPv)"
                                          />
                                        </AreaChart>
                                      ) : (
                                          <span>
                                            Não existem dados disponiveis
                                          </span>
                                        )
                                    ) : (
                                        <span>Não existem dados disponiveis</span>
                                      )}
                                  </ResponsiveContainer>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col xs="12" sm="12" md="6" lg="6">
                            <Row>
                              <Col xs="12" sm="12" md="6" lg="12">
                                <p
                                  style={{
                                    fontSize: "18px",
                                    textAlign: "center",
                                    backgroundColor: "#e0f3ff",
                                    color: "#495057"
                                  }}
                                >
                                  (Ganhas - Pagas = Receber)
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    textAlign: "center"
                                  }}
                                >
                                  {this.state.data.Ganhas ? (
                                    <FormattedNumber
                                      value={this.state.data.Ganhas}
                                      style="currency"
                                      currency="EUR"
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                    />
                                  ) : (
                                      "0.00"
                                    )}{" "}
                                  -{" "}
                                  {this.state.data.Pagas ? (
                                    <FormattedNumber
                                      value={this.state.data.Pagas}
                                      style="currency"
                                      currency="EUR"
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                    />
                                  ) : (
                                      "0.00"
                                    )}{" "}
                                  ={" "}
                                  {this.state.data.Receber ? (
                                    <FormattedNumber
                                      value={this.state.data.Receber}
                                      style="currency"
                                      currency="EUR"
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                    />
                                  ) : (
                                      "0.00"
                                    )}
                                </p>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs="12" sm="12" md="6" lg="6">
                                <p
                                  style={{
                                    fontSize: "18px",
                                    textAlign: "center",
                                    backgroundColor: "#e0f3ff",
                                    color: "#495057"
                                  }}
                                >
                                  Disponivel para pagamento
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    textAlign: "center"
                                  }}
                                >
                                  {this.state.data.DisponivelPagamento ? (
                                    <FormattedNumber
                                      value={
                                        this.state.data.DisponivelPagamento
                                      }
                                      style="currency"
                                      currency="EUR"
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                    />
                                  ) : (
                                      "0.00"
                                    )}
                                </p>
                              </Col>
                              <Col xs="12" sm="12" md="6" lg="6">
                                <p
                                  style={{
                                    fontSize: "18px",
                                    textAlign: "center",
                                    backgroundColor: "#e0f3ff",
                                    color: "#495057"
                                  }}
                                >
                                  Créditos
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    textAlign: "center"
                                  }}
                                >
                                  {this.state.data.Creditos ? (
                                    <FormattedNumber
                                      value={this.state.data.Creditos}
                                      style="currency"
                                      currency="EUR"
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                    />
                                  ) : (
                                      "0.00"
                                    )}
                                </p>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs="12" sm="12" md="4" lg="4">
                                <p
                                  style={{
                                    fontSize: "18px",
                                    textAlign: "center",
                                    backgroundColor: "#e0f3ff",
                                    color: "#495057"
                                  }}
                                >
                                  NetPoints Clientes
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    textAlign: "center"
                                  }}
                                >
                                  {this.state.data.NetPointsCliente
                                    ? this.state.data.NetPointsCliente
                                    : "0.00"}
                                </p>
                              </Col>
                              <Col xs="12" sm="12" md="4" lg="4">
                                <p
                                  style={{
                                    fontSize: "18px",
                                    textAlign: "center",
                                    backgroundColor: "#e0f3ff",
                                    color: "#495057"
                                  }}
                                >
                                  NetPoints Pessoal
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    textAlign: "center"
                                  }}
                                >
                                  {this.state.data.NetPointsPessoal
                                    ? this.state.data.NetPointsPessoal
                                    : "0.00"}
                                </p>
                              </Col>
                              <Col xs="12" sm="12" md="4" lg="4">
                                <p
                                  style={{
                                    fontSize: "18px",
                                    textAlign: "center",
                                    backgroundColor: "#e0f3ff",
                                    color: "#495057"
                                  }}
                                >
                                  NetPoints Rede
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    textAlign: "center"
                                  }}
                                >
                                  {this.state.data.NetPointsRede
                                    ? this.state.data.NetPointsRede
                                    : "0.00"}
                                </p>
                              </Col>
                            </Row>
                            <br />
                            <Row>
                              <Col xs="12" sm="12" md="4" lg="4">
                                <p
                                  style={{
                                    fontSize: "18px",
                                    textAlign: "center",
                                    backgroundColor: "#e0f3ff",
                                    color: "#495057"
                                  }}
                                >
                                  Volume Mês
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    textAlign: "center"
                                  }}
                                >
                                  {this.state.data.VolumeMes ? (
                                    <FormattedNumber
                                      value={this.state.data.VolumeMes}
                                      style="currency"
                                      currency="EUR"
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                    />
                                  ) : (
                                      "0.00"
                                    )}
                                </p>
                              </Col>
                              <Col xs="6" sm="6" md="4" lg="4">
                                <p
                                  style={{
                                    fontSize: "18px",
                                    textAlign: "center",
                                    backgroundColor: "#e0f3ff",
                                    color: "#495057"
                                  }}
                                >
                                  Volume Trimestre
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    textAlign: "center"
                                  }}
                                >
                                  {this.state.data.VolumeTrimestre ? (
                                    <FormattedNumber
                                      value={this.state.data.VolumeTrimestre}
                                      style="currency"
                                      currency="EUR"
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                    />
                                  ) : (
                                      "0.00"
                                    )}
                                </p>
                              </Col>
                              <Col xs="6" sm="6" md="4" lg="4">
                                <p
                                  style={{
                                    fontSize: "18px",
                                    textAlign: "center",
                                    backgroundColor: "#e0f3ff",
                                    color: "#495057"
                                  }}
                                >
                                  Volume Ano
                                </p>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    textAlign: "center"
                                  }}
                                >
                                  {this.state.data.VolumeAno ? (
                                    <FormattedNumber
                                      value={this.state.data.VolumeAno}
                                      style="currency"
                                      currency="EUR"
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                    />
                                  ) : (
                                      "0.00"
                                    )}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </span>
                    ) : (
                        <div style={{ textAlign: "center" }}>
                          {!this.state.blocking
                            ? "Não existem informações sobre as comissões"
                            : null}
                        </div>
                      )}
                  </BlockUi>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default ResumoComissoes;
