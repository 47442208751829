import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, UncontrolledTooltip } from "reactstrap";
import { allCommissions } from "../../../actions/comissoesActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class ComissoesCalculadas extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0
    };
  }

  componentDidMount() {
    allCommissions().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "Ano - Mês",
            accessor: "Data",
            resizable: false,
            Cell: row => {
              return <span>{row.original.Ano + " - " + row.original.Mes}</span>;
            },
            style: { display: "grid", textAlign: "center" }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-npc3">NPC</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-npc3"}
                  >
                    NetPoints Cliente
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NetPointsCliente",
            resizable: false,
            style: { display: "grid", textAlign: "center" }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-NPP3">NPP</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-NPP3"}
                  >
                    NetPoints Pessoal
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NetPointsPessoal",
            resizable: false,
            style: { display: "grid", textAlign: "center" }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-NPR3">NPR</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-NPR3"}
                  >
                    NetPoints Rede
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NetPointsRede",
            resizable: false,
            style: { display: "grid", textAlign: "center" }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-CRD3">CRD</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-CRD3"}
                  >
                    Créditos
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "Creditos",
            resizable: false,
            style: { display: "grid", textAlign: "center" }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VCM3">VCM</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VCM3"}
                  >
                    Valor Comissão
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "ValorComissao",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.ValorComissao}
                  style="currency"
                  currency={row.original.ID_Moeda}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-NEN3">NEN</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-NEN3"}
                  >
                    Nr. Encomendas
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NrEncomendasSemTalentos",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                row.original.NrEncomendasPessoal + row.original.NrEncomendasClientes
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VEN3">VEN</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VEN3"}
                  >
                    Valor Encomendas
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "ValorEncomendasSemTalentos",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.ValorEncomendasPessoal + row.original.ValorEncomendasClientes}
                  style="currency"
                  currency={row.original.ID_Moeda}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-NPT3">NPT</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-NPT3"}
                  >
                    NetPoints Total
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NetPointsTotal",
            resizable: false,
            style: { display: "grid", textAlign: "center" }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-TBG3">TBG</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-TBG3"}
                  >
                    Taxa Bonús Grupo
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "TaxaBonusGroup",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return row.original.TaxaBonusGroup ? (
                <FormattedNumber
                  value={row.original.TaxaBonusGroup / 100}
                  style="percent"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              ) : (
                "0.00%"
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VBG3">VBG</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VBG3"}
                  >
                    Valor Bonús Grupo
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "ValorBonusGroup",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.ValorBonusGroup}
                  style="currency"
                  currency={row.original.ID_Moeda}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-NRT3">NRT</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-NRT3"}
                  >
                    Nr. Redes Top
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NrRedesTop",
            resizable: false,
            style: { display: "grid", textAlign: "center" }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-TBL3">TBL</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-TBL3"}
                  >
                    Taxa Bonús Lider
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "TaxaBonusLider",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return row.original.TaxaBonusLider ? (
                <FormattedNumber
                  value={row.original.TaxaBonusLider / 100}
                  style="percent"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              ) : (
                "0.00%"
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VBLD3">VBLD</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VBLD3"}
                  >
                    Valor Bonús Lider Dream
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "ValorBonusLiderDream",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.ValorBonusLiderDream}
                  style="currency"
                  currency={row.original.ID_Moeda}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VBL3">VBL</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VBL3"}
                  >
                    Valor Bonús Lider
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "ValorBonusLider",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.ValorBonusLider}
                  style="currency"
                  currency={row.original.ID_Moeda}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-NLB3">NLB</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-NLB3"}
                  >
                    Nr. Leader Builder
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NrLeaderBuilder",
            resizable: false,
            style: { display: "grid", textAlign: "center" }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VLB3">VLB</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VLB3"}
                  >
                    Valor Leader Builder
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "ValorLeaderBuilder",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.ValorLeaderBuilder}
                  style="currency"
                  currency={row.original.ID_Moeda}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VTL3">VTL</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VTL3"}
                  >
                    Valor Total
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "ValorTotal",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.ValorTotal}
                  style="currency"
                  currency={row.original.ID_Moeda}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: "Ações",
            accessor: "EstadoNegocio",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <div>
                  <div className="d-block w-100 text-center">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon btn-icon-only btn btn-link"
                        color="link"
                      >
                        <i className="lnr-menu-circle btn-icon-wrapper" />
                      </DropdownToggle>
                      <DropdownMenu
                        right
                        className="rm-pointers dropdown-menu-hover-link"
                      >
                        <DropdownItem
                          onClick={() => {
                            window.location.href =
                              "/comissoes/" +
                              row.original.Ano +
                              "/" +
                              row.original.Mes;
                          }}
                        >
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Ver Detalhe</span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                </div>
              );
            }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }
  render() {
    return (
      <SimpleTable
        data={this.state.data}
        columns={this.state.columns}
        blocking={this.state.blocking}
        rowNumber={this.state.rowNumber}
        dataSize={this.state.dataSize}
        noDataText={"Não tem comissões disponiveis"}
        title={"Comissões dos últimos 12 meses"}
      />
    );
  }
}

export default ComissoesCalculadas;
