import React, { Component } from 'react';
import { Col, Label, Row } from 'reactstrap';

const tempoStyle = {
    margin: "0px",
    padding: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    flexDirection: "column"
};

export default class TableStatsColumnCell extends Component {

    //Função para calcular o tempo de forma "humana"
    calculateTimeInHumanLanguage(timeInSeconds) {

        //Maior que 1 dia 
        if (timeInSeconds >= 86400) {
            return (Math.floor(timeInSeconds / 86400)) + " dias";
        }

        //Maior que 1 hora
        if (timeInSeconds >= 3600) {
            return (Math.floor(timeInSeconds / 3600)) + " horas";
        }

        //Maior que 1 minuto 
        if (timeInSeconds >= 60) {
            return (Math.floor(timeInSeconds / 60)) + " minutos";
        }

        if (timeInSeconds <= 0) return 0;

        return timeInSeconds + " segundos";
    }

    render() {
        return (
            <div>
                <Row>
                    <Col md={6}><Label>Número Leads: {this.props.stats.NumeroLeads}</Label></Col>
                    <Col md={6}><Label>Valor: {(this.props.stats.ValorAcumulado).toFixed(2)}€</Label></Col>
                </Row>
                <Row>
                    <Col md={12}><Label style={{ fontWeight: "600" }}>Tempo</Label></Col>
                </Row>
                <Row>
                    <Col md={4} style={tempoStyle}>
                        <Label>Mínimo:</Label>
                        <Label>{this.calculateTimeInHumanLanguage(this.props.stats.TempoMinimo)}</Label>
                    </Col>
                    <Col md={4} style={tempoStyle}>
                        <Label>Médio:</Label>
                        <Label>{this.calculateTimeInHumanLanguage(this.props.stats.TempoMedio)}</Label>
                    </Col>
                    <Col md={4} style={tempoStyle}>
                        <Label>Máximo:</Label>
                        <Label>{this.calculateTimeInHumanLanguage(this.props.stats.TempoMaximo)}</Label>
                    </Col>
                </Row>
            </div>
        )
    }
}
