// 25-07-2019 - AFONSO - Funcao para obter os melhores clientes
export function getTopBuyers() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getTopBuyers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 25-07-2019 - AFONSO - Funcao para obter os piores clientes
export function getBottomBuyers() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/getBottomBuyers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 25-07-2019 - AFONSO - Funcao para obter os piores clientes
export function orderRedeGraph() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/orderRedeGraph", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 29-07-2019 - AFONSO - Funcao para numero de clientes com registo por acabar
export function registosNaoConcluidos() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/registosNaoConcluidos", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 29-07-2019 - AFONSO - Funcao para numero de clientes sem encomendas
export function clientsWithNoOrders() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/clientsWithNoOrders", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 29-07-2019 - AFONSO - Funcao para numero de clientes sem encomendas no ultimo ano
export function clientsWithOrders1YearAgo() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/clientsWithOrders1YearAgo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function allConsultantsCurrentMonthBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/allConsultantsCurrentMonthBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function allConsultantsSecondQuarterBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/allConsultantsSecondQuarterBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function allConsultantsThirdQuarterBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/allConsultantsThirdQuarterBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function allConsultantsFourthQuarterBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/allConsultantsFourthQuarterBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function allConsultantsFirstQuarterBilling() {
  return new Promise((resolve, reject) => {
    return fetch("/indicadores/allConsultantsFirstQuarterBilling", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}