import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Col, Row } from "reactstrap";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import Detalhe from "./Components/Detalhe";
import Timeline from "./Components/Timeline";

class DetalheContacto extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Detalhe do contacto"
            subheading="Página onde pode ver o detalhe do contacto"
            icon="pe-7s-graph icon-gradient bg-ripe-malin"
          />
          <Row>
            <Col xs={12} sm={12} md={4} lg={4}>
              <Detalhe id={this.props.id} />
            </Col>
            <Col xs={12} sm={12} md={8} lg={8}>
              <Timeline id={this.props.id} />
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default DetalheContacto;
