import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Col, Row, Card, CardBody, CardHeader } from "reactstrap";
import PageTitleAlt2 from "../../../helpers/Layout/PageTitleAlt2";
import Pesquisa from "./Components/Pesquisa";
import Resultados from "./Components/Resultados";

export default class PesquisaLeads extends Component {
    render() {
        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
                    <PageTitleAlt2
                        heading="Pesquisa de Leads"
                        subheading="Página onde pode pesquisar pelas suas Leads"
                        icon="pe-7s-search icon-gradient bg-ripe-malin"
                    />
                    <Row className="main-card mb-6">
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                        <i className="header-icon lnr-magnifier mr-3 text-muted opacity-6">
                                            {" "}
                                        </i>
                                        Formulário de Pesquisa
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Pesquisa />
                                    <Resultados />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </Fragment>
        )
    }
}
