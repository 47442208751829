import React, { Component } from "react";
import { FormattedDate } from "react-intl";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { getEmpresas } from "../../../actions/empresasActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

var that;
class NegociosComCliente extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0,
      deleteMessage: false
    };
    that = this;
    this.toggle = this.toggle.bind(this);
    this.deleteModal = this.deleteModal.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  deleteModal() {
    this.setState({
      deleteMessage: !this.state.deleteMessage
    });
  }

  getData() {
    getEmpresas().then(data => {
      if (data.data && !data.message) {
        that.setState({ dataSize: data.data.length });
        that.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      if (data.message) {
        that.setState({
          data: [],
          dataSize: 0
        });
      }
      that.setState({
        columns: [
          {
            Header: "Nome",
            accessor: "Nome",
            resizable: false,
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Data Criação",
            accessor: "DataCriacao",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.DataCriacao ? (
                <FormattedDate value={new Date(row.original.DataCriacao)} />
              ) : (
                  "-"
                );
            }
          },
          {
            Header: "Ações",
            accessor: "EstadoNegocio",
            resizable: false,
            show: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <div>
                  <div className="d-block w-100 text-center">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon btn-icon-only btn btn-link"
                        color="link"
                      >
                        <i className="lnr-menu-circle btn-icon-wrapper" />
                      </DropdownToggle>
                      <DropdownMenu
                        right
                        className="rm-pointers dropdown-menu-hover-link"
                      >
                        <DropdownItem
                          onClick={() => {
                            window.location.href =
                              "/negocio/" + row.original.ID_Negocio;
                          }}
                        >
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Ver Detalhe</span>
                        </DropdownItem>
                        <DropdownItem>
                          <i
                            className="dropdown-icon lnr-file-empty"
                            onClick={() => {
                              that.toggle();
                              that.child.getBusiness(row.original.ID_Negocio);
                            }}
                          >
                            {" "}
                          </i>
                          <span
                            onClick={() => {
                              that.toggle();
                              that.child.getBusiness(row.original.ID_Negocio);
                            }}
                          >
                            Editar Negócio
                          </span>
                        </DropdownItem>
                        <DropdownItem>
                          <i
                            className="dropdown-icon lnr-book"
                            onClick={() => {
                              that.deleteModal();
                              that.child2.setID(row.original.ID_Negocio);
                            }}
                          >
                            {" "}
                          </i>
                          <span
                            onClick={() => {
                              that.deleteModal();
                              that.child2.setID(row.original.ID_Negocio);
                            }}
                          >
                            Remover Negócio
                          </span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                </div>
              );
            }
          }
        ]
      });
      that.setState({ blocking: false });
    });
  }

  componentDidMount() {
    this.props.onRef(this);
    this.getData();
  }
  render() {
    return (
      <span>
        <SimpleTable
          data={this.state.data}
          columns={this.state.columns}
          blocking={this.state.blocking}
          rowNumber={this.state.rowNumber}
          dataSize={this.state.dataSize}
          noDataText={"Sem empresas para apresentar"}
          title={"Empresas disponiveis"}
        />
      </span>
    );
  }
}

export default NegociosComCliente;
