import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import { FormattedNumber } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { confirmOrder, getOrderDetails, removeCredits, removePromotionalCode, removeVouchers, verifyOrder } from "../../actions/checkoutActions";
import GoogleAnalytics from "../../helpers/googleAnalytics";
import Toast from '../../helpers/Toast';
import { ValidateBillingAddress, ValidateShippingAddress } from '../../helpers/UtilsCheckout';
import IHTPButton from "../Common/Button";
import SkeletonComponent from "../Common/Skeleton";
import Loading from '../Loading/Loading';

export interface TotalProps {
	showMoreDetailsOnMount: boolean,
	user: any
}

export default function Total(props: TotalProps) {

	const [buttonCheckout, setButtonCheckout] = useState<boolean>(false);
	const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
	const [showMoreDetails, setShowMoreDetails] = useState<boolean>(props.showMoreDetailsOnMount);
	const [showPasswordErrors, setShowPasswordErrors] = useState<boolean>(false);

	const upline = useSelector((state: any) => state.upline); //Se estiver no seu user, aparece a sua informação. Se estiver num cliente em rede aparece a informação desse cliente
	const checkoutData = useSelector((state: any) => state.checkoutData);
	const loadingCheckoutPrices = useSelector((state: any) => state.loadingCheckoutPrices);
	const billingAddress = useSelector((state: any) => state.billingAddress);
	const shippingAddress = useSelector((state: any) => state.shippingAddress);
	const cart = useSelector((state: any) => state.cart);

	const dispatch = useDispatch();
	const cookies = useCookies();
	const googleAnalytics : GoogleAnalytics = new GoogleAnalytics();

	useEffect(() => {
		loadOrder();
		return () => { }
	}, [])

	const loadOrder = async () => {
		try {
			var data: any = await dispatch(verifyOrder());
			if (data.message !== undefined) {
				Toast.Show("error", data.message);
			}
		} catch (exp) { }
	}

	const removeCreditsClick = async (e) => {
		e.stopPropagation();
		try {
			var data: any = await dispatch(removeCredits())
			if (data.message !== undefined) {
				Toast.Show("error", data.message);
			}
		} catch (exp) { }
	}

	const removeVouchersClick = async (e) => {
		e.stopPropagation();
		try {
			var data: any = await dispatch(removeVouchers());
			if (data.message !== undefined) {
				Toast.Show("error", data.message);
			}
		} catch (exp) { }
	};

	const removePromotionalCodeClick = async e => {
		e.stopPropagation();
		try {
			var data: any = await dispatch(removePromotionalCode())
			if (data.message !== undefined) {
				Toast.Show("error", data.message);
			}

		} catch (exp) { }
	};

	const encomendar = async () => {
		try {
			//Vamos buscar a cookie que contem o client id do user
			var gaClientId = cookies["_ga"];

			//Fazemos parse do id de cliente do utilizador
			// gaClientId = ParseClientIdFromGoogleAnalytics(gaClientId);

			setButtonCheckout(true);
			setPaymentLoading(true);

			//Quer dizer que está a inserir uma nova morada de faturação
			//Então vamos tentar adicioná-la primeiro.
			if (checkoutData.ProdutosTodosGratuitos !== true) {
				if (checkoutData.billingAddress == 0) {
					let resultValidateBillingAddress = ValidateBillingAddress(billingAddress, dispatch);
					if (resultValidateBillingAddress.success === false) {
						Toast.Show("error", resultValidateBillingAddress.message);

						setButtonCheckout(false);
						setPaymentLoading(false);
						return;
					}
				}
			}

			//Quer dizer que está a inserir uma nova morada de envio
			//Então vamos tentar adicioná-la primeiro.
			if (checkoutData.TemProdutosFisicos === 1 && checkoutData.TipoTransporteExterno === 'S') {
				if (checkoutData.shippingAddress == 0) {
					let resultValidateShippingAddress = ValidateShippingAddress(shippingAddress, dispatch);
					if (resultValidateShippingAddress.success === false) {
						Toast.Show("error", resultValidateShippingAddress.message);

						setButtonCheckout(false);
						setPaymentLoading(false);
						return;
					}
				}
			}


			var data: any = null;
			//É para criar o cliente
			//Então vamos fazer o pedido HTTP a outro endpoint
			//Este endpoint recebe os dados do cliente a ser criado, os dados das moradas e os dados da encomenda
			data = await dispatch(confirmOrder(gaClientId));

			//Se não tiver sido feito com sucesso
			if (data.success === false) {

				//Ficamos com a mensagem
				var message = data.message;

				//Se a mensagem estiver undefined
				if (typeof message === 'undefined' || message == null) {

					//Vamos buscar uma com o valor default
					message = "Problema ao criar a encomenda";
				}

				setPaymentLoading(false);
				setButtonCheckout(false);
				Toast.Show("error", message);
				return;
			}

			var obj = data.obj;

			// Obter o detalhe de um produto
			const result: any = await getOrderDetails(obj.ID_Encomenda);

			//Se conseguirmos obter os dados com sucesso da API
			if (result.success === true) {

				var detalhes = result.obj.Detalhes[0];
				sendDataToAnalytics(detalhes);

				const successMessage = data.message ? data.message : "Encomenda registada com sucesso, a reencaminhar para pagamento!";
				Toast.Show("success", successMessage);

				setTimeout(function () {
					var x: Window = window;
					x.location.href = "/payment/" + obj.HashEncomenda;
				}, 2000);

			}
		} catch (err) {
			setButtonCheckout(false);
			setPaymentLoading(false);

			Toast.Show("error", "Problema ao criar a encomenda");
		};
	}

	const sendDataToAnalytics = (ordeDetails: any) => {
		// // Dados de encomenda
		// var order = {
		// 	IDEncomenda: ordeDetails.ID_Encomenda,
		// 	Affiliation: "Loja Online",
		// 	precoFinal: ordeDetails.PrecoFinal,
		// 	precoTaxa: ordeDetails.IvaTotal,
		// 	precoEnvio: ordeDetails.CustoTransporte,
		// 	codigoPromocional: ordeDetails.CodigoPromocional
		// };

		// var items = googleAnalytics.CreateProductObjectToSendToGoogleAnalytics(cart.Carrinho, cart.CarrinhoPack);

		// // Criar o datalayer e enviar datalayer
		// googleAnalytics.Checkout_Encomendar(order, items);


		// Dados para o GA antigo
		var order_old = {
			IDEncomenda: ordeDetails.ID_Encomenda,
			Affiliation: "Loja Online",
			precoFinal: 0.01, //Fizemos este "hack" para a encomenda pode ser contabilizada no Analytics
			precoTaxa: 0,
			precoEnvio: 0
		};

		// Dados de produtos
		var products = [];

		// Criar o datalayer e enviar datalayer
		googleAnalytics.Checkout_Encomendar_Old(order_old, products);
	}


	const CustomizedSketelon = () => {
		return (<SkeletonComponent width={75} height={24} />);
	}

	const handleShowDetails = (value) => {
		setShowMoreDetails(value);
	}

	var loadingPrices = loadingCheckoutPrices;

	return (
		<Row style={{ minHeight: "100%" }}>
			<Col xs={12} sm={12} md={12} lg={12}>
				<div className="checkout-cart-total">
					{paymentLoading ? (
						<Loading />
					) : (
						<div>

							{/* Titulo do Formulário */}
							<div style={{ textAlign: "center" }} className="checkoutBoxTitle">
								Total
							</div>

							{showMoreDetails === true ?
								<div id="checkout-show-less">
									<Button
										className="btn btn-primary"
										onClick={() => handleShowDetails(false)}
									>
										<AiOutlineMinusCircle size={15} /> Esconder Detalhes
									</Button>

									{/* 1º listagem */}
									<ul>

										{/* Sub Total */}
										<li>
											{"SubTotal "}
											<span>
												{loadingPrices === true ? (
													CustomizedSketelon()
												) : (
													<FormattedNumber value={checkoutData.Subtotal} style="currency"
														currency={upline.ID_Moeda} minimumFractionDigits={2} maximumFractionDigits={2} />
												)}
											</span>
										</li>

										{/* Portes */}
										<li>
											{"Portes "}
											<span>
												{loadingPrices === true ? (
													CustomizedSketelon()
												) : (
													<FormattedNumber value={checkoutData.Portes} style="currency"
														currency={upline.ID_Moeda} minimumFractionDigits={2} maximumFractionDigits={2} />
												)}
											</span>
										</li>

										{/* Iva */}
										<li>
											{"Iva "}
											<span>
												{loadingPrices === true ? (
													CustomizedSketelon()
												) : (
													<FormattedNumber value={checkoutData.Iva} style="currency"
														currency={upline.ID_Moeda} minimumFractionDigits={2} maximumFractionDigits={2} />
												)}
											</span>
										</li>

										{/* Código Promocional */}
										{checkoutData.ChaveCodigoPromocional ? (
											<li>
												<div className="discountAbleToRemove">
													- {"Código Promocional "}

													{loadingPrices === true ? (
														CustomizedSketelon()
													) : (
														<span className='removeDiscountButtonContainer'>
															<span className="removeDiscountButton" onClick={removePromotionalCodeClick}>
																Remover
															</span>

															<FormattedNumber value={checkoutData.ValorDescontoCP} style="currency"
																currency={upline.ID_Moeda}
																minimumFractionDigits={2} maximumFractionDigits={2} />
														</span>
													)}
												</div>
											</li>
										) : null}

										{/* Vouchers */}
										{checkoutData.vouchers ? (
											<li>
												<div className="discountAbleToRemove">
													- {"Valor em Vales: "}

													{loadingPrices === true ? (
														CustomizedSketelon()
													) : (
														<span className="removeDiscountButtonContainer">
															<span className="removeDiscountButton" onClick={removeVouchersClick}>
																Remover
															</span>

															<FormattedNumber value={checkoutData.vouchers} style="currency" currency={upline.ID_Moeda}
																minimumFractionDigits={2} maximumFractionDigits={2} />
														</span>
													)}
												</div>
											</li>
										) : null}

										{/* Créditos */}
										{checkoutData.credits ? (
											<li>
												<div className="discountAbleToRemove">
													- {"Créditos: "}

													{loadingPrices === true ? (
														CustomizedSketelon()
													) : (
														<span className='removeDiscountButtonContainer'>
															<span className="removeDiscountButton" onClick={removeCreditsClick}>
																Remover
															</span>

															<FormattedNumber value={checkoutData.credits} style="currency" currency={upline.ID_Moeda}
																minimumFractionDigits={2} maximumFractionDigits={2} />
														</span>
													)}
												</div>
											</li>
										) : null}

										{/* Descontos */}
										<li>
											<div className={checkoutData.Descontos > 0 ? "hasGlobalDiscount" : ""}>
												- {"Desconto: "}

												{loadingPrices === true ? (
													CustomizedSketelon()
												) : (
													<span>
														<FormattedNumber value={checkoutData.Descontos} style="currency" currency={upline.ID_Moeda}
															minimumFractionDigits={2} maximumFractionDigits={2} />
													</span>
												)}

											</div>
										</li>
									</ul>

								</div>
								:
								<div id="checkout-show-more">
									<Button
										className="btn btn-primary"
										onClick={() => handleShowDetails(true)}
									>
										<AiOutlinePlusCircle size={15} /> Mostrar Detalhes
									</Button>
								</div>}


							<div className="inputTotal">

								{/* Label do valor a pagar */}
								<Row style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center", margin: "0px", padding: "0px" }}>
									<Col style={{ padding: "0px", textAlign: "left" }}>
										{"Valor a Pagar: "}
									</Col>

									{/* Valor a Pagar */}
									<Col style={{ padding: "0px", textAlign: "right", display: "flex", justifyContent: "flex-end" }}>

										{loadingPrices === true ? (
											<SkeletonComponent width={150} height={33} />
										) : (
											<FormattedNumber value={checkoutData.Total} style="currency" currency={upline.ID_Moeda}
												minimumFractionDigits={2} maximumFractionDigits={2} />
										)}

									</Col>
								</Row>
							</div>

							{/*Divisória*/}
							<p className="center" style={{ textAlign: "right" }} />

							<br />
							<div className="col-12 center">
								<IHTPButton
									text={"Finalizar"}
									disabled={buttonCheckout || loadingPrices === true ? true : false}
									buttonStyle={"primary"}
									onClick={encomendar} />
							</div>
						</div>
					)}
				</div>
			</Col>
		</Row >
	)
}