import React, { Component } from "react";
import { Slide, toast } from "react-toastify";
import SweetAlert from "sweetalert-react";
import { removeContactComment } from "../../../../../../actions/contactosActions";
import "./ObservacaoTool.css";

class ObservacaoTool extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  removeObservacaoConfirm = async e => {
    this.setState({ dialogConfirm: true });
  };

  removeObservacao = async e => {
    removeContactComment(this.props.contactID, this.props.ID_Observacao).then(
      data => {
        if (data.message === "Observação removida com sucesso") {
          this.setState({
            dialogConfirm: false
          });
          toast(data.message, {
            transition: Slide,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-right",
            type: "success"
          });
          this.props.updateObservacoes(this.props.contactID);
        } else {
          toast(data.message, {
            transition: Slide,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-right",
            type: "error"
          });
        }
      }
    );
  };

  render() {
    return (
      <div
        style={{
          display: this.props.observacaoToolState ? "initial" : "none"
        }}
      >
        <span
          className="tool"
          style={{ marginRight: "15px", cursor: "pointer" }}
          onClick={this.props.changeEditObservacaoState}
        >
          <a>Editar</a>
        </span>
        <span
          className="tool"
          style={{ marginRight: "15px", cursor: "pointer" }}
          onClick={this.removeObservacaoConfirm}
        >
          <a>Remover</a>
        </span>
        <SweetAlert
          title="Deseja remover a observação?"
          confirmButtonColor=""
          show={this.state.dialogConfirm}
          text="Não será possivel recuperar a observação!"
          showCancelButton
          onConfirm={() => {
            this.removeObservacao();
          }}
          onCancel={() => this.setState({ dialogConfirm: false })}
        />
      </div>
    );
  }
}

export default ObservacaoTool;
