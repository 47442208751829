import React, { Component } from "react";
import CountUp from "react-countup";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { getDashboardFaturacaoMesCorrente } from "../../../actions/homeActions";

class FaturacaoMesCorrente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faturacao: 0
    };
  }

  componentDidMount() {
    getDashboardFaturacaoMesCorrente().then(data => {
      if (data[0] && !data.message) {
        this.setState({ faturacao: data[0].total });
      }
    });
  }
  render() {
    return (
      <Col md="4">
        <div className="card mb-3 widget-chart">
          <div className="icon-wrapper rounded-circle">
            <div className="icon-wrapper-bg bg-primary" />
            <i className="pe-7s-cash text-primary" />
          </div>
          <div className="widget-numbers text-success">
            <CountUp
              start={0}
              decimals={2}
              end={this.state.faturacao}
              prefix="€"
              duration="3"
            />
          </div>
          <div className="widget-subheading">Faturação mês corrente</div>
        </div>
      </Col>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user,
  pesquisa: state.pesquisa
});

export default connect(mapStateToProps)(FaturacaoMesCorrente);
