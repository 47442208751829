import { Col, Row } from "react-bootstrap";

export default function Loading() {

  return (
    <Row>
      <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
        <div className="lds-roller">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </Col>
    </Row>
  );
}