import { Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import Checkout from "../Checkout/Checkout";
import "./Cart.css";
import PesquisaProdutos from "./Components/PesquisaProdutos";
import ProdutosCarrinho from "./Components/ProdutosCarrinho";

export default function Cart() {
  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitleAlt2
          heading="Carrinho"
          subheading="Página onde pode adicionar produtos ao carrinho"
          icon="pe-7s-cart icon-gradient bg-ripe-malin"
        />
        <div>
          <PesquisaProdutos />
        </div>

        <div style={{ paddingTop: "2em" }}>
          <ProdutosCarrinho />
        </div>

        <div style={{ paddingTop: "2em" }}>
          <Checkout />
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  )
}