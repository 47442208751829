import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getPromotores } from "../../../actions/redeActions";
import { changeUser, getContacts } from "../../../actions/userActions";
import ContactosCliente from "../../../helpers/ContactosCliente";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class TabelaPromotores extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0,
      contactos: []
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    getPromotores().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "",
            width: 45,
            Cell: row => {
              return (
                <span
                  onClick={() => {
                    this.getUserContacts(row.original.ID_Cliente);
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                </span>
              );
            }
          },
          {
            Header: "ID Cliente",
            accessor: "ID_Cliente",
            resizable: false,
            filterable: true,
            width: 100,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.ID_Cliente ? row.original.ID_Cliente : "-";
            }
          },
          {
            Header: "Nome Cliente",
            accessor: "NomeCompleto",
            resizable: false,
            filterable: true,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <span  style={{cursor:"pointer"}}
                  onClick={() => {
                    this.props
                      .dispatch(changeUser(row.original.ID_Cliente))
                      .then(() => {
                        window.location.href = "/";
                      });
                  }}
                >
                  {row.original.NomeCompleto ? row.original.NomeCompleto : "-"}
                </span>
              );
            }
          },
          {
            Header: "Preferencias",
            accessor: "Preferencias",
            resizable: false,
            filterable: true,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.Preferencias
                ? row.original.Preferencias
                : "-";
            }
          },
          {
            Header: "Sonhos",
            accessor: "NecessidadesSonhos",
            resizable: false,
            filterable: true,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.NecessidadesSonhos
                ? row.original.NecessidadesSonhos
                : "-";
            }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  getUserContacts(id) {
    getContacts(id).then(data => {
      if (data && !data.message) {
        this.setState({ contactos: data });
        this.toggle();
      }
    });
  }

  render() {
    return (
      <span>
        <ContactosCliente
          onRef={ref => (this.child = ref)}
          isOpen={this.state.modal}
          toggle={this.toggle}
          contactos={this.state.contactos}
        />
        <SimpleTable
          data={this.state.data}
          columns={this.state.columns}
          blocking={this.state.blocking}
          rowNumber={this.state.rowNumber}
          dataSize={this.state.dataSize}
          noDataText={"Sem promotores para apresentar"}
          title={"Promotores"}
        />
      </span>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(TabelaPromotores);
