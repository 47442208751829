import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { getContactComments } from "../../../../actions/contactosActions";
import AdicionarObservacao from "./AdicionarObservacao";
import NovaObservacao from "./NovaObservacao";
import Observacao from "./Observacao/Observacao";

var that;
class Observacoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: true,
      hidden: "initial",
      show: "none",
      addButtonState: true,
      comentarios: [],
      observacao: {}
    };
    that = this;
  }

  getData(contactID) {
    getContactComments(contactID).then(data => {
      if (data && !data.message) {
        that.setState({
          comentarios: data,
          dataSize: 1
        });
      }
    });
  }
  componentDidMount() {
    this.getData(this.props.contactID);
  }

  changeAddButtonState = async e => {
    this.setState({
      addButtonState: !this.state.addButtonState
    });
  };

  render() {
    return (
      <Row>
        <Col lg={12}>
          {/* Adicionar nova observação */}
          <AdicionarObservacao
            addButtonState={this.state.addButtonState}
            onChangeState={this.changeAddButtonState}
          />
          {/* Observações */}
          {this.state.comentarios.map(comentario => {
            return (
              <Row>
                <Col lg={12}>
                  <Observacao
                    addButtonState={this.state.addButtonState}
                    Descricao={comentario.Descricao}
                    DataCriacao={comentario.DataCriacao}
                    ID_ObservacaoContacto={comentario.ID_ObservacaoContacto}
                    updateObservacoes={this.getData}
                    contactID={this.props.contactID}
                  />
                </Col>
              </Row>
            );
          })}
          {/* Nova Observacao */}
          <NovaObservacao
            addButtonState={this.state.addButtonState}
            handleChange={this.handleChange}
            novaObservacao={this.novaObservacao}
            novaObservacaoData={this.state.observacao}
            updateObservacoes={this.getData}
            contactID={this.props.contactID}
            changeAddButtonState={this.changeAddButtonState}
          />
        </Col>
      </Row>
    );
  }
}

export default Observacoes;
