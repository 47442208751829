import { Col, Row } from "react-bootstrap";

export default function CartIsEmpty() {
    return (
        <div>
            <Row>
                <Col>
                    <h3 style={{fontWeight: "500"}}>O seu carrinho de compras encontra-se vazio.</h3>
                </Col>
            </Row>
        </div>
    )

}