import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitleAlt3 from "../../helpers/Layout/PageTitleAlt3";
import NegociosComCliente from "./Components/NegociosComCliente";
import NegociosDesteCliente from "./Components/NegociosDesteCliente";
import OutrosNegocios from "./Components/OutrosNegocios";

class Negocios extends Component {
  constructor() {
    super();
    this.state = {};
    this.updateData = this.updateData.bind(this);
  }

  updateData() {
    this.child.getData();
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt3
            heading="Negócios"
            subheading="Página onde pode ver os seus negócios"
            icon="lnr-briefcase icon-gradient bg-ripe-malin"
            updateData={this.updateData}
          />
          <NegociosComCliente onRef={ref => (this.child = ref)} />
          <NegociosDesteCliente />
          <OutrosNegocios />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Negocios;
