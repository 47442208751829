import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { orderRedeGraph } from "../../../actions/indicadoresActions";

var that;
class Faturacao12mesesRede extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: true,
      hidden: "initial",
      show: "none",
      data: [],
      comissoesGraph: []
    };
    that = this;
  }

  getData() {
    orderRedeGraph().then(data => {
      if (data.data && !data.message) {
        this.setState({
          comissoesGraph: data.data
        });
      }
      this.setState({ blocking: false });
    });
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div />
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">
                      {" "}
                    </i>
                    Faturação da rede nos ultimos 12 meses
                  </div>
                  <div className="btn-actions-pane-right">
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="danger"
                      onClick={() => {
                        this.setState({ hidden: "none", show: "initial" });
                      }}
                      style={{ display: this.state.hidden }}
                    >
                      <i
                        className="lnr-circle-minus"
                        style={{ display: this.state.hidden }}
                        onClick={() => {
                          this.setState({
                            hidden: "none",
                            show: "initial"
                          });
                        }}
                      />
                    </Button>
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="success"
                      onClick={() => {
                        this.setState({ hidden: "initial", show: "none" });
                      }}
                      style={{ display: this.state.show }}
                    >
                      <i
                        className="lnr-plus-circle"
                        style={{ display: this.state.show }}
                        onClick={() => {
                          this.setState({
                            hidden: "initial",
                            show: "none"
                          });
                        }}
                      />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody style={{ display: this.state.hidden }}>
                  <BlockUi
                    tag="div"
                    blocking={this.state.blocking}
                    className="block-overlay-dark"
                    loader={
                      <Loader
                        color="#ffffff"
                        active
                        type="ball-spin-fade-loader"
                      />
                    }
                  >
                    {!this.state.blocking ? (
                      <span>
                        <Card className="main-card mb-3">
                          <CardBody>
                            <CardTitle>Faturação ultimos 12 meses</CardTitle>
                            <div className="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
                              <ResponsiveContainer width="100%" height={240}>
                                {this.state.comissoesGraph ? (
                                  this.state.comissoesGraph[0] ? (
                                    <LineChart
                                      data={this.state.comissoesGraph}
                                      margin={{
                                        top: 0,
                                        right: 5,
                                        left: 5,
                                        bottom: 0
                                      }}
                                    >
                                      <XAxis dataKey="mes" label="Mês" />
                                      <Tooltip />
                                      <Line
                                        type="monotone"
                                        dataKey="valorEncomendas"
                                        stroke="var(--pink)"
                                        strokeWidth={3}
                                      />
                                    </LineChart>
                                  ) : (
                                      <span>Não existem dados disponiveis</span>
                                    )
                                ) : (
                                    <span>Não existem dados disponiveis</span>
                                  )}
                              </ResponsiveContainer>
                            </div>
                          </CardBody>
                        </Card>
                      </span>
                    ) : (
                        <div style={{ textAlign: "center" }}>
                          {!this.state.blocking
                            ? "Não existem informações a faturação"
                            : null}
                        </div>
                      )}
                  </BlockUi>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Faturacao12mesesRede;
