export function getNameById(productId) {
    return new Promise((resolve, reject) => {
        return fetch("/product/getNameById", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                productId: productId
            }),
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export function getByNameOrId(productId, productName, currentPage, pageSize, sortOrder, sortOrderAsc) {
    return new Promise((resolve, reject) => {
        return fetch("product/getByNameOrId", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                productId: productId ?? '',
                productName: productName ?? "",
                currentPage: currentPage,
                pageSize: pageSize,
                sortOrder: sortOrder,
                sortOrderAsc: sortOrderAsc
            }),
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export function getProductDetailsWP(id, tipo) {
    return new Promise((resolve, reject) => {
        return fetch("/product/productDetailsWP", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({
                id: id,
                tipo: tipo
            })
        })
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                console.log(error.message);
                resolve({
                    success: false,
                    message: error.message,
                });
            });
    });
}

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}