import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Avatar from "react-avatar";
import { Button, Card, CardBody, CardFooter, Col, Collapse, Row } from "reactstrap";
import { getContactoDetail } from "../../../actions/contactosActions";
import DetalheButtons from "./DetalheButtons";

var that;
class Detalhe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: true,
      hidden: "initial",
      show: "none",
      data: [],
      collapse: false,
      accordion: [true, false, false],
      custom: [true, false],
      status: "Closed",
      fadeIn: true,
      timeout: 300
    };
    that = this;
    this.onEntering = this.onEntering.bind(this);
    this.onEntered = this.onEntered.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleAccordion = this.toggleAccordion.bind(this);
    this.toggleCustom = this.toggleCustom.bind(this);
    this.toggleFade = this.toggleFade.bind(this);
  }

  onEntering() {
    this.setState({ status: "Opening..." });
  }

  onEntered() {
    this.setState({ status: "Opened" });
  }

  onExiting() {
    this.setState({ status: "Closing..." });
  }

  onExited() {
    this.setState({ status: "Closed" });
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  toggleAccordion(tab) {
    const prevState = this.state.accordion;
    const state = prevState.map((x, index) => (tab === index ? !x : false));

    this.setState({
      accordion: state
    });
  }

  toggleCustom(tab) {
    const prevState = this.state.custom;
    const state = prevState.map((x, index) => (tab === index ? !x : false));

    this.setState({
      custom: state
    });
  }

  toggleFade() {
    this.setState({ fadeIn: !this.state.fadeIn });
  }

  getData() {
    getContactoDetail(this.props.id).then(data => {
      if (data && !data.message) {
        this.setState({
          data: data
        });
      }
      this.setState({ blocking: false });
    });
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div />
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <Card
                className="card-shadow-primary card-border text-white mb-3"
                color="white"
              >
                <div className="dropdown-menu-header">
                  <div
                    className="dropdown-menu-header-inner"
                    style={{ background: "#f9fafb" }}
                  >
                    <div className="menu-header-content">
                      <div className="avatar-icon-wrapper mb-3 avatar-icon-xxl">
                        <div className="avatar-icon">
                          <Avatar name={this.state.data.Nome} round={true} />
                        </div>
                      </div>
                      <div>
                        <h5
                          className="menu-header-title"
                          style={{ color: "black" }}
                        >
                          {this.state.data.Nome}
                        </h5>
                      </div>
                      <br />
                      <DetalheButtons />
                    </div>
                  </div>
                </div>
                <CardFooter className="text-center d-block">
                  <br />
                  <Button
                    block
                    color="link"
                    className="text-left m-0 p-0"
                    onClick={() => this.toggleAccordion(1)}
                    aria-expanded={this.state.accordion[1]}
                    aria-controls="collapseTwo"
                  >
                    <h5 className="m-0 p-0">Informação contacto</h5>
                  </Button>
                  <Collapse
                    isOpen={this.state.accordion[1]}
                    data-parent="#accordion"
                    id="collapseTwo"
                  >
                    <CardBody style={{ color: "black", textAlign: "left" }}>
                      2. Anim pariatur cliche reprehenderit, enim eiusmod high
                      life accusamus terry richardson ad squid. 3 wolf moon
                      officia aute, non cupidatat skateboard dolor brunch. Food
                      truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                      tempor, sunt aliqua put a bird on it squid single-origin
                      coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                      helvetica, craft beer labore wes anderson cred nesciunt
                      sapiente ea proident. Ad vegan excepteur butcher vice
                      lomo. Leggings occaecat craft beer farm-to-table, raw
                      denim aesthetic synth nesciunt you probably haven't heard
                      of them accusamus labore sustainable VHS.
                    </CardBody>
                  </Collapse>
                  <hr />
                  <Button
                    block
                    color="link"
                    className="text-left m-0 p-0"
                    onClick={() => this.toggleAccordion(2)}
                    aria-expanded={this.state.accordion[2]}
                    aria-controls="collapseTwo"
                  >
                    <h5 className="m-0 p-0">Empresa</h5>
                  </Button>
                  <Collapse
                    isOpen={this.state.accordion[2]}
                    data-parent="#accordion"
                    id="collapseTwo"
                  >
                    <CardBody style={{ color: "black" }}>
                      2. Anim pariatur cliche reprehenderit, enim eiusmod high
                      life accusamus terry richardson ad squid. 3 wolf moon
                      officia aute, non cupidatat skateboard dolor brunch. Food
                      truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                      tempor, sunt aliqua put a bird on it squid single-origin
                      coffee nulla assumenda shoreditch et. Nihil anim keffiyeh
                      helvetica, craft beer labore wes anderson cred nesciunt
                      sapiente ea proident. Ad vegan excepteur butcher vice
                      lomo. Leggings occaecat craft beer farm-to-table, raw
                      denim aesthetic synth nesciunt you probably haven't heard
                      of them accusamus labore sustainable VHS.
                    </CardBody>
                  </Collapse>
                  <br />
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Detalhe;
