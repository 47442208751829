import React, { Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import "../../App/App.css";

export default class SimpleTable extends React.Component {
  constructor() {
    super();
    this.state = {
      blocking: true,
      hidden: "initial",
      show: "none"
    };
  }

  calculateColor = (rowInfo) => {
    if (typeof rowInfo === 'undefined') return null;
    if (typeof rowInfo.original === 'undefined') return null;

    if (rowInfo.original.colorValorEnc === "Red") return "rgba(252, 178, 184, 0.6)";
    if (rowInfo.original.colorValorEnc === "Green") return "rgba(178, 252, 182, 0.6)";

    //Cor para o amarelo => "rgba(255, 250, 174, 0.6)"

    return null;
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div />
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">
                      {" "}
                    </i>
                    {this.props.title}
                  </div>
                  <div className="btn-actions-pane-right">
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="danger"
                      onClick={() => {
                        this.setState({ hidden: "none", show: "initial" });
                      }}
                      style={{ display: this.state.hidden }}
                    >
                      <i
                        className="lnr-circle-minus"
                        style={{ display: this.state.hidden }}
                        onClick={() => {
                          this.setState({ hidden: "none", show: "initial" });
                        }}
                      />
                    </Button>
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="success"
                      onClick={() => {
                        this.setState({ hidden: "initial", show: "none" });
                      }}
                      style={{ display: this.state.show }}
                    >
                      <i
                        className="lnr-plus-circle"
                        style={{ display: this.state.show }}
                        onClick={() => {
                          this.setState({ hidden: "initial", show: "none" });
                        }}
                      />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody style={{ display: this.state.hidden }}>
                  <BlockUi
                    tag="div"
                    blocking={this.props.blocking}
                    className="block-overlay-dark"
                    loader={
                      <Loader
                        color="#ffffff"
                        active
                        type="ball-spin-fade-loader"
                      />
                    }
                  >
                    {this.props.dataSize > 0 && !this.props.blocking ? (
                      <ReactTable
                        className="-highlight tableReact"
                        data={this.props.data}
                        getTheadProps={(state, rowInfo, column) => {
                          return {
                            style: {
                              "text-align": "center"
                            }
                          };
                        }}
                        getTrProps={(state, rowInfo, column, instance) => {
                          return {
                            style: {
                              "text-align": "center",
                              background: this.calculateColor(rowInfo)
                            }
                          };
                        }}
                        columns={this.props.columns}
                        defaultPageSize={this.props.rowNumber}
                        previousText="Anterior"
                        nextText="Próxima"
                        pageText="Página"
                        ofText="de"
                        rowsText="linhas"
                        showPagination={
                          this.props.data.length > this.props.rowNumber
                            ? true
                            : false
                        }
                      />
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        {!this.props.blocking ? this.props.noDataText : null}
                      </div>
                    )}
                  </BlockUi>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}
