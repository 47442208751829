import Joi from "@hapi/joi";
import CircularProgress from '@material-ui/core/CircularProgress';
import * as EmailValidator from 'email-validator';
import React, { Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { Slide, toast } from "react-toastify";
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { getPossibleEvents } from "../../../actions/eventsActions";
import { getPaises, getPositions } from "../../../actions/geralActions";
/* Ações */
import { checkEmail, checkNIF, getTypesLead, newLead } from "../../../actions/leadsActions";
import { getClientDetailsByID, getLoggedInUser } from "../../../actions/userActions";
import PageTitleAlt4 from "../../../helpers/Layout/PageTitleAlt4";

const schema = Joi.object().keys({
  nome: Joi.string()
    .trim()
    .min(2)
    .max(100)
    .required()
    .messages({
      'string.min': `Nome deve estar preenchido e ter no mínimo 2 caracteres`,
      'string.max': `Nome deve ter no máximo 100 caracteres`,
      'any.required': `Nome é obrigatório`,
      'string.empty': `Nome não pode estar vazio`
    }),
  telemovel: Joi.string()
    .trim()
    .empty()
    .required()
    .regex(/^[+0-9 ]+$/)
    .messages({
      'any.trim': `Telemóvel deve ser um número`,
      'any.required': `Telemóvel é obrigatório`,
      'string.empty': `Telemóvel não pode estar vazio`,
      'string.pattern.base': `Telemóvel num formato inválido`
    }),
  pais: Joi.string()
    .trim()
    .min(3)
    .max(3)
    .required()
    .messages({
      'string.min': `País não pode estar vazio`,
      'string.max': `País deve estar com valores corretos`,
      'any.required': `País é obrigatório`,
    }),
  localidade: Joi.string()
    .trim()
    .empty()
    .min(1)
    .max(100)
    .required()
    .messages({
      'string.min': `Localidade não pode estar vazia`,
      'string.max': `Localidade não deve ter mais de 100 caracteres`,
      'any.required': `Localidade é obrigatório`,
      'string.empty': `Localidade não pode estar vazia`
    }),
  email: Joi.string()
    .trim()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .messages({
      'string.email': `Email deve ser um email válido`,
      'any.required': `Email é obrigatório`,
    }),
  tipoLead: Joi.string()
    .trim()
    .min(2)
    .max(2)
    .required()
    .messages({
      'string.min': `Tipo de Lead deve estar num formato correto`,
      'string.max': `Tipo de Lead deve estar num formato correto`,
      'any.required': `Tipo de Lead é obrigatório`,
    }),
  idEvento: Joi.string()
    .trim()
    .min(1)
    .max(2)
    .required()
    .messages({
      'any.required': `Evento é obrigatório`,
      'string.min': `Evento deve estar num formato correto`,
      'string.max': `Evento deve estar num formato correto`,
    }),
  valor: Joi.number()
    .positive()
    .allow(0)
    .required()
    .messages({
      'any.required': `Valor é obrigatório`,
      'number.base': `Valor deve ser um número`,
      'number.positive': `Valor deve ser um número positivo`,
    }),
  nif: Joi.optional()
    .when('tipoLead',
      {
        is: 'BB',
        then: Joi.string().required()
      }),
  nomeContacto: Joi.optional()
    .when('tipoLead',
      {
        is: 'BB',
        then: Joi.string()
          .trim()
          .required()
          .empty()
          .min(2)
          .messages({
            'any.required': `Nome de Contacto é obrigatório`,
            'string.empty': `Nome de Contacto não pode estar vazio`,
            'string.min': `Nome de Contacto deve estar preenchido e ter no mínimo 2 caracteres`,
            'any.empty': `Nome de Contacto não pode estar vazio`
          })
      }),
  emailContacto: Joi.optional()
    .when('tipoLead',
      {
        is: 'BB',
        then: Joi.string()
          .trim()
          .required()
          .email({ minDomainSegments: 2, tlds: { allow: false } })
          .messages({
            'string.email': `Email de Contacto deve ser um email válido`,
            'any.required': `Email de Contacto é obrigatório`,
          })
      }),
  telemovelContacto: Joi.optional()
    .when('tipoLead',
      {
        is: 'BB',
        then: Joi.string()
          .trim()
          .empty()
          .required()
          .regex(/^[+0-9 ]+$/)
          .messages({
            'any.required': `Telemóvel de Contacto é obrigatório`,
            'any.empty': `Telemóvel de Contacto não pode estar vazio`,
            'any.trim': `Telemóvel de Contacto não pode estar vazio`,
            'string.pattern.base': `Telemóvel de Contacto num formato inválido`
          }),
      }),
  cargoContacto: Joi.optional()
    .when('tipoLead',
      {
        is: 'BB',
        then: Joi.number()
          .integer()
          .required()
          .messages({
            'number.base': `Cargo de Contacto deve ser um número`,
            'any.required': `Cargo de Contacto é obrigatório`,
          })
      }),
});

const initialStateLead = {
  id_tipo_lead: "",
  email: "",
  nome: "",
  telemovel: "",
  id_pais: "PRT",
  localidade: "",
  id_estado: "",
  id_origem: "",
  id_empresa: "",
  valor: '0',
  nif: "",
  observacoes: "",
  contacto: {
    nome: "",
    email: "",
    cargo: "",
    telemovel: ""
  }
};

function validateNIF(value) {
  const nif = typeof value === "string" ? value : value.toString();
  const validationSets = {
    one: ["1", "2", "3", "5", "6", "8"],
    two: ["45", "70", "71", "72", "74", "75", "77", "79", "90", "91", "98", "99"]
  };

  if (nif.length !== 9) return false;

  if (!validationSets.one.includes(nif.substr(0, 1)) && !validationSets.two.includes(nif.substr(0, 2))) {
    return false;
  }

  let total =
    nif[0] * 9 +
    nif[1] * 8 +
    nif[2] * 7 +
    nif[3] * 6 +
    nif[4] * 5 +
    nif[5] * 4 +
    nif[6] * 3 +
    nif[7] * 2;

  let modulo11 = Number(total) % 11;
  const checkDigit = modulo11 < 2 ? 0 : 11 - modulo11;
  return checkDigit === Number(nif[8]);
}

function validateEmail(value) {
  return EmailValidator.validate(value);
}

class NovaLead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lead: initialStateLead,
      paisesOptions: [],
      tiposLead: [],
      eventosPossiveis: [],
      cargoPossiveis: [],
      confirmAuthorization: false,
      isB2BLead: false,
      emailMessage: "",
      emailError: true,
      emailIsVerified: false,
      nifMessage: "",
      nifError: true,
      nifIsVerified: false,
      extraEmailError: true,
      extraEmailIsVerified: false,
      extraEmailMessage: "",
      isCreating: false,
      dadosUtilizador: [],
      utilizadorEmRede: false
    };

    this.verifyExtraEmail = this.verifyExtraEmail.bind(this);
    this.onFocusOutVerify = this.onFocusOutVerify.bind(this);
    this.verifyNifIsAssociated = this.verifyNifIsAssociated.bind(this);
    this.verifyEmailIsAssociated = this.verifyEmailIsAssociated.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeInContact = this.handleChangeInContact.bind(this);
    this.handleClientAccepted = this.handleClientAccepted.bind(this);
    this.validarDados = this.validarDados.bind(this);
    this.getPaisesFromAPI = this.getPaisesFromAPI.bind(this);
    this.getTipoLeadFromAPI = this.getTipoLeadFromAPI.bind(this);
    this.getEventosPossiveis = this.getEventosPossiveis.bind(this);
    this.getPositionsFromAPI = this.getPositionsFromAPI.bind(this);
    this.resetState = this.resetState.bind(this);
    this.novaLead = this.novaLead.bind(this);
    this.useClientData = this.useClientData.bind(this);
  }

  //Lidar com o consultor ter colocado que o consultor autorizou o uso de dados
  handleClientAccepted(e) {
    this.setState(prevState => (
      {
        ...prevState,
        confirmAuthorization: !prevState.confirmAuthorization
      }
    ));
  }

  //Lidar com as alterações nos campos de contacto
  handleChangeInContact(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(prevState => ({
      lead: {
        ...prevState.lead,
        contacto: {
          ...prevState.lead.contacto,
          [name]: value
        }
      }
    }));

    if (name === 'email') {
      this.setState({
        extraEmailIsVerified: false
      });
    }
  }

  //Verificamos o email
  verifyExtraEmail() {
    var emailIsValid = validateEmail(this.state.lead.contacto.email);

    this.setState({
      extraEmailError: !emailIsValid,
      extraEmailMessage: (emailIsValid) ? "Email válido" : "Email inválido",
      extraEmailIsVerified: true
    });
  }

  //No focus out de alguns controlos, deve se validar o nif como no caso da alteração de Pais
  onFocusOutVerify() {
    this.verifyNifIsAssociated();
  }

  //Verificamos o nif no focus out
  verifyNifIsAssociated(e) {

    //Se for portugal nif deve ter 9 dígitos
    if (this.state.lead.id_pais === 'PRT' && (this.state.lead.nif.length !== 9 || !validateNIF(this.state.lead.nif))) {
      this.setState({
        nifMessage: 'NIF inválido',
        nifError: true,
        nifIsVerified: true
      });
      return;
    }

    if (this.state.lead.id_pais !== 'PRT' && this.state.lead.nif.length <= 0) {
      this.setState({
        nifMessage: 'NIF inválido',
        nifError: true,
        nifIsVerified: true
      });
      return;
    }

    checkNIF(this.state.lead.nif, this.state.lead.id_pais)
      .then(data => {

        if (data.success) {
          this.setState({
            nifMessage: (data.message) ? data.message : "Válido para uso",
            nifError: false,
            nifIsVerified: true
          })

          if (typeof data.nomeEmpresa !== 'undefined' && data.nomeEmpresa.length > 0) {
            this.setState({
              lead: {
                ...this.state.lead,
                nome: data.nomeEmpresa
              }
            });
          }
        } else {
          this.setState({
            nifMessage: (data.message) ? data.message : "Erro ao verificar com o servidor",
            nifError: true,
            nifIsVerified: true
          });
        }
      })
      .catch(e => {
        this.setState({
          nifMessage: 'Erro ao ligar ao servidor',
          nifError: true,
          nifIsVerified: true
        });
      });

  }

  //Verificamos o email no focus out
  verifyEmailIsAssociated(e) {

    //Verificamos se o campo tem alguma
    if (this.state.lead.email.length > 0 && validateEmail(this.state.lead.email)) {
      checkEmail(this.state.lead.email)
        .then(data => {
          // Se não chegar uma mensagem da API, colocamos que houve um erro
          if (typeof data.message === 'undefined') {
            this.setState({
              emailMessage: 'Erro ao verificar com o servidor',
              emailError: true,
              emailIsVerified: true
            });

            //Caso chegue a resposta da API
          } else {

            //Se a length > 0 é sinal que existe um erro e mostramos a mensagem de erro
            if (data.message.length > 0) {
              this.setState({
                emailMessage: data.message,
                emailError: true,
                emailIsVerified: true
              });

              //Se a length = 0 é sinal que este cliente é válido
            } else {

              this.setState(prevState => {
                return {
                  ...prevState,
                  emailMessage: "Válido para uso",
                  emailError: false,
                  emailIsVerified: true,
                  lead: {
                    ...prevState.lead,

                    //Se for uma lead B2B não mexemos no nome, só se for lead de outro tipo
                    nome: (!prevState.isB2BLead && data.obj.length > 0) ? data.obj : prevState.lead.nome
                  }
                }
              });
            }
          }
        })
        .catch(e => {
          this.setState({
            emailMessage: 'Erro ao ligar ao servidor',
            emailError: true,
            emailIsVerified: true
          });
        });
    } else {
      this.setState({
        emailMessage: 'Email inválido',
        emailError: true,
        emailIsVerified: true
      });
    }

  }

  //Campo para lidar com alterações no formulário visual
  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(prevState => ({
      lead: {
        ...prevState.lead,
        [name]: value
      }
    }));

    //Se o valor que é para mudar é o do campo TipoLead
    if (name === 'id_tipo_lead') {

      var isB2B = false;

      //Verificamos se o valor mudado é para BB (B2B) que significa que vamos ter que mostrar info extra para preencher
      if (value.toUpperCase() === 'BB') {
        isB2B = true;
      }

      this.setState({ isB2BLead: isB2B });
      this.getEventosPossiveis(value.toUpperCase());
    }

    if (name === 'email') {
      this.setState({ emailIsVerified: false });
    }

    if (name === 'nif') {
      this.setState({ nifIsVerified: false });
    }
  }

  componentDidMount() {

    //Vai buscar os paises
    this.getPaisesFromAPI();

    //Vais buscar o tipo de lead à API
    this.getTipoLeadFromAPI();

    //Vamos buscar os cargos 
    this.getPositionsFromAPI();

    //Vamos buscar os dados do utilizador
    this.getUser();
  }

  //Vamos buscar os cargos
  getPositionsFromAPI() {
    getPositions()
      .then(result => {
        //Se estiver definido
        if (result) {

          //Vemos se temos o parametro succes
          if (typeof result.success !== 'undefined' && result.success == true) {
            this.setState({
              cargoPossiveis: result.obj
            });
          }
        }

      }).catch(e => {
        console.log(e);
      });
  }

  //Vamos buscar os países à API
  getPaisesFromAPI() {
    this.props.dispatch(getPaises()).then(data => {
      const paises = data.data.paises.map(item => {
        item.id = item.alpha3;
        item.title = item.name;
        return item;
      });

      this.setState({ paisesOptions: paises });
    });
  };

  //Vamos buscar os tipos de lead à API
  getTipoLeadFromAPI() {
    getTypesLead().then(data => {
      this.setState({ tiposLead: data });
    }).catch(e => {
      console.log(e);
    });
  };

  //Vamos buscar os tipos de eventos possiveis
  getEventosPossiveis(leadType) {
    getPossibleEvents(null, leadType).then(data => {
      if (typeof data.success !== 'undefined') {

        if (data.success == true) {
          this.setState({
            eventosPossiveis: data.obj,
            lead: {
              ...this.state.lead,
              id_estado: ""
            }
          });
        }
      }

    }).catch(e => {
      console.log(e);
    });
  }

  //Método para validar os dados da lead
  validarDados(lead) {

    var promise = new Promise(function (resolve, reject) {
      try {
        var validationResult = schema.validate(
          {
            nome: lead.nome,
            email: lead.email,
            telemovel: lead.telemovel,
            pais: lead.id_pais,
            localidade: lead.localidade,
            tipoLead: lead.id_tipo_lead,
            cargoContacto: lead.contacto.cargo,
            telemovelContacto: lead.contacto.telemovel,
            nomeContacto: lead.contacto.nome,
            emailContacto: lead.contacto.email,
            nif: lead.nif,
            idEvento: lead.id_estado,
            valor: lead.valor
          },
          { abortEarly: true });

        //Se error==null significa que não houve erro
        if (validationResult.error == null) {
          resolve();

          // Se houver erro vamos mostrar o erro e mostramos ao utilizadro
        } else {
          var errorMessage = validationResult.error.details[0].message;
          reject(new Error(errorMessage));
        }

      } catch (err) {
        reject(new Error("Por favor preencha todos os campos corretamente"));
      }
    });
    return promise;
  }

  //Método de criar uma nova lead
  novaLead() {

    //Criamos uma cópia do objeto lead
    let lead = { ...this.state.lead };

    //Se contiver , nós substituimos
    if (lead.valor.indexOf(",") >= 0) {
      lead.valor = lead.valor.replace(",", ".");
    }

    this.setState(prevState => ({
      ...prevState,
      isCreating: true
    }
    ));

    //Chamamos o metodo de validar os dados da lead    
    this.validarDados(lead)
      .then(() => {


        //Chamamos o método de validar na API
        var promise = new Promise(function (resolve, reject) {
          newLead(lead).then(data => {
            resolve(data);
          })
            .catch(data => {
              reject(data)
            });
        });
        return promise;
      })
      .then(data => {
        this.setState(prevState => ({
          ...prevState,
          isCreating: false
        }
        ));

        //Caso resposta seja suceso
        if (data.success === true) {
          this.showToast(data.message, "success");
          this.resetState();

          //Caso resposta inválida
        } else {
          this.showToast(data.message, "error");
        }
      })
      .catch(err => {

        this.setState(prevState => ({
          ...prevState,
          isCreating: false
        }
        ));

        this.showToast(err.message, "error");
      })

  };

  //Método de resetar os dados da UI
  resetState() {
    this.setState(prevState => ({
      ...prevState,
      lead: initialStateLead,
      confirmAuthorization: false
    }));
  }

  //Método para mostrar toast
  showToast(message, type) {
    toast.dismiss();

    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-right",
      type: type
    });
  }

  useClientData() {
    if (this.state.lead.id_tipo_lead !== "BB") {
      this.setState(prevState => ({
        lead: {
          ...prevState.lead,
          email: this.state.dadosUtilizador.Detalhes[0].Email,
          nome: this.state.dadosUtilizador.Detalhes[0].NomeCompleto,
          telemovel: this.state.dadosUtilizador.Contactos != null ? this.state.dadosUtilizador.Contactos[0].Contacto ?? "" : "",
          id_pais: this.state.dadosUtilizador.Contactos != null ? this.state.dadosUtilizador.Contactos[0].Pais ?? "PRT" : "PRT",
          localidade: this.state.dadosUtilizador.Detalhes[0].Localidade ?? ""
        }
      }), () => {
        this.verifyEmailIsAssociated();
      })
    }
    else {
      this.setState(prevState => ({
        lead: {
          ...prevState.lead,
          contacto: {
            ...prevState.lead.contacto,
            email: this.state.dadosUtilizador.Detalhes[0].Email,
            nome: this.state.dadosUtilizador.Detalhes[0].NomeCompleto,
            telemovel: this.state.dadosUtilizador.Contactos != null ? this.state.dadosUtilizador.Contactos[0].Contacto ?? "" : ""
          }
        }
      }), () => {
        this.verifyExtraEmail();
      })
    }
  }

  getUser() {
    getLoggedInUser()
      .then(data => {
        if (data.userId != data.pessoaId) {
          this.setState(prevState => ({
            ...prevState,
            utilizadorEmRede: true
          }))
        }
      })
      .then(getClientDetailsByID)
      .then(data => {
        if (data.success === true) {
          this.setState(prevState => ({
            ...prevState,
            dadosUtilizador: data.obj
          }));
        }
      });
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
          <PageTitleAlt4 heading="Nova Lead" subheading="Página onde pode criar Novas Leads" icon="lnr-file-add icon-gradient bg-ripe-malin" />

          <Fragment>
            <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
              <div />
              <Row className="main-card mb-6">
                <Col md="12">
                  <Card className="main-card mb-6">
                    <CardBody>
                      <Form>
                        {/*Row para a informação standard*/}
                        <Row>
                          <Col md={6} sm={12} >
                            {/* DropDown Tipo de Lead*/}
                            <FormGroup>
                              <Label for="exampleSelect">Tipo de Lead</Label>
                              <Input type="select" name="id_tipo_lead" id="id_tipo_lead" value={this.state.lead.id_tipo_lead} onChange={this.handleChange}>
                                <option value="">Tipo de Lead</option>
                                {this.state.tiposLead.length > 0 ? (
                                  this.state.tiposLead.map((lead, j) => {
                                    return (
                                      <option value={lead.Id}>{lead.Descricao} </option>
                                    );
                                  })
                                ) : null}
                              </Input>

                              <Label style={{ color: "#dc3545", marginLeft: "2px", marginTop: "4px", fontSize: "0.7rem" }} hidden={this.state.lead.id_tipo_lead.length > 0 ? true : false}>* Campo obrigatório</Label>
                            </FormGroup>

                            {this.state.utilizadorEmRede ?
                              <FormGroup style={{ textAlign: "center" }}>
                                <Button color="primary" onClick={this.useClientData}>Utilizar dados cliente atual</Button>
                              </FormGroup>
                              : ""
                            }

                            {/* Input para NIF*/}
                            <FormGroup hidden={!this.state.isB2BLead}>
                              <Label for="examplePassword" className="mr-sm-2"> NIF </Label>
                              <Input type="text" name="nif" id="nif" onBlur={this.verifyNifIsAssociated} onChange={this.handleChange} value={this.state.lead.nif} placeholder="NIF" />

                              <Label style={{ color: "#dc3545", marginLeft: "2px", marginTop: "4px", fontSize: "0.7rem" }} hidden={this.state.lead.nif.length > 0 ? true : false}>* Campo obrigatório</Label>

                              {/* Mostrar se NIF é inválido*/}
                              <FormGroup hidden={!this.state.nifIsVerified} style={{ display: "flex", flexWrap: "nowrap", margin: "10px 0px 0px 0px", alignItems: "center", justifyContent: "start" }}>
                                <Label style={{ color: (this.state.nifError == false ? "green" : "#dc3545"), fontSize: "25px", marginRight: "4px", padding: "0" }} className={this.state.nifError == false ? "lnr-checkmark-circle" : "lnr-cross-circle"}></Label>
                                <Label style={{ color: (this.state.nifError == false ? "green" : "#dc3545"), fontWeight: "400" }}>{this.state.nifMessage}</Label>
                              </FormGroup>

                            </FormGroup>

                            {/* Input para Email*/}
                            <FormGroup>
                              <Label for="examplePassword" className="mr-sm-2"> Email </Label>
                              <Input required type="text" name="email" id="email" onBlur={this.verifyEmailIsAssociated} onChange={this.handleChange} value={this.state.lead.email} placeholder="Email" />

                              <Label style={{ color: "#dc3545", marginLeft: "2px", marginTop: "4px", fontSize: "0.7rem" }} hidden={this.state.lead.email.trim().length > 0 ? true : false}>* Campo obrigatório</Label>

                              {/* Mostrar se email é inválido*/}
                              <FormGroup hidden={!this.state.emailIsVerified} style={{ display: "flex", flexWrap: "nowrap", margin: "10px 0px 0px 0px", alignItems: "center", justifyContent: "start" }}>
                                <Label style={{ color: (this.state.emailError == false ? "green" : "#dc3545"), fontSize: "25px", marginRight: "4px", padding: "0" }} className={this.state.emailError == false ? "lnr-checkmark-circle" : "lnr-cross-circle"}></Label>
                                <Label style={{ color: (this.state.emailError == false ? "green" : "#dc3545"), fontWeight: "400" }}>{this.state.emailMessage}</Label>
                              </FormGroup>

                            </FormGroup>

                            {/* Input para Nome*/}
                            <FormGroup>
                              <Label for="examplePassword" className="mr-sm-2"> Nome</Label>
                              <Input type="text" name="nome" id="nome" onChange={this.handleChange} value={this.state.lead.nome} placeholder="Nome" />

                              <Label style={{ color: "#dc3545", marginLeft: "2px", marginTop: "4px", fontSize: "0.7rem" }} hidden={this.state.lead.nome.length > 0 ? true : false}>* Campo obrigatório</Label>
                            </FormGroup>

                            {/* Input para Telemovel*/}
                            <FormGroup>
                              <Label for="examplePassword" className="mr-sm-2">Telemóvel</Label>
                              <Input type="text" name="telemovel" id="telemovel" value={this.state.lead.telemovel} onChange={this.handleChange} placeholder="Telemóvel" />

                              <Label style={{ color: "#dc3545", marginLeft: "2px", marginTop: "4px", fontSize: "0.7rem" }} hidden={this.state.lead.telemovel.length > 0 ? true : false}>* Campo obrigatório</Label>
                            </FormGroup>
                          </Col>
                          <Col md={6} sm={12} >

                            {/* DropDown para País */}
                            <FormGroup>
                              <Label for="examplePassword" className="mr-sm-2"> País </Label>
                              <Input type="select" name="id_pais" id="id_pais" onBlur={this.onFocusOutVerify} onChange={this.handleChange} value={this.state.lead.id_pais}>
                                <option value="">País</option>
                                {this.state.paisesOptions.length > 0 ?
                                  (this.state.paisesOptions.map((address, j) => {
                                    return (
                                      <option value={address.id}>
                                        {address.title}
                                      </option>
                                    );
                                  })
                                  ) : null}
                              </Input>

                              <Label style={{ color: "#dc3545", marginLeft: "2px", marginTop: "4px", fontSize: "0.7rem" }} hidden={this.state.lead.id_pais.length > 0 ? true : false}>* Campo obrigatório</Label>
                            </FormGroup>

                            {/* Input para localidade */}
                            <FormGroup>
                              <Label for="examplePassword" className="mr-sm-2"> Localidade </Label>
                              <Input type="text" name="localidade" id="localidade" value={this.state.lead.localidade} onChange={this.handleChange} placeholder="Localidade" />

                              <Label style={{ color: "#dc3545", marginLeft: "2px", marginTop: "4px", fontSize: "0.7rem" }} hidden={this.state.lead.localidade.length > 0 ? true : false}>* Campo obrigatório</Label>
                            </FormGroup>

                            {/* Dropdown para estado */}
                            <FormGroup>
                              <Label for="examplePassword" className="mr-sm-2">Estado da Lead</Label>
                              <Input type="select" name="id_estado" id="id_estado" value={this.state.lead.id_estado} onChange={this.handleChange} placeholder="Estado">
                                <option value="">Estado</option>
                                {this.state.eventosPossiveis.length > 0 ? (
                                  this.state.eventosPossiveis.map((evento, j) => {
                                    return (
                                      <option value={evento.Id}>
                                        {evento.Descricao}
                                      </option>
                                    );
                                  })
                                ) : null}
                              </Input>

                              <Label style={{ color: "#dc3545", marginLeft: "2px", marginTop: "4px", fontSize: "0.7rem" }} hidden={this.state.lead.id_estado.length > 0 ? true : false}>* Campo obrigatório</Label>
                            </FormGroup>

                            {/* Input para Valor Previsto */}
                            <FormGroup>
                              <Label for="examplePassword" className="mr-sm-2"> Valor Previsto </Label>
                              <Input type="number" name="valor" id="valor" value={this.state.lead.valor} onChange={this.handleChange} placeholder="Valor Previsto" />
                            </FormGroup>
                            {/* Input para observações */}
                            <FormGroup>

                              <Label for="examplePassword" className="mr-sm-2"> Observações </Label>
                              <Input type="textarea" rows={2} name="observacoes" id="observacoes" value={this.state.lead.observacoes} onChange={this.handleChange} placeholder="Observações" />
                            </FormGroup>

                          </Col>
                        </Row>

                        {/* Row para a informação extra*/}
                        <Row style={{ border: "0.5px solid #707070", borderRadius: "10px", margin: "5px", padding: "10px" }} hidden={!this.state.isB2BLead}>
                          <Col md={12} sm={12}>
                            <Label style={{ fontSize: "18px" }}>Informações Contacto para Empresa:</Label>
                          </Col>
                          <Col md={6} sm={12}>
                            {/* Input para Nome Contacto*/}
                            <FormGroup>
                              <Label for="examplePassword" className="mr-sm-2"> Nome </Label>
                              <Input type="text" name="nome" id="nome" required onChange={this.handleChangeInContact} value={this.state.lead.contacto.nome} placeholder="Nome" />

                              <Label style={{ color: "#dc3545", marginLeft: "2px", marginTop: "4px", fontSize: "0.7rem" }} hidden={this.state.lead.contacto.nome.length > 0 ? true : false}>* Campo obrigatório</Label>
                            </FormGroup>

                            {/* Input para Email Contacto*/}
                            <FormGroup>
                              <Label for="examplePassword" className="mr-sm-2"> Email </Label>
                              <Input type="text" name="email" id="email" onChange={this.handleChangeInContact} value={this.state.lead.contacto.email} onBlur={this.verifyExtraEmail} placeholder="Email" />

                              <Label style={{ color: "#dc3545", marginLeft: "2px", marginTop: "4px", fontSize: "0.7rem" }} hidden={this.state.lead.contacto.email.length > 0 ? true : false}>* Campo obrigatório</Label>

                              <FormGroup hidden={!this.state.extraEmailIsVerified} style={{ display: "flex", flexWrap: "nowrap", margin: "10px 0px 0px 0px", alignItems: "center", justifyContent: "start" }}>
                                <Label style={{ color: (this.state.extraEmailError == false ? "green" : "#dc3545"), fontSize: "25px", marginRight: "4px", padding: "0" }} className={this.state.extraEmailError == false ? "lnr-checkmark-circle" : "lnr-cross-circle"}></Label>
                                <Label style={{ color: (this.state.extraEmailError == false ? "green" : "#dc3545"), fontWeight: "400" }}>{this.state.extraEmailMessage}</Label>
                              </FormGroup>
                            </FormGroup>


                          </Col>
                          <Col md={6} sm={12}>


                            {/* Input para Telemóvel Contacto*/}
                            <FormGroup>
                              <Label for="examplePassword" className="mr-sm-2"> Telemóvel </Label>
                              <Input type="text" name="telemovel" id="telemovel" onChange={this.handleChangeInContact} value={this.state.lead.contacto.telemovel} placeholder="Telemóvel" />

                              <Label style={{ color: "#dc3545", marginLeft: "2px", marginTop: "4px", fontSize: "0.7rem" }} hidden={this.state.lead.contacto.telemovel.length > 0 ? true : false}>* Campo obrigatório</Label>
                            </FormGroup>

                            {/* Dropdown para Cargo Contacto*/}
                            <FormGroup>
                              <Label for="examplePassword" className="mr-sm-2"> Cargo </Label>
                              <Input type="select" name="cargo" id="cargo" onChange={this.handleChangeInContact} value={this.state.lead.contacto.cargo} placeholder="Cargo">
                                <option value="">Cargo</option>
                                {this.state.cargoPossiveis.length > 0 ?
                                  (this.state.cargoPossiveis.map((cargo, j) => {
                                    return (
                                      <option value={cargo.Id}>
                                        {cargo.Descricao}
                                      </option>
                                    );
                                  })
                                  ) : null}
                              </Input>

                              <Label style={{ color: "#dc3545", marginLeft: "2px", marginTop: "4px", fontSize: "0.7rem" }} hidden={this.state.lead.contacto.cargo.length > 0 ? true : false}>* Campo obrigatório</Label>
                            </FormGroup>
                          </Col>
                        </Row>

                        {/* Row para os botões de confirmação e de criar lead */}
                        <Row style={{ display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center", marginBottom: "10px" }}>
                          <Col md={12} sm={12}>
                            <FormGroup check style={{ marginTop: "20px", marginBottom: "0px" }}>
                              <Label check>
                                <Input type="checkbox" onChange={this.handleClientAccepted} checked={this.state.confirmAuthorization} />{' '}Confirmo que o cliente autorizou o uso dos dados
                              </Label>
                            </FormGroup>
                            <br />

                            <Row style={{ display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center" }}>
                              <Col md={1} lg={1} sm={12} xs={12}>
                                {/* Botão para adicionar a lead*/}
                                <FormGroup style={{ marginBottom: "0px" }}>
                                  <Button color="primary" disabled={
                                    (this.state.isCreating === true
                                      || this.state.lead.id_tipo_lead.length <= 0
                                      || this.state.lead.nome.length <= 0
                                      || this.state.lead.telemovel.length <= 0
                                      || this.state.lead.id_pais.length <= 0
                                      || this.state.lead.localidade.length <= 0
                                      || this.state.lead.id_estado.length <= 0
                                      || this.state.emailError == true
                                      || this.state.confirmAuthorization == false
                                      || (this.state.isB2BLead == true) &&
                                      (this.state.lead.contacto.nome.length <= 0
                                        || this.state.extraEmailError == true
                                        || this.state.lead.contacto.cargo.length <= 0
                                        || this.state.lead.contacto.telemovel.length <= 0
                                        || this.state.nifError == true))
                                      ? true
                                      : false
                                  } onClick={this.novaLead}> Criar Lead </Button>
                                </FormGroup>
                              </Col>
                              <br />
                              <Col>
                                {this.state.isCreating === true ?
                                  <div>
                                    <label style={{ fontWeight: "600", fontStyle: "italic", marginRight: "10px" }}>A criar Lead...</label>
                                    <CircularProgress thickness={2} size={25} disableShrink />
                                  </div>
                                  : null}
                              </Col>
                            </Row>

                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ReactCSSTransitionGroup>
          </Fragment>
        </ReactCSSTransitionGroup>
      </Fragment >
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(NovaLead);

