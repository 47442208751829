import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import { UncontrolledTooltip } from "reactstrap";
import { comparativeMonthly } from "../../../actions/comissoesActions";
import ArrowStatus from "../../../helpers/ArrowStatus/ArrowStatus";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class ComparativoMensal extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0
    };
  }

  componentDidMount() {
    comparativeMonthly().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "Ano - Mês",
            accessor: "Data",
            resizable: false,
            Cell: row => {
              return <span>{row.original.Ano}</span>;
            },
            style: { display: "grid", textAlign: "center" }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-npc">NPC</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-npc"}
                  >
                    NetPoints Cliente
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NPC",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusNPC} />
                  ) : null}
                  &nbsp;&nbsp;
                  {row.original.NPC}
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-NPP">NPP</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-NPP"}
                  >
                    NetPoints Pessoal
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NPP",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusNPP} />
                  ) : null}
                  &nbsp;&nbsp;
                  {row.original.NPP}
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-NPR">NPR</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-NPR"}
                  >
                    NetPoints Rede
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NPR",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusNPR} />
                  ) : null}
                  &nbsp;&nbsp;
                  {row.original.NPR}
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-CRD">CRD</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-CRD"}
                  >
                    Créditos
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "CRD",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusCRD} />
                  ) : null}
                  &nbsp;&nbsp;
                  {row.original.CRD}
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VCM">VCM</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VCM"}
                  >
                    Valor Comissão
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "VCM",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusVCM} />
                  ) : null}
                  &nbsp;&nbsp;
                  <FormattedNumber
                    value={row.original.VCM}
                    style="currency"
                    currency="EUR"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-NEN">NEN</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-NEN"}
                  >
                    Nr. Encomendas
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NEN",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusNEN} />
                  ) : null}
                  &nbsp;&nbsp;
                  {row.original.NEN}
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VEN">VEN</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VEN"}
                  >
                    Valor Encomendas
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "VEN",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusVEN} />
                  ) : null}
                  &nbsp;&nbsp;
                  <FormattedNumber
                    value={row.original.VEN}
                    style="currency"
                    currency="EUR"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-NPT">NPT</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-NPT"}
                  >
                    NetPoints Total
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NPT",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusNPT} />
                  ) : null}
                  &nbsp;&nbsp;
                  {row.original.NPT}
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-TBG">TBG</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-TBG"}
                  >
                    Taxa Bonús Grupo
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "TBG",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return row.original.TBG ? (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusTBG} />
                  ) : null}
                  &nbsp;&nbsp;
                  <FormattedNumber
                    value={row.original.TBG / 100}
                    style="percent"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </span>
              ) : (
                "0.00%"
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VBG">VBG</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VBG"}
                  >
                    Valor Bonús Grupo
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "VBG",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusVBG} />
                  ) : null}
                  &nbsp;&nbsp;
                  <FormattedNumber
                    value={row.original.VBG}
                    style="currency"
                    currency="EUR"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-NRT">NRT</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-NRT"}
                  >
                    Nr. Redes Top
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NRT",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusNRT} />
                  ) : null}
                  &nbsp;&nbsp;
                  {row.original.NRT}
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-TBL">TBL</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-TBL"}
                  >
                    Taxa Bonús Lider
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "TBL",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusTBL} />
                  ) : null}
                  &nbsp;&nbsp;
                  <FormattedNumber
                    value={row.original.TBL / 100}
                    style="percent"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VBLD">VBLD</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VBLD"}
                  >
                    Valor Bonús Lider Dream
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "VBLD",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusVBLD} />
                  ) : null}
                  &nbsp;&nbsp;
                  <FormattedNumber
                    value={row.original.VBLD}
                    style="currency"
                    currency="EUR"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VBL">VBL</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VBL"}
                  >
                    Valor Bonús Lider
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "VBL",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusVBL} />
                  ) : null}
                  &nbsp;&nbsp;
                  <FormattedNumber
                    value={row.original.VBL}
                    style="currency"
                    currency="EUR"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-NLB">NLB</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-NLB"}
                  >
                    Nr. Leader Builder
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "NLB",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusNLB} />
                  ) : null}
                  &nbsp;&nbsp;
                  {row.original.NLB}
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VLB">VLB</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VLB"}
                  >
                    Valor Leader Builder
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "VLB",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusVLB} />
                  ) : null}
                  &nbsp;&nbsp;
                  <FormattedNumber
                    value={row.original.VLB}
                    style="currency"
                    currency="EUR"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </span>
              );
            }
          },
          {
            Header: () => {
              return (
                <div>
                  <span id="Tooltip-VTL">VTL</span>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={"Tooltip-VTL"}
                  >
                    Valor Total
                  </UncontrolledTooltip>
                </div>
              );
            },
            accessor: "VTL",
            resizable: false,
            style: { display: "grid", textAlign: "center" },
            Cell: row => {
              return (
                <span>
                  {row.index === 0 ? (
                    <ArrowStatus status={row.original.ArrowStatusVTL} />
                  ) : null}
                  &nbsp;&nbsp;
                  <FormattedNumber
                    value={row.original.VTL}
                    style="currency"
                    currency="EUR"
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                  />
                </span>
              );
            }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }
  render() {
    return (
      <SimpleTable
        data={this.state.data}
        columns={this.state.columns}
        blocking={this.state.blocking}
        rowNumber={this.state.rowNumber}
        dataSize={this.state.dataSize}
        noDataText={"Não tem comissões disponiveis"}
        title={"Comparativo mensal"}
      />
    );
  }
}

export default ComparativoMensal;
