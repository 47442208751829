import React, { Component } from "react";
import CountUp from "react-countup";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { clientsWithNoOrders } from "../../../actions/indicadoresActions";
import { alterarDadosPesquisa } from "../../../actions/pesquisaActions";

class NumeroClientesSemEncomendas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberClientes: 0
    };
  }

  componentDidMount() {
    clientsWithNoOrders().then(data => {
      if (data.clientes && !data.message) {
        this.setState({ numberClientes: data.clientes[0].numero });
      }
    });
  }
  render() {
    const { user, upline } = this.props;
    return (
      <Col
        md="4"
        onClick={() => {
          alterarDadosPesquisa({
            Clientesemencomendas: "1",
            ID_ClienteRecomendacao: upline.ID_Cliente ? upline.ID_Cliente : user.ID_Cliente
          });
          window.location = "/pesquisa";
        }}
      >
        <div className="card mb-3 widget-chart">
          <div className="icon-wrapper rounded-circle">
            <div className="icon-wrapper-bg bg-primary" />
            <i className="lnr-users text-primary" />
          </div>
          <div className="widget-numbers">
            <CountUp start={0} end={this.state.numberClientes} duration="3" />
          </div>
          <div className="widget-subheading">Clientes diretos que nunca encomendaram</div>
        </div>
      </Col>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user,
  upline: state.upline,
  pesquisa: state.pesquisa
});

export default connect(mapStateToProps)(NumeroClientesSemEncomendas);
