import React, { Component } from "react";
import { FormattedDate } from "react-intl";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import EditarObservacao from "./EditarObservacao";
import ObservacaoTool from "./ObservacaoTool/ObservacaoTool";

class Observacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editObservacaoState: false
    };
  }

  componentDidMount() {}

  observacaoToolState = async e => {
    this.setState({
      observacaoToolState: !this.state.observacaoToolState
    });
  };

  changeEditObservacaoState = async e => {
    this.setState({
      editObservacaoState: !this.state.editObservacaoState
    });
  };

  render() {
    return (
      <Card
        className="main-card mb-3"
        style={{ display: this.props.addButtonState ? "initial" : "none" }}
        onMouseEnter={this.observacaoToolState}
        onMouseLeave={this.observacaoToolState}
      >
        <CardBody>
          <CardTitle>
            <Row>
              <Col lg={6}>Observação</Col>
              <Col lg={6} style={{ textAlign: "right" }}>
                <ObservacaoTool
                  observacaoToolState={this.state.observacaoToolState}
                  updateObservacoes={this.props.updateObservacoes}
                  changeEditObservacaoState={this.changeEditObservacaoState}
                  contactID={this.props.contactID}
                  ID_Observacao={this.props.ID_ObservacaoContacto}
                />
                <FormattedDate value={new Date(this.props.DataCriacao)} />
              </Col>
            </Row>
          </CardTitle>
          <Row>
            <Col
              lg={12}
              style={{
                display: !this.state.editObservacaoState ? "initial" : "none"
              }}
            >
              {this.props.Descricao}
            </Col>
            <EditarObservacao
              changeEditObservacaoState={this.changeEditObservacaoState}
              updateObservacoes={this.props.updateObservacoes}
              descricao={this.props.Descricao}
              contactID={this.props.contactID}
              ID_Observacao={this.props.ID_ObservacaoContacto}
              editObservacaoState={!this.state.editObservacaoState}
            />
          </Row>
          <br />
          <Row>
            <Col lg={12}>{this.props.DataCriacao} deixou uma observação</Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default Observacao;
