import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from "react-dom";
import { addLocaleData, IntlProvider } from "react-intl";
import locale_pt from "react-intl/locale-data/pt";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { checkLogin } from "./actions/userActions";
import App from "./App/App";
import "./assets/base.scss";
import "./index.css";
import reducer from "./reducers/index";
import * as serviceWorker from "./serviceWorker";

const store = createStore(reducer, applyMiddleware(thunk));
const rootElement = document.getElementById("root");

addLocaleData([...locale_pt]);
checkLogin().then(user => {
  ReactDOM.render(
    <Provider store={store}>
      <IntlProvider locale={"PT"} timeZone="UTC">
        <BrowserRouter>
          <App userID={user.ID_Cliente} userType={user.ID_TipoCliente} />
        </BrowserRouter>
      </IntlProvider>
    </Provider>,
    rootElement
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
