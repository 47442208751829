import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./Payment.css";


class PaymentMB extends Component {

  render() {
    const { currencies } = this.props;
    return (
      <div>
        <Row className="payment">
          <Col
            xs={12} sm={12} md={6} lg={6}
            style={{
              textAlign: isMobile ? "center" : "right",
              margin: "auto",
              top: "0px",
              bottom: "0px"
            }}
          >
            <img src="https://shop.ihavethepower.net/Content/imagens/emails/multibanco.png" className="logoPayment" alt="" style={{ width: "34%" }}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}
            style={{
              textAlign: isMobile ? "center" : "left",
              margin: "auto",
              top: "0px",
              bottom: "0px"
            }}
          >
            <p><b>Entidade:</b> {this.props.entidade}</p>
            <p><b>Referência:</b> {this.props.referencia}</p>
            <p>
              <b>Valor: </b>
              <FormattedNumber
                value={this.props.total}
                style="currency"
                currency={this.props.currency.toUpperCase()}
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
              {/* {this.props.total} {this.props.currency.toUpperCase()} */}
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currencies: state.currencies
  };
};

export default withRouter(connect(mapStateToProps)(PaymentMB));
