import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { FormattedDate } from "react-intl";
import { connect } from "react-redux";
import { birthdays } from "../../../actions/homeActions";
import { changeUser, getContacts } from "../../../actions/userActions";
import ContactosCliente from "../../../helpers/ContactosCliente";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class Aniversarios extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0,
      contactos: []
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    birthdays().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "",
            width: 45,
            Cell: row => {
              return (
                <span
                  onClick={() => {
                    this.getUserContacts(row.original.ID_Cliente);
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                </span>
              );
            }
          },
          {
            Header: "Nome",
            accessor: "nome",
            resizable: false,
            Cell: row => {
              return (
                <span  style={{cursor:"pointer"}}
                onClick={() => {
                  this.props
                    .dispatch(changeUser(row.original.ID_Cliente))
                    .then(() => {
                      window.location.href = "/";
                    });
                }}
                >
                  {row.original.nome}
                </span>
              );
            },
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Idade",
            accessor: "anos",
            resizable: false,
            width: 100,
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Data Nascimento",
            accessor: "dataDeNascimento",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.dataDeNascimento ? (
                <FormattedDate
                  value={new Date(row.original.dataDeNascimento)}
                />
              ) : (
                "-"
              );
            }
          },
          {
            Header: "Nome Recomendação",
            accessor: "nomeRecomendacao",
            resizable: false,
            style: { display: "block", textAlign: "center" }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  getUserContacts(id) {
    getContacts(id).then(data => {
      if (data && !data.message) {
        this.setState({ contactos: data });
        this.toggle();
      }
    });
  }
  render() {
    return (
      <span>
        <ContactosCliente
          onRef={ref => (this.child = ref)}
          isOpen={this.state.modal}
          toggle={this.toggle}
          contactos={this.state.contactos}
        />
        <SimpleTable
          data={this.state.data}
          columns={this.state.columns}
          blocking={this.state.blocking}
          rowNumber={this.state.rowNumber}
          dataSize={this.state.dataSize}
          noDataText={"Sem aniversários para apresentar"}
          title={"Aniversários da sua rede"}
        />
      </span>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(Aniversarios);
