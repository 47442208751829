import React, { Component } from "react";
import LaddaButton, { EXPAND_RIGHT } from "react-ladda";
import { Slide, toast } from "react-toastify";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { editContactComment } from "../../../../../actions/contactosActions";

class EditarObservacao extends Component {
  state = {
    expRight: false
  };

  constructor(props) {
    super(props);

    this.state = {
      descricao: "",
      cSelected: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  toggle(name) {
    this.setState({
      [name]: !this.state[name],
      progress: 0.5
    });
  }

  componentDidMount() {
    this.setState({ descricao: this.props.descricao });
  }

  handleChange(e) {
    let value = e.target.value;
    this.setState({ descricao: value });
  }

  editarObservacao = async e => {
    // Inserir uma nova observação no contacto
    editContactComment(
      this.props.contactID,
      this.props.ID_Observacao,
      this.state.descricao
    ).then(data => {
      if (data.message === "Observação editada com sucesso") {
        toast(data.message, {
          transition: Slide,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-right",
          type: "success"
        });
        this.props.updateObservacoes(this.props.contactID);
        this.props.changeEditObservacaoState();
      } else {
        toast(data.message, {
          transition: Slide,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-right",
          type: "error"
        });
      }
    });
  };

  render() {
    return (
      <Col
        lg={12}
        style={{
          display: !this.props.editObservacaoState ? "initial" : "none"
        }}
      >
        <FormGroup>
          <Row>
            <Col lg={12}>
              <Input
                type="textarea"
                name="descricao"
                value={this.state.descricao}
                onChange={this.handleChange}
                style={{ fontSize: "14px", color: "#0091ae" }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col lg={12} style={{ textAlign: "right" }}>
              <LaddaButton
                className="mb-2 mr-2 btn btn-outline-success"
                loading={this.state.expRight}
                onClick={() => {
                  this.toggle("expLeft");
                  this.editarObservacao();
                }}
                data-style={EXPAND_RIGHT}
              >
                Guardar
              </LaddaButton>
              <Button outline className="mb-2" color="danger">
                Cancelar
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </Col>
    );
  }
}

export default EditarObservacao;
