export function getOrdersWhereProductWhereBought(productId, productName, orderState, initDate, endDate, networkDistance, currentPage, pageSize, sortOrder) {
    return new Promise((resolve, reject) => {
        return fetch("/order/ordersSpecificProductsBought", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                productId: productId,
                productName: productName,
                orderState: orderState,
                initDate: initDate,
                endDate: endDate,
                networkDistance: networkDistance,
                currentPage: currentPage,
                pageSize: pageSize,
                sortOrder: sortOrder
            }),
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}

export function getPossibleStates() {
    return new Promise((resolve, reject) => {
        return fetch("/order/possibleStates", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },            
            credentials: "include"
        })
            .then(res => res.json())
            .then(json => {
                resolve(json);
            })
            .catch(function (error) {
                reject(error);
            });
    });
}