import React, { Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { withRouter } from "react-router-dom";

import ResizeDetector from "react-resize-detector";

import Marketing from "../../../components/Marketing/Marketing";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";

class LeadsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false
    };
  }

  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt
    } = this.props;

    return (
      <ResizeDetector
        handleWidth
        render={({ width }) => (
          <Fragment>
            <div
              className={cx(
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                { "fixed-footer": enableFixedFooter },
                { "closed-sidebar": enableClosedSidebar || width < 1250 },
                {
                  "closed-sidebar-mobile": closedSmallerSidebar || width < 1250
                },
                { "sidebar-mobile-open": enableMobileMenu },
                { "body-tabs-shadow-btn": enablePageTabsAlt }
              )}
            >
              <Header />
              <div className="app-main">
                <Sidebar />
                <div className="app-main__outer">
                  <div className="app-main__inner">
                    <Marketing />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProp = state => ({
  colorScheme: state.colorScheme,
  enableFixedHeader: state.enableFixedHeader,
  enableMobileMenu: state.enableMobileMenu,
  enableFixedFooter: state.enableFixedFooter,
  enableFixedSidebar: state.enableFixedSidebar,
  enableClosedSidebar: state.enableClosedSidebar,
  enablePageTabsAlt: state.enablePageTabsAlt
});

export default withRouter(connect(mapStateToProp)(LeadsView));
