import { Fragment, useEffect, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Col, Row } from "react-bootstrap";
import { FormattedNumber } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { clearCart, fetchCart } from "../../../actions/cartActions";
import { getProductDetailsWP } from "../../../actions/productActions";
import GoogleAnalytics, { GoogleAnalyticsProduct } from "../../../helpers/googleAnalytics";
import Toast from "../../../helpers/Toast";
import Loading from "../../Loading/Loading";
import CartIsEmpty from "./CartIsEmpty";
import CartItem from "./CartItem";
import ErrorLoadingCart from "./ErrorLoadingCart";
import TrashIcon from "./TrashIcon";

interface ProductDetailsWPDTO {
    priceToOtherUsers: number,
    price: number,
    priceWithoutDiscount: number,
    discountValue: number,
    discountPercentage: number,
    currency: string,
    id: number,
    outOfStock: boolean,
    mayAskToBeNotified: boolean,
    alreadyRequestNotification: boolean,
    notificationHash: string,
    netpoints: number,
    credits: number,
    initDate: Date,
    endDate: Date,
    promotionInitDate: Date,
    promotionEndDate: Date,
    description: string,
    productType: string,
    packType: string
}

export default function ProdutosCarrinho() {
    const [display, setDisplay] = useState<string>("block");
    const [show, setShow] = useState<string>("none");

    const cart = useSelector((state: any) => state.cart);
    const isLoadingCart = useSelector((state: any) => state.loadingCart);
    const cartLoadError = useSelector((state: any) => state.cartLoadError);
    const upline = useSelector((state: any) => state.upline); //Se estiver no seu user, aparece a sua informação. Se estiver num cliente em rede aparece a informação desse cliente
    const errorLoadingCart = cartLoadError != null && cartLoadError.length > 0;
    const removeCartError = useSelector((state: any) => state.cartRemoveError);

    const isCartEmpty = !((cart.Carrinho || cart.CarrinhoPack) && (cart.Carrinho.length > 0 || cart.CarrinhoPack.length > 0));

    const dispatch = useDispatch();

    const googleAnalytics: GoogleAnalytics = new GoogleAnalytics();

    const getProdutosCarrinho = () => {
        dispatch(fetchCart());
    }

    const removeAllCart = () => {
        dispatch(clearCart());
        removeFromGoogleAnalytics(cart);

        //É necessário para garantir que o evento view_cart é colocado no datalayer depois de remover
        setTimeout(async () => {
            SendCartToGA()
        }, 500)
    }

    const removeFromGoogleAnalytics = (cart: any) => {
        //Remover produtos
        if (typeof cart.Carrinho !== 'undefined' && cart.Carrinho !== null && cart.Carrinho.length > 0) {
            cart.Carrinho.forEach(async item => {
                var productFromDb = await getProductInfo(item.ID_Produto, item.ID_TipoProduto);

                var product: GoogleAnalyticsProduct = {
                    ID_Produto: item.ID_Produto,
                    ID_Pack: null,
                    Descricao: item.Descricao,
                    ID_TipoProduto: item.ID_TipoProduto,
                    ID_TipoPack: null,
                    Quantidade: item.Quantidade,
                    Preco: (productFromDb?.price ?? 0) + ""
                }
                googleAnalytics.RemoveFromCart(product);
                googleAnalytics.RemoveFromCart_Old(product);
            });
        };

        //Remover packs
        if (typeof cart.CarrinhoPack !== 'undefined' && cart.CarrinhoPack !== null && cart.CarrinhoPack.length > 0) {
            cart.CarrinhoPack.forEach(async item => {

                var productFromDb = await getProductInfo(item.ID_Pack, item.ID_TipoPack);

                var product: GoogleAnalyticsProduct = {
                    ID_Produto: null,
                    ID_Pack: item.ID_Pack,
                    Descricao: item.Descricao,
                    ID_TipoProduto: null,
                    ID_TipoPack: item.ID_TipoPack,
                    Quantidade: item.Quantidade,
                    Preco: (productFromDb?.price ?? 0) + ""
                }
                googleAnalytics.RemoveFromCart(product);
                googleAnalytics.RemoveFromCart_Old(product);
            });
        };
    }

    const getProductInfo = (id_item, tipo_item): Promise<ProductDetailsWPDTO | null> => {
        return new Promise((resolve) => {
            getProductDetailsWP(id_item, tipo_item).then(result => {
                if (result.success === true) {
                    resolve(result.obj);
                    return;
                }
            });
        })
    };

    const SendCartToGA = async () => {
        var data: any = await dispatch(fetchCart());
        if (data.success === true) {
            googleAnalytics.SendCartToGA(data.obj);
        };
    }

    useEffect(() => {
        var loadData = new Promise(async () => {
            getProdutosCarrinho();

            SendCartToGA();
        })

        loadData;
    }, [])


    useEffect(() => {
        if (typeof removeCartError !== 'undefined' && removeCartError !== null && removeCartError !== "") {
            Toast.Show("error", removeCartError);
        }
    }, [removeCartError])

    return (
        <>
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}
                >
                    <Card className="main-card mb-3">
                        <CardHeader className="card-header-tab">
                            <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">
                                    {" "}
                                </i>
                                {"Carrinho"}
                            </div>
                            <div className="btn-actions-pane-right">
                                <Button
                                    className="mr-2 btn-icon btn-icon-only"
                                    outline
                                    color="danger"
                                    onClick={() => {
                                        setDisplay("none");
                                        setShow("initial");
                                    }}
                                    style={{ display: display }}
                                >
                                    <i
                                        className="lnr-circle-minus"
                                        style={{ display: display }}
                                        onClick={() => {
                                            setDisplay("none");
                                            setShow("initial");
                                        }}
                                    />
                                </Button>
                                <Button
                                    className="mr-2 btn-icon btn-icon-only"
                                    outline
                                    color="success"
                                    onClick={() => {
                                        setDisplay("initial");
                                        setShow("none");
                                    }}
                                    style={{ display: show }}
                                >
                                    <i
                                        className="lnr-plus-circle"
                                        style={{ display: show }}
                                        onClick={() => {
                                            setDisplay("initial");
                                            setShow("none");
                                        }}
                                    />
                                </Button>
                            </div>
                        </CardHeader>
                        <CardBody style={{ display: display }}>
                            <div className="cartContainer">
                                <div className="cartGridContainer">
                                    {/* Verificar se o Carrinho, está vazio e mostrar mensagem correspondente*/}
                                    {errorLoadingCart == false && isLoadingCart == false && isCartEmpty === true ? (
                                        <div className="cartIsEmptyContainer">
                                            <CartIsEmpty />
                                        </div>
                                    ) : (
                                        <div className="cartItemsContainer">
                                            {isLoadingCart === true && errorLoadingCart === false ? (
                                                <Loading />
                                            ) : (
                                                <div>
                                                    {/* Apresentar Produtos no Carrinho */}
                                                    {errorLoadingCart === false && cart.Carrinho && cart.Carrinho.length > 0 && cart.Carrinho.map((item, j) => {
                                                        return <CartItem currency={upline.ID_Moeda} key={item.ID_Produto} item={item} canUpdateQuantity={true} canDelete={true} isOnCheckout={false} />
                                                    })
                                                    }

                                                    {/* Apresentar Packs no Carrinho */}
                                                    {errorLoadingCart === false && cart.CarrinhoPack && cart.CarrinhoPack.length > 0 && cart.CarrinhoPack.map((item, j) => {
                                                        return <CartItem currency={upline.ID_Moeda} key={item.ID_Pack} item={item} canUpdateQuantity={true} canDelete={true} isOnCheckout={false} />
                                                    })
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    <div className="cartPossibleActionsBeforeCheckout">
                                        {errorLoadingCart === false && isCartEmpty === false && (
                                            <div className="cartPossibleActionsBeforeCheckoutContainer">
                                                <div className="cartPossibleActionTrash">
                                                    <div style={{ float: "right", minWidth: "100%" }}>
                                                        <Button aria-label="Decrement" className="cartRemoveAllItems" color="primary"
                                                            onClick={() => { removeAllCart(); }}>
                                                            <TrashIcon color="white" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    {errorLoadingCart == true && (
                                        <div className="cartErrorMessage">
                                            <ErrorLoadingCart errorMessage={cartLoadError} />
                                        </div>
                                    )}
                                </div >


                                {/* Linha para mostrar o total e o que pagar em DESKTOP */}
                                {errorLoadingCart == false && isCartEmpty === false && (
                                    <div className="cartBottomCheckoutParent">
                                        <div className="cartBottomCheckoutContainer">
                                            <Row style={{ width: "100%", margin: "0px", backgroundColor: "white" }}>
                                                <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                                                    <div>
                                                        <div className="center" style={{ backgroundColor: "white" }}>

                                                            {/* Total */}
                                                            <div>
                                                                {/* Total label */}
                                                                <span>
                                                                    Total{": "}
                                                                </span>

                                                                {/* Total value */}
                                                                <span style={{ fontSize: "20px" }}>
                                                                    <FormattedNumber value={cart.Total} style="currency" currency={upline.ID_Moeda} minimumFractionDigits={2} maximumFractionDigits={2} />
                                                                </span>
                                                            </div>

                                                            {/* Poupou */}
                                                            {cart.Descontos && cart.Descontos > 0 ? (
                                                                <div style={{ color: "green", fontWeight: "400" }}>

                                                                    {/* Poupou label */}
                                                                    <span style={{ fontSize: "18px" }}>
                                                                        Poupou{": "}
                                                                    </span>

                                                                    {/* Poupou value */}
                                                                    <span style={{ fontSize: "18px" }}>
                                                                        <FormattedNumber value={cart.Descontos} style="currency" currency={upline.ID_Moeda} minimumFractionDigits={2} maximumFractionDigits={2} />
                                                                    </span>
                                                                </div>
                                                            ) : null}

                                                            {/* Créditos ou NetPoints*/}
                                                            {upline.ID_TipoCliente === "CS" ? (
                                                                <div style={{ marginBottom: "15px", color: "green" }}>

                                                                    {/* Label Mostrar NetPoints da Encomenda */}
                                                                    <span style={{ fontSize: "15px" }}>
                                                                        NetPoints da Encomenda{": "}
                                                                    </span>

                                                                    {/* Mostrar valor dos netpoints*/}
                                                                    <span style={{ fontSize: "15px" }}>
                                                                        <FormattedNumber
                                                                            value={cart.Netpoints}
                                                                            minimumFractionDigits={2}
                                                                            maximumFractionDigits={2}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div style={{ marginBottom: "15px", color: "green" }}>

                                                                    {/* Label Mostrar Créditos da Encomenda */}
                                                                    <span style={{ fontSize: "15px" }}>
                                                                        Créditos{": "}
                                                                    </span>

                                                                    {/* Mostrar valor dos Créditos*/}
                                                                    <span style={{ fontSize: "15px" }}>
                                                                        <FormattedNumber
                                                                            value={cart.Creditos}
                                                                            style="currency"
                                                                            currency={upline.ID_Moeda}
                                                                            minimumFractionDigits={2}
                                                                            maximumFractionDigits={2}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div >
                                                </Col >
                                            </Row >
                                        </div>
                                    </div>
                                )}
                            </div >
                        </CardBody>
                    </Card>
                </ReactCSSTransitionGroup>
            </Fragment>
        </>
    )
}