import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import { getVouchers } from "../../../actions/userActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class ValesCliente extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0
    };
  }

  componentDidMount() {
    getVouchers().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "Nome Cliente Ativação ",
            accessor: "NomeClienteActivacao",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.NomeClienteActivacao
                ? row.original.NomeClienteActivacao
                : "-";
            }
          },
          {
            Header: "Valor",
            accessor: "Valor",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.Valor}
                  style="currency"
                  currency={row.original.ID_Moeda}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: "	Valor Remanescente ",
            accessor: "ValorRemanescente",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.ValorRemanescente}
                  style="currency"
                  currency={row.original.ID_Moeda}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            }
          },
          {
            Header: "Utilizado",
            accessor: "Utilizado",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.Utilizado ? row.original.Utilizado : "-";
            }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }
  render() {
    return (
      <SimpleTable
        data={this.state.data}
        columns={this.state.columns}
        blocking={this.state.blocking}
        rowNumber={this.state.rowNumber}
        dataSize={this.state.dataSize}
        noDataText={"Sem vales para apresentar"}
        title={"Vales"}
      />
    );
  }
}

export default ValesCliente;
