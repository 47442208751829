import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row } from "reactstrap";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import NumeroConsultores from "./Components/NumeroConsultores";
import NumeroConsultoresRede from "./Components/NumeroConsultoresRede";
import PinoConsultores from "./Components/PinoConsultores";
import TabelaConsultores from "./Components/TabelaConsultores";

class Clientes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Consultores"
            subheading="Página onde pode ver os seus consultores"
            icon="pe-7s-users icon-gradient bg-ripe-malin"
          />
          <Row style={{ display: "flex", alignItems: "center" }}>
            <NumeroConsultores />
            <NumeroConsultoresRede />
            <PinoConsultores />
          </Row>
          <TabelaConsultores />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}
const mapStateToProp = state => {
  return {};
};

export default withRouter(connect(mapStateToProp)(Clientes));
