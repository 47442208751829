import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Col, Row } from "reactstrap";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import EncomendasCliente from "./Components/EncomendasCliente";
import PesquisarProdutosComprados from "./Components/PesquisarProdutosComprados/PesquisarProdutosComprados";
import ProdutosComprados from "./Components/ProdutosComprados";
import ProdutosMaisComprados from "./Components/ProdutosMaisComprados";
import ProdutosMenosComprados from "./Components/ProdutosMenosComprados";
import ProdutosNaoComprados from "./Components/ProdutosNaoComprados";

class Encomendas extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Encomendas"
            subheading="Página onde pode ver as encomendas da sua rede"
            icon="pe-7s-shopbag icon-gradient bg-ripe-malin"
          />
          <PesquisarProdutosComprados/>
          <ProdutosComprados />
          <EncomendasCliente />
          <Row>
            <Col xs="12" sm="12" md="6" lg="4">
              <ProdutosMaisComprados />
            </Col>
            <Col xs="12" sm="12" md="6" lg="4">
              <ProdutosMenosComprados />
            </Col>
            <Col xs="12" sm="12" md="6" lg="4">
              <ProdutosNaoComprados />
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Encomendas;
