
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import ConsultantItem from "./ConsultantItem";
import TableStatsColumnCell from './TableStatsColumnCell';

export default class TableStatsRow extends Component {
    render() {
        return (
            <Row className="flex-nowrap" style={{ padding: "0px", margin: "0px" }} key={this.props.id}>
                <ConsultantItem id={this.props.consultant.ID_Cliente} nome={this.props.consultant.Nome} numLeads={this.props.consultant.NumLeadsNaoTratadas} />
                {
                    this.props.consultant.Fases && this.props.consultant.Fases.length > 0 ? (
                        this.props.consultant.Fases.map(fase => {
                            return <Col md={4} xs={11} lg={3} className="cardStyleStats" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <TableStatsColumnCell stats={fase} />
                            </Col>
                        })
                    ) : null
                }
            </Row >
        )
    }
}
