import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import InformacaoPessoal from "./Components/InformacaoPessoal";
import MediaCenterCliente from "./Components/MediaCenter";
import ProtocolosCliente from "./Components/Protocolos";
import SubscricoesCliente from "./Components/Subscricoes";
import ValesCliente from "./Components/Vales";

class Perfil extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {}
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Perfil"
            subheading="Página onde pode ver o seu perfil"
            icon="pe-7s-id icon-gradient bg-ripe-malin"
          />
          <InformacaoPessoal />
          <SubscricoesCliente />
          <ProtocolosCliente />
          <ValesCliente />
          <MediaCenterCliente />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Perfil;
