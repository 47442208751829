// 18-07-2019 - AFONSO - Funcao para obter produtos vistos e nao comprados
export function viewedProductsNotPurchased() {
  return new Promise((resolve, reject) => {
    return fetch("/oportunidades/viewedProductsNotPurchased", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 18-07-2019 - AFONSO - Funcao para obter packs vistos e nao comprados
export function viewedPacksNotPurchased() {
  return new Promise((resolve, reject) => {
    return fetch("/oportunidades/viewedPacksNotPurchased", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}


// 18-07-2019 - AFONSO - Funcao para obter produtos encomendas e nao comprados
export function orderedProductsNotPurchased() {
  return new Promise((resolve, reject) => {
    return fetch("/oportunidades/orderedProductsNotPurchased", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 15-03-2023 - Funcao para obter produtos encomendas e comprados
export function orderedProductsPurchased() {
  return new Promise((resolve, reject) => {
    return fetch("/oportunidades/orderedProductsPurchased", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 05-08-2019 - AFONSO - Funcao para obter visualizacoes do mediacenter
export function mediacenterViews() {
  return new Promise((resolve, reject) => {
    return fetch("/oportunidades/mediacenterViews", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}
