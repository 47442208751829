import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import ComissoesCalculadas from "./Components/ComissoesCalculadas";
import ComparativoAnual from "./Components/ComparativoAnual";
import ComparativoMensal from "./Components/ComparativoMensal";
import ResumoComissoes from "./Components/ResumoComissoes";
import UltimosPagamentos from "./Components/UltimosPagamentos";

class Comissoes extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Comissoes"
            subheading="Página onde pode ver as suas comissões"
            icon="lnr-diamond icon-gradient bg-ripe-malin"
          />
          <ResumoComissoes />
          <ComparativoMensal />
          <ComparativoAnual />
          <ComissoesCalculadas />
          <UltimosPagamentos />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Comissoes;
