import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BlockUi from "react-block-ui";
import { FormattedDate, FormattedNumber } from "react-intl";
import { Loader } from "react-loaders";
import { connect } from "react-redux";
import { Slide, toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row, Spinner } from "reactstrap";
import { updateBusinessContactInfo, updateBusinessDetailPageInfo } from "../../../actions/negociosActions";
import { changeUser } from "../../../actions/userActions";

const columnNameStyle = {
  fontSize: "18px",
  textAlign: "center",
  backgroundColor: "rgb(224, 243, 255)",
  color: "#495057"
};

const columnNameClientStyle = {
  fontSize: "18px",
  textAlign: "center",
  backgroundColor: "rgb(208,240,192)",
  color: "#495057"
};

const columnNameCompanyStyle = {
  fontSize: "18px",
  textAlign: "center",
  backgroundColor: "rgb(255, 229, 180)",
  color: "#495057"
};

const columnValueStyle = {
  fontSize: "16px",
  textAlign: "center"
};

const columnValueStyleToClick = {
  fontSize: "16px",
  textAlign: "center",
  cursor: "pointer"
};

var crmUrl = process.env.NODE_ENV !== 'production' ? "http://localhost:3009/encomenda/" : "https://crm.ihavethepower.net/encomenda/";

class Detalhe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: "initial",
      show: "none",
      data: [],
      isEditingContacto: false,
      isSavingContacto: false
    };

    this.getData = this.getData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveNewContactData = this.saveNewContactData.bind(this);
  }


  //Ao ir buscar os detalhes da Lead
  getData() {
    this.props.dispatch(updateBusinessDetailPageInfo(this.props.id, true))
      .then(result => {
        this.setState({
          data: this.props.negocioInfo
        })
        if (!result) {
          this.showToast("Erro ao carregar a informação do Negócio", "error");
        }
      })
      .catch(err => {
        this.showToast("Erro ao carregar a informação do Negócio", "error");
      });
  }

  //Ao componente atualizar propriedades
  componentDidUpdate(prevProps) {
    if (prevProps.negocioAtualizado !== this.props.negocioAtualizado) {
      this.getData();
    }
  }

  //Ao montar o componente vamos buscar os dados
  componentDidMount() {
    this.getData();
    // this.handleEditContactButton();
  }

  // Mostrar uma toast, ou seja uma messagebox no ecrã
  showToast(message, type) {
    toast.dismiss();
    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-right",
      type: type
    });
  }

  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [name]: value
      }
    }));
  }

  handleEditContactButton() {
    if (this.state.data.TipoNegocio === 'B2B') {
      if (this.state.isEditingContacto === false) {
        return (
          <div>
            <Button color="primary" onClick={() => this.setState({ isEditingContacto: !this.state.isEditingContacto })}
              style={{ display: "flex", alignItems: "center" }}>
              Editar dados contacto
              <Label className="lnr-pencil" style={{ flex: "1", fontSize: "1.2em", margin: "0px 0px 0px 0.5em", textAlign: "right", cursor: "pointer", display: "inline" }} />
            </Button>
          </div>
        )
      }
      else {
        if (this.state.isSavingContacto) {
          return (
            <div>
              <Spinner variant="dark" />
            </div>
          )
        }
        else {
          return (
            <div>
              <Button color="primary" onClick={this.saveNewContactData}
                style={{ display: "flex", alignItems: "center" }}>
                Gravar dados
                <Label className="lnr-checkmark-circle" style={{ flex: "1", fontSize: "1.2em", margin: "0px 0px 0px 0.5em", textAlign: "right", cursor: "pointer", display: "inline" }} />
              </Button>
            </div>
          )
        }
      }
    }
  }

  saveNewContactData() {
    let negocio = { ...this.state.data };
    let idNegocio = this.props.id;
    this.setState({
      isSavingContacto: true
    });

    updateBusinessContactInfo(idNegocio, negocio.NomeContacto, negocio.EmailContacto, negocio.TelemovelContacto, negocio.LocalidadeContacto)
      .then(data => {
        if (data.success === true) {
          this.setState({ isEditingContacto: !this.state.isEditingContacto });
          this.showToast("Dados atualizados com sucesso", "success");
        }
        else {
          this.showToast(data.message, "error");
        }
      })
      .catch(data => {
        this.showToast("Ocorreu um erro inesperado ao atualizar os dados do contacto.", "error");
      })
      .finally(() => this.setState({
        isSavingContacto: false
      }));
  }

  render() {
    var negocioInfo = this.props.negocioInfo;
    var observacoesNumberOfRows = negocioInfo.Observacao && negocioInfo.Observacao.length > 0 ? Math.round(negocioInfo.Observacao.length / 200) : 1;
    observacoesNumberOfRows = observacoesNumberOfRows <= 0 ? 1 : observacoesNumberOfRows;
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div />
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">
                      {" "}
                    </i>
                    Informações do Negócio (Lead): <span style={{ marginLeft: "5px", color: "#545cd8", fontWeight: "600" }}>{this.props.id}</span>
                  </div>

                  <div className="btn-actions-pane-right">
                    {/* Botões de minimizar e maximizar o espaço*/}
                    <Button className="mr-2 btn-icon btn-icon-only" outline color="danger" style={{ display: this.state.hidden }}
                      onClick={() => {
                        this.setState({ hidden: "none", show: "initial" });
                      }}>

                      <i className="lnr-circle-minus" style={{ display: this.state.hidden }}
                        onClick={() => {
                          this.setState({
                            hidden: "none",
                            show: "initial"
                          });
                        }}
                      />
                    </Button>

                    <Button className="mr-2 btn-icon btn-icon-only" outline color="success" style={{ display: this.state.show }}
                      onClick={() => {
                        this.setState({ hidden: "initial", show: "none" });
                      }}>

                      <i className="lnr-plus-circle" style={{ display: this.state.show }}
                        onClick={() => {
                          this.setState({
                            hidden: "initial",
                            show: "none"
                          });
                        }}
                      />
                    </Button>

                  </div>
                </CardHeader>
                <CardBody style={{ display: this.state.hidden }}>
                  <BlockUi
                    tag="div"
                    blocking={this.props.loading}
                    className="block-overlay-dark"
                    loader={
                      <Loader
                        color="#ffffff"
                        active
                        type="ball-spin-fade-loader"
                      />
                    }
                  >
                    {negocioInfo && !this.props.loading ? (
                      <span>
                        <Row>

                          {/* Nome de Consultor */}
                          <Col xs="12" sm="12" md="3" lg="3">
                            <p style={columnNameStyle}> Nome Consultor </p>
                            <p style={columnValueStyle}> {negocioInfo.Consultor_NomeCompleto ? negocioInfo.Consultor_NomeCompleto : "-"}</p>
                          </Col>

                          {/* Tipo de Lead */}
                          <Col xs="12" sm="12" md="3" lg="3">
                            <p style={columnNameStyle}> Tipo Lead </p>
                            <p style={columnValueStyle}> {negocioInfo.TipoNegocio ? negocioInfo.TipoNegocio : "-"}</p>
                          </Col>

                          {/* Origem de Lead */}
                          <Col xs="12" sm="12" md="3" lg="3">
                            <p style={columnNameStyle}> Origem </p>
                            <p style={columnValueStyle}> {negocioInfo.Origem ? negocioInfo.Origem : "-"}</p>
                          </Col>

                          {/* Descrição */}
                          <Col xs="12" sm="12" md="3" lg="3">
                            <p style={columnNameStyle}>Descrição</p>
                            <p style={columnValueStyle}>{negocioInfo.Descricao ? negocioInfo.Descricao : "-"}</p>
                          </Col>

                        </Row>
                        <br />

                        {/* Linha com informação da Empresa */}
                        {(negocioInfo.TipoNegocio === 'B2B') ? (
                          <Row>

                            {/* Id de Empresa */}
                            <Col xs="12" sm="12" md="2" lg="2">
                              <p style={columnNameCompanyStyle}> Id Empresa</p>
                              <p style={columnValueStyle}> {negocioInfo.ID_Empresa ? negocioInfo.ID_Empresa : "-"}</p>
                            </Col>

                            {/* Nome de Empresa */}
                            <Col xs="12" sm="12" md="3" lg="3">
                              <p style={columnNameCompanyStyle}> Nome Empresa</p>
                              <p style={columnValueStyle}> {negocioInfo.NomeEmpresa ? negocioInfo.NomeEmpresa : "-"}</p>
                            </Col>

                            {/* Email Empresa */}
                            <Col xs="12" sm="12" md="3" lg="3">
                              <p style={columnNameCompanyStyle}> Email Empresa </p>
                              <p style={columnValueStyle}>
                                {negocioInfo.EmailEmpresa ?
                                  (<a href={"mailto:" + negocioInfo.EmailEmpresa}>
                                    {negocioInfo.EmailEmpresa}
                                  </a>
                                  ) :
                                  "-"}
                              </p>
                            </Col>

                            {/* Telemovel  Empresa */}
                            <Col xs="12" sm="12" md="2" lg="2">
                              <p style={columnNameCompanyStyle}> Telemóvel Empresa</p>
                              <p style={columnValueStyle}>
                                {negocioInfo.TelemovelEmpresa ?
                                  (<a href={"tel:" + negocioInfo.TelemovelEmpresa}>
                                    {negocioInfo.TelemovelEmpresa}
                                  </a>
                                  ) :
                                  "-"}
                              </p>
                            </Col>

                            {/* Localidade Empresa */}
                            <Col xs="12" sm="12" md="2" lg="2">
                              <p style={columnNameCompanyStyle}> Localidade Empresa </p>
                              <p style={columnValueStyle}> {negocioInfo.LocalidadeEmpresa ? negocioInfo.LocalidadeEmpresa : "-"}</p>
                            </Col>
                          </Row>
                        )
                          : (null)
                        }

                        {/* Linha com informação do contacto */}
                        <Row>

                          {/* Id de Cliente */}
                          <Col xs="12" sm="12" md="2" lg="2">
                            <p style={columnNameClientStyle}> Id Contacto</p>
                            <p style={columnValueStyleToClick} onClick={() => {
                              this.props
                                .dispatch(changeUser(negocioInfo.IdContacto))
                                .then(() => {
                                  window.location.href = "/perfil";
                                });
                            }}>
                              {negocioInfo.IdContacto ? negocioInfo.IdContacto :
                                "-"}
                            </p>
                          </Col>

                          {/* Nome de Contacto */}
                          <Col xs="12" sm="12" md="3" lg="3">
                            <p style={columnNameClientStyle}> Nome Contacto</p>
                            {this.state.isEditingContacto ?
                              <Input autoFocus type="text" name="NomeContacto" value={this.state.data.NomeContacto} onChange={this.handleChange}>
                              </Input> :
                              <p style={columnValueStyleToClick} onClick={() => {
                                this.props
                                  .dispatch(changeUser(negocioInfo.IdContacto))
                                  .then(() => {
                                    window.location.href = "/perfil";
                                  });
                              }}>
                                {this.state.data.NomeContacto ? this.state.data.NomeContacto : "-"}
                              </p>
                            }
                          </Col>

                          {/* Email Contacto */}
                          <Col xs="12" sm="12" md="3" lg="3">
                            <p style={columnNameClientStyle}> Email Contacto</p>
                            {this.state.isEditingContacto ?
                              <Input type="text" name="EmailContacto" value={this.state.data.EmailContacto} onChange={this.handleChange}>
                              </Input> :
                              <p style={columnValueStyle}>
                                {negocioInfo.EmailContacto ?
                                  (<a href={"mailto:" + this.state.data.EmailContacto}>
                                    {this.state.data.EmailContacto}
                                  </a>
                                  ) :
                                  "-"}
                              </p>}
                          </Col>

                          {/* Telemovel  Contacto */}
                          <Col xs="12" sm="12" md="2" lg="2">
                            <p style={columnNameClientStyle}> Telemóvel Contacto</p>
                            {this.state.isEditingContacto ?
                              <Input type="text" name="TelemovelContacto" value={this.state.data.TelemovelContacto} onChange={this.handleChange}>
                              </Input> :
                              <p style={columnValueStyle}>
                                {this.state.data.TelemovelContacto ?
                                  (<a href={"tel:" + this.state.data.TelemovelContacto}>
                                    {this.state.data.TelemovelContacto}
                                  </a>
                                  ) :
                                  "-"}
                              </p>}
                          </Col>

                          {/* Localidade Contacto */}
                          <Col xs="12" sm="12" md="2" lg="2">
                            <p style={columnNameClientStyle}> Localidade Contacto</p>
                            {this.state.isEditingContacto ?
                              <Input type="text" name="LocalidadeContacto" value={this.state.data.LocalidadeContacto} onChange={this.handleChange}>
                              </Input> :
                              <p style={columnValueStyle}> {this.state.data.LocalidadeContacto ? this.state.data.LocalidadeContacto : "-"}</p>}
                          </Col>
                        </Row>

                        <br />
                        {/* Linhas com as datas e valor previsto */}
                        <Row>
                          {/* Data de Inicio */}
                          <Col xs="12" sm="12" md="3" lg="3">
                            <p style={columnNameStyle}>Data Inicio</p>
                            <p style={columnValueStyle}>
                              {negocioInfo.DataInicio ?
                                (<FormattedDate value={new Date(negocioInfo.DataInicio)} />)
                                : ("-")}
                            </p>
                          </Col>

                          {/* Data Prevista de Fim */}
                          <Col xs="12" sm="12" md="3" lg="3">
                            <p style={columnNameStyle}>Data Prevista de Fim</p>
                            <p style={columnValueStyle}>
                              {negocioInfo.DataPrevistaFecho ?
                                (<FormattedDate value={new Date(negocioInfo.DataPrevistaFecho)} />)
                                : ("-")}
                            </p>
                          </Col>

                          {/* Valor Previsto */}
                          <Col xs="12" sm="12" md="2" lg="2">
                            <p style={columnNameStyle}>Valor Previsto</p>
                            <p style={columnValueStyle}>
                              {negocioInfo.ValorPrevisto ?
                                (<FormattedNumber value={negocioInfo.ValorPrevisto} style="currency" currency="EUR" minimumFractionDigits={2} maximumFractionDigits={2} />)
                                : ("0.00")}
                            </p>
                          </Col>

                          {/* Referencia */}
                          <Col xs="12" sm="12" md="2" lg="2">
                            <p style={columnNameStyle}>Referencia</p>
                            <p style={columnValueStyle}>{negocioInfo.Referencia ? negocioInfo.Referencia : "-"}</p>
                          </Col>

                          {/* Id de Encomenda */}
                          <Col xs="12" sm="12" md="2" lg="2">
                            <p style={columnNameStyle}>ID Encomenda</p>
                            <p style={columnValueStyle}>
                              {negocioInfo.ID_Encomenda ?
                                <a href={crmUrl + negocioInfo.ID_Encomenda}>
                                  {negocioInfo.ID_Encomenda}
                                </a> : "-"
                              }
                            </p>
                          </Col>
                        </Row>

                        <br />
                        <Row>
                          {/* Observação */}
                          <Col xs="12" sm="12" md="12" lg="12">
                            <p style={columnNameStyle}>Observação</p>
                            <textarea style={{ fontSize: "16px", width: "100%", border: "none", outline: "none", textAlign: observacoesNumberOfRows == 1 ? "center" : "left" }}
                              rows={observacoesNumberOfRows}
                              readOnly={true}>
                              {negocioInfo.Observacao ? negocioInfo.Observacao.replaceAll("  ", "\r\n") : "-"}
                            </textarea>
                          </Col>
                        </Row>

                        {this.handleEditContactButton()}
                      </span>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        {!this.props.loading
                          ? "Não existem informações sobre o negócio"
                          : null}
                      </div>
                    )}
                  </BlockUi>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment >
    );
  }
}

const mapStateToProps = state => {
  return {
    negocioAtualizado: state.negocioAtualizado,
    negocioInfo: state.businessDetailPageInfo,
    loading: state.businessDetailLoading
  };
};

export default connect(mapStateToProps)(Detalhe);
