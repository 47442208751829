class Routes {
  constructor() {
    this.user = {
      login: "/login",
      perfil: "/perfil"
    };
    this.index = {
      index: "/"
    };
    this.oportunidades = {
      index: "/oportunidades"
    };
    this.negocios = {
      index: "/negocios",
      detalhe: "/negocio/:id"
    };
    this.encomendas = {
      index: "/encomendas",
      detalhe: "/encomenda/:id"
    };
    this.comissoes = {
      index: "/comissoes",
      detalhe: "/comissoes/:ano/:mes"
    };
    this.rede = {
      estrutura: "/rede/estrutura",
      clientes: "/rede/clientes",
      consultores: "/rede/consultores",
      afiliados: "/rede/afiliados",
      promotores: "/rede/promotores"
    };
    this.pesquisa = {
      index: "/pesquisa"
    };
    this.indicadores = {
      index: "/indicadores"
    };
    this.comparativos = {
      index: "/comparativos"
    };
    this.prospect = {
      index: "/Prospect/Prospect/:id"
    };
    this.cliente = {
      index: "/Home/Cliente/:id"
    };
    this.leads = {
      listaLeads: "/leads/listaLeads",
      novaLead: "/leads/novaLead",
      analise:"/leads/analise",
      detalhe: "/lead/:id",
      pesquisa:"/leads/pesquisa",
      leadsPerdidasPorConsultor:"/leads/leadsPerdidasPorConsultor"
    };
    this.contactos = {
      contactos: "/contactos/contactos",
      empresas: "/contactos/empresas",
      detalhe: "/contacto/:id"
    };
    this.marketing = {
      listaLeads: "/marketing/listaLeads",
      contactos: "/marketing/contactos"
    };
    this.posicaoConsultores = {
      index: "/posicaoConsultores"
    };
    this.cart = {
      index: "/cart"
    };
    this.payment = {
      index: "/payment/:hash",
    }
  }
}

var RoutesObject = new Routes();

export default RoutesObject;
