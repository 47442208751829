import React, { Component } from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
import { getContactoTimeline } from "../../../../actions/contactosActions";
import Atividade from "./Atividade";

var that;
class Atividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actividades: []
    };
    that = this;
  }

  getData() {
    getContactoTimeline(this.props.contactID).then(data => {
      if (data && !data.message) {
        this.setState({
          actividades: data
        });
      }
    });
  }
  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <VerticalTimeline layout="1-column" className="vertical-without-time">
        {that.state.actividades.map(event => {
          return (
            <Atividade
              TipoAtividade={event.TipoAtividade}
              Descricao={event.Descricao}
            />
          );
        })}
      </VerticalTimeline>
    );
  }
}

export default Atividades;
