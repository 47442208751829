import { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import Faturacao1TrimestreTodosConsultores from "./Components/Faturacao1TrimestreTodosConsultores";
import Faturacao2TrimestreTodosConsultores from "./Components/Faturacao2TrimestreTodosConsultores";
import Faturacao3TrimestreTodosConsultores from "./Components/Faturacao3TrimestreTodosConsultores";
import Faturacao4TrimestreTodosConsultores from "./Components/Faturacao4TrimestreTodosConsultores";
import FaturacaoMesCorrenteTodosConsultores from "./Components/FaturacaoMesCorrenteTodosConsultores";

class PosicaoConsultores extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Posição Consultores"
            subheading="Página onde pode ver a posição de todos consultores, relativamente à faturação mensal e de todos os trimestres"
            icon="lnr-chart-bars icon-gradient bg-ripe-malin"
          />

          <FaturacaoMesCorrenteTodosConsultores />
          <Row>
            <Col xs="12" sm="12" md="6" lg="4">
              <Faturacao1TrimestreTodosConsultores />
            </Col>
            <Col xs="12" sm="12" md="6" lg="4">
              <Faturacao2TrimestreTodosConsultores />
            </Col>
            <Col xs="12" sm="12" md="6" lg="4">
              <Faturacao3TrimestreTodosConsultores />
            </Col>

          </Row>
          <Row>
            <Col xs="12" sm="12" md="6" lg="4">
              <Faturacao4TrimestreTodosConsultores />
            </Col>
          </Row>
        </ReactCSSTransitionGroup >
      </Fragment >
    );
  }
}
const mapStateToProp = state => {
  return {};
};

export default withRouter(connect(mapStateToProp)(PosicaoConsultores));
