import React, { Component } from "react";
import { FormattedDate, FormattedNumber } from "react-intl";
import { getPayments } from "../../../actions/comissoesActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

class UltimosPagamentos extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0
    };
  }

  componentDidMount() {
    getPayments().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "Data",
            accessor: "Data",
            resizable: false,
            Cell: row => {
              return row.original.Data ? (
                <FormattedDate value={new Date(row.original.Data)} />
              ) : (
                "-"
              );
            },
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Valor Pago",
            accessor: "ValorPago",
            resizable: false,
            Cell: row => {
              return (
                <FormattedNumber
                  value={row.original.ValorPago}
                  style="currency"
                  currency={row.original.ID_Moeda}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              );
            },
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Observações",
            accessor: "Observacoes",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.Observacoes ? row.original.Observacoes : "-";
            }
          },
          {
            Header: "Tipo pagamento",
            accessor: "TipoPagamento",
            resizable: false,
            style: { display: "block", textAlign: "center" }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }
  render() {
    return (
      <SimpleTable
        data={this.state.data}
        columns={this.state.columns}
        blocking={this.state.blocking}
        rowNumber={this.state.rowNumber}
        dataSize={this.state.dataSize}
        noDataText={"Não tem pagamentos disponiveis"}
        title={"Ultimos pagamentos realizados"}
      />
    );
  }
}

export default UltimosPagamentos;
