import CircularProgress from '@material-ui/core/CircularProgress';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Col, Label, Row } from 'reactstrap';
import { changeUser, getContacts } from '../../../../actions/userActions';
import { askSearchForNextPage } from '../../../../actions/leadsActions';
import ContactosCliente from '../../../../helpers/ContactosCliente';

class Resultados extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            contactosCliente: [],
            totalPages: 0,
            currentPage: 0,
            recordsPerPage: 5,
            hidden: "none",
            show: "initial",
            columns: [
                {
                    Header: "ID Negócio",
                    accessor: "ID_Negocio",
                    width: 100,
                    resizable: false,
                    style: { display: "block", textAlign: "center" },
                    Cell: row => {
                        return <a href={"/negocio/" + row.original.ID_Negocio}>
                            {row.original.ID_Negocio
                                ? row.original.ID_Negocio
                                : "-"}
                        </a>
                    }
                },
                {
                    Header: "Consultor",
                    accessor: "ID_Consultor",
                    resizable: false,
                    style: { display: "block", textAlign: "center" },
                    Cell: row => {
                        return <div>
                            <span style={{ cursor: "pointer", display: "block" }} onClick={() => this.clickToShowPerfil(row.original.ID_Consultor)}>
                                {row.original.ID_Consultor
                                    ? row.original.ID_Consultor + " - " + row.original.NomeConsultor
                                    : "-"}
                            </span>
                            <span style={{ cursor: "pointer", display: "block", marginTop: "7px" }} onClick={() => this.clickToShowConsultantDetails(row.original.ID_Consultor)}>
                                <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                            </span>
                        </div>
                    }
                },
                {
                    Header: "Cliente/Empresa",
                    accessor: "ID_Cliente",
                    resizable: false,
                    style: { display: "block", textAlign: "center" },
                    Cell: row => {
                        return <span style={{ cursor: "pointer" }} onClick={() => this.clickToShowPerfil(row.original.ID_Cliente)}>
                            {row.original.ID_Cliente ?
                                (row.original.ID_Cliente + " - " + row.original.NomeCliente)
                                : (row.original.ID_Empresa ? row.original.ID_Empresa + " - " + row.original.NomeEmpresa : "-")
                            }
                        </span>
                    }
                },
                {
                    Header: "Data Início",
                    accessor: "DataInicio",
                    resizable: false,
                    style: { display: "block", textAlign: "center" },
                    Cell: row => {
                        return row.original.DataInicio ? (
                            this.formatDate(row.original.DataInicio)
                        ) : (
                            "-"
                        );
                    }
                },
                {
                    Header: "Data Fim",
                    accessor: "DataFim",
                    resizable: false,
                    style: { display: "block", textAlign: "center" },
                    Cell: row => {
                        return row.original.DataFim ? (
                            this.formatDate(row.original.DataFim)
                        ) : (
                            "-"
                        );
                    }
                },
                {
                    Header: "Observação",
                    accessor: "Observacao",
                    resizable: false,
                    style: { display: "block", textAlign: "center" },
                    Cell: row => {
                        return row.original.Observacao
                            ? row.original.Observacao
                            : "-";
                    }
                },
                {
                    Header: "Estado Atual Evolução",
                    accessor: "NomeEstadoAtual",
                    resizable: false,
                    style: { display: "block", textAlign: "center" },
                    Cell: row => {
                        return row.original.NomeEstadoAtual
                            ? row.original.NomeEstadoAtual
                            : "-";
                    }
                }
            ]
        };

        this.clickToShowPerfil = this.clickToShowPerfil.bind(this);
        this.toggle = this.toggle.bind(this);
        this.getUserContacts = this.getUserContacts.bind(this);
        this.clickToShowConsultantDetails = this.clickToShowConsultantDetails.bind(this);
    }

    clickToShowPerfil(ID_Cliente) {
        this.props
            .dispatch(changeUser(ID_Cliente))
            .then(() => {
                window.location.href = "/perfil";
            });
    }


    //Abrir e fechar o modal
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }


    formatDate(value) {

        var d = new Date(value);
        var month = '' + (d.getMonth() + 1);
        var days = '' + d.getDate();
        var hours = '' + d.getHours();
        var minutes = '' + d.getMinutes();

        if (month.length < 2) month = '0' + month;
        if (days.length < 2) days = '0' + days;
        if (hours.length < 2) hours = '0' + hours;
        if (minutes.length < 2) minutes = '0' + minutes;

        var result = days + "-" + month + "-" + d.getFullYear() + " " + hours + ":" + minutes;
        return result;
    }

    fetchNewInfo(page, pageSize) {
        if (pageSize !== this.state.recordsPerPage) page = 0;
        this.props.dispatch(askSearchForNextPage(page, pageSize, Date.now()));
    }

    componentDidUpdate(prevProps, prevState) {

        if (typeof prevProps.pagesInfo !== 'undefined' && (prevProps.pagesInfo.page !== this.props.pagesInfo.page || prevProps.pagesInfo.pageSize !== this.props.pagesInfo.pageSize
            || prevProps.pagesInfo.totalPages !== this.props.pagesInfo.totalPages)) {

            this.setState(prevState => ({
                ...prevState,
                recordsPerPage: this.props.pagesInfo.pageSize,
                currentPage: this.props.pagesInfo.page,
                totalPages: this.props.pagesInfo.totalPages
            }));
        }
    }

    //Método para ir buscar as informações do cliente
    getUserContacts(id) {
        getContacts(id).then(data => {
            if (data && !data.message) {
                this.setState({ contactosCliente: data });
                this.toggle();
            }
        });
    }

    clickToShowConsultantDetails(id) {
        this.getUserContacts(id);
    }

    //Carregar os resultados onload
    componentDidMount() {
        this.fetchNewInfo(this.state.currentPage, this.state.recordsPerPage);
    }

    render() {
        return (
            <div style={{ margin: "0px", padding: "0px" }}>
                {this.props.error && this.props.error.length > 0 ?
                    <Label style={{ marginBottom: "20px", fontSize: "20px", color: "#FF8484", fontWeight: "600", fontStyle: "italic", textAlign: "center" }}>{this.props.error}</Label>
                    : null}


                <ContactosCliente onRef={ref => (this.child = ref)} isOpen={this.state.modal}
                    toggle={this.toggle} contactos={this.state.contactosCliente} />

                <Row>
                    <Col md={12} style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", margin: "20px 0px 20px 0px" }}>
                        {this.props.loading === true ?
                            <div>
                                <label style={{ fontWeight: "600", fontSize: "17px", fontStyle: "italic", marginRight: "10px" }}>A carregar resultados...</label>
                                <CircularProgress thickness={2} size={25} disableShrink />
                            </div>
                            : null}
                    </Col>
                </Row>

                <div>
                    <ReactTable
                        className="-highlight tableReact"
                        data={this.props.results}
                        pageSizeOptions={[5, 10, 20]}
                        defaultPageSize={5}
                        showPageJump={true}
                        sortable={false}
                        page={this.state.currentPage}
                        pages={this.state.totalPages}
                        pageSize={this.state.recordsPerPage}
                        showPagination={true}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        loading={this.props.loading}
                        getTheadProps={(state, rowInfo, column) => {
                            return {
                                style: {
                                    "text-align": "center"
                                }
                            };
                        }}
                        getTrProps={(state, rowInfo, column, instance) => {
                            return {
                                style: {
                                    "text-align": "center"
                                }
                            };
                        }}
                        columns={this.state.columns}
                        previousText="Anterior"
                        nextText={"Próxima"}
                        pageText={"Página"}
                        ofText={"de"}
                        rowsText={"linhas"}
                        manual
                        onPageSizeChange={(pageSize, page) => {
                            this.fetchNewInfo(page, pageSize);
                        }}
                        onPageChange={(page) => {
                            this.fetchNewInfo(page, this.state.recordsPerPage);
                        }}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        results: state.searchLeadResults,
        loading: state.searchLeadLoading,
        error: state.searchLeadError,
        pagesInfo: state.searchLeadCurrentPage,
    };
};

export default withRouter(connect(mapStateToProps)(Resultados));