import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitleEmpresas from "../../helpers/Layout/PageTitleEmpresas";
import TabelaEmpresas from "./Components/TabelaEmpresas";

class Empresas extends Component {
  constructor() {
    super();
    this.state = {};
    this.updateData = this.updateData.bind(this);
  }

  updateData() {
    this.child.getData();
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleEmpresas
            heading="Empresas"
            subheading="Página onde pode ver as empresas"
            icon="lnr-briefcase icon-gradient bg-ripe-malin"
            updateData={this.updateData}
          />
          <TabelaEmpresas onRef={ref => (this.child = ref)} />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Empresas;
