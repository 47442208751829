import React, { Component } from "react";
import { Button, Col, Container, Row } from "reactstrap";

class DetalheButtons extends Component {

  render() {
    return (
      <Container>
        <Row>
          <Col xs="2" sm="2" md="2" lg="2">
            <Button className="mb-2 mr-2 btn-icon btn-icon-only" color="alternate">
              <i className="pe-7s-note btn-icon-wrapper"> </i>
            </Button>
          </Col>
          <Col xs="2" sm="2" md="2" lg="2">
            <Button className="mb-2 mr-2 btn-icon btn-icon-only" color="alternate">
              <i className="pe-7s-mail btn-icon-wrapper"> </i>
            </Button>
          </Col>
          <Col xs="2" sm="2" md="2" lg="2">
            <Button className="mb-2 mr-2 btn-icon btn-icon-only" color="alternate">
              <i className="pe-7s-call btn-icon-wrapper"> </i>
            </Button>
          </Col>
          <Col xs="2" sm="2" md="2" lg="2">
            <Button className="mb-2 mr-2 btn-icon btn-icon-only" color="alternate">
              <i className="pe-7s-plus btn-icon-wrapper"> </i>
            </Button>
          </Col>
          <Col xs="2" sm="2" md="2" lg="2">
            <Button className="mb-2 mr-2 btn-icon btn-icon-only" color="alternate">
              <i className="pe-7s-note2 btn-icon-wrapper"> </i>
            </Button>
          </Col>
          <Col xs="2" sm="2" md="2" lg="2">
            <Button className="mb-2 mr-2 btn-icon btn-icon-only" color="alternate">
              <i className="pe-7s-date btn-icon-wrapper"> </i>
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DetalheButtons;
