import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import Faturacao1Trimestre from "./Components/Faturacao1Trimestre";
import Faturacao2Trimestre from "./Components/Faturacao2Trimestre";
import Faturacao3Trimestre from "./Components/Faturacao3Trimestre";
import Faturacao4Trimestre from "./Components/Faturacao4Trimestre";
import FaturacaoAcumulada from "./Components/FaturacaoAcumulada";
import FaturacaoMesCorrente from "./Components/FaturacaoMesCorrente";
import NiveisNegocio from "./Components/NiveisNegocio";
import Recomendacao from "./Components/Recomendacao";
import Rentabilidade from "./Components/Rentabilidade";

class Comparativo extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Comparativos"
            subheading="Página onde pode ver alguns comparativos"
            icon="pe-7s-edit icon-gradient bg-ripe-malin"
          />
          <FaturacaoMesCorrente />
          <FaturacaoAcumulada />
          <Faturacao1Trimestre />
          <Faturacao2Trimestre />
          <Faturacao3Trimestre />
          <Faturacao4Trimestre />
          {/* <NiveisNegocio />
          <Recomendacao />
          <Rentabilidade /> */}
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Comparativo;
