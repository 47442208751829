import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Avatar from "react-avatar";
import { FormattedDate } from "react-intl";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import { getContactos } from "../../../actions/contactosActions";
import PageTitleContactos from "../../../helpers/Layout/PageTitleContactos";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

var that;
class TabelaContactos extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0,
      deleteMessage: false
    };
    that = this;
    this.toggle = this.toggle.bind(this);
    this.deleteModal = this.deleteModal.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  deleteModal() {
    this.setState({
      deleteMessage: !this.state.deleteMessage
    });
  }

  getData() {
    getContactos().then(data => {
      if (data && !data.message) {
        that.setState({ dataSize: 1 });
        that.setState({
          data: data
        });
      }
      if (data.message) {
        that.setState({
          data: [],
          dataSize: 0
        });
      }
      that.setState({
        columns: [
          {
            Header: "Nome",
            accessor: "Nome",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <div>
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left mr-3">
                        <div className="widget-content-left">
                          <Avatar
                            name={row.original.Nome}
                            round={true}
                            size={"50px"}
                          />
                        </div>
                      </div>
                      <div className="widget-content-left flex2">
                        <span
                          className="widget-heading fake-link"
                          id="fake-link-1"
                          onClick={() => {
                            window.location.href =
                              "/contacto/" + row.original.ID_Contacto;
                          }}
                        >
                          {row.original.Nome}
                        </span>
                        <div className="widget-subheading opacity-10">
                          <span className="pr-2">
                            <b>Estado: </b> {row.original.StatusContacto}
                          </span>
                          <span>
                            <b>Contactos:</b> 0
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          },
          {
            Header: "Nome Consultor",
            accessor: "NomeCompleto",
            resizable: false,
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Email",
            accessor: "Email",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <div>
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <span>{row.original.Email}</span>
                        <div className="widget-subheading opacity-10">
                          <span className="pr-2">
                            <b>Validado: </b> Não
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          },
          {
            Header: "Telemóvel",
            accessor: "Telemovel",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <div>
                  <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                      <div className="widget-content-left flex2">
                        <span>{row.original.Telemovel}</span>
                        <div className="widget-subheading opacity-10">
                          <span className="pr-2">
                            <b>Validado: </b> Não
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          },
          {
            Header: "Data Criação",
            accessor: "DataCriacao",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.DataCriacao ? (
                <FormattedDate value={new Date(row.original.DataCriacao)} />
              ) : (
                  "-"
                );
            }
          },
          {
            Header: "Ações",
            accessor: "EstadoNegocio",
            resizable: false,
            show: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return (
                <div>
                  <div className="d-block w-100 text-center">
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        caret
                        className="btn-icon btn-icon-only btn btn-link"
                        color="link"
                      >
                        <i className="lnr-menu-circle btn-icon-wrapper" />
                      </DropdownToggle>
                      <DropdownMenu
                        right
                        className="rm-pointers dropdown-menu-hover-link"
                      >
                        <DropdownItem
                          onClick={() => {
                            window.location.href =
                              "/negocio/" + row.original.ID_Negocio;
                          }}
                        >
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Ver Detalhe</span>
                        </DropdownItem>
                        <DropdownItem>
                          <i
                            className="dropdown-icon lnr-file-empty"
                            onClick={() => {
                              that.toggle();
                              that.child.getBusiness(row.original.ID_Negocio);
                            }}
                          >
                            {" "}
                          </i>
                          <span
                            onClick={() => {
                              that.toggle();
                              that.child.getBusiness(row.original.ID_Negocio);
                            }}
                          >
                            Editar Negócio
                          </span>
                        </DropdownItem>
                        <DropdownItem>
                          <i
                            className="dropdown-icon lnr-book"
                            onClick={() => {
                              that.deleteModal();
                              that.child2.setID(row.original.ID_Negocio);
                            }}
                          >
                            {" "}
                          </i>
                          <span
                            onClick={() => {
                              that.deleteModal();
                              that.child2.setID(row.original.ID_Negocio);
                            }}
                          >
                            Remover Negócio
                          </span>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </div>
                </div>
              );
            }
          }
        ]
      });
      that.setState({ blocking: false });
    });
  }

  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleContactos
            heading="Contactos"
            subheading="Página onde pode ver todos os contactos"
            icon="lnr-briefcase icon-gradient bg-ripe-malin"
            updateData={this.updateData}
          />
          <SimpleTable
            data={this.state.data}
            columns={this.state.columns}
            blocking={this.state.blocking}
            rowNumber={this.state.rowNumber}
            dataSize={this.state.dataSize}
            noDataText={"Sem contactos para apresentar"}
            title={"Contactos disponiveis"}
          />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default TabelaContactos;
