// 23-07-2019 - AFONSO - Funcao para pesquisar clientes
export function searchClients(pesquisa) {  
  return new Promise((resolve, reject) => {
    return fetch("/pesquisa/searchClients", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Cliente: pesquisa.ID_Cliente,
        Nome: pesquisa.Nome,
        Email: pesquisa.Email,
        Empresa: pesquisa.Empresa,
        TiposCliente: pesquisa.TiposCliente,
        Particular:
          pesquisa.Particular === true
            ? "S"
            : pesquisa.Particular === false
            ? "N"
            : undefined,
        Naturalidade: pesquisa.Naturalidade,
        Distrito: pesquisa.Distrito,
        Concelho: pesquisa.Concelho,
        Cargo: pesquisa.Cargo,
        NomeRecomendacao: pesquisa.NomeRecomendacao,
        EmailRecomendacao: pesquisa.EmailRecomendacao,
        ID_ClienteRecomendacao: pesquisa.ID_ClienteRecomendacao,
        ID_Moeda: pesquisa.ID_Moeda,
        ID_Lingua: pesquisa.ID_Lingua,
        ID_Encomenda: pesquisa.ID_Encomenda,
        ID_Produto: pesquisa.ID_Produto,
        DescricaoProduto: pesquisa.DescricaoProduto,
        Registosnaoconcluidos: pesquisa.Registosnaoconcluidos,
        Clientesemencomendas: pesquisa.Clientesemencomendas,
        Clientessemenc1ano: pesquisa.Clientessemenc1ano,
        RGPD:
          pesquisa.RGPD === true
            ? "1"
            : pesquisa.RGPD === false
            ? "0"
            : undefined,
        NIF: pesquisa.NIF,
        Preferencias: pesquisa.Preferencias,
        Sonhos: pesquisa.Sonhos
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 23-07-2019 - AFONSO - Funcao para obter idiomas
export function getLanguages() {
  return new Promise((resolve, reject) => {
    return fetch("/pesquisa/getLanguages", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 23-07-2019 - AFONSO - Funcao para obter moedas
export function getCurrencies() {
  return new Promise((resolve, reject) => {
    return fetch("/pesquisa/getCurrencies", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 23-07-2019 - AFONSO - Funcao para obter tipos de cliente
export function getTypeClients() {
  return new Promise((resolve, reject) => {
    return fetch("/pesquisa/getTypeClients", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 23-07-2019 - AFONSO - Funcao para obter tipos de cliente
export function alterarDadosPesquisa(pesquisa) {
  return new Promise((resolve, reject) => {
    return fetch("/pesquisa/alterarDadosPesquisa", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify(pesquisa)
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

// 23-07-2019 - AFONSO - Funcao para obter tipos de cliente
export function getDadosPesquisa() {
  return new Promise((resolve, reject) => {
    return fetch("/pesquisa/getDadosPesquisa", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export const PESQUISA = "PESQUISA";
export const pesquisaFunction = pesquisa => ({
  type: PESQUISA,
  payload: { pesquisa }
});
