// 18-07-2019 - AFONSO - Funcao para obter as empresas
export function getContactos() {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/getContactos", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 06-09-2019 - AFONSO - Funcao para obter os contactos de um cliente
export function getContactosCliente() {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/getContactsCliente", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 14-08-2019 - AFONSO - Funcao para obter detalhe de contacto
export function getContactoDetail(id) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/getContactoDetail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Contacto: id
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 14-08-2019 - AFONSO - Funcao para obter timeline de contacto
export function getContactoTimeline(id) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/getContactoTimeline", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Contacto: id
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 14-08-2019 - AFONSO - Funcao para obter emails de contacto
export function getContactEmails(id) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/getContactEmails", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Contacto: id
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 14-08-2019 - AFONSO - Funcao para obter observações de contacto
export function getContactComments(id) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/getContactComments", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Contacto: id
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function insertContactComment(id, descricao) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/insertContactComment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Contacto: id,
        Descricao: descricao
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function editContactComment(id, ID_Observacao, descricao) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/editContactComment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Contacto: id,
        Descricao: descricao,
        ID_Observacao: ID_Observacao
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function removeContactComment(id, ID_Observacao) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/removeContactComment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Contacto: id,
        ID_Observacao: ID_Observacao
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 14-08-2019 - AFONSO - Funcao para obter chamadas de contacto
export function getContactCalls(id) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/getContactCalls", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Contacto: id
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// 14-08-2019 - AFONSO - Funcao para obter tarefas de contacto
export function getContactTasks(id) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/getContactTasks", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Contacto: id
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function insertContactEmail(id, email) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/insertContactEmail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Contacto: id,
        Assunto: email.Assunto,
        CorpoEmail: email.CorpoEmail,
        DataEmail: email.DataEmail
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function insertContactCall(id, chamada) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/insertContactCall", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Contacto: id,
        Resultado: chamada.Resultado,
        DataChamada: chamada.DataChamada
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function insertContactTask(id, tarefa) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/insertContactTask", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ID_Contacto: id,
        Tarefa: tarefa.Tarefa,
        Observacao: tarefa.Observacao
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getContactsByLead(leadId) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/getContactsByLead", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Lead: leadId,
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export function getLastContactByLead(leadId) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/getLastContactByLead", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Lead: leadId,
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export function editContactById(leadId, contact) {
  return new Promise((resolve, reject) => {
    return fetch("/contactos/editContactById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Lead: leadId,
        Contacto: contact.id,
        Resultado: contact.resultado,
        Observacoes: contact.observacao,
        DataInicio: contact.dataInicio,
        DataFim: contact.dataFim,
        ValorPrevisto: contact.valor,
        Encomenda: contact.idEncomenda ? contact.idEncomenda : null,
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


