import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import ListaLeads from "./ListaLeads/ListaLeads";

class Leads extends Component {
  constructor() {
    super();
    this.state = {};
    this.updateData = this.updateData.bind(this);
  }

  updateData() {
    this.child.getData();
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup component="div" transitionName="TabsAnimation" transitionAppear={true} transitionAppearTimeout={0} transitionEnter={false} transitionLeave={false}>
          <PageTitleAlt2 heading="Leads" subheading="Página onde pode ver a Evolução das suas Leads" icon="lnr-list icon-gradient bg-ripe-malin" />
          <ListaLeads onRef={ref => (this.child = ref)} />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Leads;
