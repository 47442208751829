import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Joi from "@hapi/joi";
import React, { Component } from "react";
import Avatar from "react-avatar";
import DateTimePicker from 'react-datetime-picker';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import { Button, Col, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { getEventById, getEventsByLeadType } from "../../../actions/eventsActions";
import { changeEventInfo, changeModalState, createContactInLead, funilChangeLeadEvent, funilEventsLoadLead, funilLoadEvents, getLeadsByEvent, editLeadContact } from "../../../actions/leadsActions";
import FunilLeads from "./Components/FunilLeads";
import EditarUltimoContacto from "../EditarUltimoContacto/EditarUltimoContacto";

const cardHeader = {
  fontSize: "19px",
  fontWeight: "600",
  textDecoration: "underline",
  margin: "10px"
}

const cardColumnStyle = {
  fontSize: "15px",
  fontWeight: "600",
  margin: "2px 2px"
}

const schema = Joi.object().keys({
  eventId: Joi.string()
    .trim()
    .empty()
    .min(1)
    .max(2)
    .required()
    .messages({
      'any.required': `Evento é obrigatório`,
      'string.base': `Evento deve estar no formato correto`,
      'string.empty': `Evento deve estar preenchido`,
      'string.max': `Evento deve ter no máximo 2 caracteres`,
    }),
  resultId: Joi.number()
    .integer()
    .min(1)
    .required()
    .messages({
      'any.required': `Resultado é obrigatório`,
      'number.base': `Resultado deve ser um número`,
      'number.min': `Resultado deve estar selecionado`,
    }),
  nomePessoa: Joi.string()
    .trim()
    .empty()
    .min(2)
    .max(100)
    .required()
    .messages({
      'any.required': `Nome da Pessoa é obrigatório`,
      'string.empty': `Nome da Pessoa deve estar preenchido`,
      'string.min': `Nome da Pessoa deve ter no mínimo 2 caracteres`,
      'string.max': `Nome da Pessoa deve ter no máximo 100 caracteres`
    }),
  contactoPessoa: Joi.string()
    .trim()
    .empty()
    .max(100)
    .required()
    .messages({
      'any.required': `Contacto da Pessoa é obrigatório`,
      'string.empty': `Contacto da Pessoa deve estar preenchido`,
      'string.base': `Contacto da Pessoa deve estar preenchido`,
      'string.max': `Contacto da Pessoa deve ter no máximo 100 caracteres`
    }),
  dataInicio: Joi.date()
    .required()
    .messages({
      'any.required': `Data Inicial é obrigatória`,
      'date.base': `Data Inicial deve ser uma data válida`
    }),
  dataFim: Joi.date()
    .required()
    .messages({
      'any.required': `Data Inicial é obrigatória`,
      'date.base': `Data Inicial deve ser uma data válida`
    }),
  value: Joi.number()
    .positive()
    .allow(0)
    .required()
    .messages({
      'any.required': `Valor é obrigatório`,
      'number.base': `Valor deve ser um número`,
      'number.positive': `Valor deve ser um número positivo`,
    })

});

const contactInitialState = {
  id: "",
  currentEvent: '0',
  nextEvent: '0',
  result: 0,
  obs: "",
  value: '0',
  nomePessoa: "",
  contactoPessoa: "",
  dataInicio: new Date(),
  dataFim: getCurrentDatePlusMinutes(15),
  shouldHaveOrderId: false,
  orderId: null,
}

function getCurrentDatePlusMinutes(minutes) {
  var value = new Date();
  value = new Date(value.getTime() + minutes * 60000);
  return value;
}

class ListaLeads extends Component {
  constructor() {
    super();
    this.state = {
      addContact: contactInitialState
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.resetContactInformation = this.resetContactInformation.bind(this);
    this.loadDataToUI = this.loadDataToUI.bind(this);
    this.validarDados = this.validarDados.bind(this);
    this.handleChangeMoveTo = this.handleChangeMoveTo.bind(this);
    this.updatedContact = this.updatedContact.bind(this);
    this.editModalClose = this.editModalClose.bind(this);
  }

  //Quando acontecerem alterações nas propriedades do componente
  componentDidUpdate(prevProps) {
    if (prevProps.modalIsOpen === false && this.props.modalIsOpen === true) {

      //O Default evento inicial é 0 
      var nextEvent = '0';
      var shouldHaveOrderId = false;

      //Mas se for uma lead que obrigatoriamente tem que mover para o próximo evento
      if (this.props.leadOpen.mustMoveToNextEvent === true) {

        //Se existir eventos possiveis, definimos que o próximo evento é o 1º dos eventos possíveis
        if (this.props.possibleEvents.length > 0) {
          shouldHaveOrderId = this.props.possibleEvents[0].EstadoFinal;
          nextEvent = this.props.possibleEvents[0].Id;
        }
      }
      //Se for uma evolução que não tem obrigatoriamente de ir para a próxima fase
      else {

        //Colocamos se deve ter id de encomenda, se a fase atual for a fase final
        shouldHaveOrderId = this.props.leadOpen.estadoFinal;
      }

      this.setState({
        addContact: {
          ...this.state.addContact,
          nomePessoa: this.props.leadOpen.nomeContacto,
          contactoPessoa: this.props.leadOpen.telemovelContacto,
          nextEvent: nextEvent,
          value: this.props.leadOpen.value,
          dataInicio: new Date(),
          shouldHaveOrderId: shouldHaveOrderId,
          orderId: null,
          dataFim: getCurrentDatePlusMinutes(15)
        }
      })
    }

    if (prevProps.editLeadContact !== this.props.editLeadContact && this.props.editLeadContact != null) {

    }
  }

  //Mal o componente é criado pela 1º vez
  componentDidMount() {
    this.loadDataToUI();
  }

  validarDados(contact) {
    var promise = new Promise(function (resolve, reject) {
      try {
        //Validamos os campos atraves do Joi
        var validationResult = schema.validate(
          {
            eventId: contact.nextEvent,
            resultId: contact.result,
            nomePessoa: contact.nomePessoa,
            contactoPessoa: contact.contactoPessoa,
            dataInicio: contact.dataInicio,
            dataFim: contact.dataFim,
            value: contact.value
          },
          { abortEarly: true });

        //Se error==null significa que não houve erro
        if (validationResult.error == null) {
          resolve();

          // Se houver erro vamos mostrar o erro e mostramos ao utilizadro
        } else {
          var errorMessage = validationResult.error.details[0].message;
          reject(new Error(errorMessage));
        }
      } catch (err) {
        console.log(err);
        reject(new Error("Por favor preencha todos os campos corretamente"));
      }
    });
    return promise;
  }

  //Carregar dados da API para o Frontend
  loadDataToUI() {

    //Por cada um dos elementos definidos
    this.props.funisData.forEach(element => {

      //Vamos buscar para o tipo de Lead definido, os eventos que devem existir
      getEventsByLeadType(element.leadType).then(eventsFromAPI => {

        //Criamos um objeto que vai conter todos os eventos
        var events = [];

        //Por cada um dos eventos retornados, adicionamos a var events
        eventsFromAPI.dados.forEach(event => {
          events.push({
            EventoId: event.Id,
            EventoNome: event.Descricao,
            EventoDados: [],
            EventoNumLeads: event.Numero,
            EventoValorLeads: event.Valor,
            EventoEstadoArquivo: event.EstadoArquivo,
            EventoEstadoFinal: event.EstadoFinal,
            key: event.Id
          });
        });

        var tipoLead = eventsFromAPI.tipoLead;
        this.props.dispatch(funilLoadEvents(tipoLead, events));

        //Por cada um dos eventos que recebemos
        for (var i = 0; i < events.length; i++) {

          //Vamos buscar as leads que estão nesse evento atualmente
          getLeadsByEvent(events[i].EventoId, tipoLead)
            .then(result => {

              //Se o result estiver definido e conseguirmos verificar o parametro success
              if (result && typeof result.success !== 'undefined') {

                //Se a API conseguiu buscar os dados
                if (result.success) {

                  this.props.dispatch(funilEventsLoadLead(result.tipoLead, result.evento, result.obj));
                }
              }
            })
            .catch(e => {
              console.log(e);
              console.log("Erro ao obter leads");
            })
        }
      })
        .catch(e => {
          console.log(e);
          console.log("Erro ao obter eventos");
        });
    });
  }

  // Mostrar uma toast, ou seja uma messagebox no ecrã
  showToast(message, type) {
    toast.dismiss();
    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-right",
      type: type
    });
  }

  //Lidar com o clique do botão de adicionar contacto
  handleAddClick() {

    //Criamos uma cópia do objeto contacto
    let contact = { ...this.state.addContact };

    var leadId = this.props.leadOpen.id;
    var currentEvent = this.props.leadOpen.eventId;
    var nextEvent = contact.nextEvent;
    var leadType = this.props.leadOpen.leadType;
    //Se o nextEvent for 0, significa que não é para mover de fase, logo deve continuar na current fase
    nextEvent = (nextEvent == '0') ? currentEvent : nextEvent;

    //Validamos se a data final é inferior a data inicial
    if (contact.dataFim < contact.dataInicio) {
      this.showToast("Data Final deve ser superior à Data Inicial", "error");
      return;
    }

    //Só devemos obrigar a ter id de encomenda se for negocio de tipo consultor
    if (contact.shouldHaveOrderId === true && leadType === 'CS' && (contact.orderId == null || contact.orderId.trim().length <= 0)) {
      this.showToast("Para poder evoluir para essa Fase deve colocar o Número da Encomenda", "error");
      return;
    }

    //Validar dados da API
    this.validarDados(contact)
      .then(() => {

        //Chamamos o método de validar na API
        var promise = new Promise(function (resolve, reject) {

          // Adicionar o contacto a uma lead
          createContactInLead(leadId, contact).then(data => {
            resolve(data);
          })
            .catch(data => {
              reject(data)
            });
        });
        return promise;
      })
      .then(data => {

        //Caso resposta seja suceso
        if (data.success === true) {

          this.props.dispatch(funilChangeLeadEvent(leadId, this.props.leadOpen.leadType, data.obj, currentEvent + "", nextEvent + ""))

          //Pedido à API para ir buscar a informação do numero e valor total do evento atual
          getEventById(currentEvent).then(result => {
            if (result.success === true) {
              this.props.dispatch(changeEventInfo(this.props.leadOpen.leadType, currentEvent, result.obj))
            }
          })

          //Pedido à API para ir buscar a informação do numero e valor total do novo evento
          getEventById(nextEvent).then(result => {
            if (result.success === true) {
              this.props.dispatch(changeEventInfo(this.props.leadOpen.leadType, nextEvent, result.obj))
            }
          })

          //O elemento da lead ainda existir, fazemos scroll até ele
          this.scrollToLead(leadId);

          this.closeModal();
          this.showToast(data.message, "success");

          //Caso resposta inválida
        } else {
          var message = (typeof data.message === 'undefined') ? 'Erro ao criar contacto' : data.message;
          this.showToast(message, "error");
        }
      })
      .catch(err => {
        var message = (typeof err.message === 'undefined') ? 'Erro ao criar contacto' : err.message;
        this.showToast(message, "error");
      })
  }

  //Lidar com alterações no formulário
  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;

    this.setState(prevState => ({
      addContact: {
        ...prevState.addContact,
        [name]: value
      }
    }));
  }

  //Lidar com alterações no campo de mover para 
  handleChangeMoveTo(e) {
    let value = e.target.value;

    var event = this.props.possibleEvents.find(e => e.Id == value);
    var shouldHaveOrderId = false;

    //Se encontrarmos o evento para qual o utilizador se quer mover
    if (event != null && typeof event.EstadoFinal !== 'undefined' && event.EstadoFinal != null) {

      //Verificamos se esse evento é de um estado final, e obrigamos a colocar o id da encomenda
      shouldHaveOrderId = event.EstadoFinal;
    } else {

      //Se o utilizador quiser manter o evento, verificamos se o evento atual necessista de ter o estado final
      shouldHaveOrderId = this.props.leadOpen.estadoFinal;
    }

    this.setState(prevState => ({
      addContact: {
        ...prevState.addContact,
        nextEvent: value,
        shouldHaveOrderId: shouldHaveOrderId,
        orderId: null
      }
    }));
  }

  handleDataFim = DataFim => this.handleChangeDate({ DataFim });

  handleDataInicio = DataInicio => this.handleChangeDate({ DataInicio });

  handleChangeDate = ({ DataFim, DataInicio }) => {
    DataFim = DataFim || this.state.addContact.dataFim;
    DataInicio = DataInicio || this.state.addContact.dataInicio;
    this.setState(prevState => ({
      addContact: {
        ...prevState.addContact,
        dataFim: DataFim,
        dataInicio: DataInicio
      }
    }));
  };

  //Resetar a Informação de Contacto
  resetContactInformation() {
    this.setState({
      addContact: contactInitialState
    });
  }

  //Fechar o formulário de adicionar contacto a lead
  closeModal() {
    this.props.dispatch(changeModalState(false));
    this.resetContactInformation();
  }

  updatedContact(leadId, contact) {

    var leadType = contact.tipoNegocio;
    var leadInfo = {
      Evento: contact.idFase,
      Evolucao: contact.id,
      ValorPrevisto: contact.valor,
      EventoDataInicio: contact.dataInicio,
      EventoDataFim: contact.dataFim
    };

    //Pedido à API para ir buscar a informação do numero e valor total do evento atual
    getEventById(contact.idFase).then(result => {
      if (result.success === true) {
        this.props.dispatch(changeEventInfo(leadType, contact.idFase, result.obj));
      }
    })

    //Mudo os dados visualmente
    this.props.dispatch(funilChangeLeadEvent(leadId, leadType, leadInfo, contact.idFase, contact.idFase));

    //Faço scroll
    this.scrollToLead(leadId);

    //Fecho o form
    this.editModalClose();
  }

  //Fechar a prop de editar o modal
  editModalClose() {
    this.props.dispatch(editLeadContact(null));
  }

  scrollToLead(leadId) {
    //O elemento da lead ainda existir, fazemos scroll até ele
    if (document.getElementById("lead" + leadId) !== null) {
      document.getElementById("lead" + leadId).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center"
      });
    }
  }

  render() {
    let {
      modalIsOpen, editLeadContact
    } = this.props;

    return (
      <span>
        <EditarUltimoContacto leadId={editLeadContact} toggle={this.editModalClose} isOpen={editLeadContact != null} afterUpdate={this.updatedContact} />

        {/*Modal de adicionar contacto */}
        {this.props.leadOpen ?
          <Modal isOpen={modalIsOpen} toggle={this.closeModal.bind(this)} style={{ minWidth: "35%" }}>
            <ModalHeader toggle={this.closeModal.bind(this)}>
              <Label>Adicionar Estado de Negócio</Label>

              {/* Campo para Mostrar o estado de negócio atual */}
              <Row>
                <Col>
                  <Label style={{ fontSize: "15px", margin: "2px 2px" }}>Estado Negócio Atual: </Label>
                  <Label style={cardColumnStyle}> {this.props.leadOpen.nameEvent} </Label>
                </Col>
              </Row>

              {/* Campo para Mostrar Avatar e Nome */}
              <Row>
                <Col>
                  <Avatar
                    name={this.props.leadOpen.nome}
                    round={true}
                    size={"35px"}
                  />
                  <Label style={cardHeader}>{this.props.leadOpen.nome}</Label>
                </Col>
              </Row>

              {/* Campo para Mostrar Telemóvel e Email  */}
              <FormGroup style={{ margin: "0px" }}>
                <Label style={cardColumnStyle}><a href={"tel:" + this.state.addContact.contactoPessoa}>{this.state.addContact.contactoPessoa}</a>
                </Label>
                <Label style={cardColumnStyle}> - <a href={"mailto:" + this.props.leadOpen.email}>{this.props.leadOpen.email}</a>
                </Label>
              </FormGroup>

            </ModalHeader>
            <ModalBody>

              {/* Data de Inicio */}
              <FormGroup>
                <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                  <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                    <Label style={{ margin: "0px", textAlign: "right" }}> Data Início: </Label>
                  </Col>
                  <Col lg={9} md={6} sm={6} xs={8}>
                    <InputGroup>
                      <DateTimePicker calendarIcon={<div className="input-group-text"> <FontAwesomeIcon icon={faCalendarAlt} /> </div>} autoComplete="off" value={this.state.addContact.dataInicio} onChange={this.handleDataInicio} />
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>

              {/* Data Fim */}
              <FormGroup>
                <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                  <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                    <Label style={{ margin: "0px", textAlign: "right" }}> Data Fim: </Label>
                  </Col>
                  <Col lg={9} md={6} sm={6} xs={8}>
                    <InputGroup>
                      <DateTimePicker calendarIcon={<div className="input-group-text"> <FontAwesomeIcon icon={faCalendarAlt} /> </div>} autoComplete="off" value={this.state.addContact.dataFim} onChange={this.handleDataFim} />
                    </InputGroup>
                  </Col>
                </Row>
              </FormGroup>

              {/* Resultado */}
              <FormGroup>
                <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                  <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                    <Label style={{ margin: "0px", textAlign: "right" }}>Resultado:</Label>
                  </Col>
                  <Col lg={9} md={6} sm={6} xs={8}>
                    <Input type="select" id="result" name="result" onChange={this.handleChange}>
                      <option value="">Resultado</option>
                      {this.props.possibleResults && this.props.possibleResults.length > 0 ?
                        (this.props.possibleResults.map((result, j) => {
                          return (
                            <option value={result.Id}>
                              {result.Descricao}
                            </option>
                          );
                        })
                        ) : null}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>

              {/* Valor Previsto */}
              <FormGroup>
                <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                  <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                    <Label style={{ margin: "0px", textAlign: "right" }}>Valor Previsto:</Label>
                  </Col>
                  <Col lg={9} md={6} sm={6} xs={8}>
                    <Input type="number" id="value" name="value" value={this.state.addContact.value} onChange={this.handleChange}></Input>
                  </Col>
                </Row>
              </FormGroup>

              {/* Observações */}
              <FormGroup>
                <Row style={{ display: "flex", alignContent: "center", alignItems: "start", justifyContent: "center", textAlign: "center" }}>
                  <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                    <Label style={{ margin: "0px", textAlign: "right" }}>Observação:</Label>
                  </Col>
                  <Col lg={9} md={6} sm={6} xs={8}>
                    <Input type="textarea" rows={4} placeholder="Observação" id="obs" name="obs" onChange={this.handleChange}></Input>
                  </Col>
                </Row>
              </FormGroup>

              {/* Opção para Mover de Fase */}
              <FormGroup>
                <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                  <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                    <Label style={{ margin: "0px", textAlign: "right" }}>Mover para:</Label>
                  </Col>
                  <Col lg={9} md={6} sm={6} xs={8}>
                    <Input type="select" id="nextEvent" name="nextEvent" onChange={this.handleChangeMoveTo}>

                      { //Se for definido que esta lead obrigatoriamente tem que mover para a próxima fase, não mostramos a opção a Não
                        (this.props.leadOpen.mustMoveToNextEvent === false) ?
                          (<option value={'0'}>Não</option>)
                          : null
                      }

                      {this.props.possibleEvents && this.props.possibleEvents.length > 0 ?
                        (this.props.possibleEvents.map((result, j) => {
                          return (
                            <option value={result.Id}>
                              {result.Descricao}
                            </option>
                          );
                        })
                        ) : null}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>

              {/* Id de Encomenda */}
              {this.state.addContact.shouldHaveOrderId === true ?
                <FormGroup>
                  <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                      <Label style={{ margin: "0px", textAlign: "right" }}>Encomenda:</Label>
                    </Col>
                    <Col lg={9} md={6} sm={6} xs={8}>
                      <Input type="number" id="orderId" name="orderId" value={this.state.addContact.orderId} onChange={this.handleChange}></Input>
                    </Col>
                  </Row>
                </FormGroup>
                : null
              }

            </ModalBody>
            <ModalFooter>
              <Button color="link" onClick={this.closeModal.bind(this)}> Cancelar </Button>
              <Button color="primary" onClick={this.handleAddClick}> Adicionar </Button>{" "}
            </ModalFooter>
          </Modal>
          : null
        }

        {
          this.props.funisData.map(funil => {
            return <FunilLeads title={funil.title} leadType={funil.leadType} eventsWithLeads={funil.events} />
          })
        }
      </span >
    );
  }
}

const mapStateToProps = state => {

  return {
    modalIsOpen: state.modalIsOpen,
    editLeadContact: state.editLeadContact,
    possibleEvents: state.events,
    possibleResults: state.results,
    leadOpen: state.leadOpen,
    funisData: state.funisData
  };
};


export default withRouter(connect(mapStateToProps)(ListaLeads));