import React, { Component } from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";

class Atividade extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <VerticalTimelineElement
        className="vertical-timeline-item"
        icon={<i className="badge badge-dot badge-dot-xl badge-success"> </i>}
      >
        <h4 className="timeline-title">{this.props.TipoAtividade}</h4>
        <p>{this.props.Descricao}</p>
      </VerticalTimelineElement>
    );
  }
}

export default Atividade;
