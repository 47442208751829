import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedNumber } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button } from 'reactstrap';
import { removePromotionalCode, selectPromotionalCode } from "../../actions/checkoutActions";
import SearchInputComponent from '../../helpers/SearchComponents/SearchInputComponent';
import Toast from '../../helpers/Toast';
import { HasError } from "../../helpers/UtilsCheckout";
import IHTPButton from "../Common/Button";
import SkeletonComponent from "../Common/Skeleton";
import IHTPSpacer from '../Common/Spacer';
import "./Checkout.css";

export interface CodigosPromocionaisProps {
	user: any
}

export default function CodigosPromocionais() {

	const [promotionalCode, setPromotionalCode] = useState<string>("");
	const [hasError, setHasError] = useState<boolean>(false);
	const [loadingPromotionalCode, setLoadingPromotionalCode] = useState<boolean>(false);

	const checkoutData = useSelector((state: any) => state.checkoutData);
	const loadingCheckoutPrices = useSelector((state: any) => state.loadingCheckoutPrices);
	const errorCode = useSelector((state: any) => state.errorCode);
	const upline = useSelector((state: any) => state.upline); //Se estiver no seu user, aparece a sua informação. Se estiver num cliente em rede aparece a informação desse cliente

	const dispatch = useDispatch();

	var loadingPrices = loadingCheckoutPrices;

	useEffect(() => {
		let tmpHasError = HasError(errorCode, ["Vales_Codigos", "Creditos_Vales"]);
		setHasError(tmpHasError);
		return () => { }
	}, [errorCode])

	useEffect(() => {
		if (hasError === true) {
			let el = document.getElementById("codigoPromo");
			if (el != null) el.scrollIntoView({ block: "center" });
		}
	}, [hasError])

	const handleFormSubmit = async () => {

		if (promotionalCode == null || promotionalCode.length <= 0) {
			Toast.Show("error", "Código promocional por preencher!");
			return;
		}

		setLoadingPromotionalCode(true);
		var data: any = await dispatch(selectPromotionalCode(promotionalCode));
		setLoadingPromotionalCode(false);

		if (data.error) {
			setPromotionalCode("");

			var resultRemove: any = await dispatch(removePromotionalCode());
			if (resultRemove.message !== undefined) {
				Toast.Show("error", data.message);
			}
			Toast.Show("error", data.error);;
		} else {
			setPromotionalCode("");
			Toast.Show("success", data.message);;
		}
	}

	return (
		<div>
			<div
				className={hasError === true ? "checkoutBox errorBorder"
					: /* Validar se existe créditos metendo a disabled se o valor for nulo ou 0 */
					checkoutData.ValorDescontoCP === 0 ||
						checkoutData.ChaveCodigoPromocional
						? "checkoutBox disabledCodigoBox"
						: "checkoutBox "
				}
				id="codigoPromo"
			>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} className="checkoutBoxTitle">
						Código Promocional
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>

						<IHTPSpacer verticalSpace='10px' />

						<SearchInputComponent showLabel={false} inputType="text"
							onChange={(e: any) => setPromotionalCode(e.target.value)}
							initialValue={promotionalCode}
							value={promotionalCode}
							placeholder="Insira um valor" />

						<IHTPSpacer verticalSpace='10px' />

						<div className='discountsText'>
							<span>
								{checkoutData.ChaveCodigoPromocional ? (
									<b>{checkoutData.ChaveCodigoPromocional}</b>
								) : (
									"Nenhum código utilizado"
								)}
							</span>

							<IHTPSpacer verticalSpace='10px' />

							<span className='promotionalCodeUsedContainer'>
								<span style={{ display: "flex", justifyContent: "center" }}>Desconto utilizado:</span>
								<span style={{ fontWeight: "600", color: "#007bff" }}>
									{loadingPrices === true ? (
										<SkeletonComponent width={50} height={24} />
									) : (
										<FormattedNumber
											value={checkoutData.ValorDescontoCP ?? 0}
											style="currency"
											currency={upline.ID_Moeda}
											minimumFractionDigits={2}
											maximumFractionDigits={2}
										/>
									)}
								</span>
							</span>

						</div>
						<IHTPSpacer verticalSpace='10px' />
						<div className="buttonsValidarCodigos">
							<IHTPButton
								text={"Usar código"}
								onClick={handleFormSubmit}
								buttonStyle={"secondary"}
								loading={loadingPromotionalCode}
							/>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}