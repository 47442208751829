import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row } from "reactstrap";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import NumeroClientes from "./Components/NumeroClientes";
import NumeroClientesRede from "./Components/NumeroClientesRede";
import TabelaClientes from "./Components/TabelaClientes";
import TipoCliente from "./Components/TipoCliente";

class Clientes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Clientes"
            subheading="Página onde pode ver os seus clientes"
            icon="pe-7s-users icon-gradient bg-ripe-malin"
          />
          <Row style={{ display: "flex", alignItems: "center" }}>
            <NumeroClientes />
            <NumeroClientesRede />
            <TipoCliente />
          </Row>
          <TabelaClientes />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}
const mapStateToProp = state => {
  return {};
};

export default withRouter(connect(mapStateToProp)(Clientes));
