import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Joi from "@hapi/joi";
import React from "react";
import Avatar from "react-avatar";
import DateTimePicker from 'react-datetime-picker';
import { connect } from "react-redux";
import { Slide, toast } from "react-toastify";
import { Button, Col, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { createContactInLead, getLeadInfoById } from "../../../actions/leadsActions";

const cardHeader = {
  fontSize: "19px",
  fontWeight: "600",
  textDecoration: "underline",
  margin: "10px"
}

const cardColumnStyle = {
  fontSize: "15px",
  fontWeight: "600",
  margin: "2px 2px"
}

const contactInitialState = {
  currentEvent: '0',
  nextEvent: '0',
  result: 0,
  obs: "",
  value: '0',
  nomePessoa: "",
  contactoPessoa: "",
  dataInicio: new Date(),
  dataFim: getCurrentDatePlusMinutes(15),
  shouldHaveOrderId: false,
  orderId: null,
}

function getCurrentDatePlusMinutes(minutes) {
  var value = new Date();
  value = new Date(value.getTime() + minutes * 60000);
  return value;
}

//Schema para Joi validar
const schema = Joi.object().keys({
  eventId: Joi.string()
    .trim()
    .empty()
    .min(1)
    .max(2)
    .required()
    .messages({
      'any.required': `Evento é obrigatório`,
      'string.empty': `Evento deve estar preenchido`,
      'string.base': `Evento deve estar preenchido`,
      'string.max': `Evento deve ter no máximo 2 caracteres`,
    }),
  resultId: Joi.number()
    .integer()
    .min(1)
    .required()
    .messages({
      'any.required': `Resultado é obrigatório`,
      'number.base': `Resultado deve estar selecionado`,
      'number.min': `Resultado deve estar selecionado`,
    }),
  nomePessoa: Joi.string()
    .trim()
    .empty()
    .min(2)
    .max(100)
    .required()
    .messages({
      'any.required': `Nome da Pessoa é obrigatório`,
      'string.empty': `Nome da Pessoa deve estar preenchido`,
      'string.base': `Nome da Pessoa deve estar preenchido e está vazio`,
      'string.min': `Nome da Pessoa deve ter no mínimo 2 caracteres`,
      'string.max': `Nome da Pessoa deve ter no máximo 100 caracteres`
    }),
  contactoPessoa: Joi.string()
    .trim()
    .empty()
    .max(100)
    .required()
    .messages({
      'any.required': `Contacto da Pessoa é obrigatório`,
      'string.empty': `Contacto da Pessoa deve estar preenchido`,
      'string.base': `Contacto da Pessoa deve estar preenchido e está vazio`,
      'string.max': `Contacto da Pessoa deve ter no máximo 100 caracteres`
    }),
  dataInicio: Joi.date()
    .required()
    .messages({
      'any.required': `Data Inicial é obrigatória`,
      'date.base': `Data Inicial deve ser uma data válida`
    }),
  dataFim: Joi.date()
    .required()
    .messages({
      'any.required': `Data Inicial é obrigatória`,
      'date.base': `Data Inicial deve ser uma data válida`
    }),
  value: Joi.number()
    .positive()
    .allow(0)
    .required()
    .messages({
      'any.required': `Valor é obrigatório`,
      'number.base': `Valor deve ser um número`,
      'number.positive': `Valor deve ser um número positivo`,
    })

});

class NovoEstadoNegocio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      addContact: contactInitialState,
      email: '',
      nome: '',
      telemovel: '',
      nomeEventoAtual: '',
      tipoEstado: [],
      possibleEvents: [],
      possibleResults: [],
      leadType: null,
      apenasRegistada: false, //Serve para indicar se esta lead está apenas registada ou se ja teve mais evolucao
      error: false,
      errorMessage: "",
      eventoAtualEstadoFinal: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.validarDados = this.validarDados.bind(this);
    this.getUpdatedLeadInfo = this.getUpdatedLeadInfo.bind(this);
    this.handleChangeMoveTo = this.handleChangeMoveTo.bind(this);
  }

  //Lidar com as mudanças na Data
  handleChangeDate = ({ DataFim, DataInicio }) => {
    DataFim = DataFim || this.state.addContact.dataFim;
    DataInicio = DataInicio || this.state.addContact.dataInicio;
    this.setState(prevState => ({
      addContact: {
        ...prevState.addContact,
        dataFim: DataFim,
        dataInicio: DataInicio
      }
    }));
  };

  //Lidar com a mudança na Data de Fim
  handleDataFim = DataFim => this.handleChangeDate({ DataFim });

  //Lidar com a mudança na Data de Inicio
  handleDataInicio = DataInicio => this.handleChangeDate({ DataInicio });

  //Lidar com as mudanças nos valores das textboxes
  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(prevState => ({
      addContact: {
        ...prevState.addContact,
        [name]: value
      }
    }));
  }

  //Lidar com alterações no campo de mover para 
  handleChangeMoveTo(e) {
    let value = e.target.value;

    var event = this.state.possibleEvents.find(e => e.Id == value);
    var shouldHaveOrderId = false;

    //Se encontrarmos o evento para qual o utilizador se quer mover
    if (event != null && typeof event.EstadoFinal !== 'undefined' && event.EstadoFinal != null) {

      //Verificamos se esse evento é de um estado final, e obrigamos a colocar o id da encomenda
      shouldHaveOrderId = event.EstadoFinal;
    } else {

      //Se o utilizador quiser manter o evento, verificamos se o evento atual necessita de ter o estado final
      shouldHaveOrderId = this.state.eventoAtualEstadoFinal;
    }

    this.setState(prevState => ({
      addContact: {
        ...prevState.addContact,
        nextEvent: value,
        shouldHaveOrderId: shouldHaveOrderId,
        orderId: null
      }
    }));
  }

  //Vamos buscar a informação atualiza sobre a Lead
  getUpdatedLeadInfo() {
    getLeadInfoById(this.props.id)
      .then(result => {

        //Se der sucesso ao ir buscar a informação da API
        if (result.success === true) {

          var obj = result.obj;
          var nextEvent = '0';
          var shouldHaveOrderId = false;

          //Se for obrigatório mudar para proxima fase, verificamos se nos dados da proxima fase é obrigatório ter id de encomenda
          if (obj.ApenasRegistada === true && obj.EventosPossiveis.length > 0) {
            nextEvent = obj.EventosPossiveis[0].Id;
            shouldHaveOrderId = obj.EventosPossiveis[0].EstadoFinal;

          //Se não formos obrigatoriamente para a proxima fase, a fase atual pode ser um evento final, logo verificamos se 
          //precisamos de obrigar o utilizador a colocar o id da encomenda
          }else {
            shouldHaveOrderId = obj.EventoEstadoFinal;
          }

          this.setState(prevState => ({
            ...prevState,
            nome: obj.Nome,
            email: obj.Email,
            nomeEventoAtual: obj.NomeEvento,
            possibleEvents: obj.EventosPossiveis,
            possibleResults: obj.ResultadosPossiveis,
            telemovel: obj.TelemovelContacto,
            apenasRegistada: obj.ApenasRegistada,
            eventoAtualEstadoFinal: obj.EventoEstadoFinal,
            leadType: obj.IdTipoLead,
            addContact: {
              ...prevState.addContact,
              nextEvent: nextEvent,
              nomePessoa: obj.Nome,
              contactoPessoa: obj.TelemovelContacto,
              value: obj.ValorPrevisto + '',
              dataInicio: new Date(),
              dataFim: getCurrentDatePlusMinutes(15),
              shouldHaveOrderId: shouldHaveOrderId,
              orderId: null,
            }
          }));

          //Se der erro ao ir buscar a informação da API
        } else {
          this.setState({
            error: true,
            errorMessage: result.message
          });
        }

      })
      .catch(err => {
        this.setState({
          error: true,
          errorMessage: "Erro ao obter informação para Adicionar Estado de Negócio"
        });
      });
  }

  //Quando os componentes atualizarem
  componentDidUpdate(prevProps) {

    //Quando carregamos para abrir o form vamos buscar a informação mais atualizada da lead
    if (prevProps.isOpen === false && this.props.isOpen === true) {
      this.getUpdatedLeadInfo();
    }
  }

  // Mostrar uma toast, ou seja uma messagebox no ecrã
  showToast(message, type) {
    toast.dismiss();
    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-right",
      type: type
    });
  }

  //Lidar com o click no botão de adicionar estado de negócio
  handleAddClick() {

    //Criamos uma cópia do objeto contacto
    let contact = { ...this.state.addContact };

    var leadId = this.props.id;

    //Validamos se a data final é inferior a data inicial
    if (contact.dataFim < contact.dataInicio) {
      this.showToast("Data Final deve ser superior à Data Inicial", "error");
      return;
    }

    //Se contiver , nós substituimos
    if (contact.value.indexOf(",") >= 0) {
      contact.value = contact.value.replace(",", ".");
    }

    //Só deve obrigar a ter o id de encomenda se for para um negocio do tipo de consultor
    if (contact.shouldHaveOrderId === true && this.state.leadType === 'CS' && (contact.orderId == null || contact.orderId.trim().length <= 0)) {
      this.showToast("Para poder evoluir para essa Fase deve colocar o Número da Encomenda", "error");
      return;
    }

    var that = this;
    //Validar dados da API
    this.validarDados(contact)
      .then(() => {

        //Chamamos o método de validar na API
        var promise = new Promise(function (resolve, reject) {

          // Adicionar o contacto a uma lead
          createContactInLead(leadId, contact).then(data => {

            //Se o contacto tiver sido criado com sucesso
            if (data.success === true) {
              that.showToast(data.message, "success");
              that.setState(prevState => ({
                addContact: contactInitialState
              }));

              that.props.toggle();
              that.props.updateData();

              //Se existir algum erro ao criar o contacto
            } else {
              that.showToast(data.message, "error");
            }

            resolve(data);
          })
            .catch(data => {
              reject(data)
            });
        });
        return promise;
      })
      .catch(err => {
        var message = (typeof err.message === 'undefined') ? 'Erro ao criar contacto' : err.message;
        that.showToast(message, "error");

      })
  }

  //Formulário para validar os dados de criar contacto
  validarDados(contact) {
    var promise = new Promise(function (resolve, reject) {
      try {
        //Validamos os campos atraves do Joi
        var validationResult = schema.validate(
          {
            eventId: contact.nextEvent,
            resultId: contact.result,
            nomePessoa: contact.nomePessoa,
            contactoPessoa: contact.contactoPessoa,
            dataInicio: contact.dataInicio,
            dataFim: contact.dataFim,
            value: contact.value
          },
          { abortEarly: true });

        //Se error==null significa que não houve erro
        if (validationResult.error == null) {
          resolve();

          // Se houver erro vamos mostrar o erro e mostramos ao utilizadro
        } else {
          var errorMessage = validationResult.error.details[0].message;
          reject(new Error(errorMessage));
        }
      } catch (err) {
        reject(new Error("Por favor preencha todos os campos corretamente"));
      }
    });
    return promise;
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
        style={{ minWidth: "35%" }}
      >
        <ModalHeader toggle={this.props.toggle}>
          <Label>Adicionar Estado de Negócio</Label>

          {/* Campo para Mostrar o estado de negócio atual */}
          <Row>
            <Col>
              <Label style={{ fontSize: "15px", margin: "2px 2px" }}>Estado Negócio Atual: </Label>
              <Label style={cardColumnStyle}> {this.state.nomeEventoAtual} </Label>
            </Col>
          </Row>

          {/* Campo para Mostrar Avatar e Nome */}
          <Row>
            <Col>
              <Avatar
                name={this.state.nome}
                round={true}
                size={"35px"}
              />
              <Label style={cardHeader}>{this.state.nome}</Label>
            </Col>
          </Row>

          {/* Campo para Mostrar Telemóvel e Email  */}
          <FormGroup style={{ margin: "0px" }}>
            <Label style={cardColumnStyle}><a href={"tel:" + this.state.telemovel}>{this.state.telemovel}</a>
            </Label>
            <Label style={cardColumnStyle}> - <a href={"mailto:" + this.state.email}>{this.state.email}</a>
            </Label>
          </FormGroup>

        </ModalHeader>
        <ModalBody>

          {this.state.error ?
            (
              <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                <Col>
                  <Label style={{ color: "red", fontSize: "20px", fontWeight: "600" }}>{this.state.errorMessage}</Label>
                </Col>
              </Row>) :
            (
              <div>
                {/* Data de Inicio */}
                < FormGroup >
                  <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                      <Label style={{ margin: "0px", textAlign: "right" }}> Data Início:</Label>
                    </Col>
                    <Col lg={9} md={6} sm={6} xs={8}>
                      <InputGroup>
                        <DateTimePicker calendarIcon={<div className="input-group-text"> <FontAwesomeIcon icon={faCalendarAlt} /> </div>} autoComplete="off" value={this.state.addContact.dataInicio} onChange={this.handleDataInicio} />
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>

                {/* Data Fim */}
                <FormGroup>
                  <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                      <Label style={{ margin: "0px", textAlign: "right" }}> Data Fim:</Label>
                    </Col>
                    <Col lg={9} md={6} sm={6} xs={8}>
                      <InputGroup>
                        <DateTimePicker calendarIcon={<div className="input-group-text"> <FontAwesomeIcon icon={faCalendarAlt} /> </div>} autoComplete="off" value={this.state.addContact.dataFim} onChange={this.handleDataFim} />
                      </InputGroup>
                    </Col>
                  </Row>
                </FormGroup>

                {/* Resultado */}
                <FormGroup>
                  <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                      <Label style={{ margin: "0px", textAlign: "right" }}>Resultado:</Label>
                    </Col>
                    <Col lg={9} md={6} sm={6} xs={8}>
                      <Input type="select" id="result" name="result" onChange={this.handleChange}>
                        <option value="">Resultado</option>
                        {this.state.possibleResults && this.state.possibleResults.length > 0 ?
                          (this.state.possibleResults.map((result, j) => {
                            return (
                              <option value={result.Id}>
                                {result.Descricao}
                              </option>
                            );
                          })
                          ) : null}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>

                {/* Valor Previsto */}
                <FormGroup>
                  <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                      <Label style={{ margin: "0px", textAlign: "right" }}>Valor Previsto:</Label>
                    </Col>
                    <Col lg={9} md={6} sm={6} xs={8}>
                      <Input type="number" id="value" name="value" value={this.state.addContact.value} onChange={this.handleChange}></Input>
                    </Col>
                  </Row>
                </FormGroup>

                {/* Campo Observação */}
                <FormGroup>
                  <Row style={{ display: "flex", alignContent: "center", alignItems: "start", justifyContent: "center", textAlign: "center" }}>
                    <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                      <Label style={{ margin: "0px", textAlign: "right" }}>Observação:</Label>
                    </Col>
                    <Col lg={9} md={6} sm={6} xs={8}>
                      <Input type="textarea" name="obs" id="obs" rows={4} value={this.state.addContact.obs} onChange={this.handleChange} placeholder="Observação" />
                    </Col>
                  </Row>
                </FormGroup>

                {/* Opção para Mover de Fase */}
                <FormGroup>
                  <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                    <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                      <Label style={{ margin: "0px", textAlign: "right" }}>Mover para:</Label>
                    </Col>
                    <Col lg={9} md={6} sm={6} xs={8}>
                      <Input type="select" id="nextEvent" name="nextEvent" onChange={this.handleChangeMoveTo}>

                        {/* Só mostra a opção Não se a Lead estiver noutro estado sem ser estar apenas registada,
               tornando obrigatório mudar o estado caso a Lead esteja apenas registada*/}
                        {this.state.apenasRegistada !== true ? (<option value={'0'}>Não</option>) : (null)}

                        {this.state.possibleEvents && this.state.possibleEvents.length > 0 ?
                          (this.state.possibleEvents.map((result, j) => {
                            return (
                              <option value={result.Id}>
                                {result.Descricao}
                              </option>
                            );
                          })
                          ) : null}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>

                {/* Id de Encomenda */}
                {this.state.addContact.shouldHaveOrderId === true ?
                  <FormGroup>
                    <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                      <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                        <Label style={{ margin: "0px", textAlign: "right" }}>Encomenda:</Label>
                      </Col>
                      <Col lg={9} md={6} sm={6} xs={8}>
                        <Input type="number" id="orderId" name="orderId" value={this.state.addContact.orderId} onChange={this.handleChange}></Input>
                      </Col>
                    </Row>
                  </FormGroup>
                  : null
                }

              </div>
            )
          }

        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={this.props.toggle}>
            Cancelar
          </Button>
          <Button color="primary" onClick={this.handleAddClick} disabled={this.state.error}>
            Adicionar
          </Button>{" "}
        </ModalFooter>
      </Modal >
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(NovoEstadoNegocio);

