import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { paymentPaypalConfirmed } from "../../../actions/checkoutActions";
import Loading from "../../Loading/Loading";
import "./Payment.css";

// https://developer.paypal.com/demo/checkout/#/pattern/responsive
// https://www.npmjs.com/package/react-paypal-express-checkout

const client = {
  sandbox: process.env.REACT_APP_PAYPAL_CLIENT_SANDBOX_KEY,
  production: process.env.REACT_APP_PAYPAL_CLIENT_PRODUCTION_KEY,
};
class PaymentPaypal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelado: false,
      sucesso: false,
    };

    this.onSuccess = this.onSuccess.bind(this);
  }

  onSuccess(payment) {
    this.props
      .dispatch(paymentPaypalConfirmed(this.props.encomenda))
      .then(data => {
        this.setState({ sucesso: true });
        setTimeout(function () {
          window.location.href = process.env.REACT_APP_MY_WEBSITE_URL + "encomendas/";
        }, 2000);
      });
  }

  onCancel() {
    // this.props
    //   .dispatch(paymentPaypalCanceled(this.props.encomenda))
    //   .then(data => {
    //     this.setState({ cancelado: true });
    //   });
  };

  onError(err) {
    alert("Erro no pagamento!");
    console.log("Error!", err);
  }

  render() {
    return (
      <Row className="payment">
        {!this.state.sucesso ? (
          !this.state.cancelado ? (
            <Col xs={12} sm={12} md={12} lg={12}>
              <p>Acede a este link para efetuar o pagamento: </p>
              {this.props.total && this.props.currency ? (
                this.props.loading ? (
                  <Loading />
                ) : (
                  <PaypalExpressBtn
                    env={process.env.REACT_APP_PAYPAL_ENV}
                    client={client}
                    currency={this.props.currency.toUpperCase()}
                    total={parseFloat(this.props.total)}
                    onError={this.onError}
                    onSuccess={this.onSuccess}
                    onCancel={this.onCancel}
                    style={{ color: "gold", zIndex: "1" }}
                  />
                )
              ) : null}
            </Col>
          ) : (
            <Col xs={12} sm={12} md={12} lg={12}>
              <p>
                <h1>Pagamento cancelado</h1>
              </p>
            </Col>
          )
        ) : (
          <Col xs={12} sm={12} md={12} lg={12}>
            <p>
              <h3>Pagamento realizado com sucesso. A redirecionar...</h3>
            </p>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    currencies: state.currencies,
    loading: state.loading,
  };
};

export default withRouter(connect(mapStateToProps)(PaymentPaypal));
