import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitleContactos from "../../helpers/Layout/PageTitleContactos";
import ListaLeads from "./Components/ListaLeads";

class Marketing extends Component {
  constructor() {
    super();
    this.state = {};
    this.updateData = this.updateData.bind(this);
  }

  updateData() {
    this.child.getData();
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleContactos
            heading="Leads"
            subheading="Página onde pode ver as leads"
            icon="lnr-briefcase icon-gradient bg-ripe-malin"
            updateData={this.updateData}
          />
          <ListaLeads onRef={ref => (this.child = ref)} />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Marketing;
