import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { getOrderDetail } from "../../actions/encomendasActions";
import PageTitleAlt5 from "../../helpers/Layout/PageTitleAlt5";
import Detalhe from "./Components/Detalhe";
import EstadosEncomenda from "./Components/EstadosEncomenda";
import ItensEncomenda from "./Components/ItensEncomenda";

class DetalheEncomenda extends Component {

  constructor() {
    super();
    this.state = {
      details: [],
      items: [],
      states: [],
      dataSize: 0,
      isLoading: true,
    };
  }

  componentDidMount() {
    getOrderDetail(this.props.id)
      .then(result => {

        if (result.success !== true) return;
        
        this.setState(prevState => ({
          ...prevState,
          details: result.obj.Detalhes[0],
          dataSize: result.obj.Detalhes.length,
          items: result.obj.Itens,
          states: result.obj.Estados,
          isLoading: false
        }));
      })
      .catch(err => {

      })
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt5
            heading="Detalhe da Encomenda"
            subheading="Página onde pode ver o detalhe de uma encomenda"
            icon="pe-7s-graph icon-gradient bg-ripe-malin"
            id={this.props.id}
          />
          <Detalhe details={this.state.details} dataSize={this.state.dataSize} isLoading={this.state.isLoading} />
          <ItensEncomenda items={this.state.items} isLoading={this.state.isLoading} />
          <EstadosEncomenda id={this.props.id} states={this.state.states} isLoading={this.state.isLoading} />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default DetalheEncomenda;
