import React, { useState } from 'react';
import ReactTable, { Column, SortingRule } from 'react-table';

interface TableProps {
    currentPage: number,
    totalPages: number,
    pageSize: number,
    countData: number,
    pageSizes: number[],
    isLoading: boolean,
    columns: Column<object>[],
    data: object[],
    sortOrder: SortingRule<object>[],
    fetchData: (page: number, pageSize: number, sort: SortingRule<object>[]) => void
}

export default function TableComponent(props: TableProps) {

    const onPageChange = (page: number) => {
        props.fetchData(page, props.pageSize, props.sortOrder);
    }

    const onPageSizeChange = (pageSize: number) => {
        var newPageNumber = Math.ceil(props.countData / pageSize) - 1;
        if (props.currentPage > newPageNumber) {
            props.fetchData(newPageNumber, pageSize, props.sortOrder);
        }
        else {
            props.fetchData(props.currentPage, pageSize, props.sortOrder);
        }
    }

    const onSortChange = (sort: SortingRule<object>[]) => {
        props.fetchData(props.currentPage, props.pageSize, sort);
    }

    return (
        <ReactTable
            className="-highlight tableReact"
            data={props.data}
            pageSizeOptions={props.pageSizes}
            defaultPageSize={5}
            showPageJump={true}
            sortable={true}
            page={props.currentPage}
            pages={props.totalPages}
            pageSize={props.pageSize}
            showPagination={true}
            showPaginationTop={false}
            showPaginationBottom={true}
            loading={props.isLoading}
            getTheadProps={(state, rowInfo, column) => {
                return {
                    style: {
                        "text-align": "center"
                    }
                };
            }}
            getTdProps={(state, rowInfo, column, instance) => {
                return {
                    style: {
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center"
                    }
                }
            }}
            getTrProps={(state, rowInfo, column, instance) => {
                return {
                    style: {
                        "text-align": "center",
                    }
                };
            }}
            columns={props.columns}
            previousText="Anterior"
            nextText={"Próxima"}
            pageText={"Página"}
            ofText={"de"}
            rowsText={"linhas"}
            manual={true}
            sorted={props.sortOrder}
            noDataText={"Não existem produtos para a pesquisa"}
            onPageChange={(page: number) => { onPageChange(page); }}
            onPageSizeChange={(newPageSize: number) => { onPageSizeChange(newPageSize); }}
            onSortedChange={(sort: SortingRule<object>[]) => { onSortChange(sort) }}
        />
    )
}