import dateFormat from "dateformat";
import React, { Component } from 'react';
import Avatar from "react-avatar";
import Countdown, { zeroPad } from "react-countdown";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { Card, CardBody, Col, FormGroup, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { changeCurrentLeadInFocus, changeModalState, editLeadContact, getLeadById, leadRemoveFlashing } from "../../../../actions/leadsActions";
import "./ItemLead.css";

var renderer = ({ total, days, hours, minutes, seconds, milliseconds, completed }) => {

    var style = { textAlign: "center", fontWeight: "bold", fontSize: "18px", color: "#CD5C5C" };

    if (days < 1) {
        if (hours < 1) {
            style.fontSize = "15px";
        }
    }

    if (completed) {
        return <span style={style}>Lead irá ser atribuída a outro Consultor</span>;
    } else {
        return <span style={style}>{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }

};

const cardHeader = {
    fontSize: "19px",
    fontWeight: "600",
    textDecoration: "underline",
    margin: "10px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "80%",
    textOverflow: "ellipsis"
}

const cardText = {
    fontWeight: "600",
    fontSize: "15px",
    marginRight: "7px",
    cursor: "pointer"
}

const cardDate = {
    fontWeight: "600",
    fontSize: "14px",
}

const cardTextError = {
    fontWeight: "bold",
    color: "red"
}

let intervalColor;

class ItemLead extends Component {

    constructor(props) {
        super(props);

        var estadoRegisto = this.props.lead.EstadoRegisto;
        var isManualLead = this.props.lead.Origem.startsWith("Manual");
        var estadoArquivo = this.props.lead.EstadoArquivo;

        var podeEditarContacto = true;

        //Se estiver no estado de registo e a Lead não for manual não pode editar
        if (estadoRegisto === true && isManualLead === false) podeEditarContacto = false;

        this.state = {
            errorMessage: "",
            shouldFlash: false,
            isFlashing: false,
            flashColor: ((estadoRegisto === true && isManualLead === false) ? "#FA8072" : "#707070"),
            flashBorderWidth: ((estadoRegisto === true && isManualLead === false) ? "3" : "1"),
            isManualLead: isManualLead,
            estadoRegisto: this.props.lead.EstadoRegisto,
            podeEditarContacto: podeEditarContacto
        };

        this.removeFlash = this.removeFlash.bind(this);
        this.changeColor = this.changeColor.bind(this);
        this.enterLeadDetailsPage = this.enterLeadDetailsPage.bind(this);
        this.openEditModal = this.openEditModal.bind(this);
    }

    openModal() {

        //Resetamos a mensagem de erro
        this.setState({ errorMessage: "" });

        //Vamos buscar a informação da Lead por ID
        this.props.dispatch(getLeadById(this.props.id))
            .then((dados) => {

                //Caso o pedido tenha sido feito com sucesso
                if (dados.success === true) {

                    //Guardamos os dados da lead
                    var lead = dados.obj;

                    //Atualizamos os dados em foco
                    this.props.dispatch(changeCurrentLeadInFocus(
                        {
                            nome: lead.Nome,
                            id: this.props.id,
                            leadType: this.props.leadType,
                            eventId: this.props.lead.Evento,
                            nameEvent: lead.NomeEvento,
                            email: lead.Email,
                            value: lead.ValorPrevisto + '',
                            nomeContacto: lead.NomeContacto,
                            telemovelContacto: lead.TelemovelContacto,
                            estadoFinal: lead.EventoEstadoFinal,
                            mustMoveToNextEvent: lead.EventoEstadoRegisto //Se a lead estiver no registo, é obrigatório mover de fase
                        }));


                    //Abrimos o Modal
                    this.props.dispatch(changeModalState(true));

                    //Caso tenha dado erro, mostramos a mensagem que veio da API
                } else {
                    this.setState({ errorMessage: dados.message });
                }
            }).catch(err => {
                console.log(err);
                this.setState({ errorMessage: "Erro ao carregar dados sobre a Lead" });
            });
    }

    componentDidMount() {
        if (typeof this.props.flash !== 'undefined' && this.props.flash == 1) {

            intervalColor = setInterval(this.changeColor, 250);

            setTimeout(this.removeFlash, 5000);
        }
    }

    changeColor() {
        var color = "";
        var borderWidth = "";

        if (this.state.flashColor === '#707070') {
            color = '#3ac47d';
            borderWidth = "5";
        } else {
            color = '#707070';
            borderWidth = "2";
        }

        this.setState({
            flashColor: color,
            flashBorderWidth: borderWidth
        });
    }

    removeFlash() {

        clearInterval(intervalColor);

        this.props.dispatch(leadRemoveFlashing(this.props.id, this.props.leadType, this.props.lead.Evento));

        this.setState({
            flashColor: "#707070",
            flashBorderWidth: "1"
        });
    }

    //Entrar na página de detalhes
    enterLeadDetailsPage() {
        window.location.href =
            "/negocio/" + this.props.id;
    }

    //Abrir o modal de editar
    openEditModal() {
        this.props.dispatch(editLeadContact(this.props.lead.Lead));
    }

    render() {
        return (
            <div>
                <Card id={"lead" + this.props.lead.Lead} style={{ border: `${this.state.flashBorderWidth}px solid ${this.state.flashColor}`, marginBottom: "10px", borderRadius: "10px", backgroundColor: this.state.isManualLead ? "#F0FFFF" : "white" }}>
                    <CardBody style={{ padding: "5px 8px 8px" }}>
                        <FormGroup style={{ marginBottom: "0px" }}>
                            <Row style={{ margin: "0px", padding: "0px", display: "flex", alignItems: "center" }}>
                                <Col md={10} sm={10} xs={10} style={{ margin: "0px", padding: "0px", }}>
                                    <a className="changeTagStyle" href={"/negocio/" + this.props.id}>
                                        <Avatar name={this.props.lead.Nome} round={true} size={"35px"} />
                                        <Label style={cardHeader} >{this.props.lead.Nome}</Label>
                                    </a>
                                </Col>
                                <Col md={2} sm={2} xs={2} style={{ margin: "0px", padding: "0px", textAlign: "right" }}><Label style={cardText}>{this.props.lead.Valor}€</Label></Col>
                            </Row>
                        </FormGroup>

                        <FormGroup style={{ marginTop: "10px", marginBottom: "10px", justifyContent: "left" }}>
                            <Row style={{ margin: "0px", padding: "0px" }}>
                                <Col md={9} xs={9} sm={9} style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                                    <a href={"tel:" + this.props.lead.TelemovelContacto}>
                                        <Label style={cardText}>Telemóvel: {this.props.lead.TelemovelContacto}</Label>
                                    </a>
                                </Col>
                                <Col md={3} xs={3} sm={3} style={{ textAlign: "right", margin: "0px", padding: "0px" }}>
                                    {(this.state.podeEditarContacto === true) ?
                                        <div>
                                            <Label className="lnr-pencil" id={"to2oltip" + this.props.lead.Lead} onClick={this.openEditModal} style={{ flex: "1", fontSize: "30px", marginRight: "2px", textAlign: "right", cursor: "pointer" }} />
                                            <UncontrolledTooltip placement="right" target={"to2oltip" + this.props.lead.Lead}>
                                                Editar Contacto
                                            </UncontrolledTooltip >
                                        </div>
                                        : null}
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup style={{ margin: "0px" }}>
                            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", margin: "0px", padding: "0px" }}>
                                {(this.state.estadoRegisto === true && this.state.isManualLead === false) ?
                                    (
                                        <Col md={9} xs={9} sm={9} style={{ textAlign: "center", display: "flex", alignContent: "center", alignItems: "center", justifyContent: "left" }}>
                                            <Countdown renderer={renderer} date={Date.parse(this.props.lead.DataFimTratamento)}></Countdown>
                                        </Col>
                                    ) :
                                    (
                                        <Col md={9} xs={9} sm={9} style={{ textAlign: "left", margin: "0px", padding: "0px" }}>
                                            <Label style={cardDate}>{dateFormat(this.props.lead.DataInicio, "dd/mm/yyyy HH:MM")}</Label>
                                            <Label style={{ marginLeft: "3px", marginRight: "3px" }}> - </Label>
                                            <Label style={cardDate}> {dateFormat(this.props.lead.DataFim, "dd/mm/yyyy HH:MM")}</Label>
                                        </Col>
                                    )
                                }

                                <Col md={3} xs={3} sm={3} style={{ textAlign: "right", margin: "0px", padding: "0px" }}>
                                    <Label className="lnr-plus-circle" id={"tooltip" + this.props.lead.Lead} onClick={this.openModal.bind(this)} style={{ flex: "1", fontSize: "40px", textAlign: "right", cursor: "pointer" }} />
                                    <UncontrolledTooltip placement="right" target={"tooltip" + this.props.lead.Lead}>
                                        Adicionar Contacto
                                    </UncontrolledTooltip >
                                </Col>
                            </Row>
                            <Row hidden={this.state.errorMessage.length > 0 ? false : true}>
                                <Col md={12}><Label style={cardTextError}>{this.state.errorMessage}</Label></Col>
                            </Row>
                        </FormGroup>
                    </CardBody>
                </Card>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        modalIsOpen: state.modalIsOpen,
        editLeadContact: state.editLeadContact
    };
};

export default withRouter(connect(mapStateToProps)(ItemLead));