import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { Col, Row } from "reactstrap";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import Aniversarios from "./Components/Aniversarios";
import Calendario from "./Components/Calendario";
import ComissoesDisponiveis from "./Components/ComissoesDisponiveis";
import ComparativoComissoes from "./Components/ComparativoComissoes";
import Downlines from "./Components/Downlines";
import Faturacao12mesesRede from "./Components/Faturacao12mesesRede";
import FaturacaoAcumulada from "./Components/FaturacaoAcumulada";
import FaturacaoMesCorrente from "./Components/FaturacaoMesCorrente";
import Ocorrencias from "./Components/Ocorrencias";

class Home extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Vista geral"
            subheading="Página onde pode ver alguns indicadores"
            icon="pe-7s-home icon-gradient bg-ripe-malin"
          />
          <Row style={{ display: "flex", alignItems: "center" }}>
            <FaturacaoMesCorrente />
            <FaturacaoAcumulada />
            <ComissoesDisponiveis />
          </Row>
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <Calendario />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="6" lg="6">
              <ComparativoComissoes />
            </Col>
            <Col xs="12" sm="12" md="6" lg="6">
              <Faturacao12mesesRede />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="12" lg="12">
              <Ocorrencias />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="6" lg="6">
              <Aniversarios />
            </Col>
            <Col xs="12" sm="12" md="6" lg="6">
              <Downlines />
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Home;
