import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { addBillingAddressCheckout, addNewBillingAddress, getBillingAddresses, setBillingAddress, setErrorCodeCheckout } from '../../../actions/checkoutActions';
import SearchInputComponent from '../../../helpers/SearchComponents/SearchInputComponent';
import SearchListComponent, { ListProps } from '../../../helpers/SearchComponents/SearchListComponent';
import Toast from '../../../helpers/Toast';
import { ValidateBillingAddress } from '../../../helpers/UtilsCheckout';
import { AddressDTO } from "../../../Models/AddressDTO";
import IHTPButton from '../../Common/Button';
import IHTPSpacer from '../../Common/Spacer';

export interface CreateBillingAddressProps {
    onClose: () => void
}

const novaMoradaInitialState: AddressDTO = {
    id_cliente_morada: "",
    nome: "",
    nif: "",
    morada: "",
    localidade: "",
    codigoPostal: "",
    principal: "N",
    id_pais: "",
    ID_Tipo_Morada: ""
}

export default function CreateBillingAddress(props: CreateBillingAddressProps) {
    const [loadingAddAddress, setLoadingAddAddress] = useState<boolean>(false);
    const dispatch = useDispatch();

    const upline = useSelector((state: any) => state.upline);
    const [defaultAddressOptions, setDefaultAddressOptions] = useState<ListProps[]>([
        { value: "N", label: "Não" },
        { value: "S", label: "Sim" }
    ]);

    //billingAddress é usado para guardar a nova morada de faturação globalmente enquanto esta não é criada
    const billingAddress: AddressDTO = useSelector((state: any) => state.billingAddress);
    const billingAddresses = useSelector((state: any) => state.billingAddresses);
    const allPaises = useSelector((state: any) => state.allPaises);

    useEffect(() => {
        //Colocamos os dados a limpo
        dispatch(setBillingAddress(novaMoradaInitialState));
    }, [])

    const paisesOptions: ListProps[] = useMemo(() => {
        if (typeof allPaises === "undefined" || allPaises == null) return [];

        var paises: ListProps[] = [];
        allPaises.forEach(i => {
            paises.push({
                value: i.alpha3,
                label: i.name
            });
        })
        return paises;
    }, [allPaises])

    const setNovaMorada = (address: AddressDTO) => {
        dispatch(setBillingAddress(address));
    }

    const handleAddAddressValidateNif = (value: string) => {
        if (billingAddress.id_pais !== "PRT") {
            setNovaMorada({
                ...billingAddress,
                nif: value
            });
            return;
        }

        if (value.length < 10 && /^\d*$/.test(value)) {
            setNovaMorada({
                ...billingAddress,
                nif: value
            });
        }
    }

    const handleAddBillingChangePais = (value: string) => {
        setNovaMorada({
            ...billingAddress,
            id_pais: value,
            nif: ""
        });
    }

    const handleFormSubmit = async () => {

        var resultValidation = ValidateBillingAddress(billingAddress, dispatch);
        if (resultValidation.success === false) {
            Toast.Show("error", `${resultValidation.message}`);
            return;
        }

        setLoadingAddAddress(true);
        var resultAdd: any = await dispatch(addNewBillingAddress(billingAddress));
        setLoadingAddAddress(false);

        if (resultAdd.success === false) {
            Toast.Show("error", `${resultAdd.message}`);
            return;
        }

        Toast.Show("success", `${resultAdd.message}`);

        setNovaMorada(novaMoradaInitialState);

        //Dizemos que já não existe erro (para retirar a borda vermelha)
        await dispatch(setErrorCodeCheckout(""))

        const addresses = await dispatch(getBillingAddresses());

        var numberOfAddresses = addresses.length;
        if (numberOfAddresses >= 0 && addresses[numberOfAddresses - 1]) {
            dispatch(addBillingAddressCheckout(addresses[numberOfAddresses - 1].ID_Cliente_Morada));
        }

        props.onClose();
    }

    const handleCleanAddBillingAddress = async () => {
        setNovaMorada(novaMoradaInitialState);
        props.onClose();
    };

    return (
        <Row style={{ textAlign: "center" }}>
            <div className="billingAddAddress">
                <Col xs={12} sm={12} md={12} lg={12}>
                    <Row className="billingRowTitleAddress">
                        <span className="titleOptionsAddress">
                            Nova morada de faturação
                        </span>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
                            <SearchInputComponent labelText={"Nome"}
                                onChange={(e: any) => setNovaMorada({ ...billingAddress, nome: e.target.value })}
                                initialValue={billingAddress.nome} value={billingAddress.nome}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchInputComponent labelText={"Morada"}
                                onChange={(e: any) => setNovaMorada({ ...billingAddress, morada: e.target.value })}
                                initialValue={billingAddress.morada} value={billingAddress.morada}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchInputComponent labelText={"Localidade"}
                                onChange={(e: any) => setNovaMorada({ ...billingAddress, localidade: e.target.value })}
                                value={billingAddress.localidade} initialValue={billingAddress.localidade}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchInputComponent labelText={"Código Postal"}
                                onChange={(e: any) => setNovaMorada({ ...billingAddress, codigoPostal: e.target.value })}
                                initialValue={billingAddress.codigoPostal} value={billingAddress.codigoPostal}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchListComponent labelText={"País"}
                                list={paisesOptions}
                                onChange={(e: any) => handleAddBillingChangePais(e.target.value)}
                                initialValue={billingAddress.id_pais}
                                value={billingAddress.id_pais}
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchInputComponent labelText={"NIF"}
                                onChange={(e: any) => handleAddAddressValidateNif(e.target.value)}
                                initialValue={billingAddress.nif} value={billingAddress.nif}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchListComponent labelText={"Definir como padrão"}
                                list={defaultAddressOptions}
                                onChange={(e: any) => setNovaMorada({ ...billingAddress, principal: e.target.value })}
                                initialValue={billingAddress.principal}
                                value={billingAddress.principal}
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"20px"} />

                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                <Col xl={6} lg={6} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "1rem" }}>
                                    {upline.ID_Cliente != null && typeof upline.ID_Cliente != 'undefined'
                                        ?
                                        <>
                                            <IHTPButton
                                                text='Criar morada'
                                                buttonStyle='primary'
                                                onClick={handleFormSubmit}
                                                loading={loadingAddAddress} />

                                            {typeof billingAddresses !== "undefined" && billingAddresses != null && Array.isArray(billingAddresses) && billingAddresses.length !== 0 ? (
                                                <IHTPButton
                                                    text='Cancelar'
                                                    buttonStyle='secondary'
                                                    onClick={handleCleanAddBillingAddress}
                                                    disabled={loadingAddAddress} />
                                            ) : null}
                                        </>
                                        : null}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>{" "}
            </div>
        </Row>
    )
}
