import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { addNewShippingAddress, addShippingAddressCheckout, getShippingAddresses, setErrorCodeCheckout, setShippingAddress, verifyOrder } from '../../../actions/checkoutActions';
import SearchInputComponent from '../../../helpers/SearchComponents/SearchInputComponent';
import SearchListComponent, { ListProps } from '../../../helpers/SearchComponents/SearchListComponent';
import Toast from '../../../helpers/Toast';
import { ValidateShippingAddress } from '../../../helpers/UtilsCheckout';
import { AddressDTO } from "../../../Models/AddressDTO";
import IHTPButton from '../../Common/Button';
import IHTPSpacer from '../../Common/Spacer';

const novaMoradaInitialState: AddressDTO = {
    id_cliente_morada: "",
    nome: "",
    morada: "",
    localidade: "",
    codigoPostal: "",
    principal: "N",
    id_pais: "",
    ID_Tipo_Morada: "",
    nif: ""
}

export interface CreateShippingAddressProps {
    onClose: () => void
}

export default function CreateShippingAddress(props: CreateShippingAddressProps) {

    const [loadingAddAddress, setLoadingAddAddress] = useState<boolean>(false);
    const dispatch = useDispatch();
    const upline = useSelector((state: any) => state.upline);

    //shippingAddress é usado para guardar a nova morada de envio globalmente enquanto esta não é criada
    const shippingAddress: AddressDTO = useSelector((state: any) => state.shippingAddress);
    const deliveryAddresses = useSelector((state: any) => state.deliveryAddresses);
    const allPaises = useSelector((state: any) => state.allPaises);

    useEffect(() => {
        //Colocamos os dados a limpo
        dispatch(setShippingAddress(novaMoradaInitialState));
    }, [])

    const paisesOptions: ListProps[] = useMemo(() => {
        if (typeof allPaises === "undefined" || allPaises == null) return [];

        var paises: ListProps[] = [];
        allPaises.forEach(i => {
            paises.push({
                value: i.alpha3,
                label: i.name
            });
        })
        return paises;
    }, [allPaises])

    const [defaultAddressOptions, setDefaultAddressOptions] = useState<ListProps[]>([
        { value: "N", label: "Não" },
        { value: "S", label: "Sim" }
    ]);

    const setNovaMorada = (address: AddressDTO) => {
        dispatch(setShippingAddress(address));
    }

    const handleFormSubmit = async () => {
        var resultValidation = ValidateShippingAddress(shippingAddress, dispatch);
        if (resultValidation.success === false) {
            Toast.Show("error", resultValidation.message);
            return;
        }

        setLoadingAddAddress(true);
        var resultAdd: any = await dispatch(addNewShippingAddress(shippingAddress, false));
        setLoadingAddAddress(false);

        if (resultAdd.success === false) {
            Toast.Show("error", resultAdd.message);
            return;
        }

        Toast.Show("success", resultAdd.message);

        //Dizemos que já não existe erro (para retirar a borda vermelha)
        await dispatch(setErrorCodeCheckout(""))

        const data: any = await dispatch(getShippingAddresses());
        var numberOfAddresses = data.length;
        if (numberOfAddresses >= 0 && data[numberOfAddresses - 1]) {
            dispatch(addShippingAddressCheckout(data[numberOfAddresses - 1].ID_Cliente_Morada));
        }

        //Resetamos a morada
        setNovaMorada(novaMoradaInitialState);

        setTimeout(() => {
            props.onClose();
        }, 500);
    }

    const handleCleanAddShippingAddress = () => {
        //Resetamos a morada
        setNovaMorada(novaMoradaInitialState);

        props.onClose();
    }

    useEffect(() => {
        dispatch(verifyOrder());
    }, [shippingAddress.codigoPostal, shippingAddress.id_pais])

    return (
        < Row style={{ textAlign: "center" }}>
            <div className="shippingAddAddress">
                <Col xs={12} sm={12} md={12} lg={12}>
                    <Row className="shippingRowTitleAddress">
                        <span className="titleOptionsAddress">
                            Nova morada de envio
                        </span>
                    </Row>

                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left" }}>
                            <SearchInputComponent labelText={"Nome"}
                                onChange={(e: any) => setNovaMorada({ ...shippingAddress, nome: e.target.value })}
                                initialValue={shippingAddress.nome} value={shippingAddress.nome}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchInputComponent labelText={"Morada"}
                                onChange={(e: any) => setNovaMorada({ ...shippingAddress, morada: e.target.value })}
                                initialValue={shippingAddress.morada} value={shippingAddress.morada}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchInputComponent labelText={"Localidade"}
                                onChange={(e: any) => setNovaMorada({ ...shippingAddress, localidade: e.target.value })}
                                initialValue={shippingAddress.localidade} value={shippingAddress.localidade}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchInputComponent labelText={"Código Postal"}
                                onChange={(e: any) => setNovaMorada({ ...shippingAddress, codigoPostal: e.target.value })}
                                initialValue={shippingAddress.codigoPostal} value={shippingAddress.codigoPostal}
                                inputType="text"
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchListComponent labelText={"País"}
                                list={paisesOptions}
                                onChange={(e: any) => setNovaMorada({ ...shippingAddress, id_pais: e.target.value })}
                                initialValue={shippingAddress.id_pais}
                                value={shippingAddress.id_pais}
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"10px"} />

                            <SearchListComponent labelText={"Definir como padrão"}
                                list={defaultAddressOptions}
                                onChange={(e: any) => setNovaMorada({ ...shippingAddress, principal: e.target.value })}
                                initialValue={shippingAddress.principal}
                                value={shippingAddress.principal}
                                labelClassName='searchComponent_labelStyle_NoBg_LeftAligned'
                                inputClassName='searchComponent_inputStyle_LeftAligned'
                            />

                            <IHTPSpacer verticalSpace={"20px"} />

                            <Row style={{ display: "flex", justifyContent: "center" }}>
                                <Col xl={6} lg={6} md={8} style={{ display: "flex", justifyContent: "center", flexDirection: "row", gap: "1rem" }}>
                                    {upline.ID_Cliente != null && typeof upline.ID_Cliente != 'undefined'
                                        ?
                                        <>
                                            <IHTPButton
                                                text='Criar morada'
                                                buttonStyle='primary'
                                                onClick={handleFormSubmit}
                                                loading={loadingAddAddress} />

                                            {typeof deliveryAddresses !== "undefined" && deliveryAddresses != null && Array.isArray(deliveryAddresses) && deliveryAddresses.length !== 0 ? (
                                                <IHTPButton
                                                    text='Cancelar'
                                                    buttonStyle='secondary'
                                                    onClick={handleCleanAddShippingAddress}
                                                    disabled={loadingAddAddress} />
                                            ) : null}
                                        </>
                                        : null
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Row >
    );
}