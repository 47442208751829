import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

const columns = [
  {
    Header: "Nome",
    accessor: "Descricao",
    resizable: false,
    style: { display: "block", textAlign: "center" },
    Cell: row => {
      return (
        <a
          class="fake-link"
          id="fake-link-1"
          target={"_blank"}
          href={process.env.REACT_APP_MAIN_WEBSITE_PRODUCTDETAIL_URL_PT + row.original.Id}>
          {row.original.Descricao ? row.original.Descricao : "-"}
        </a>
      );
    }
  },
  {
    Header: "Taxa Iva",
    accessor: "TaxaIva",
    resizable: false,
    style: { display: "block", textAlign: "center" },
    Cell: row => {
      return row.original.TaxaIva ? (
        <FormattedNumber
          value={row.original.TaxaIva}
          style="percent"
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      ) : (
        "-"
      );
    }
  },
  {
    Header: "Quantidade",
    accessor: "Quantidade",
    resizable: false,
    style: { display: "block", textAlign: "center" },
    Cell: row => {
      return row.original.Quantidade ? row.original.Quantidade : "-";
    }
  },
  {
    Header: "NetPoints",
    accessor: "TotalNetPoints",
    resizable: false,
    style: { display: "block", textAlign: "center" },
    Cell: row => {
      return row.original.TotalNetPoints || row.original.TotalNetPoints == 0
        ? row.original.TotalNetPoints
        : "-";
    }
  },
  {
    Header: "Créditos",
    accessor: "TotalCreditos",
    resizable: false,
    style: { display: "block", textAlign: "center" },
    Cell: row => {
      return row.original.TotalCreditos || row.original.TotalCreditos == 0 ? (
        <FormattedNumber
          value={row.original.TotalCreditos}
          style="currency"
          currency={row.original.ID_Moeda}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      ) : (
        "-"
      );
    }
  },
  {
    Header: "Preço Unitário (C/ Iva)",
    accessor: "PrecoUnitarioComIva",
    resizable: false,
    style: { display: "block", textAlign: "center" },
    Cell: row => {
      return row.original.PrecoUnitarioComIva || row.original.PrecoUnitarioComIva == 0 ? (
        <FormattedNumber
          value={row.original.PrecoUnitarioComIva}
          style="currency"
          currency={row.original.ID_Moeda}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      ) : (
        "-"
      );
    }
  },
  {
    Header: "Preço Total (C/ Iva)",
    accessor: "Preco",
    resizable: false,
    style: { display: "block", textAlign: "center" },
    Cell: row => {
      return row.original.Preco || row.original.Preco == 0 ? (
        <FormattedNumber
          value={row.original.Preco}
          style="currency"
          currency={row.original.ID_Moeda}
          minimumFractionDigits={2}
          maximumFractionDigits={2}
        />
      ) : (
        "-"
      );
    }
  }
];
class ItensEncomenda extends Component {

  render() {
    return (
      <span>
        <SimpleTable
          data={this.props.items}
          columns={columns}
          blocking={this.props.isLoading}
          rowNumber={this.props.items.length > 5 ? 10 : 5}
          dataSize={this.props.items.length}
          noDataText={"Sem itens da encomenda para apresentar"}
          title={"Itens da encomenda"}
        />
      </span>
    );
  }
}

export default ItensEncomenda;
