import { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import PageTitleAlt2 from "../../helpers/Layout/PageTitleAlt2";
import BottomClientes from "./Components/BottomClientes";
import ClientesEnc1Ano from "./Components/ClientesEnc1Ano";
import ClientesWithNoOrders from "./Components/ClientesWithNoOrders";
import ClientesWithOrders from "./Components/ClientsWithOrders";
import Faturacao12mesesRede from "./Components/Faturacao12mesesRede";
import NumeroClientesSemEncomendas from "./Components/NumeroClientesSemEncomendas";
import RegistosNaoConfirmados from "./Components/RegistosNaoConfirmados";
import TopClientes from "./Components/TopClientes";

class Pesquisa extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <PageTitleAlt2
            heading="Indicadores"
            subheading="Página onde pode ver os seus indicadores"
            icon="pe-7s-graph2 icon-gradient bg-ripe-malin"
          />
          <Row style={{ display: "flex", alignItems: "center" }}>
            <RegistosNaoConfirmados />
            <NumeroClientesSemEncomendas />
            <ClientesEnc1Ano />
          </Row>
          <Faturacao12mesesRede />
          <Row>
            <Col xs="12" sm="12" md="6" lg="4">
              <TopClientes />
            </Col>
            <Col xs="12" sm="12" md="6" lg="4">
              <BottomClientes />
            </Col>
            <Col xs="12" sm="12" md="6" lg="4">
              <ClientesWithNoOrders />
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="12" md="6" lg="4">
              <ClientesWithOrders />
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}
const mapStateToProp = state => {
  return {};
};

export default withRouter(connect(mapStateToProp)(Pesquisa));
