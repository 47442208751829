import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Joi from "@hapi/joi";
import React from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { Slide, toast } from "react-toastify";
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { editBusiness, getBusinessDetail, leadWasUpdated } from "../../../actions/negociosActions";

//Schema para Joi validar
const schema = Joi.object().keys({
  nomePessoa: Joi.string()
    .trim()
    .empty()
    .min(2)
    .max(100)
    .required()
    .messages({
      'any.required': `Nome do Contacto é obrigatório`,
      'string.empty': `Nome do Contacto deve estar preenchido`,
      'string.min': `Nome do Contacto deve ter no mínimo 2 caracteres`,
      'string.max': `Nome do Contacto deve ter no máximo 100 caracteres`
    }),
  emailPessoa: Joi.string()
    .trim()
    .required()
    .email({ minDomainSegments: 2, tlds: { allow: false } })
    .messages({
      'string.email': `Email deve ser um email válido`,
      'any.required': `Email é obrigatório`,
      'string.base': `Email deve estar preenchido`,
    }),
  telemovelPessoa: Joi.string()
    .trim()
    .empty()
    .min(2)
    .max(100)
    .required()
    .messages({
      'any.required': `Telemóvel do Contacto é obrigatório`,
      'string.empty': `Telemóvel do Contacto deve estar preenchido`,
      'string.min': `Telemóvel do Contacto deve ter no mínimo 2 caracteres`,
      'string.max': `Telemóvel do Contacto deve ter no máximo 100 caracteres`
    }),
  localidade: Joi.string()
    .trim()
    .empty()
    .min(1)
    .max(100)
    .required()
    .messages({
      'string.min': `Localidade não pode estar vazia`,
      'string.max': `Localidade não deve ter mais de 100 caracteres`,
      'any.required': `Localidade é obrigatório`,
      'string.empty': `Localidade não pode estar vazia`
    }),
  referencia: Joi.string()
    .trim()
    .empty()
    .max(10)
    .required()
    .messages({
      'string.max': `Referência não pode ter mais de 100 caracteres`,
      'any.required': `Referência é obrigatório`,
      'string.base': `Referência deve estar preenchida`,
      'string.empty': `Referência não pode estar vazia`
    }),
  valorPrevisto: Joi.number()
    .positive()
    .allow(0)
    .required()
    .messages({
      'any.required': `Valor Previsto é obrigatório`,
      'number.base': `Valor Previsto deve ser um número`,
      'number.positive': `Valor Previsto deve ser um número positivo`,
    }),
  dataPrevistaInicio: Joi.date()
    .required()
    .messages({
      'any.required': `Data Prevista Início é obrigatória`,
      'date.base': `Data Prevista Início deve ser uma data válida`
    }),
  dataPrevistaFim: Joi.date()
    .required()
    .messages({
      'any.required': `Data Prevista Fim é obrigatória`,
      'date.base': `Data Prevista Fim deve ser uma data válida`
    })
});

const initialNegocioState = {
  emailCanBeChanged: false,
  idNegocio: null,
  nomeContacto: '',
  emailContacto: '',
  telemovelContacto: '',
  localidade: '',
  referencia: '',
  valorPrevisto: '0',
  dataPrevistaInicio: new Date(),
  dataPrevistaFim: new Date(),
  obs: ''
}
class EditarNegocio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      tiposNegocio: [],
      negocio: initialNegocioState
    };

    this.validarDados = this.validarDados.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
  }

  handleChangeDate = ({ DataPrevistaFecho, DataPrevistaInicio }) => {

    DataPrevistaFecho = DataPrevistaFecho || this.state.negocio.dataPrevistaFim;
    DataPrevistaInicio = DataPrevistaInicio || this.state.negocio.dataPrevistaInicio;

    this.setState(prevState => ({
      negocio: {
        ...prevState.negocio,
        dataPrevistaInicio: DataPrevistaInicio,
        dataPrevistaFim: DataPrevistaFecho
      }
    }));
  };

  handleDataPrevistaFecho = DataPrevistaFecho =>
    this.handleChangeDate({ DataPrevistaFecho });

  handleDataPrevistaInicio = DataPrevistaInicio =>
    this.handleChangeDate({ DataPrevistaInicio });

  handleChange(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(prevState => ({
      negocio: {
        ...prevState.negocio,
        [name]: value
      }
    }));
  }

  //Formulário para validar os dados da edição de negócio
  validarDados(negocio) {

    var promise = new Promise(function (resolve, reject) {
      try {
        //Validamos os campos atraves do Joi
        var validationResult = schema.validate(
          {
            nomePessoa: negocio.nomeContacto,
            emailPessoa: negocio.emailContacto,
            telemovelPessoa: negocio.telemovelContacto,
            localidade: negocio.localidade,
            referencia: negocio.referencia,
            valorPrevisto: negocio.valorPrevisto,
            dataPrevistaInicio: negocio.dataPrevistaInicio,
            dataPrevistaFim: negocio.dataPrevistaFim,
          },
          { abortEarly: true });

        //Se error==null significa que não houve erro
        if (validationResult.error == null) {

          //Validamos se a data final é inferior a data inicial
          if (negocio.dataPrevistaFim < negocio.dataPrevistaInicio) {
            reject(new Error("Data Prevista Final deve ser superior à Data Prevista Inicial"));
            return;
          }

          resolve();

          // Se houver erro vamos mostrar o erro e mostramos ao utilizadro
        } else {
          var errorMessage = validationResult.error.details[0].message;
          reject(new Error(errorMessage));
        }
      } catch (err) {
        reject(new Error("Por favor preencha todos os campos corretamente"));
      }
    });
    return promise;
  }

  //Lidar com o click no botão de editar negocio
  handleEditButtonClick() {

    //Criamos uma cópia do objeto contacto
    let negocio = { ...this.state.negocio };

    if (typeof negocio.idNegocio === 'undefined' || negocio.idNegocio == null) {
      this.showToast("Erro ao identificar o Negócio", "error");
      return;
    }

    //Se contiver , nós substituimos
    if (negocio.valorPrevisto.indexOf(",") >= 0) {
      negocio.valorPrevisto = negocio.valorPrevisto.replace(",", ".");
    }

    var that = this;
    //Validar dados da API
    this.validarDados(negocio)
      .then(() => {

        //Chamamos o método de validar na API
        var promise = new Promise(function (resolve, reject) {

          // Adicionar o contacto a uma lead
          editBusiness(negocio).then(data => {
            resolve(data);
          })
            .catch(data => {
              reject(data)
            });
        });
        return promise;
      }).then(data => {
        if (data.success === true) {
          that.showToast(data.message, "success");

          this.props.dispatch(leadWasUpdated());
          that.props.toggle();
          that.props.updateData();
        } else {
          var message = (data.message) ? data.message : "Erro ao editar Negócio";
          that.showToast(message, "error");
        }
      })
      .catch(err => {
        var message = (typeof err.message === 'undefined') ? 'Erro ao editar Negócio' : err.message;
        that.showToast(message, "error");

      })
  }

  // Mostrar uma toast, ou seja uma messagebox no ecrã
  showToast(message, type) {
    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-right",
      type: type
    });
  }

  //Buscar as informações do negócio
  getBusiness(id_negocio) {
    getBusinessDetail(id_negocio).then(data => {

      if (data && data.Detalhes) {        
        data.Detalhes.ID_Negocio = id_negocio;
        
        var detalhes = data.Detalhes;
        this.setState(prevState => ({
          negocio: {
            ...prevState.negocio,
            idNegocio: detalhes.ID_Negocio,
            nomeContacto: detalhes.NomeContacto,
            emailContacto: detalhes.EmailContacto,
            telemovelContacto: detalhes.TelemovelContacto,
            localidade: detalhes.LocalidadeContacto,
            referencia: detalhes.Referencia,
            valorPrevisto: (detalhes.ValorPrevisto) ? detalhes.ValorPrevisto + '' : '0',
            dataPrevistaInicio: detalhes.DataPrevistaInicio,
            dataPrevistaFim: detalhes.DataPrevistaFecho,
            obs: detalhes.Observacao,
            emailCanBeChanged: (detalhes.Email) ? false : true
          }
        }));
      }
    }).catch(err => {      
      this.showToast("Erro ao obter informação sobre o Negócio", "error");
      return;
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen === false && this.props.isOpen === true) {
      this.setState(prevState => ({
        ...prevState,
        negocio: initialNegocioState
      }));
    }
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className={this.props.className} style={{ minWidth: "35%" }}>
        <ModalHeader toggle={this.props.toggle}>Editar <b>Negócio</b></ModalHeader>
        <ModalBody>

          {/* Campo Nome Contacto */}
          <FormGroup>
            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
              <Col md={3} sm={12}>
                <Label for="exampleSelect" style={{ marginBottom: "0px" }}>Nome Contacto</Label>
              </Col>
              <Col>
                <Input type="text" name="nomeContacto" id="nomeContacto" value={this.state.negocio.nomeContacto} onChange={this.handleChange} placeholder="Nome Contacto" />
              </Col>
            </Row>
          </FormGroup>

          {/* Campo Email */}
          <FormGroup>
            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
              <Col md={3} sm={12}>
                <Label for="exampleSelect" style={{ marginBottom: "0px" }}>Email Contacto</Label>
              </Col>
              <Col>
                <Input type="text" disabled={!this.state.negocio.emailCanBeChanged} name="emailContacto" onChange={this.handleChange} value={this.state.negocio.emailContacto} placeholder="Email Contacto" />
              </Col>
            </Row>
          </FormGroup>

          {/* Campo Telemóvel */}
          <FormGroup>
            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
              <Col md={3} sm={12}>
                <Label for="exampleSelect" style={{ marginBottom: "0px" }}>Telemóvel Contacto</Label>
              </Col>
              <Col>
                <Input type="text" name="telemovelContacto" id="telemovelContacto" value={this.state.negocio.telemovelContacto} onChange={this.handleChange} placeholder="Telemóvel Contacto" />
              </Col>
            </Row>
          </FormGroup>

          {/* Campo Localidade */}
          <FormGroup>
            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
              <Col md={3} sm={12}>
                <Label for="exampleSelect" style={{ marginBottom: "0px" }}>Localidade</Label>
              </Col>
              <Col>
                <Input type="text" name="localidade" id="localidade" value={this.state.negocio.localidade} onChange={this.handleChange} placeholder="Localidade" />
              </Col>
            </Row>
          </FormGroup>

          {/* Campo Referencia */}
          <FormGroup>
            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
              <Col md={3} sm={12}>
                <Label for="exampleSelect" style={{ marginBottom: "0px" }}>Referência</Label>
              </Col>
              <Col>
                <Input type="text" name="referencia" id="referencia" value={this.state.negocio.referencia} onChange={this.handleChange} placeholder="Referência" />
              </Col>
            </Row>
          </FormGroup>

          {/* Campo Valor Real */}
          <FormGroup>
            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center" }}>
              <Col md={3} sm={12}>
                <Label for="exampleSelect" style={{ marginBottom: "0px" }}>Valor Previsto</Label>
              </Col>
              <Col>
                <Input type="number" name="valorPrevisto" id="valorPrevisto" value={this.state.negocio.valorPrevisto} onChange={this.handleChange} placeholder="Valor Previsto" />
              </Col>
            </Row>
          </FormGroup>

          {/* Campo Data Inicio */}
          <FormGroup>
            <Row>
              <Col md="6">
                <Label for="examplePassword" className="mr-sm-2"> Data Prevista Inicio </Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                  </InputGroupAddon>                  
                  <DatePicker className="form-control" dateFormat="dd-MM-yyyy" name="dataPrevistaInicio" id="dataPrevistaInicio" selected={this.state.negocio.dataPrevistaInicio} onChange={this.handleDataPrevistaInicio} />
                </InputGroup>
              </Col>
              <Col md="6">
                <Label for="examplePassword" className="mr-sm-2"> Data Prevista Fim </Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </div>
                  </InputGroupAddon>
                  <DatePicker className="form-control" dateFormat="dd-MM-yyyy" name="dataPrevistaFim" id="dataPrevistaFim" selected={this.state.negocio.dataPrevistaFim} onChange={this.handleDataPrevistaFecho} />
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>



          {/* Campo Observações*/}
          <FormGroup>
            <Label for="exampleSelect">Observações</Label>
            <Input type="textarea" name="obs" id="obs" value={this.state.negocio.obs} onChange={this.handleChange} placeholder="Observações" />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={this.props.toggle}>
            Cancelar
          </Button>
          <Button color="primary" onClick={this.handleEditButtonClick}>
            Editar
          </Button>{" "}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(EditarNegocio);