import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Bounce, toast } from "react-toastify";
import { Button, Col, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from "reactstrap";
import { getUser, logout } from "../../../actions/userActions";
import city3 from "../../../assets/utils/images/dropdown-header/city3.jpg";

class UserBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false
    };
  }
  componentDidMount() {
    this.props.dispatch(getUser());
  }
  notify2 = () =>
    (this.toastId = toast(
      "You don't have any new items in your calendar for today! Go out and play!",
      {
        transition: Bounce,
        closeButton: true,
        autoClose: 5000,
        position: "bottom-right",
        type: "success"
      }
    ));

  render() {
    const { user } = this.props;
    return (
      <Fragment>
        <div className="header-btn-lg pr-0">
          <div className="widget-content p-0">
            <div className="widget-content-wrapper">
              <div className="widget-content-left">
                <UncontrolledButtonDropdown>
                  <DropdownToggle color="link" className="p-0">
                    <img
                      width={42}
                      className="rounded-circle"
                      src={
                        "https://doremon.ihavethepower.net/assets/uploads/" +
                        user.urlFoto
                      }
                      alt=""
                    />
                    <FontAwesomeIcon
                      className="ml-2 opacity-8"
                      icon={faAngleDown}
                    />
                  </DropdownToggle>
                  <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                    <div className="dropdown-menu-header">
                      <div className="dropdown-menu-header-inner bg-info">
                        <div
                          className="menu-header-image opacity-2"
                          style={{
                            backgroundImage: "url(" + city3 + ")"
                          }}
                        />
                        <div className="menu-header-content text-left">
                          <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                              <div className="widget-content-left mr-3">
                                <img
                                  width={42}
                                  className="rounded-circle"
                                  src={
                                    "https://doremon.ihavethepower.net/assets/uploads/" +
                                    user.urlFoto
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="widget-content-left">
                                <div className="widget-heading">
                                  {user.NomeCompleto}
                                </div>
                                <div className="widget-subheading opacity-8">
                                  {user.tipoCliente}
                                </div>
                              </div>
                              <div className="widget-content-right mr-2">
                                <Button
                                  onClick={() => {
                                    logout().then(data => {
                                      window.location = "/login";
                                    });
                                  }}
                                  className="btn-pill btn-shadow btn-shine"
                                  color="focus"
                                >
                                  Logout
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid-menu grid-menu-2col">
                      <Row className="no-gutters">
                        <Col sm="6">
                          <Button
                            className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                            outline
                            color="info"
                            onClick={() => {
                              window.location = "/perfil";
                            }}
                          >
                            <i className="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2">
                              {" "}
                            </i>
                            Perfil
                          </Button>
                        </Col>
                        <Col sm="6">
                          <Button
                            className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                            outline
                            color="warning"
                            onClick={() => {
                              window.location = "/";
                            }}
                          >
                            <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2">
                              {" "}
                            </i>
                            <b>Visão Geral</b>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              </div>
              <div className="widget-content-left  ml-3 header-user-info">
                <div className="widget-heading">{user.NomeCompleto}</div>
                <div className="widget-subheading">{user.tipoCliente}</div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProp = state => {
  return {
    user: state.user
  };
};
export default connect(mapStateToProp)(UserBox);
