import React, { Fragment } from "react";
import Hamburger from "react-hamburgers";
import { connect } from "react-redux";
import { setEnableClosedSidebar, setEnableMobileMenu, setEnableMobileMenuSmall } from "../../../reducers";
import MobileMenu from "./MobileMenu";

class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      mobile: false,
      activeSecondaryMenuMobile: false
    };
  }

  toggleEnableClosedSidebar = () => {
    let { enableClosedSidebar, setEnableClosedSidebar } = this.props;
    setEnableClosedSidebar(!enableClosedSidebar);
  };

  state = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false
  };

  render() {
    let { enableClosedSidebar } = this.props;
    const { } = this.state;

    return (
      <Fragment>
        <div className="app-header__logo">
          <img
            src="https://doremon.ihavethepower.net/assets/uploads/Logo_IHTP(3).png"
            width="80%"
            height="auto"
          />
          <div className="header__pane ml-auto">
            <div onClick={this.toggleEnableClosedSidebar}>
              <Hamburger
                active={enableClosedSidebar}
                type="elastic"
                onClick={() => this.setState({ active: !this.state.active })}
              />
            </div>
          </div>
        </div>
        <MobileMenu />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  enableClosedSidebar: state.enableClosedSidebar,
  enableMobileMenu: state.enableMobileMenu,
  enableMobileMenuSmall: state.enableMobileMenuSmall
});

const mapDispatchToProps = dispatch => ({
  setEnableClosedSidebar: enable => dispatch(setEnableClosedSidebar(enable)),
  setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: enable => dispatch(setEnableMobileMenuSmall(enable))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logo);
