import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BlockUi from "react-block-ui";
import { FormattedDate, FormattedNumber } from "react-intl";
import { Loader } from "react-loaders";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";

class Detalhe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: "initial",
      show: "none",
    };
  }

  columnLabel(labelText) {
    return (
      <p style={{ fontSize: "18px", textAlign: "center", backgroundColor: "rgb(224, 243, 255)", color: "#495057" }}>
        {labelText}
      </p>
    );
  }

  columnValue() {

  }


  render() {
    const details = this.props.details;
    const dataSize = this.props.dataSize;
    const isLoading = this.props.isLoading;

    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div />
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">
                      {" "}
                    </i>
                    Informações da encomenda
                  </div>
                  <div className="btn-actions-pane-right">
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="danger"
                      onClick={() => {
                        this.setState({ hidden: "none", show: "initial" });
                      }}
                      style={{ display: this.state.hidden }}
                    >
                      <i
                        className="lnr-circle-minus"
                        style={{ display: this.state.hidden }}
                        onClick={() => {
                          this.setState({
                            hidden: "none",
                            show: "initial"
                          });
                        }}
                      />
                    </Button>
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="success"
                      onClick={() => {
                        this.setState({ hidden: "initial", show: "none" });
                      }}
                      style={{ display: this.state.show }}
                    >
                      <i
                        className="lnr-plus-circle"
                        style={{ display: this.state.show }}
                        onClick={() => {
                          this.setState({
                            hidden: "initial",
                            show: "none"
                          });
                        }}
                      />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody style={{ display: this.state.hidden }}>
                  <BlockUi
                    tag="div"
                    blocking={isLoading}
                    className="block-overlay-dark"
                    loader={
                      <Loader
                        color="#ffffff"
                        active
                        type="ball-spin-fade-loader"
                      />
                    }
                  >
                    {dataSize > 0 && !isLoading ? (
                      <span>
                        {details.ID_TipoPagamento === "MB" ? (
                          <Row>
                            <Col xs="12" sm="12" md="12" lg="12" style={{ textAlign: "center" }}>
                              {this.columnLabel("Informação de Pagamento")}
                              
                              <Row>
                                <Col xs="12" sm="12" md="6" lg="6" style={{ textAlign: "right" }}>
                                  <img src="https://my.ihavethepower.net/Content/imagens/emails/multibanco.png" />
                                </Col>
                                <Col xs="12" sm="12" md="6" lg="6" style={{
                                  textAlign: "left", margin: "auto", top: "0px", bottom: "0px"
                                }}>
                                  <p style={{ fontSize: "16px", textAlign: "left" }} >
                                    <b>Entidade:</b>{" "}
                                    {details.EntidadeMB}
                                  </p>
                                  <p style={{ fontSize: "16px", textAlign: "left" }} >
                                    <b>Referência:</b>{" "}
                                    {details.ReferenciaMB}
                                  </p>
                                  <p style={{ fontSize: "16px", textAlign: "left" }} >
                                    <b>Valor:</b>{" "}
                                    <FormattedNumber
                                      value={details.PrecoFinal}
                                      style="currency"
                                      currency={details.ID_Moeda}
                                      minimumFractionDigits={2}
                                      maximumFractionDigits={2}
                                    />
                                  </p>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ) : null}

                        <Row>
                          <Col xs="12" sm="12" md="6" lg="6">
                            {this.columnLabel("ID de Encomenda")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {details.ID_Encomenda}
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="6" lg="6">
                            {this.columnLabel("Data da Encomenda")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {details.DataEncomenda ? (
                                <FormattedDate
                                  value={
                                    new Date(details.DataEncomenda)
                                  }
                                />
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs="12" sm="12" md="6" lg="6">
                            {this.columnLabel("Estado da Encomenda")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {details.EstadoEncomenda}
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="6" lg="6">
                            {this.columnLabel("Tipo de Transporte")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {details.DescricaoTipoTransporte}
                            </p>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs="12" sm="12" md="6" lg="6">
                            {this.columnLabel("Tipo de Pagamento")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {details.DescricaoTipoPagamento}
                            </p>
                          </Col>
                          <Col xs="6" sm="6" md="3" lg="3">
                            {this.columnLabel("Data de Pagamento")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {details.DataPagamento ? (
                                <FormattedDate
                                  value={
                                    new Date(details.DataPagamento)
                                  }
                                />
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                          <Col xs="6" sm="6" md="3" lg="3">
                            {this.columnLabel("Data de Envio")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {details.DataEnvio ? (
                                <FormattedDate
                                  value={new Date(details.DataEnvio)}
                                />
                              ) : (
                                "-"
                              )}
                            </p>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs="12" sm="12" md="6" lg="6">
                            {this.columnLabel("Dados de Envio")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {details.moradaEnvioMorada ? (
                                <div>
                                  <p>{details.moradaEnvioNome}</p>
                                  <p>{details.moradaEnvioMorada}</p>
                                  <p>
                                    {details.moradaEnvioCodigoPostal}{" "}
                                    {details.moradaEnvioLocalidade}
                                  </p>
                                  <p>{details.moradaEnvioPais}</p>
                                </div>
                              ) : (
                                <p>Encomenda sem morada de envio</p>
                              )}
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="6" lg="6">
                            {this.columnLabel("Dados de Faturação")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {details.moradaFaturacaoMorada ? (
                                <div>
                                  <p>{details.moradaFaturacaoNome}</p>
                                  <p>{details.moradaFaturacaoNif}</p>
                                  <p>{details.moradaFaturacaoMorada}</p>
                                  <p>
                                    {
                                      details
                                        .moradaFaturacaoCodigoPostal
                                    }{" "}
                                    {details.moradaFaturacaoLocalidade}
                                  </p>
                                  <p>{details.moradaFaturacaoPais}</p>
                                </div>
                              ) : (
                                <p>Encomenda sem morada de faturação</p>
                              )}
                            </p>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs="12" sm="12" md="6" lg="6">
                            {this.columnLabel("Descontos de Crédito")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              <FormattedNumber
                                value={details.DescontoCredito}
                                style="currency"
                                currency={details.ID_Moeda}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                              />
                            </p>

                          </Col>
                          <Col xs="12" sm="12" md="6" lg="6">
                            {this.columnLabel("Desconto Total")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              <FormattedNumber
                                value={details.DescontoTotal}
                                style="currency"
                                currency={details.ID_Moeda}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                              />
                            </p>
                          </Col>
                        </Row>
                        <br />

                        <Row>
                          <Col xs="12" sm="12" md="6" lg="6">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              Iva Total
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              <FormattedNumber
                                value={details.IvaTotal}
                                style="currency"
                                currency={details.ID_Moeda}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                              />
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="6" lg="6">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              Custo de Transporte
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              <FormattedNumber
                                value={details.CustoTransporte}
                                style="currency"
                                currency={details.ID_Moeda}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                              />
                            </p>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs="12" sm="12" md="4" lg="4">
                            {this.columnLabel("NetPoints")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {details.NetPointsPessoal
                                ? details.NetPointsPessoal
                                : "0.00"}
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="4" lg="4">
                            {this.columnLabel("Preço Total (Total dos Produtos s/IVA)")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              <FormattedNumber
                                value={
                                  details.PrecoTotal < 0
                                    ? 0.0
                                    : details.PrecoTotal
                                }
                                style="currency"
                                currency={details.ID_Moeda}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                              />
                            </p>
                          </Col>
                          <Col xs="12" sm="12" md="4" lg="4">
                            {this.columnLabel("Preço Final (Preço Total + IVA + Portes - Descontos)")}
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              <FormattedNumber
                                value={details.PrecoFinal}
                                style="currency"
                                currency={details.ID_Moeda}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                              />
                            </p>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col xs="12" sm="12" md="12" lg="12">
                            <p
                              style={{
                                fontSize: "18px",
                                textAlign: "center",
                                backgroundColor: "rgb(224, 243, 255)",
                                color: "#495057"
                              }}
                            >
                              Observações
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                textAlign: "center"
                              }}
                            >
                              {details.Observacao
                                ? details.Observacao
                                : "Não existem observações"}
                            </p>
                          </Col>
                        </Row>
                      </span>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        {!isLoading
                          ? "Não existem informações sobre a encomenda"
                          : null}
                      </div>
                    )}
                  </BlockUi>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Detalhe;
