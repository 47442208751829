
export function getPossibleEvents(eventoBase, tipoLead) {
  return new Promise((resolve, reject) => {
    return fetch("/event/getPossibleEvents", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        EventoBase: (typeof eventoBase !== 'undefined') ? eventoBase : null,
        TipoLead: (typeof tipoLead !== 'undefined') ? tipoLead : null
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getEventsByLeadType(tipoLead) {
  return new Promise((resolve, reject) => {
    return fetch("/event/getEventsByLeadType", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        TipoLead: (typeof tipoLead !== 'undefined') ? tipoLead : null
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({
          tipoLead: tipoLead,
          dados: json
        });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function getEventsByLeadTypeFiltered(tipoLead,visivelParaConsultor,estadoPreRegisto,estadoRegisto,ativo) {
  return new Promise((resolve, reject) => {
    return fetch("/event/getEventsByLeadTypeFiltered", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        TipoLead: (typeof tipoLead !== 'undefined') ? tipoLead : null,
        VisivelConsultor: (typeof visivelParaConsultor !== 'undefined') ? visivelParaConsultor : null,
        EstadoRegisto: (typeof estadoPreRegisto !== 'undefined') ? estadoPreRegisto : null,
        EstadoPreRegisto: (typeof estadoRegisto !== 'undefined') ? estadoRegisto : null,
        Ativo: (typeof ativo !== 'undefined') ? ativo : null
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve({
          tipoLead: tipoLead,
          dados: json
        });
      })
      .catch(function (error) {
        reject(error);
      });
  });
}


export function getEventById(eventId) {
  return new Promise((resolve, reject) => {
    return fetch("/event/getEventById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Evento: eventId
      }),
      credentials: "include"
    })
      .then(res => res.json())
      .then(json => {
        resolve(json);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}