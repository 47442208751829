import React, { Component } from "react";

import { connect } from "react-redux";

import { Row, Col, Container } from "react-bootstrap";

import Login from "../../../components/Login/Login";

class LoginView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Login />;
  }
}

export default LoginView;
