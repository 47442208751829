import React, { Component } from "react";
import CountUp from "react-countup";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { registosNaoConcluidos } from "../../../actions/indicadoresActions";
import { alterarDadosPesquisa } from "../../../actions/pesquisaActions";

class RegistosNaoConfirmados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numberClientes: 0
    };
  }

  componentDidMount() {
    registosNaoConcluidos().then(data => {
      if (data.clientes && !data.message) {
        this.setState({ numberClientes: data.clientes[0].numero });
      }
    });
  }
  render() {
    return (
      <Col md="4" style={{ cursor: "pointer" }}
        onClick={() => {
          alterarDadosPesquisa({ Registosnaoconcluidos: "1" });
          window.location = "/pesquisa";
        }}
      >
        <div className="card mb-3 widget-chart">
          <div className="icon-wrapper rounded-circle">
            <div className="icon-wrapper-bg bg-primary" />
            <i className="lnr-users text-primary" />
          </div>
          <div className="widget-numbers">
            <CountUp start={0} end={this.state.numberClientes} duration="3" />
          </div>
          <span className="widget-subheading">Registos da Rede não concluídos</span>
        </div>
      </Col>
    );
  }
}
const mapStateToProps = state => ({
  user: state.user,
  pesquisa: state.pesquisa
});

export default connect(mapStateToProps)(RegistosNaoConfirmados);
