import React, { Component } from "react";
import { FormattedDate, FormattedNumber } from "react-intl";
import { getDetailCommissions } from "../../../actions/comissoesActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";

var that;
class Tabela extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 5,
      dataSize: 0,
      modal: false
    };
    that = this;
  }

  getData() {
    getDetailCommissions(this.props.ano, this.props.mes).then(data => {
      if (data.comissoes && !data.message) {
        if (data.comissoes.length > 5) {
          this.setState({ rowNumber: 10 });
        }
        this.setState({ dataSize: data.comissoes.length });
        this.setState({
          data: data.comissoes
        });
      }
      this.setState({
        columns: [
          {
            Header: "Tipo Comissão",
            accessor: "tipoCom",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.tipoCom ? row.original.tipoCom : "-";
            }
          },
          {
            Header: "Data",
            accessor: "Data",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.Data ? (
                <FormattedDate value={new Date(row.original.Data)} />
              ) : (
                "-"
              );
            }
          },
          {
            Header: "ID Encomenda",
            accessor: "ID_Encomenda",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.ID_Encomenda ? (
                <span
                  class="fake-link"
                  id="fake-link-1"
                  onClick={() => {
                    window.location = "/encomenda/" + row.original.ID_Encomenda;
                  }}
                >
                  {row.original.ID_Encomenda}
                </span>
              ) : (
                "-"
              );
            }
          },
          {
            Header: "Creditos",
            accessor: "Creditos",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.Creditos ? (
                <FormattedNumber
                  value={row.original.Creditos}
                  style="number"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              ) : (
                "0.00"
              );
            }
          },
          {
            Header: "Net Points Pessoal",
            accessor: "NetPointsPessoal",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.NetPointsPessoal ? (
                <FormattedNumber
                  value={row.original.NetPointsPessoal}
                  style="number"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              ) : (
                "0.00"
              );
            }
          },
          {
            Header: "Net Points Cliente",
            accessor: "NetPointsCliente",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.NetPointsCliente ? (
                <FormattedNumber
                  value={row.original.NetPointsCliente}
                  style="number"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              ) : (
                "0.00"
              );
            }
          },
          {
            Header: "Net Points Rede",
            accessor: "NetPointsRede",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.NetPointsRede ? (
                <FormattedNumber
                  value={row.original.NetPointsRede}
                  style="number"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              ) : (
                "0.00"
              );
            }
          },
          {
            Header: "Valor Comissão",
            accessor: "Observacao",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.ValorComissao ? (
                <FormattedNumber
                  value={row.original.ValorComissao}
                  style="currency"
                  currency={row.original.ID_Moeda}
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                />
              ) : (
                "0.00"
              );
            }
          },
          {
            Header: "Observações",
            accessor: "Observacoes",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.Observacoes ? row.original.Observacoes : "-";
            }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }

  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <span>
        <SimpleTable
          data={this.state.data}
          columns={this.state.columns}
          blocking={this.state.blocking}
          rowNumber={this.state.rowNumber}
          dataSize={this.state.dataSize}
          noDataText={"Sem detalhe de comissões para mostar"}
          title={"Detalhe de comissão"}
        />
      </span>
    );
  }
}

export default Tabela;
