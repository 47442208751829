import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTable, { SortingRule } from 'react-table';
import { toast } from 'react-toastify';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { getOrdersWhereProductWhereBought, getPossibleStates } from '../../../../actions/orderActions';
import { getNameById } from '../../../../actions/productActions';
import { getContacts } from '../../../../actions/userActions';
import CardLayout from '../../../../helpers/CardLayout';
import ContactosCliente from '../../../../helpers/ContactosCliente';
import SearchInputComponent from '../../../../helpers/SearchComponents/SearchInputComponent';
import SearchListComponent, { ListProps } from '../../../../helpers/SearchComponents/SearchListComponent';
import { ConvertDateToLocalDate } from '../../../../helpers/UsefulFunctions';

interface ReactTableProps {
    currentPage: number,
    pageSize: number,
    totalPages: number,
    sortOrder: SortingRule
};

export default function PesquisarProdutosComprados() {

    const columns = [
        {
            Header: "ID Encomenda",
            accessor: "ID_Encomenda",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
                return (<Link to={"/encomenda/" + row.original.ID_Encomenda}>
                    {row.original.ID_Encomenda
                        ? row.original.ID_Encomenda
                        : "-"}
                </Link>);
            }
        },
        {
            Header: "Cliente",
            accessor: "ID_Cliente",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
                return <div>
                    <span style={{ cursor: "pointer", display: "block" }}>
                        {row.original.ID_Cliente
                            ? row.original.ID_Cliente + " - " + row.original.NomeCliente
                            : "-"}
                    </span>
                    <span style={{ cursor: "pointer", display: "block", marginTop: "7px" }} onClick={() => getUserContacts(row.original.ID_Cliente)}>
                        <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                    </span>
                </div>
            }
        },
        {
            Header: "Data Encomenda",
            accessor: "DataEncomenda",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
                return (
                    <div>
                        {ConvertDateToLocalDate(row.original.DataEncomenda)}
                    </div>
                )
            }
        },
        {
            Header: "Estado da Encomenda",
            accessor: "EstadoEncomenda",
            resizable: false,
            style: { display: "block", textAlign: "center" },
        },
        {
            Header: "Quantidade Adquirida do Produto",
            accessor: "Quantidade",
            resizable: false,
            style: { display: "block", textAlign: "center" },
        }
    ];
    //Campos de Pesquisa
    const [productId, setProductId] = useState<number | undefined>(); //ID do Produto
    const [productIdName, setProductIdName] = useState<string | undefined>(); // Nome do Produto que encontramos atraves do ID
    const [productName, setProductName] = useState<string | undefined>(); //Nome do Produto
    const [initDate, setInitDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const [networkDistance, setNetworkDistance] = useState<number | undefined>(1);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [orderState, setOrderState] = useState<string | undefined>();

    const [loading, setLoading] = useState<boolean>(false);

    const [results, setResults] = useState<[]>([]);

    const [reactTableProps, setReactTableProps] = useState<ReactTableProps>({
        pageSize: 5,
        currentPage: 0,
        totalPages: 0,
        sortOrder: { id: "ID_Encomenda", desc: true }
    });

    //Dados para o modal
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [clientContacts, setClientContacts] = useState(null);

    //Variável que vai ficar com os estados da encomenda
    const [orderStates, setOrderStates] = useState<ListProps[] | null>();

    //Vai correr a primeira vez que entramos na página
    useEffect(() => {

        loadOrderStates();
        //Aqui fazemos a limpeza se houver
        return () => {

        }
    }, []);

    const loadOrderStates = () => {
        getPossibleStates().then(result => {
            if (result.success == true) {
                var states: ListProps[] = [];
                result.obj.forEach(s => {
                    states.push({
                        value: s.ID,
                        label: s.Descricao
                    })
                })
                setOrderStates(states);
            }
        })
    }


    //Ação de carregar no botão de pesquisar
    const onSearchClick = () => {
        searchOrders(reactTableProps.pageSize, 0, reactTableProps.sortOrder);
    }

    const searchOrders = (pageSize: number, currentPage: number, sortOrder: SortingRule) => {
        setLoading(true);
        getOrdersWhereProductWhereBought(productId, productName, orderState, initDate, endDate, networkDistance, currentPage + 1, pageSize, sortOrder).then(result => {
            if (result.success === true) {
                setResults(result.obj.data);
                setReactTableProps({
                    totalPages: parseInt(result.obj.totalPages),
                    currentPage: currentPage,
                    pageSize: pageSize,
                    sortOrder: sortOrder
                })

                setTotalRecords(result.obj.totalRecords);
            } else {
                setResults([]);
                toast.dismiss();
                toast.error(result.message);
            }

            setLoading(false);
        }).catch(err => {
            toast.dismiss();
            toast.error("Erro ao pesquisar as encomendas com os parâmetros de pesquisa!");
            setLoading(false);
        })
    }

    //Método para ir buscar as informações do cliente
    const getUserContacts = (id) => {
        getContacts(id).then(data => {
            if (data && !data.message) {
                setClientContacts(data);
                toggle();
            }
        });
    }

    const toggle = () => setModalOpen(!modalOpen);

    const onFocusOut = () => {
        //Se obter do produto for efetivamente um número válido
        if (productId != null && typeof productId != 'undefined' && !isNaN(productId)) {

            //Fazemos um pedido para obter o nome do produto
            getNameById(productId).then(result => {

                //Se conseguirmos obter com sucesso
                if (result.success === true) {

                    //Colocamos o nome do produto
                    setProductIdName(result.obj);
                } else {
                    //Caso contrário mostramos a mensagem de erro
                    setProductIdName(result.message);
                }
            }).catch(err => {
                setProductIdName("");
            })

            //Se não tivermos Id de Produto preenchido, resetamos o campo
        } else {
            setProductIdName("");
        }
    }

    return (
        <CardLayout
            title="Pesquisar Produtos Comprados"
            icon="header-icon lnr-magnifier mr-3 text-muted opacity-6"
            startOpen={true}>

            <ContactosCliente //onRef={ref => (this.child = ref)}
                isOpen={modalOpen}
                toggle={toggle} contactos={clientContacts} />

            {/* Campos de Pesquisa */}
            <Row style={{ marginBottom: "30px" }}>

                {/* Coluna para apresentar o primeiro campo de pesquisa por ID*/}
                <Col md={2}>
                    <SearchInputComponent key={"productId"} onBlur={onFocusOut} inputType={"number"} labelText={"Id do Produto"} onChange={(e) => setProductId(parseInt(e.target.value))} initialValue={""} />

                    {/* Se conseguirmos encontrar o nome do produto, mostramos ao utilizador */}
                    {typeof productIdName !== 'undefined' && productIdName?.length > 0 && (
                        <span style={{ fontStyle: "italic", fontSize: "0.9em" }}>
                            {productIdName}
                        </span>
                    )}
                </Col>

                {/* Coluna para apresentar o segundo campo de pesquisa por Nome de Produto */}
                <Col md={4}>
                    <SearchInputComponent key={"productName"} inputType={"text"} labelText={"Nome do Produto"} onChange={(e) => setProductName(e.target.value)} initialValue={""} />
                </Col>

                {/* Coluna para apresentar o campo de pesquisa por Estado de Encomenda  */}
                <Col md={2}>
                    <SearchListComponent key={"orderStates"} labelBackgroundColor={"rgb(249 200 23 / 36%)"} list={orderStates} labelText={"Estado da Encomenda"} onChange={(e) => setOrderState(e.target.value)} initialValue={""} />
                </Col>

                {/* Coluna para apresentar o campo de pesquisa por Data Fim */}
                <Col md={2}>
                    <SearchInputComponent key={"initDate"} inputType={"date"} labelBackgroundColor={"rgb(249 200 23 / 36%)"} labelText={"Data Início"} onChange={(e) => setInitDate(new Date(e.target.value))} initialValue={""} />
                </Col>

                {/* Coluna para apresentar o campo de pesquisa por Data Fim */}
                <Col md={2}>
                    <SearchInputComponent key={"endDate"} inputType={"date"} labelBackgroundColor={"rgb(249 200 23 / 36%)"} labelText={"Data Fim"} onChange={(e) => setEndDate(new Date(e.target.value))} initialValue={""} />
                </Col>
            </Row>

            <Row style={{ margin: "0px", padding: "0px" }}>
                <Col md={12}>
                    <Row>
                        <Col md={12} style={{ margin: "0px", padding: "0px" }}>
                            <Label style={{ margin: "0px" }} >Insira o nível de profundidade da rede</Label>
                        </Col>
                    </Row>
                    <Row style={{ display: "flex", alignItems: "start", justifyContent: "start" }}>
                        <Col md={3} style={{ margin: "5px 7px 10px 0px", padding: "0px" }}>
                            <Input type="number" min="1" max="999" bsSize="sm" fullWidth={false} value={networkDistance} onChange={(e) => setNetworkDistance(parseInt(e.target.value))} name="profundidadeRede" placeholder={"Nível Rede"}></Input>
                        </Col>
                    </Row>
                </Col>
            </Row>


            {/* Botão para Pesquisar */}
            <Row style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", marginBottom: "30px" }}>
                <Col md={4}>
                    <Button color="primary" disabled={loading} style={{ fontSize: "18px", width: "100%" }} onClick={onSearchClick}>Pesquisar</Button>
                </Col>
            </Row>

            {/* Mostrar Resultados */}
            <Row>

                {/* Mostrar o nº de vezes que foi comprado */}
                <Col md={12} style={{ marginBottom: "20px" }}>
                    <div>Nº de Vezes adquirido: <b>{totalRecords}</b></div>
                </Col>

                {/* Mostrar a tabela */}
                <Col md={12}>
                    <ReactTable
                        className="-highlight tableReact"
                        data={results}
                        pageSizeOptions={[5, 10, 20]}
                        defaultPageSize={5}
                        showPageJump={true}
                        sortable={true}
                        page={reactTableProps.currentPage}
                        pages={reactTableProps.totalPages}
                        pageSize={reactTableProps.pageSize}
                        showPagination={true}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        loading={loading}
                        getTheadProps={(state, rowInfo, column) => {
                            return {
                                style: {
                                    "text-align": "center"
                                }
                            };
                        }}
                        getTdProps={(state, rowInfo, column, instance) => {
                            return {
                                style: {
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }
                            }
                        }}
                        getTrProps={(state, rowInfo, column, instance) => {
                            return {
                                style: {
                                    "text-align": "center",
                                }
                            };
                        }}
                        columns={columns}
                        previousText="Anterior"
                        nextText={"Próxima"}
                        pageText={"Página"}
                        ofText={"de"}
                        rowsText={"linhas"}
                        manual={true}
                        sorted={[reactTableProps.sortOrder]}
                        onPageChange={(page: number) => { searchOrders(reactTableProps.pageSize, page, reactTableProps.sortOrder); }}
                        onPageSizeChange={(newPageSize: number) => { searchOrders(newPageSize, 0, reactTableProps.sortOrder); }}
                        onSortedChange={(newSorted: SortingRule[], column, additive: boolean) => {
                            searchOrders(reactTableProps.pageSize, reactTableProps.currentPage, { id: newSorted[0].id, desc: newSorted[0].desc });
                        }}
                    />
                </Col>
            </Row>

        </CardLayout >
    )
}
