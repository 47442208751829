import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getShipmentTypes, selectShipmentType } from "../../actions/checkoutActions";
import { updateDefaultTransportType } from "../../actions/userActions";
import GoogleAnalytics from "../../helpers/googleAnalytics";
import Toast from "../../helpers/Toast";
import { HasError, SetUserDefaultValues } from "../../helpers/UtilsCheckout";
import IHTPButton from "../Common/Button";
import "./Checkout.css";

export default function FormasEnvio() {

	const [hasError, setHasError] = useState<boolean>(false);
	const [loadingChangeDefaultTransporteType, setLoadingChangeDefaultTransporteType] = useState<boolean>(false);

	const shipmentType = useSelector((state: any) => state.checkoutData.selectedShipmentType);
	const shipmentTypes = useSelector((state: any) => state.shipmentTypes);
	const errorCode = useSelector((state: any) => state.errorCode);
	const userCheckoutDefaultValues = useSelector((state: any) => state.userCheckoutDefaultValues);
	const confirmOrderClicked = useSelector((state: any) => state.checkoutData.confirmOrderClicked);
	const credits = useSelector((state: any) => state.checkoutData.credits);
	const vouchers = useSelector((state: any) => state.checkoutData.vouchers);
	const valorDescontoCP = useSelector((state: any) => state.checkoutData.ValorDescontoCP);
	const total = useSelector((state: any) => state.checkoutData.Total);
	const portes = useSelector((state: any) => state.checkoutData.Portes);
	const upline = useSelector((state: any) => state.upline);
	const cart = useSelector((state: any) => state.cart);

	const dispatch = useDispatch();

	const googleAnalytics: GoogleAnalytics = new GoogleAnalytics();

	useEffect(() => {
		dispatch(getShipmentTypes());
	}, []);

	useEffect(() => {
		let tmpHasError = HasError(errorCode, ["Transporte"]);
		if (hasError !== tmpHasError) {
			setHasError(tmpHasError);
		}
		return () => { }
	}, [errorCode])

	useEffect(() => {
		if (hasError === true) {
			let el = document.getElementById("shippmentType");
			if (el != null) el.scrollIntoView({ block: "center" });
		}
	}, [hasError])

	useEffect(() => {

		if (userCheckoutDefaultValues.transportType != null) {
			loadDefaultTransportType();
		}

	}, [userCheckoutDefaultValues.transportType, shipmentTypes])

	const loadDefaultTransportType = () => {

		if (shipmentType != null) return;

		if (shipmentTypes == null || shipmentTypes.length <= 0) return null;

		let newShipmentType = shipmentTypes.find(s => s.ID_TipoTransporte == userCheckoutDefaultValues.transportType);
		if (newShipmentType == null) return;

		const isValidShipmentType = isShipmentTypeValid(newShipmentType);
		if (isValidShipmentType == false) return;

		handleChangeShipmentType(userCheckoutDefaultValues.transportType);
	}

	const handleChangeShipmentType = async (value) => {
		var data: any = await dispatch(selectShipmentType(value));
		if (typeof data !== 'undefined') {
			Toast.Show("error", data.error);
			return;
		}
		sendOrderProductsAndShippmentTypeToGoogleAnalytics(value);
	};

	const onUpdateTransportClick = async () => {
		const transportType = shipmentType;

		setLoadingChangeDefaultTransporteType(true);
		var result = await updateDefaultTransportType(transportType);
		setLoadingChangeDefaultTransporteType(false);

		if (result.success === false) {
			Toast.Show("error", result.message);
			return;
		}

		if (result.success === true) {
			Toast.Show("success", "Método de entrega definido como padrão!");

			await SetUserDefaultValues(dispatch, transportType, userCheckoutDefaultValues.paymentType, userCheckoutDefaultValues.telephone);
		}
	}

	const isShipmentTypeValid = (shipmentType) => {
		var tmpCredits = credits ? credits : 0;
		var tmpVoucher = vouchers ? vouchers : 0;

		var tmpValorDescontoCP = valorDescontoCP ? valorDescontoCP : 0;

		var valorTotalEncomenda = total + tmpCredits + tmpVoucher + tmpValorDescontoCP;
		var valorTotalEncomendaMenosPortes = total - portes + tmpCredits + tmpVoucher + tmpValorDescontoCP;

		//Acrescentamos a validação se pais da morada atual é portugal
		if (
			(!shipmentType.ValorMinimo && !shipmentType.ValorMaximo) ||
			(!shipmentType.ValorMinimo && shipmentType.ValorMaximo >= valorTotalEncomenda) ||
			(!shipmentType.ValorMaximo && shipmentType.ValorMinimo <= valorTotalEncomendaMenosPortes) ||
			(shipmentType.ValorMinimo <= valorTotalEncomendaMenosPortes && shipmentType.ValorMaximo >= valorTotalEncomendaMenosPortes)
		) {
			return true;
		} else {
			return false;
		}
	}

	const sendOrderProductsAndShippmentTypeToGoogleAnalytics = (paymentType: any) => {
		var items = googleAnalytics.CreateProductObjectToSendToGoogleAnalytics(cart.Carrinho, cart.CarrinhoPack);
		googleAnalytics.Checkout_MeioEnvio(items, paymentType);
	}

	return (
		<div>
			<div className={hasError === true ? "checkoutBox errorBorder"
				: !confirmOrderClicked && shipmentType
					? "checkoutBox doneSelected"
					: confirmOrderClicked
						? shipmentType
							? "checkoutBox doneSelected"
							: "checkoutBox discountSelected"
						: "checkoutBox"
			}
				id="shippmentType">
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} style={{ textAlign: "left" }} className="checkoutBoxTitle">
						Método de Entrega
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12} className="shippmentType" style={{ marginTop: "1em" }}>
						<Select
							value={shipmentType ?? 0}
							onChange={(e) => handleChangeShipmentType(e.target.value)}
							fullWidth={true}
							className="SelectField"
						>
							<MenuItem key={0} value={0} disabled={true}>
								Selecione um método de entrega
							</MenuItem>
							{shipmentTypes.map((shipmentType, j) => {
								if (isShipmentTypeValid(shipmentType) === true) {
									return (
										<MenuItem key={shipmentType.ID_TipoTransporte} value={shipmentType.ID_TipoTransporte}>
											{shipmentType.Descricao}
										</MenuItem>
									);
								}
							})}
						</Select>
					</Col>
				</Row>

				{typeof shipmentType !== 'undefined' && shipmentType != null && upline.ID_Cliente != null && typeof upline.ID_Cliente != 'undefined' && (
					<Row style={{ display: "flex", justifyContent: "right", alignItems: "flex-end" }}>
						<Col xs={12} sm={12} md={6} lg={5} xl={4} style={{ display: "flex", justifyContent: "right", alignItems: "flex-end" }}>
							{shipmentType !== 0 ?
								userCheckoutDefaultValues.transportType != shipmentType ? (
									<IHTPButton
										text={"Definir como método de entrega padrão"}
										onClick={onUpdateTransportClick}
										loading={loadingChangeDefaultTransporteType}
										buttonStyle={"primary"} />
								) : (
									<span style={{
										textAlign: "right",
										fontFamily: "Montserrat", fontWeight: 600, fontSize: "13px", color: "#007bff"
									}}>Método de entrega padrão selecionado</span>
								)
								: null}
						</Col>
					</Row>
				)}
			</div>
		</div>
	);
}