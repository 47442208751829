import React, { Component } from "react";
import { Col, Label } from "reactstrap";
import './TableStatsColumn.css';

class TableStatsColumn extends Component { 
    render() {
        return (
            <Col md={4} xs={11} lg={3} className="cardStyleStats" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Label style={{ fontSize: "20px", fontWeight: "600", color: "rgb(87, 95, 217)" }}>{this.props.event.Descricao}</Label>
            </Col>
        );
    }
}

export default TableStatsColumn;
