import moment from "moment";
import "moment/locale/pt";
import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BigCalendar from "react-big-calendar";
import BlockUi from "react-block-ui";
import { Loader } from "react-loaders";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { ResponsiveContainer } from "recharts";
import { getCalendar } from "../../../actions/homeActions";
const localizer = BigCalendar.momentLocalizer(moment);

const messages = {
  allDay: "Todo o dia",
  previous: "Anterior",
  next: "Próximo",
  today: "Hoje",
  month: "Mês",
  week: "Semana",
  day: "Dia",
  agenda: "Agenda",
  date: "Data",
  time: "Hora",
  event: "Evento", // Or anything you want
  showMore: total => `+ ${total} eventos`
};

var that;
class Calendario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: true,
      hidden: "initial",
      show: "none",
      data: [],
      events: []
    };
    that = this;
  }

  getData() {
    getCalendar().then(data => {      
      if (data.data && !data.message) {

        var allDates = data.data;

        allDates.forEach(element => {
          element.start = new Date(element.start);
          element.end = new Date(element.end);
          return element;
        });
        this.setState({
          events: allDates
        });        
      }
      this.setState({ blocking: false });
    });
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <Fragment>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}
        >
          <div />
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">
                      {" "}
                    </i>
                    Calendário
                  </div>
                  <div className="btn-actions-pane-right">
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="danger"
                      onClick={() => {
                        this.setState({ hidden: "none", show: "initial" });
                      }}
                      style={{ display: this.state.hidden }}
                    >
                      <i
                        className="lnr-circle-minus"
                        style={{ display: this.state.hidden }}
                        onClick={() => {
                          this.setState({
                            hidden: "none",
                            show: "initial"
                          });
                        }}
                      />
                    </Button>
                    <Button
                      className="mr-2 btn-icon btn-icon-only"
                      outline
                      color="success"
                      onClick={() => {
                        this.setState({ hidden: "initial", show: "none" });
                      }}
                      style={{ display: this.state.show }}
                    >
                      <i
                        className="lnr-plus-circle"
                        style={{ display: this.state.show }}
                        onClick={() => {
                          this.setState({
                            hidden: "initial",
                            show: "none"
                          });
                        }}
                      />
                    </Button>
                  </div>
                </CardHeader>
                <CardBody style={{ display: this.state.hidden }}>
                  <BlockUi
                    tag="div"
                    blocking={this.state.blocking}
                    className="block-overlay-dark"
                    loader={
                      <Loader
                        color="#ffffff"
                        active
                        type="ball-spin-fade-loader"
                      />
                    }
                  >
                    {!this.state.blocking ? (
                      this.state.events[0] ? (
                        <span>
                          <Card className="main-card mb-3">
                            <CardBody>
                              <div className="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
                                <ResponsiveContainer width="100%" height="100%">
                                  <BigCalendar
                                    localizer={localizer}
                                    events={this.state.events}
                                    startAccessor="start"
                                    endAccessor="end"
                                    messages={messages}
                                    onSelectEvent={event => {
                                      window.location.href =
                                        "/negocio/" + event.idLead;
                                    }}
                                    step={10}
                                  />
                                </ResponsiveContainer>
                              </div>
                            </CardBody>
                          </Card>
                        </span>
                      ) : (
                          <div style={{ textAlign: "center" }}>
                            {!this.state.blocking ? "Não existem eventos" : null}
                          </div>
                        )
                    ) : (
                        <div style={{ textAlign: "center" }}>
                          {!this.state.blocking ? "Não existem eventos" : null}
                        </div>
                      )}
                  </BlockUi>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

export default Calendario;
