import React, { Component, Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import BlockUi from "react-block-ui";
import { FormattedDate } from "react-intl";
import { Loader } from "react-loaders";
import { connect } from "react-redux";
import renderHTML from "react-render-html";
import ReactTable from "react-table";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { occurrencesAlerts, tratarOcorrencia } from "../../../actions/ocorrenciasActions";

class Aniversarios extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0,
      contactos: [],
      expanded: {}
    };
  }

  handleRowExpanded(rowsState, index) {
    this.setState({
      expanded: {
        [index[0]]: !this.state.expanded[index[0]]
      }
    });
  }

  mudarEstadoOcorrencia = id => async e => {
    tratarOcorrencia(id).then(data => {
      occurrencesAlerts().then(data => {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      });
    });
  };

  componentDidMount() {
    occurrencesAlerts().then(data => {
      if (data.data && !data.message) {
        this.setState({ dataSize: data.data.length });
        this.setState({
          data: data.data,
          rowNumber: data.rowNumber
        });
      }
      this.setState({
        columns: [
          {
            Header: "Tipo de Ocorrência",
            accessor: "tipo",
            resizable: false,
            style: { display: "block", textAlign: "center" }
          },
          {
            Header: "Data",
            accessor: "dataHora",
            resizable: false,
            style: { display: "block", textAlign: "center" },
            Cell: row => {
              return row.original.dataHora ? (
                <FormattedDate value={new Date(row.original.dataHora)} />
              ) : (
                "-"
              );
            }
          }
        ]
      });
      this.setState({ blocking: false });
    });
  }
  render() {
    return (
      <span>
        <Fragment>
          <ReactCSSTransitionGroup
            component="div"
            transitionName="TabsAnimation"
            transitionAppear={true}
            transitionAppearTimeout={0}
            transitionEnter={false}
            transitionLeave={false}
          >
            <div />
            <Row>
              <Col md="12">
                <Card className="main-card mb-3">
                  <CardHeader className="card-header-tab">
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                      <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6">
                        {" "}
                      </i>
                      Ocorrências
                    </div>
                    <div className="btn-actions-pane-right">
                      <Button
                        className="mr-2 btn-icon btn-icon-only"
                        outline
                        color="danger"
                        onClick={() => {
                          this.setState({ hidden: "none", show: "initial" });
                        }}
                        style={{ display: this.state.hidden }}
                      >
                        <i
                          className="lnr-circle-minus"
                          style={{ display: this.state.hidden }}
                          onClick={() => {
                            this.setState({ hidden: "none", show: "initial" });
                          }}
                        />
                      </Button>
                      <Button
                        className="mr-2 btn-icon btn-icon-only"
                        outline
                        color="success"
                        onClick={() => {
                          this.setState({ hidden: "initial", show: "none" });
                        }}
                        style={{ display: this.state.show }}
                      >
                        <i
                          className="lnr-plus-circle"
                          style={{ display: this.state.show }}
                          onClick={() => {
                            this.setState({ hidden: "initial", show: "none" });
                          }}
                        />
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody style={{ display: this.state.hidden }}>
                    <BlockUi
                      tag="div"
                      blocking={this.props.blocking}
                      className="block-overlay-dark"
                      loader={
                        <Loader
                          color="#ffffff"
                          active
                          type="ball-spin-fade-loader"
                        />
                      }
                    >
                      {this.state.dataSize > 0 && !this.state.blocking ? (
                        <ReactTable
                          className="-highlight tableReact"
                          data={this.state.data}
                          expanded={this.state.expanded}
                          onExpandedChange={(newExpanded, index, event) =>
                            this.handleRowExpanded(newExpanded, index, event)
                          }
                          getTheadProps={(state, rowInfo, column) => {
                            return {
                              style: {
                                "text-align": "center"
                              }
                            };
                          }}
                          columns={this.state.columns}
                          defaultPageSize={this.state.rowNumber}
                          previousText="Anterior"
                          nextText="Próxima"
                          pageText="Página"
                          ofText="de"
                          rowsText="linhas"
                          showPagination={
                            this.state.data.length > this.state.rowNumber
                              ? true
                              : false
                          }
                          SubComponent={row => {
                            return (
                              <div
                                style={{
                                  "font-size": "14px",
                                  padding: "10px",
                                  backgroundColor: "#f8f9fa",
                                  textAlign: "center"
                                }}
                              >
                                <Row>
                                  <Col xs="12" sm="12" md="3" lg="3">
                                    Data e Hora
                                  </Col>
                                  <Col xs="12" sm="12" md="9" lg="9">
                                    <FormattedDate
                                      value={new Date(row.row.dataHora)}
                                    />
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                  <Col xs="12" sm="12" md="3" lg="3">
                                    Tipo de Ocorrência
                                  </Col>
                                  <Col xs="12" sm="12" md="9" lg="9">
                                    {row.row.tipo}
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                  <Col xs="12" sm="12" md="3" lg="3">
                                    Mensagem
                                  </Col>
                                  <Col xs="12" sm="12" md="9" lg="9">
                                    {renderHTML(row.original.mensagem)}
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                  <Col
                                    xs="12"
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    style={{ textAlign: "center" }}
                                  >
                                    <Button
                                      className="mb-2 mr-2 btn-pill"
                                      size="lg"
                                      onClick={this.mudarEstadoOcorrencia(
                                        row.original.idOcorrencia
                                      )}
                                      color={
                                        row.row.tratado === "Sim"
                                          ? "success"
                                          : "danger"
                                      }
                                    >
                                      {row.row.tratado === "Sim"
                                        ? "Tornar pendente"
                                        : "Tratar Ocorrência"}
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            );
                          }}
                        />
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          {!this.state.blocking ? this.state.noDataText : null}
                        </div>
                      )}
                    </BlockUi>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ReactCSSTransitionGroup>
        </Fragment>
      </span>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(Aniversarios);
