import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Joi from "@hapi/joi";
import React, { Component } from 'react';
import Avatar from "react-avatar";
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import { connect } from "react-redux";
import { Slide, toast } from "react-toastify";
import { Button, Col, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { editContactById, getLastContactByLead } from "../../../actions/contactosActions";
import { getTypesResults } from "../../../actions/leadsActions";

const cardHeader = {
    fontSize: "19px",
    fontWeight: "600",
    textDecoration: "underline",
    margin: "10px"
}

const cardColumnStyle = {
    fontSize: "15px",
    fontWeight: "600",
    margin: "2px 2px"
}


//Schema para Joi validar
const schema = Joi.object().keys({
    resultId: Joi.number()
        .integer()
        .min(1)
        .required()
        .messages({
            'any.required': `Resultado é obrigatório`,
            'number.base': `Resultado deve estar selecionado`,
            'number.min': `Resultado deve estar selecionado`,
        }),
    dataInicio: Joi.date()
        .required()
        .messages({
            'any.required': `Data Inicial é obrigatória`,
            'date.base': `Data Inicial deve ser uma data válida`
        }),
    dataFim: Joi.date()
        .required()
        .messages({
            'any.required': `Data Inicial é obrigatória`,
            'date.base': `Data Inicial deve ser uma data válida`
        }),
    value: Joi.number()
        .positive()
        .allow(0)
        .required()
        .messages({
            'any.required': `Valor é obrigatório`,
            'number.base': `Valor deve ser um número`,
            'number.positive': `Valor deve ser um número positivo`,
        })
});


const addContactInitialState = {
    id: null,
    dataInicio: null,
    dataFim: null,
    resultado: null,
    observacao: null,
    valor: null,
    idFase: null,
    fase: null,
    nome: null,
    contacto: null,
    estadoFinal: null,
    idEncomenda: null,
    deveTerEncomenda: false,
    tipoNegocio: null
}
class EditarUltimoContacto extends Component {

    constructor(props) {
        super(props);

        this.state = {
            results: [],
            addContact: addContactInitialState
        };

        this.showToast = this.showToast.bind(this);
        this.handleEditClick = this.handleEditClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    //Buscar resultados
    loadResults() {
        getTypesResults()
            .then(result => {
                if (result.success === true) {
                    this.setState(prevState => ({
                        ...prevState,
                        results: result.obj
                    }));
                } else {
                    this.showToast("Erro ao obter a informção do último contacto dessa Lead", "error");
                }
            })
            .catch(err => {
                this.showToast("Erro ao obter a informção do último contacto dessa Lead", "error");
            })
    }

    //Carregar a informação do contacto
    loadLastContact() {
        getLastContactByLead(this.props.leadId)
            .then(result => {
                if (result.success === true) {
                    if (result.obj.length > 0) {
                        var obj = result.obj[0];
                        this.setState(prevState => ({
                            ...prevState,
                            addContact: {
                                ...prevState.addContact,
                                dataInicio: new Date(obj.DataInicio),
                                dataFim: new Date(obj.DataFim),
                                observacao: obj.Observacao,
                                resultado: obj.Resultado,
                                idFase: obj.ID_Estado,
                                fase: obj.Descricao,
                                valor: obj.ValorPrevisto,
                                nome: obj.NomePessoa,
                                contacto: obj.ContactoPessoa,
                                id: obj.ID,
                                tipoNegocio: obj.TipoLead,
                                idEncomenda: obj.ID_Encomenda,
                                deveTerEncomenda: (obj.EstadoFinal === true)
                            }
                        }));
                    }
                } else {
                    this.showToast("Erro ao obter a informção do último contacto dessa Lead", "error");
                }
            }).catch(err => {
                this.showToast("Erro ao obter a informção do último contacto dessa Lead", "error");
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen === false && this.props.isOpen === true) {

            this.setState(prevState => ({
                ...prevState,
                results: [],
                addContact: addContactInitialState
            }))
            this.loadResults();
            this.loadLastContact();
        }
    }

    //Lidar com alterações no formulário
    handleChange(e) {
        let value = e.target.value;
        let name = e.target.name;

        this.setState(prevState => ({
            addContact: {
                ...prevState.addContact,
                [name]: value
            }
        }));
    }

    handleDataFim = DataFim => this.handleChangeDate({ DataFim });

    handleDataInicio = DataInicio => this.handleChangeDate({ DataInicio });

    handleChangeDate = ({ DataFim, DataInicio }) => {

        DataFim = DataFim || this.state.addContact.dataFim;
        DataInicio = DataInicio || this.state.addContact.dataInicio;

        this.setState(prevState => ({
            addContact: {
                ...prevState.addContact,
                dataFim: DataFim,
                dataInicio: DataInicio
            }
        }));
    };

    // Mostrar uma toast, ou seja uma messagebox no ecrã
    showToast(message, type) {
        toast.dismiss();
        toast(message, {
            transition: Slide,
            closeButton: true,
            autoClose: 5000,
            position: "bottom-right",
            type: type
        });
    }

    //Formulário para validar os dados de editar contacto
    validarDados(contact) {
        var promise = new Promise(function (resolve, reject) {
            try {
                //Validamos os campos atraves do Joi
                var validationResult = schema.validate(
                    {
                        resultId: contact.resultado,
                        dataInicio: contact.dataInicio,
                        dataFim: contact.dataFim,
                        value: contact.valor
                    },
                    { abortEarly: true });

                //Se error==null significa que não houve erro
                if (validationResult.error == null) {
                    resolve();

                    // Se houver erro vamos mostrar o erro e mostramos ao utilizadro
                } else {
                    var errorMessage = validationResult.error.details[0].message;
                    reject(new Error(errorMessage));
                }
            } catch (err) {
                reject(new Error("Por favor preencha todos os campos corretamente"));
            }
        });
        return promise;
    }

    handleEditClick() {

        //Criamos uma cópia do objeto contacto
        let contact = { ...this.state.addContact };

        var leadId = this.props.leadId;

        //Validamos se a data final é inferior a data inicial
        if (contact.dataFim < contact.dataInicio) {
            this.showToast("Data Final deve ser superior à Data Inicial", "error");
            return;
        }

        //Só deve obrigar a ter o id de encomenda se for para um negocio do tipo de consultor
        if (contact.deveTerEncomenda === true && contact.tipoNegocio === 'CS' && contact.idEncomenda == null) {
            this.showToast("Para poder editar a informação desta Fase deve colocar o Número da Encomenda", "error");
            return;
        }

        var that = this;
        this.validarDados(contact)
            .then(() => {
                //Chamamos o método de validar na API
                var promise = new Promise(function (resolve, reject) {

                    // editar o contacto a uma lead
                    editContactById(leadId, contact).then(data => {

                        //Se o contacto tiver sido editado com sucesso
                        if (data.success === true) {
                            that.showToast(data.message, "success");

                            that.props.afterUpdate(leadId, contact);

                            //Se existir algum erro ao criar o contacto
                        } else {
                            that.showToast(data.message, "error");
                        }

                        resolve(data);
                    })
                        .catch(data => {
                            reject(data)
                        });
                });
                return promise;
            })
            .catch(err => {
                var message = (typeof err.message === 'undefined') ? 'Erro ao criar contacto' : err.message;
                that.showToast(message, "error");
            })
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} style={{ minWidth: "35%" }}>

                    <ModalHeader toggle={this.props.toggle}>
                        <Label>Editar Estado de Negócio</Label>

                        {/* Campo para Mostrar o estado de negócio atual */}
                        <Row>
                            <Col>
                                <Label style={{ fontSize: "15px", margin: "2px 2px" }}>Estado Negócio: </Label>
                                <Label style={cardColumnStyle}> {this.state.addContact.fase} </Label>
                            </Col>
                        </Row>

                        {/* Campo para Mostrar Avatar e Nome */}
                        <Row>
                            <Col>
                                <Avatar
                                    name={this.state.addContact.nome}
                                    round={true}
                                    size={"35px"}
                                />
                                <Label style={cardHeader}>{this.state.addContact.nome}</Label>
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody>

                        {/*É MUITO IMPORTANTER TER ISTO PARA O GOOGLE TRADUTOR TRADUZIR OS ESTADOS DA SELECT QUE SÃO APRESENTADOS A BAIXO */}
                        {this.state.results && this.state.results.length > 0 ?
                            (this.state.results.map((result, j) => {
                                return (
                                    <span style={{ visibility: "hidden", fontSize: "1px" }}>
                                        {result.Descricao}
                                    </span>
                                );
                            })
                            ) : null}

                        {/* Data de Inicio */}
                        <FormGroup>
                            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                                    <Label style={{ margin: "0px", textAlign: "right" }}> Data Início: </Label>
                                </Col>
                                <Col lg={9} md={6} sm={6} xs={8}>

                                    <InputGroup>
                                        <DateTimePicker calendarIcon={<div className="input-group-text"> <FontAwesomeIcon icon={faCalendarAlt} /> </div>} autoComplete="off" value={this.state.addContact.dataInicio} onChange={this.handleDataInicio} />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </FormGroup>

                        {/* Data Fim */}
                        <FormGroup>
                            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                                    <Label style={{ margin: "0px", textAlign: "right" }}> Data Fim: </Label>
                                </Col>
                                <Col lg={9} md={6} sm={6} xs={8}>
                                    <InputGroup>
                                        <DateTimePicker calendarIcon={<div className="input-group-text"> <FontAwesomeIcon icon={faCalendarAlt} /> </div>} autoComplete="off" value={this.state.addContact.dataFim} onChange={this.handleDataFim} />
                                    </InputGroup>
                                </Col>
                            </Row>
                        </FormGroup>

                        {/* Resultado */}
                        <FormGroup>
                            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                                    <Label style={{ margin: "0px", textAlign: "right" }}>Resultado:</Label>
                                </Col>
                                <Col lg={9} md={6} sm={6} xs={8}>
                                    {this.state.results != null && this.state.results.length > 0 ? (
                                        <Input type="select" id="resultado" style={{ display: "inline" }} className="translate" value={this.state.addContact.resultado} name="resultado" onChange={this.handleChange}>
                                            <option value="">Resultado</option>
                                            {this.state.results && this.state.results.length > 0 ?
                                                (this.state.results.map((result, j) => {
                                                    return (
                                                        <option value={result.Id}>
                                                            {result.Descricao}
                                                        </option>
                                                    );
                                                })
                                                ) : null}
                                        </Input>
                                    ) : null}
                                </Col>
                            </Row>
                        </FormGroup>

                        {/* Valor Previsto */}
                        <FormGroup>
                            <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                                    <Label style={{ margin: "0px", textAlign: "right" }}>Valor Previsto:</Label>
                                </Col>
                                <Col lg={9} md={6} sm={6} xs={8}>
                                    <Input type="number" id="valor" min={0} name="valor" value={this.state.addContact.valor} onChange={this.handleChange}></Input>
                                </Col>
                            </Row>
                        </FormGroup>

                        {/* Observações */}
                        <FormGroup>
                            <Row style={{ display: "flex", alignContent: "center", alignItems: "start", justifyContent: "center", textAlign: "center" }}>
                                <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                                    <Label style={{ margin: "0px", textAlign: "right" }}>Observação:</Label>
                                </Col>
                                <Col lg={9} md={6} sm={6} xs={8}>
                                    <Input type="textarea" rows={4} placeholder="Observação" id="observacao" name="observacao" value={this.state.addContact.observacao} onChange={this.handleChange}></Input>
                                </Col>
                            </Row>
                        </FormGroup>

                        {/* Id de Encomenda */}
                        {this.state.addContact.deveTerEncomenda === true ?
                            <FormGroup>
                                <Row style={{ display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                    <Col lg={3} md={6} sm={6} xs={4} style={{ textAlign: "right" }}>
                                        <Label style={{ margin: "0px", textAlign: "right" }}>Encomenda:</Label>
                                    </Col>
                                    <Col lg={9} md={6} sm={6} xs={8}>
                                        <Input type="number" id="idEncomenda" name="idEncomenda" value={this.state.addContact.idEncomenda} onChange={this.handleChange}></Input>
                                    </Col>
                                </Row>
                            </FormGroup>
                            : null
                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button color="link" onClick={this.props.toggle}> Cancelar </Button>
                        <Button color="primary" onClick={this.handleEditClick}> Editar </Button>{" "}
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

export default connect(mapStateToProps)(EditarUltimoContacto);