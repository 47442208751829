import { Component, Fragment } from "react";
import MetisMenu from "react-metismenu";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MegaMenu from '../../Header/Components/MegaMenu';
import { MainNav, RedeNav } from "./NavItems";


//O que estiver aqui definido é que vai indicar se mostra ou não a opção
//Na opção com o id 1, neste caso os negócios (leads), apenas pode aceder à página se for o id de cliente 1 (Adelino) ou se o tipo de conta do user logado for "CS"
//É necessário colocar a propriedade "id" no "NavItems.js".
var linksPermissions = [
  { idLink: "leads", userIds: [1], userTypes: ['CS', 'CT'] },
  { idLink: "comparativos", userIds: [1], userTypes: ['CS', 'CT'] },
  { idLink: "posicaoConsultores", userIds: [1], userTypes: ['CS', 'CT'] }
];

class Nav extends Component {
  state = {};

  render() {
    var navBar = MainNav;
    //percorre todas as permissões
    linksPermissions.map(l => {
      //procura o item com o id da permissão
      var item = MainNav.findIndex(a => a.id === l.idLink);
      //Verificar se existe algum item com o id da permissão, verifica se o id do user está preenchido e se o user tem permissões para aceder à página,
      //e se tem o tipo de cliente preenchido e se o tipo de user tem permissões para aceder à página
      if (item > -1 && typeof this.props.user.ID_Cliente !== 'undefined' && !l.userIds.includes(this.props.user.ID_Cliente)
        && typeof this.props.user.ID_TipoCliente !== 'undefined' && !l.userTypes.includes(this.props.user.ID_TipoCliente)) {
        navBar = MainNav.splice(item, 1);
      }
    })

    return (
      <Fragment>
        {/* Se estiver em mobile mostra a opção para subir na rede */}
        {this.props.isMobile
          ?
          <div style={{ marginTop: "1em", marginBottom: "2em" }}>
            <MegaMenu />
          </div>
          : ""
        }

        <h5 className="app-sidebar__heading">Geral</h5>
        <MetisMenu
          content={navBar}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
        <h5 className="app-sidebar__heading">Rede</h5>
        <MetisMenu
          content={RedeNav}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}

const mapStateToProps = state => ({
  user: state.user,
  upline: state.upline
});

export default connect(mapStateToProps)(withRouter(Nav));
