import React, { Component } from "react";
import { connect } from "react-redux";
import { Slide, toast } from "react-toastify";
import { Label, UncontrolledTooltip } from "reactstrap";
import { updateBusinessDetailPageInfo } from "../../../actions/negociosActions";
import SimpleTable from "../../../helpers/Tables/SimpleTable";
import EditarUltimoContacto from "../../Leads/EditarUltimoContacto/EditarUltimoContacto";
import NovoEstadoNegocio from "./NovoEstadoNegocio";

class EstadosNegocio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      columns: [],
      blocking: true,
      hidden: "initial",
      show: "none",
      rowNumber: 10,
      dataSize: 0,
      modal: false,
      editModalIsOpen: false
    };

    this.openEditModal = this.openEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
    this.updatedContact = this.updatedContact.bind(this);
  }

  formatDate(value) {

    var d = new Date(value);
    var month = '' + (d.getMonth() + 1);
    var days = '' + d.getDate();
    var hours = '' + d.getHours();
    var minutes = '' + d.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (days.length < 2) days = '0' + days;
    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;

    var result = days + "-" + month + "-" + d.getFullYear() + " " + hours + ":" + minutes;
    return result;
  }

  openEditModal() {
    this.setState(prevState => ({
      ...prevState,
      editModalIsOpen: true
    }));
  }

  closeEditModal() {
    this.setState(prevState => ({
      ...prevState,
      editModalIsOpen: false
    }));
  }

  //Carregar a definição das colunas
  loadColumns() {
    this.setState({
      columns: [
        {
          Header: "Editar",
          accessor: "Editar",
          width: 70,
          resizable: false,
          style: { display: "block", textAlign: "center" },
          Cell: row => {
            return (
              <div>
                {typeof row.original.PodeEditar !== 'undefined' && row.original.PodeEditar === true ?
                  <div>
                    <Label className="lnr-pencil" id={"to2oltip" + row.original.ID_NegocioEstadoNegocio} onClick={this.openEditModal} style={{ flex: "1", fontSize: "25px", marginRight: "2px", textAlign: "right", cursor: "pointer" }} />
                    <UncontrolledTooltip placement="right" target={"to2oltip" + row.original.ID_NegocioEstadoNegocio}>
                      Editar Contacto
                    </UncontrolledTooltip >
                  </div>
                  :
                  "-"}
              </div>)
          }
        },
        {
          Header: "Estado Negócio",
          accessor: "EstadoNegocio",
          resizable: false,
          style: { display: "block", textAlign: "center" },
          Cell: row => {
            return row.original.EstadoNegocio
              ? row.original.EstadoNegocio
              : "-";
          }
        },
        {
          Header: "Data Inicio",
          accessor: "DataInicio",
          resizable: false,
          style: { display: "block", textAlign: "center" },
          Cell: row => {
            return row.original.DataInicio ? (
              this.formatDate(row.original.DataInicio)
            ) : (
              "-"
            );
          }
        },
        {
          Header: "Data Fim",
          accessor: "DataFim",
          resizable: false,
          style: { display: "block", textAlign: "center" },
          Cell: row => {
            return row.original.DataFim ? (
              this.formatDate(row.original.DataFim)
            ) : (
              "-"
            );
          }
        },
        {
          Header: "Observação",
          accessor: "Observacao",
          resizable: false,
          style: { display: "block", textAlign: "center" },
          Cell: row => {
            return row.original.Observacao
              ? row.original.Observacao
              : "-";
          }
        },
        {
          Header: "Resultado",
          accessor: "Resultado",
          resizable: false,
          style: { display: "block", textAlign: "center" },
          Cell: row => {
            return row.original.Resultado
              ? row.original.Resultado
              : "-";
          }
        }
      ]
    });
  }

  //Quando o componente carregar
  componentDidMount() {
    this.loadColumns();
  }

  //Método responsável por lidar com os dados depois de um contacto ser alterado
  updatedContact() {
    this.props.dispatch(updateBusinessDetailPageInfo(this.props.id, false))
      .then(result => {
        if (!result) {
          this.showToast("Erro ao carregar a informação do Negócio", "error");
        }
      })
      .catch(err => {
        this.showToast("Erro ao carregar a informação do Negócio", "error");
      });
    this.closeEditModal();
  }

  // Mostrar uma toast, ou seja uma messagebox no ecrã
  showToast(message, type) {
    toast.dismiss();
    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: 5000,
      position: "bottom-right",
      type: type
    });
  }
  
  render() {
    var dataSize = this.props.estados.length;
    return (
      <span>

        <EditarUltimoContacto leadId={this.props.id} toggle={this.closeEditModal} isOpen={this.state.editModalIsOpen} afterUpdate={this.updatedContact} />

        <NovoEstadoNegocio
          isOpen={this.state.modal}
          toggle={this.toggle}
          id={this.props.id}
        />
        <SimpleTable
          data={this.props.estados}
          columns={this.state.columns}
          blocking={this.props.loading}
          rowNumber={this.state.rowNumber}
          dataSize={dataSize}
          noDataText={"Sem estados de negócio para apresentar"}
          title={"Estados do negócio"}
        />
      </span>
    );
  }
}

const mapStateToProps = state => {
  return {
    negocioAtualizado: state.negocioAtualizado,
    estados: state.businessDetailPageStates,
    loading: state.businessDetailLoading
  };
};

export default connect(mapStateToProps)(EstadosNegocio);
